import * as angular from 'angular';
import * as _ from 'lodash';

export class KeywordFilterService {
    constructor() {}

    public formatYearsFromQueryString(syears : string[]) : number[] {
        const cutoffYear = 45;
        return _.map(_.filter(syears, y => y.length !== 3),
            (y) => {
                if (y.length === 2) {
                    if (Number(y) > cutoffYear) {
                        return Number('19' + y);
                    } else if (Number(y) <= cutoffYear) {
                        return Number('20' + y);
                    }
                } else {
                    return Number(y);
                }
            });
    }

    public formatQueryFilterWithYears(nyears : number[], syears : string[]) : string {
        let filter : string = '';
        if (nyears.length === 1) {
            filter += ` and (yearFrom eq ${nyears[0]} or yearTo eq ${nyears[0]}`;
            filter += this.addAdditionalYearsCondition(syears);
        } else if (nyears.length === 2) {
            filter += ` and ((yearFrom ge ${nyears[0]} and yearFrom lt ${nyears[1]})` +
                ` or (yearTo ge ${nyears[0]} and yearTo lt ${nyears[1]})`;
            filter += this.addAdditionalYearsCondition(syears);
        } else if (nyears.length > 2) {
            filter += ` and ((yearFrom ge ${nyears[0]} and yearFrom lt ${nyears[1]})` +
                ` or (yearTo ge ${nyears[0]} and yearTo lt ${nyears[1]})`;
            for (let i = 2; i < nyears.length; i++) {
                filter += ` or (yearFrom eq ${nyears[i]} or yearTo eq ${nyears[i]})`;
                filter += this.addAdditionalYearsCondition(syears);
            }
        }
        return filter;
    }

    public formatQueryFilterWithKeywords(keyword : string, columns : string[]) : string {
        let filter : string = '';

        // split the whole keyword on separate words, choosing only those which are alphanumeric
        let keywords = keyword.split(' ').filter((k) => k.match(/[a-zA-z0-9]/g));

        _.each(keywords,
            (_keyword, i) => {
                filter += ` and (contains(`;
                filter += columns.join(`,'${_keyword}') or contains(`);
                filter += `,'${_keyword}'))`;
            });
        return filter;
    }

    public addAdditionalYearsCondition(syears : string[]) : string {
        let filter : string = '';
        _.each(syears,
            (y, i) => {
                filter += ` or (contains(partsTitle, '${y}')` +
                    ` or contains(additionalInformation, '${y}')` +
                    ` or contains(makeName, '${y}')` +
                    ` or contains(modelName, '${y}'))`;
            });
        if (syears.length !== 0) {
            filter += `)`;
        }
        return filter;
    }

    public getStrYears(keyword : string) : string[] {
        const regex = /(\d{2,4})/g;
        let strYears : string[] = keyword.match(regex);

        // it's definitely not a year, if contains 3 digits
        strYears = _.filter(strYears, y => y.length !== 3);

        return strYears;
    }

    public getKeywordFilter(keyword : string, columns : string[], searchType : string) : string {
        let filter : string = '';
        let numYears : number[] = null;
        let keywords : string[] = null;
        let _keyword = keyword;
        let strYears : string[] = this.getStrYears(keyword);

        if (strYears && strYears.length > 0) {
            numYears = this.formatYearsFromQueryString(strYears);
            if (searchType === 'PW') {
                filter += ` and year eq ${numYears[0]}`;
            } else {
                filter += this.formatQueryFilterWithYears(numYears, strYears);
            }
        }
        _.each(strYears, (y) => { _keyword = _keyword.replace(y, ''); });
        filter += this.formatQueryFilterWithKeywords(_keyword, columns);
        return filter;
    }
}

angular.module('Row52.Services.KeywordFilterService', [])
    .service('KeywordFilterService', KeywordFilterService);