import * as angular from 'angular';

interface ILargerThanScope extends angular.IScope {
    largerThan : number;
}

class LargerThanDirective implements angular.IDirective {
    public restrict : string                   = 'A';
    public scope : { [key : string] : string } = {
        'largerThan' : '='
    };
    public require : string                    = 'ngModel';

    public link : (scope : ILargerThanScope,
                   element : ng.IAugmentedJQuery,
                   attrs : ng.IAttributes,
                   ctrl : ng.INgModelController) => void;

    constructor() {
        this.link = (scope : ILargerThanScope,
                     element : ng.IAugmentedJQuery,
                     attrs : ng.IAttributes,
                     ctrl : ng.INgModelController) => {
            ctrl.$validators.largerThan = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue) || !scope.largerThan) {
                    // consider empty models to be valid
                    return true;
                }
                return scope.largerThan <= viewValue;
            }
        };
    }

    public static Factory() {
        let directive = () => {
            return new LargerThanDirective();
        };

        return directive;
    }
}

angular
    .module('Row52.Directives')
    .directive('largerThan', LargerThanDirective.Factory());