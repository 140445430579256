import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService }                from '../../../../services/odata/user.service';
import './personal-info.html';
import './personal-info.scss';

class SettingsPersonalInfoCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService'
    ];

    public personalInfo : angular.IFormController;

    constructor(protected $scope : ISettingsPersonalInfoScope,
                protected $window : angular.IWindowService,
                private userService : UserService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
    }



    async updateUser($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.personalInfo.$valid) {
            this.$scope.alertInfo = [];
            this.$scope.loading   = true;

            try {
                await this.userService.updateUserData(this.$scope.user.id,
                                                      this.$scope.user.email,
                                                      this.$scope.user.firstName,
                                                      this.$scope.user.lastName);
                this.$scope.alertInfo.push({
                                               msg  : 'Your changes have been saved.',
                                               type : 'success'
                                           });
            }
            catch (err) {
                switch (err.status) {
                    case 401:
                        this.$scope.alertInfo.push({
                                                       msg  : 'You are not authorized to take this action.',
                                                       type : 'danger'
                                                   });
                        break;
                    case 404:
                        this.$scope.alertInfo.push({
                                                       msg  : 'We were unable to locate the user you were '
                                                              + 'attempting to modify.',
                                                       type : 'danger'
                                                   });
                        break;
                    default:
                        this.$scope.alertInfo.push({
                                                       msg  : err.data.error.message,
                                                       type : 'danger'
                                                   });
                        break;
                }
            }

            this.$scope.loading = false;
        }
    }
}

interface ISettingsPersonalInfoScope extends IBaseScope {
    alertInfo : {
        msg : string;
        type : 'danger' | 'success'
    }[]
    loading : boolean;
}

angular.module('Row52.Views.Account.Settings.SettingsPersonalInfo', [])
       .controller('SettingsPersonalInfoCtrl', SettingsPersonalInfoCtrl);