import * as angular from 'angular';
import { IQuoteModel, IQuoteUserModel, IQuoteImageModel } from 'Row52.Models';
import { ICountry, IPartsWantedQuote, IState } from 'Row52.Models.Entities';
import { PartWantedQutoteStatuses } from '../../../../constants/statuses';
import { BaseController, IBaseScope } from '../../../../base-controller';
import { MessagesService } from '../../../../services/messages.service';
import { ShippingVendorsService, IShippingVendor } from '../../../../services/data/shipping-vendors.service';
import { PartsWantedQuoteService } from '../../../../services/odata/parts-wanted-quote.service';
import { StateService } from '../../../../services/odata/state.service';
import { ImageServiceModal } from '../../../../services/images/image.service';
import { QuoteEvents } from '../../../../constants/events';
import {
    IStripeChargeFailure,
    IStripeTokenResponse,
    IStripeCardToken
    } from '../../../../services/marketplace/stripe.service';
import './incoming-quote-detail.html';
import './incoming-quote-detail.scss';
import { AddressCompletionService, IAddress } from '../../../../services/helpers/address.completion.service';
import { CountryService } from '../../../../services/odata/country.service';
import * as _ from 'lodash';
import { IIncomingQuotesScope } from '../incoming-quote-list/incoming-quote-list';
import { RemoteErrorModalService } from '../../../../services/ui/remoteerror.modal';
import '../../../../templates/partials/sales-tax-tooltip.html';

class IncomingQuoteCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window',
        'MessagesService',
        'PartsWantedQuoteService',
        'stripeKey',
        'StateService',
        'ImageServiceModal',
        'AddressCompletionService',
        'ShippingVendorsService',
        'CountryService',
        '$q',
        'remoteErrorModalService'
    ];

    private stripe : any;
    private elements : any;
    private card : any;

    constructor(protected $scope : IIncomingQuoteScope,
        protected $window : angular.IWindowService,
        private messages : MessagesService,
        private pwqService : PartsWantedQuoteService,
        private stripeKey : string,
        private stateService : StateService,
        protected imageModalService : ImageServiceModal,
        private addressService : AddressCompletionService,
        private shippingVendorsService : ShippingVendorsService,
        private countryService : CountryService,
        private $q : angular.IQService,
        private remoteErrorService : RemoteErrorModalService) {
        super();

        this.$scope.autoCompleteOptions = {
            types : ['address']
        };

        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        await this.initDropdowns();
        this.initAddress();
        this.initEvents();
    }

    private initModels() {
        this.$scope.quote = null;
        this.$scope.$on(QuoteEvents.UpdateActiveQuote, this.loadQuote);
        this.$scope.quoteStatuses = PartWantedQutoteStatuses;
        this.$scope.shippingVendors = this.shippingVendorsService.getAll();
        this.stripe = Stripe(this.stripeKey);
        this.$scope.dropdowns = {
            selectedCountry : null,
            selectedState : null
        };
        this.$scope.declineLoading = false;
        this.$scope.submitPaymentLoading = false;
        this.$scope.stripeValidationMessage = null;
        this.$scope.payment = angular.extend({},
            { firstName : this.$window.user.firstName, lastName : this.$window.user.lastName },
            this.addressService.emptyAddress());
        if ((this.$scope.$parent as IIncomingQuotesScope).incomingQuote) {
            this.$scope.quote = (this.$scope.$parent as IIncomingQuotesScope).incomingQuote;
        }
    }

    private initEvents() {
        this.$scope.$on(QuoteEvents.UpdateActiveQuote,
            ($event : angular.IAngularEvent, quote : IQuoteModel) => {
                $event.preventDefault();
                console.log(quote);
                this.$scope.quote = quote;
            });

        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
            this.addressService.createHandler(this.$scope.payment,
                this.$scope.states,
                this.$scope.countries,
                this.$scope.dropdowns));
    }

    private async initDropdowns() {
        try {
            await this.$q.all([
                (async () => {
                    this.$scope.states = await this.stateService.getAll();
                })(),
                (async () => {
                    this.$scope.countries = await this.countryService.getSorted();
                })()
            ]);
        } catch (err) {

        }
    }

    private initAddress = () => {
        let address : IAddress = {
            address1 : this.$scope.user.address1,
            city : this.$scope.user.city,
            zipCode : this.$scope.user.zipCode,
            latitude : this.$scope.user.latitude,
            longitude : this.$scope.user.longitude,
            stateId : this.$scope.user.stateId,
            provinceName : this.$scope.user.provinceName,
            countryId : this.$scope.user.countryId,
            isInexactAddress : true
        };

        if (this.addressService.validate(address)) {
            this.$scope.payment.address1 = this.$scope.user.address1;
            this.$scope.payment.address2 = this.$scope.user.address2;
            this.$scope.payment.city = this.$scope.user.city;
            this.$scope.payment.stateId = this.$scope.user.stateId;
            this.$scope.payment.postalCode = this.$scope.user.zipCode;
            this.$scope.payment.countryId = this.$scope.user.countryId;
            this.$scope.payment.latitude = this.$scope.user.latitude;
            this.$scope.payment.longitude = this.$scope.user.longitude;

            this.$scope.dropdowns.selectedCountry =
                _.find(this.$scope.countries, { 'id' : this.$scope.payment.countryId });
            this.$scope.dropdowns.selectedState = _.find(this.$scope.states, { 'id' : this.$scope.payment.stateId });
        } else {
            this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries, { 'id' : 234 });
        }
    };

    public loadQuote = ($event : angular.IAngularEvent, quote : IQuoteModel) => {
        this.$scope.paymentMode = false;
        this.$scope.quote = quote;
    };

    public acceptQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        let style = {
            base : {
                color : '#303238',
                fontSize : '16px',
                lineHeight : '32px',
                fontWeight : '600',
                fontSmoothing : 'antialiased',
                '::placeholder' : {
                    color : '#ccc'
                }
            },
            empty : {
                color : '#e542f4'
            },
            invalid : {
                color : '#e5424d',
                ':focus' : {
                    color : '#303238'
                }
            }
        };

        this.$scope.paymentMode = true;
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card', { style : style });
        this.card.mount('#card-element');

        this.card.addEventListener('change',
            (event) => {
                this.$scope.stripeValidationMessage = event.error ? event.error.message : null;
                this.$scope.$digest();
            });
    };

    public async declineQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$scope.declineLoading = true;

        try {
            let updatedQuote = await this.pwqService.decline(quote);
            this.$scope.$emit(QuoteEvents.UpdatedIncomingQuote, quote);
            this.$scope.paymentMode = false;
        } catch (err) {
// TODO : Add error handling display
        }

        this.$scope.declineLoading = false;
    };

    openLink($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/partswanted/detail/${this.$scope.quote.listing.id}`;
    }

    async submitPayment($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        if (this.$scope.ccForm.$valid) {
            this.$scope.submitPaymentLoading = true;

            try {
                let tokenResult = await this.stripe.createToken(this.card, this.getStripeModel());

                if (tokenResult.token.type === 'card') {
                    await this.processPayment(quote, tokenResult);

                    this.$scope.submitPaymentLoading = false;
                } else {
                    // TODO : Figure out this scenario
                }
            } catch (err) {
                this.$scope.submitPaymentLoading = false;
            }
        }
    }

    private async processPayment(quote : IQuoteModel, tokenResult : any) {
        try {
            let apiResult = await this.pwqService.accept(this.$scope.quote, this.getApiModel(tokenResult));
            if (IncomingQuoteCtrl.isPartsWantedQuote(apiResult)) {
                this.$scope.$emit(QuoteEvents.UpdatedIncomingQuote, quote);
                await this.remoteErrorService
                    .open('Success!',
                        'You have successfully accepted this quote.',
                        { okBtnText : 'OK' });
            } else if (IncomingQuoteCtrl.isStripeError(apiResult)) {
                console.log(apiResult.code);
                console.log(apiResult.message);
                await this.remoteErrorService
                    .open('Attention',
                        apiResult.message,
                        { okBtnText : 'OK' });
            } else {
                console.log(apiResult);
            }

            this.$scope.paymentMode = false;
        } catch (err) {
            let msg = err.data ? err.data.error.message : err.message ? err.message : 'Something has gone wrong';
            await this.remoteErrorService
                .open('Attention',
                    msg,
                    { okBtnText : 'OK' });
        }
    }

    protected getStripeModel() {
        return {
            'name' : `${this.$scope.payment.firstName} ${this.$scope.payment.lastName}`,
            'address_line1' : this.$scope.payment.address1,
            'address_line2' : this.$scope.payment.address2 || '',
            'address_city' : this.$scope.payment.city,
            'address_zip' : this.$scope.dropdowns.selectedState.name,
            'address_country' : 'US'
        };
    }

    protected getApiModel(tokenResult : any) {
        return {
            token : tokenResult.token.id,
            billingCardBrand : tokenResult.token.card.brand,
            firstName : this.$scope.payment.firstName,
            lastName : this.$scope.payment.lastName,
            address1 : this.$scope.payment.address1,
            address2 : this.$scope.payment.address2,
            city : this.$scope.payment.city,
            stateId : this.$scope.dropdowns.selectedState.id,
            countryId : this.$scope.dropdowns.selectedCountry.id,
            postalCode : this.$scope.payment.postalCode,
            provinceName : this.$scope.payment.provinceName
        };
    }

    openMessage($event : angular.IAngularEvent, seller : IQuoteUserModel, subject : string) {
        $event.preventDefault();

        this.messages.open(seller.userName, subject);
    }

    displayImage($event : angular.IAngularEvent, image : IQuoteImageModel) {
        $event.preventDefault();

        let url = image.resourceUrl + image.size1 + image.extension;
        this.imageModalService.open(url);
    }

    getShippingVendor() {
        const id = this.$scope.quote.shippingVendorId;
        if (id && id !== 99) {
            return this.shippingVendorsService.get(id);
        } else if (id === 99) {
            return this.$scope.quote.shippingVendorOther;
        } else {
            return 'N/A';
        }
    }

    private static isPartsWantedQuote(x : any) : x is IPartsWantedQuote {
        return x.id !== undefined && x.id !== null;
    }

    private static isStripeError(x : any) : x is IStripeChargeFailure {
        return x.code !== undefined && x.code !== null;
    }
}

interface IIncomingQuoteScope extends IBaseScope {
    activeQuoteId : number;
    quote : IQuoteModel;
    quoteStatuses : PartWantedQutoteStatuses;
    shippingVendors : IShippingVendor[];
    payment : {
        firstName : string;
        lastName : string;
        address1 : string;
        address2 : string;
        city : string;
        postalCode : string;
        latitude : number;
        longitude : number;
        provinceName : string;
        countryId : number;
        stateId : number;
        isInexactAddress : true;
    };
    paymentMode : boolean;
    states : IState[];
    countries : ICountry[];
    dropdowns : any;
    ccForm : angular.IFormController;
    declineLoading : boolean;
    autoCompleteOptions : any;
    submitPaymentLoading : boolean;
    stripeValidationMessage : string;
}

angular.module('Row52.Views.Account.Quote.IncomingQuoteDetail', [])
    .controller('IncomingQuoteCtrl', IncomingQuoteCtrl);