export class QuoteEvents {
    public static LoadOutgoingQuotes : string = 'loadedOutgoingQuotes';
    public static LoadIncomingQuotes : string = 'loadedIncomingQuotes';

    public static UpdateActiveQuote : string    = 'updatedActiveQuote';
    public static UpdatedOutgoingQuote : string = 'updatedOutgoingQuote';
    public static UpdatedIncomingQuote : string = 'updatedIncomingQuote';

    public static SortOutgoingQuotes : string = 'sortOutgoingQuotes';
    public static CloseQuote : string         = 'closeQuote';
    public static EditQuote : string          = 'editQuote';
}

export class GoogleEvents {
    public static AutoCompleteSelect : string = 'g-places-autocomplete:select';
}