import './mobile-number.html';
import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService }                from '../../../../services/odata/user.service';

export class MobileNumberCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService'
    ];

    public static MobileNumberSuccess = 'MobileNumberSuccess';
    public mobilePhone;

    private static PhoneRegExp : RegExp = /^\+?1?[-. ]?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;

    constructor(protected $scope : IMobileNumberScope,
                protected $window : angular.IWindowService,
                private userService : UserService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        
        if (this.$scope.user.mobilePhone) {
            this.$scope.user.mobilePhone = this.$scope.user.mobilePhone.replace(MobileNumberCtrl.PhoneRegExp, '$1$2$3');
        }

        this.mobilePhone = this.$scope.user.mobilePhone;

        this.$scope.alerts = [];
    }

    verifyViaSms = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();
        this.$scope.alerts = [];
        this.$scope.user.mobilePhone = this.mobilePhone;

        if (this.$scope.newMobileForm.$valid
            && this.validatePhone(this.$scope.user.mobilePhone)) {

            this.$scope.newMobileFormLoading = true;

            try {
                await this.userService.updateMobile(this.$scope.user);
                this.$scope.$emit(MobileNumberCtrl.MobileNumberSuccess, this.$scope.user.mobilePhone);
            }
            catch (result) {
                switch (result.status) {
                    case 409:
                        this.$scope.alerts.push({ type : 'danger', msg : 'This number is already in use.' });
                        break;
                }
            }

            this.$scope.newMobileFormLoading = false;
        }
    };

    private validatePhone(phoneNumber : string) {
        if (MobileNumberCtrl.PhoneRegExp.test(phoneNumber)) {
            return phoneNumber.replace(MobileNumberCtrl.PhoneRegExp, '$1$2$3');
        }
        else {
            return '';
        }
    }
    
    cancelChange($event : angular.IAngularEvent) {
        $event.preventDefault();
        
        this.$scope.user.mobileStatus = 'confirm';
    }
}

interface IMobileNumberScope extends IBaseScope {
    newMobileForm : angular.IFormController;
    newMobileFormLoading : boolean;
    alerts : {
        type : 'danger' | 'success';
        msg : string;
    }[]
}

angular.module('Row52.Views.Account.MobileRegistration.MobileNumber', [
           'ui.mask',
           'Row52.Services.UserService'
       ])
       .controller('MobileNumberCtrl', MobileNumberCtrl);