import { MobileVerificationCtrl }     from './mobile-verification/mobile-verification';

import * as angular                  from 'angular';
import { BaseController, IBaseScope } from '../../../base-controller';
import './mobile-registration.html';
import './mobile-registration.scss';
import { MobileNumberCtrl }           from './mobile-number/mobile-number';

export class MobileRegistrationCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : IMobileVerificationScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.alerts = [];

        this.$scope.$on(MobileNumberCtrl.MobileNumberSuccess,
                        ($event : angular.IAngularEvent, mobilePhone : string) => {
                            this.$scope.user.mobileStatus = 'confirm';
                            this.$scope.user.mobilePhone  = mobilePhone;
                        });

        this.$scope.$on(MobileVerificationCtrl.MobileVerificationSuccess,
                        ($event : angular.IAngularEvent) => {
                            this.$scope.user.mobileStatus = 'activated';
                        });
    }
}

export interface IMobileVerificationScope extends IBaseScope {
    alerts : {
        type : string;
        msg : string;
    }[];
}

angular.module('Row52.Views.Account.MobileRegistrationView', [])
       .controller('MobileRegistrationCtrl', MobileRegistrationCtrl);