import * as angular                   from 'angular';
import { IUserJsonModel }             from 'Row52.Models';
import { IPartsWantedListing }        from 'Row52.Models.Entities';
import '../../../css/parts-wanted/detail.scss';
import { BaseController }             from '../../base-controller';
import { PartsWantedStatusService }   from '../../services/data/parts-wanted-status.service';
import { ImageServiceModal }          from '../../services/images/image.service';
import { MessagesService }            from '../../services/messages.service';
import { PartsWantedFavoriteService } from '../../services/odata/parts-wanted-favorite.service';
import { PartsWantedListingService }  from '../../services/odata/parts-wanted-listing.service';
import { ConfirmModalService }        from '../../services/ui/confirm.modal';
import '../../templates/partswantedlisting/details.html';
import '../../templates/partswantedlisting/listing-status-template.html';
import '../../templates/partswantedlisting/mark-spam-template.html';
import '../../templates/partswantedlisting/publish-modal.html';
import '../../templates/partswantedlisting/wtp-template.html';

interface IPartsWantedListingScope extends angular.IScope {
    listing : IPartsWantedListing;
    vehicleCount : any;
    user : IUserJsonModel;
    isUserLike : boolean;
    isUserOwner : boolean;
    loading : boolean;
    published : boolean;
    vehicleCountLoading : boolean;
}

class PartsWantedListingDetailCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$uibModal',
        '$window',
        'confirmModalService',
        'PartsWantedListingService',
        'PartsWantedStatusService',
        '$rootScope',
        'MessagesService',
        'PartsWantedFavoriteService',
        'ImageServiceModal'
    ];

    constructor(protected $scope : IPartsWantedListingScope,
                private $modal : angular.ui.bootstrap.IModalService,
                protected $window : angular.IWindowService,
                private confirmModalService : ConfirmModalService,
                private pwService : PartsWantedListingService,
                public pwStatusService : PartsWantedStatusService,
                private $rootScope : angular.IRootScopeService,
                public messageService : MessagesService,
                private pwfService : PartsWantedFavoriteService,
                public imageModal : ImageServiceModal) {
        super();
        // noinspection JSIgnoredPromiseFromCall
        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.vehicleCountLoading = true;
        this.$scope.listing             = this.$window[ 'listing' ];
        this.$scope.isUserLike          = false;
        this.$scope.isUserOwner         = this.$scope.listing.userId === this.$scope.user.id;
        this.$scope.loading             = false;
        this.$scope.published           = false;

        // noinspection JSIgnoredPromiseFromCall
        this.loadLike();
        // noinspection JSIgnoredPromiseFromCall
        this.loadRelatedVehicles();
    }

    private loadLike = async () => {
        if (this.$scope.user.isAuthenticated) {
            let favorites = await this.pwfService.getForListing(this.$scope.listing.id, this.$scope.user.id);
            if (favorites.length > 0) {
                this.$scope.isUserLike = true;
            }
        }
    };

    private loadRelatedVehicles = async () => {
        this.$scope.vehicleCount        = await this.pwService.vehicleCount(this.$scope.listing);
        this.$scope.vehicleCountLoading = false;
    };

    editListing() {
        this.$window.location.href = `/PartsWanted/edit/${ this.$scope.listing.id }`;
    }

    async publish() {
        this.$scope.loading = true;

        try {
            await this.pwService.publish(this.$scope.listing);
            this.$scope.published = true;
            this.$scope.loading   = false;
            let scope             = this.$rootScope.$new() as IPartsWantedPublishModalScope;
            scope.listing         = this.$scope.listing;
            // noinspection SpellCheckingInspection
            this.$modal.open({
                                 scope       : scope,
                                 templateUrl : 'partswantedlisting/publish-modal.html',
                                 size        : 'md',
                                 controller  : 'PartsWantedPublishModalCtrl as mctrl'
                             }).result.catch(function (res) {
                if (res === 'backdrop click') {
                    parent.window.location.href = `/PartsWanted/detail/${ scope.listing.id }`;
                }
            });
        }
        catch (err) {

        }
    }

    async like($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (!this.$scope.isUserLike) {
            try {
                await this.pwfService.createFavorite(this.$scope.listing.id, this.$scope.user.id);
                this.$scope.isUserLike = true;
            }
            catch (err) {
                // TODO: Add error handling
            }
        }
        else {
            try {
                await this.pwfService.deleteAllForListing(this.$scope.listing.id, this.$scope.user.id);
                this.$scope.isUserLike = false;
            }
            catch (err) {
                // TODO: Add error handling
            }
        }
    }

    edit($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/PartsWanted/Edit/${ this.$scope.listing.id }`;
    }

    markSpam = async ($event : angular.IAngularEvent) => {
        try {
            await this.confirmModalService
                      .open('Inappropriate listing',
                            'Does this listing have inappropriate material or incorrect information? ' +
                            'If so, would you like to notify us?',
                            {
                                okBtnText        : 'Yes',
                                cancelBtnText    : 'No',
                                replyMessageText : 'Row52 will review it. Thanks!'
                            });
            this.pwService.reportInappropriateListing(this.$scope.listing.id);

        }
        catch (err) {
            if (!err) {
                alert('Please login to flag this item');
                this.$window.location.href = `/Account/Login?RedirectUrl=/partswanted/detail/${ this.$scope.listing.id }`;
            }
        }
    };
}

angular.module('Row52.Views')
       .controller('PartsWantedListingDetailCtrl', PartsWantedListingDetailCtrl);

export interface IPartsWantedPublishModalScope extends angular.ui.bootstrap.IModalScope {
    listing : IPartsWantedListing;
}

class PartsWantedPublishModalCtrl implements angular.IController {
    static $inject : string[] = [ '$window', '$scope' ];

    constructor(private $window : angular.IWindowService,
                private $scope : IPartsWantedPublishModalScope) {

    }

    ok() {
        this.$window.location.href = `/partswanted/detail/${ this.$scope.listing.id }`;
    }
}

angular.module('Row52.Views')
       .controller('PartsWantedPublishModalCtrl', PartsWantedPublishModalCtrl);
