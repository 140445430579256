import * as angular from 'angular';
import { PartsSaleBaseController, IPartsSaleBaseScope } from '../../parts-sale-base-controller';
import { PartsForSaleListingService } from '../../../../services/odata/parts-for-sale-listing.service';
import { IPartsSaleDetailPublishModalScope } from './modal';
import './publish.html';
import './publish.scss';

class PartsSaleDetailPublishCtrl extends PartsSaleBaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$cookies',
        '$uibModal',
        '$rootScope',
        'PartsForSaleListingService'
    ];

    constructor(protected $scope : IPartsSaleDetailPublishScope,
        protected $window : angular.IWindowService,
        protected $cookies : angular.cookies.ICookiesService,
        private $modal : angular.ui.bootstrap.IModalService,
        private $rootScope : angular.IRootScopeService,
        private listingService : PartsForSaleListingService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
    }

    editListing() {
        this.$scope.loading = true;
        this.$window.location.href = '/partssale/edit/' + this.$scope.listing.id;
    }

    async publish() {
        this.$scope.loading = true;

        try {
            await this.listingService.publish(this.$scope.listing);

            this.$scope.published = true;

            let scope = this.$rootScope.$new() as IPartsSaleDetailPublishModalScope;
            scope.listing = this.$scope.listing;
            this.$modal.open({
                scope : scope,
                templateUrl : 'partsforsale/detail/publish/modal.html',
                size : 'md',
                controller : 'PartsSaleDetailPublishModalCtrl as ctrl'
            }).result.then(function() {
                    parent.window.location.href = `/partssale/detail/${scope.listing.id}`;
                },
                function() {
                    parent.window.location.href = `/partssale/detail/${scope.listing.id}`;
                }).catch(function(res) {
                if (res === 'backdrop click') {
                    parent.window.location.href = `/partssale/detail/${scope.listing.id}`;
                }
            });
        } catch (err) {

        }

        this.$scope.loading = false;
    }
}

interface IPartsSaleDetailPublishScope extends IPartsSaleBaseScope {
    loading : boolean;
    published : boolean;
}

angular.module('Row52.Views.PartsSale.Detail.Publish.PartsSaleDetailPublish', [])
    .controller('PartsSaleDetailPublishCtrl', PartsSaleDetailPublishCtrl);