import * as angular                      from 'angular';

import './seller-tab.html';
import './seller-tab.scss';
import { BaseController, IBaseScope }    from '../../../../base-controller';
import { OutgoingQuoteWidgetCtrl }       from '../outgoing-quote-widget/outgoing-quote-widget';
import { SellerPartsSaleSoldWidgetCtrl } from '../seller-parts-sale-sold-widget/seller-parts-sale-sold-widget';
import { ListingsTabs }                  from '../../../../constants/listings-tabs';
import { Pages }                         from '../../../../constants/pages';
import { QuotesTabs }                    from '../../../../constants/quotes-tabs';

export class SellerTabCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : ISellerTabScope,
                protected $window : angular.IWindowService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.listingsTabs = ListingsTabs;
        this.$scope.quotesTabs   = QuotesTabs;
        this.$scope.pages        = Pages;

        this.$scope.activeTab   = QuotesTabs.OutgoingQuotes;
        this.$scope.viewAllTab  = QuotesTabs.IncomingQuotes;
        this.$scope.viewAllPage = Pages.MyQuotes;

        this.$scope.$on(OutgoingQuoteWidgetCtrl.OutgoingQuoteLoaded, ($event : angular.IAngularEvent, count : number) => {
            this.$scope.activeOutgoingQuotes = count;
        });

        this.$scope.$on(SellerPartsSaleSoldWidgetCtrl.SoldPartsForSaleLoaded,
                        ($event : angular.IAngularEvent, count : number) => {
                            this.$scope.partsToShipCount = count;
                        });
    }

    onTabSelect($event, $index, $page) {
        this.$scope.viewAllPage = $page;
        this.$scope.viewAllTab  = $index;
    }
}

interface ISellerTabScope extends IBaseScope {
    activeOutgoingQuotes : number;
    partsToShipCount : number;
    activeTab : number;
    listingsTabs : ListingsTabs;
    quotesTabs : QuotesTabs;
    pages : Pages;
    viewAllTab : number;
    viewAllPage : number;
}

angular.module('Row52.Views.Account.Summary.SellerTabWidget', [])
       .controller('SellerTabCtrl', SellerTabCtrl);