import * as angular                                from 'angular';
import * as moment                                 from 'moment';
import * as _                                      from 'lodash';
import {
    IPaginationFilter, IPaginationState, IPartsForSaleListingSearchResult, IShippingRate
}                                                  from 'Row52.Models';
import {
    IListing_SqlView
}                                                  from 'Row52.Models.Entities';
import { PartsForSaleStatuses }                    from '../../constants/parts-for-sale-statuses';
import { PaginationService }                       from '../ui/pagination.service';
import { IODataCountWrapper, ResourceCrudService } from './odata.resource';
import { ShippingOfferTypes }                      from '../../constants/shipping-offer-types';
import { IStripeChargeFailure }                    from '../marketplace/stripe.service';
import { KeywordFilterService }                    from './keyword-filter.service';

export class ListingSqlViewService extends ResourceCrudService<IListing_SqlView> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain', 'PaginationService', 'KeywordFilterService' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string,
                private paginationService : PaginationService,
                private keywordFilterService : KeywordFilterService) {
        super($http, $q, $cookies, domain, 'Listings');
    }

    getAllForBuyer(userId : string,
                   top? : number) : angular.IPromise<IODataCountWrapper<IListing_SqlView>> {

        let topClause = '99999';
        if (top) {
            topClause = `${ top }`;
        }

        return this.unwrapODataCountArray(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }/` +
                     `?$count=true` +
                     `&$filter=buyerId eq ${ userId } and (status eq 2 or status eq 4)` +
                     `&$top=${ topClause }` +
                     `&$expand=images`,
                     this.getHeaders())
        );
    }

    sort(listings :
             IListing_SqlView[],
         by :
             string) {
        switch (by) {
            case 'status' : {
                return _.chain(listings)
                        .orderBy('creationDate', 'desc')
                        .sortBy((listing : IListing_SqlView) => {
                            switch (listing.status) {
                                case PartsForSaleStatuses.Completed:
                                    return 1;
                                case PartsForSaleStatuses.Sold:
                                    return 2;
                                default:
                                    return 3;
                            }
                        })
                        .value();
            }
            default:
                return listings;
        }
    }
}

angular.module('Row52.Services.ListingSqlViewService', [ 'ngCookies' ])
       .service('ListingSqlViewService', ListingSqlViewService);
