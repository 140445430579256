import './account-summary';
import './puller-expiration-warning/puller-expiration-warning';
import './profile-widget/profile-widget';
import './favorite-widget/favorite-widget';
import './saved-search-widget/saved-search-widget';
import './favorite-search-tab/favorite-search-tab';
import './incoming-quote-widget/incoming-quote-widget';
import './outgoing-quote-widget/outgoing-quote-widget';
import './consumer-listings-tab/consumer-listings-tab';
import './consumer-parts-sale-widget/consumer-parts-sale-widget';
import './consumer-parts-wanted-widget/consumer-parts-wanted-widget';
import './profile-tab/profile-tab';
import './seller-tab/seller-tab';
import './seller-parts-sale-active-widget/seller-parts-sale-active-widget';
import './seller-parts-sale-sold-widget/seller-parts-sale-sold-widget';
import './messages-widget/messages-widget';

import * as angular from 'angular';

angular.module('Row52.Views.Account.Summary', [
    'Row52.Views.Account.Summary.AccountSummaryCtrl',
    'Row52.Views.Account.Summary.PullerExpirationWarning',
    'Row52.Views.Account.Summary.ProfileWidget',
    'Row52.Views.Account.Summary.FavoriteVehicleWidget',
    'Row52.Views.Account.Summary.SavedSearchWidget',
    'Row52.Views.Account.Summary.SavedSearchFavoriteWidget',
    'Row52.Views.Account.Summary.IncomingQuoteWidget',
    'Row52.Views.Account.Summary.OutgoingQuoteWidget',
    'Row52.Views.Account.Summary.ConsumerListingTab',
    'Row52.Views.Account.Summary.ConsumerPartsSaleWidget',
    'Row52.Views.Account.Summary.ConsumerPartsWantedWidget',
    'Row52.Views.Account.Summary.ProfileTab',
    'Row52.Views.Account.Summary.SellerTabWidget',
    'Row52.Views.Account.Summary.SellerPartsSaleSoldWidget',
    'Row52.Views.Account.Summary.SellerPartsSaleActiveWidget',
    'Row52.Views.Account.Summary.MessagesWidget'
]);