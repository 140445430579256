﻿$(function () {
    var $dropdown = $('drop-down');

    $('.drop-down')
        .on('click',
            function (event) {
                event.stopPropagation();
                $('.header-search').toggleClass('show');
            });
    $dropdown
        .click(function () {
            $('.header-search').removeClass('show');
        });

    // Mobile toggle
    $('.menu-device .user-area a, .menu-device .mobile-dropdown > a, .menu-device .mobile-dropdown .mobile-menu-header > a')
        .on('click',
            function (event) {
                event.stopPropagation();
                $('.menu-device').toggleClass('account-drop');
            });

    $dropdown
        .click(function () {
            $('.menu-device').removeClass('account-drop');
        });

    // noinspection SpellCheckingInspection
    $('.tinyscrollbar').tinyscrollbar({ thumbSize : 182, wheelSpeed : 30, axis : 'x' });
});

var wrapper    = $('#site-wrapper'),
    menu       = $('.menu'),
    toggle     = $('.nav-toggle'),
    toggleIcon = $('.nav-toggle span');

function toggleThatNav() {
    if (menu.hasClass('show-nav')) {
        menu.removeClass('show-nav');
        toggle.removeClass('show-nav');
    }
    else {
        menu.addClass('show-nav');
        toggle.addClass('show-nav');
    }
}

function changeToggleClass() {
    toggleIcon.toggleClass('fa-times');
    toggleIcon.toggleClass('fa-bars');
}

$(function () {
    toggle.on('click',
              function (e) {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleThatNav();
                  changeToggleClass();
              });
    // Keyboard Esc event support
    $(document)
        .keyup(function (e) {
            if (e.keyCode === 27) {
                if (menu.hasClass('show-nav')) {
                    menu.removeClass('show-nav');
                    toggle.removeClass('show-nav');
                    changeToggleClass();
                }
            }
        });
});

if ($(window).width() > 1080) {
    $('.web-menu .menu-first').append($('.menu-device .menu ul:nth-child(3)'));
    $('.web-menu .menu-first ul').append($('.menu-device .menu ul li.user-area'));
    $('.user-area').append($('.drop-account'));
    $('.web-menu .menu-second').append($('.menu-device .menu ul:nth-child(1)'));
}

var ww    = $(window).width(),
    limit = 1080;

$('.menu-first li ul')
    .mouseover(function () {
        $('.menu-first .user-area').addClass('open');
    })
    .mouseout(function () {
        $('.menu-first .user-area').removeClass('open');
    });
//tooltip
$(document)
    .ready(function () {
        $('.hoverdropdown').on({
                                   mouseenter : function () {
                                       $(this).addClass('open');
                                       $(this).find('.dropdown-toggle').attr('aria-expanded', true);
                                   },
                                   mouseleave : function () {
                                       $(this).removeClass('open');
                                       $(this).find('.dropdown-toggle').attr('aria-expanded', false);
                                   }
                               });

        var container = $('body, html'),
            scrollTo  = $('.scrollit');

        $('.banner-down').on('click', function () {
            container.animate({
                                  scrollTop : scrollTo.offset().top - container.offset().top + container.scrollTop()
                              }, 1000);

        });

        /*
         * Replace all SVG images with inline SVG
         */
        jQuery('img.svg').each(function () {
            var $img     = jQuery(this);
            var imgID    = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL   = $img.attr('src');

            jQuery.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
    });

$(window).load(function () {
    var $this         = $(this),
        windowWidth   = $this.width(),
        windowHeight  = $this.height(),
        $jumbotronTop = $('.jumbotron.top');

    $jumbotronTop.fadeIn(150);
    $('.jumbotron.top .container').fadeIn(50);
    $('.jumbotron').fadeIn(150);

    setTimeout(function () {
                   $('.home-load').fadeOut(300);
               },
               1000);
});