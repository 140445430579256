import * as angular from 'angular';

angular.module('Row52.Constants', [])
       .constant('domain', API_DOMAIN)
       .constant('oldDomain', DOMAIN)
       .constant('stripeKey', STRIPE_KEY)
       .constant('GoogleTagManagerKey', GOOGLE_TAG_KEY)
       .constant('STATIC_LABELS',
                 {
                     addresses : {
                         inexactAddressWarning :
                             'Row52 is unable to confirm the address provided. Please review and adjust, if necessary.'
                     }
                 });

export enum Row52ClaimTypes {
    PhotoAgent      = 'https://row52.com/location/photoagent',
    MobileActivated = 'https://row52.com/identity/mobile-activated',
    Active          = 'https://row52.com/identity/active',
    Deleted         = 'https://row52.com/identity/deleted',
    Avatar          = 'https://row52.com/identity/avatar',
    Seller          = 'https://row52.com/identity/seller',
    Status          = 'https://row52.com/identity/status',
    LastActivity    = 'https://row52.com/identity/last-activity',
}
