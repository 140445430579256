import * as angular from 'angular';
import * as moment from 'moment';
import '../../constants/constants';
import { ResourceCrudService } from './odata.resource';
import { IUserVehicleRelationship } from 'Row52.Models.Entities';
import { IVehicleFavorite } from 'Row52.Models';

/**
 * Created by Ri Scott on 5/5/2017.
 */
export class VehicleFavoriteService extends ResourceCrudService<IUserVehicleRelationship> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'UserVehicleRelationships');
    }

    public createFavorite(vehicleId : number, userId : string) : angular.IPromise<IUserVehicleRelationship> {
        let favorite = {
            vehicleId    : vehicleId,
            userId       : userId,
            id           : 0,
            user         : null,
            vehicle      : null,
            creationDate : moment()
                .toDate()
        };

        return this.create(favorite);
    }

    public deleteAllForVehicle(vehicleId : number, userId : string) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}/Row52.DeleteAllForVehicle`,
                      { VehicleId : vehicleId, UserId : userId },
                      this.getHeaders())
        );
    }

    public getVehiclesForUser(userId : string) : angular.IPromise<IVehicleFavorite[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/Users(${userId})/Row52.GetAllVehicleFavorites`,
                     this.getHeaders())
        );
    }
}

angular.module('Row52.Services.VehicleFavoriteService', [ 'ngCookies', 'Row52.Constants' ])
       .service('VehicleFavoriteService', VehicleFavoriteService);