import * as angular from 'angular';

class HeaderCtrl {
    static $inject : string[]       = [ '$scope', '$window', '$cookies' ];
    static LogoutCookieKey : string = 'R2LOGOUT';
    static TokenCookieKey : string  = 'R52ACK';

    constructor(private $scope : IHeaderScope,
                private $window : angular.IWindowService,
                private $cookies : angular.cookies.ICookiesService) {
    }

    userMenuInit(yardManager, partsPuller) {
        this.$scope.yardManager = ( yardManager.toLowerCase() === 'true' );
        this.$scope.partsPuller = ( partsPuller.toLowerCase() === 'true' );
    }

    logout() {
        this.$cookies.remove(HeaderCtrl.TokenCookieKey, { path : '/' });
        this.$cookies.put(HeaderCtrl.LogoutCookieKey, 'true', { path : '/' });
        this.$window.location.href = '/Account/Login';
    }
}

interface IHeaderScope extends angular.IScope {
    yardManager : boolean,
    partsPuller : boolean
}

angular.module('Row52.Views')
       .controller('HeaderCtrl', HeaderCtrl);