import * as angular                                        from 'angular';
import * as _                                              from 'lodash';
import * as moment                                         from 'moment';
import { IShippingRate, IUserJsonModel, IUserPublicModel } from 'Row52.Models';
import {
    IAttachment,
    ILocation,
    IMake,
    IModel,
    IPartsStatus,
    IPartsWantedListing,
    IPartsWantedQuote,
    IReturnPolicy
}                                                          from 'Row52.Models.Entities';
import { FilterClause }                                    from 'ts-odata/src/FilterClause';
import { BaseController, IBaseScope }                      from '../../../../base-controller';
import { PartsWantedQuoteService }                         from '../../../../services/odata/parts-wanted-quote.service';
import { MakeResourceService }                             from '../../../../services/odata/make.service';
import { ModelResourceService }                            from '../../../../services/odata/model.service';
import { PartsWantedListingService }                       from '../../../../services/odata/parts-wanted-listing.service';
import { ReturnPoliciesService }                           from '../../../../services/odata/return-policies.service';
import { PartsStatusesService }                            from '../../../../services/odata/part-statuses.service';
import { LocationResourceService }                         from '../../../../services/odata/location-service';
import { UserService }                                     from '../../../../services/odata/user.service';
import './outgoing-quote-edit.html';
import './outgoing-quote-edit.scss';
import { QuoteEvents }                                     from '../../../../constants/events';
import { EstimateShippingCostsModalService }               from '../estimate-shipping-costs/estimate-shipping-costs';
import { IOutgoingQuoteListScope }                         from '../outgoing-quote-list/outgoing-quote-list';
import { StripeFeeCalculatorService }                      from '../../../../services/marketplace/stripe-fee-calculator.service';
import { QuoteSubmitter }                                  from './outgoing-quote-submitter.service';
import { RemoteErrorModalService }                         from '../../../../services/ui/remoteerror.modal';
import '../../../../templates/partials/sales-tax-tooltip.html';

class AddEditQuoteCtrl extends BaseController {
    static $inject : string[]                = [
        '$scope',
        '$window',
        'PartsWantedQuoteService',
        'FileUploader',
        'MakeService',
        'ModelService',
        'PartsWantedListingService',
        'ReturnPoliciesService',
        'PartsStatusesService',
        'LocationService',
        'UserService',
        '$uibModal',
        '$q',
        'StripeFeeCalculator',
        'QuoteSubmitter',
        'remoteErrorModalService',
        'EstimateShippingCostsModalService'
    ];
    static readonly OUNCES_IN_POUND : number = 16;

    private feePercentage : number;
    private feeMinimum : number;
    private debouncedTaxCalc : any;

    constructor(protected $scope : IAddEditQuoteScope,
                protected $window : angular.IWindowService,
                private pwqService : PartsWantedQuoteService,
                private FileUploader : any,
                private makeService : MakeResourceService,
                private modelService : ModelResourceService,
                private pwService : PartsWantedListingService,
                private rpService : ReturnPoliciesService,
                private psService : PartsStatusesService,
                private locationService : LocationResourceService,
                private userService : UserService,
                private $modal : angular.ui.bootstrap.IModalService,
                private $q : angular.IQService,
                public stripeFeeCalculator : StripeFeeCalculatorService,
                private quoteSubmitter : QuoteSubmitter,
                private remoteErrorService : RemoteErrorModalService,
                public shipEstimateModal : EstimateShippingCostsModalService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        this.initEvents();
        await this.initCalculateInitialView();

        let taxCalculator = async () => {
            let shipping = this.$scope.quote.shippingQuote;

            if (shipping === undefined || shipping === null) {
                shipping = 0;
            }

            try {
                let taxResult = await this.pwService
                                          .calculateTaxes(this.$scope.listing,
                                                          this.$scope.quote.partsLaborQuote,
                                                          shipping);

                this.$scope.quote.taxes = taxResult.amountToCollect;
                this.$scope.taxError    = false;
            }
            catch (err) {
                this.$scope.taxError = true;
            }
        };

        this.debouncedTaxCalc = _.debounce(taxCalculator, 1200);
    }

    private initModels() {
        this.$scope.attachments               = [];
        this.$scope.newImages                 = [];
        this.$scope.dropdowns                 = {};
        this.$scope.expirationTime            = new Date();
        this.$scope.loading                   = false;
        this.$scope.userId                    = this.$scope.user.id;
        this.$scope.isImageRemoving           = false;
        this.$scope.imageRemovingId           = null;
        this.$scope.lowPriceError             = false;
        this.$scope.taxError                  = false;
        this.$scope.displayShippingCalculator = false;
        this.$scope.shippingData              = {
            width  : undefined,
            length : undefined,
            height : undefined,
            pounds : undefined,
            ounces : undefined
        };

        this.feeMinimum    = parseFloat(this.$window[ 'feeMinimum' ]);
        this.feePercentage = parseFloat(this.$window[ 'feePercentage' ]);

        this.$scope.datepickerOptions = this.getStandardDatepickerOptions();
    }

    private async initCalculateInitialView() {
        if (this.$scope.$parent.activeQuoteId) {
            await this.getQuoteToEdit();
        }
        else if (this.$scope.$parent.quoteToClone) {
            await this.initClonedQuote();
        }
        else if (this.$window[ 'partsWantedListingId' ]) {
            await this.initQuoteForListing(this.$window[ 'partsWantedListingId' ]);
        }
    }

    private initQuoteForListing = async (pwid : any) => {
        let pwListingId = pwid ? pwid : this.$window[ 'partsWantedListingId' ];

        try {
            let listing = await this.pwService.getById(pwListingId);

            this.$scope.listing = listing;
            this.$scope.buyer   = await this.userService.getUser(listing.userId);

            try {
                this.$scope.quote                = await this.pwqService.getActiveQuoteForListing(listing.id);
                this.$scope.quote.expirationDate = new Date(this.$scope.quote.expirationDate);
                this.$scope.expirationTime       = new Date(this.$scope.quote.expirationDate);
                if (!this.$scope.user.isTaxable) {
                    this.$scope.quote.taxes = 0.00;
                }
            }
            catch (resp) {
                switch (resp.status) {
                    case 404 :
                        this.$scope.quote = this.createNewQuote();
                        if (pwid && listing) {
                            this.$scope.quote.partsWantedListingId = listing.id;
                        }
                        this.$scope.shippingType = 1;
                        break;
                }
            }
        }
        catch (response) {

        }

        await this.initDropdowns();
    };

    private async getQuoteToEdit() {
        try {
            const activeQuoteId   = this.$scope.$parent.activeQuoteId;
            let quote             = await this.pwqService.getById(activeQuoteId);
            let activeQuoteImages = _.find(this.$scope.$parent.quotes, { id : activeQuoteId }).images;
            quote.images          = activeQuoteImages as any;

            if (quote) {
                quote.expirationDate       = new Date(quote.expirationDate);
                this.$scope.quote          = quote;
                this.$scope.expirationTime = new Date(quote.expirationDate);
                if (!this.$scope.user.isTaxable) {
                    this.$scope.quote.taxes = 0.00;
                }
                this.$scope.shippingType = quote.shippingType != null ? quote.shippingType : 1;
            }
            else {
                this.$scope.shippingType = 1;
            }
        }
        catch (err) {

        }
        await this.initDropdowns();
    }

    private async initClonedQuote() {
        try {
            this.$scope.quote                 = this.cloneQuote();
            let listing : IPartsWantedListing =
                    await this.pwService.getById(this.$scope.$parent.quoteToClone.listing.id);
            this.$scope.listing               = listing;
            this.$scope.buyer                 = await this.userService.getUser(listing.userId);
        }
        catch (err) {

        }
        this.initDropdowns();
    }

    private initDropdowns = async () => {
        try {
            await this.$q.all([
                                  this.initMakeDropdown(),
                                  this.initModelDropdown(),
                                  this.initLocationDropdown(),
                                  this.initPartStatuses(),
                                  this.initReturnPolicies()
                              ]);
        }
        catch (err) {

        }
    };

    private async initMakeDropdown() {
        this.$scope.makes = await this.makeService.getSorted();

        try {
            this.$scope.dropdowns.selectedMake = _.find(this.$scope.makes,
                                                        (make : IMake) => {
                                                            if (this.$scope.quote.makeId) {
                                                                return this.$scope.quote.makeId === make.id;
                                                            }
                                                            else {
                                                                return this.$scope.listing.makeId === make.id;
                                                            }
                                                        });
        }
        catch (err) {
            console.log(err);
        }
    }

    private async initReturnPolicies() {
        this.$scope.returnPolicies = await this.rpService.getAll();
    }

    private async initPartStatuses() {
        this.$scope.partsStatuses = await this.psService.getAll();
    }

    private async initModelDropdown() {
        try {
            this.$scope.models                  = await this.modelService.getSorted();
            this.$scope.dropdowns.selectedModel = _.find(this.$scope.models,
                                                         (model : IModel) => {
                                                             if (this.$scope.quote.modelId) {
                                                                 return this.$scope.quote.modelId === model.id;
                                                             }
                                                             else {
                                                                 return this.$scope.listing.modelId === model.id;
                                                             }
                                                         });
            if (this.$scope.dropdowns.selectedMake &&
                this.$scope.dropdowns.selectedModel.makeId !== this.$scope.dropdowns.selectedMake.id) {
                this.$scope.dropdowns.selectedModel = null;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    private async initLocationDropdown() {
        this.$scope.locations = await this.locationService
                                          .getByQuery(this.locationService
                                                          .createQuery()
                                                          .filter(new FilterClause('isActive').eq(true))
                                                          .andFilter(new FilterClause('isPublishable').eq(true))
                                                          .expand('state($select=name)')
                                                          .orderBy('state/name')
                                                          .select([ 'id', 'name', 'isParticipating' ]));

        if (this.$scope.quote.locationId) {
            this.$scope.dropdowns.selectedLocation = _.find(this.$scope.locations,
                                                            (location : ILocation) => {
                                                                return this.$scope.quote.locationId === location.id;
                                                            });
        }
    }

    private initEvents() {
        this.$scope.$on('fineUploader:file-upload',
                        ($event : angular.IAngularEvent, id : number, attachment : IAttachment) => {
                            this.$scope.attachments[ id ] = attachment;
                        });

        this.$scope.$on('fineUploader:file-removed',
                        ($event : angular.IAngularEvent, id : number) => {
                            delete this.$scope.attachments[ id ];
                        });

        this.$scope.$on(QuoteEvents.UpdatedOutgoingQuote,
                        () => {
                            this.remoteErrorService
                                .open('Success',
                                      'The selected quote has been successfully updated.',
                                      { okBtnText : 'OK' });
                        });

        this.$scope.$on(
            'remoteErrorCondition',
            async ($event : angular.IAngularEvent, err : any) => {
                let
                    msg = err && err.data && err.data.error ? err.data.error.message : 'Something has gone wrong.';
                await this.remoteErrorService
                          .open(
                              'An unspecified error has occurred',
                              msg,
                              {
                                  okBtnText : 'OK'
                              });
            });

        this.$scope.$on('toggleEditMode',
                        ($event : angular.IAngularEvent, quote : any) => {
                            this.initQuoteForListing(quote.partsWantedListing ? quote.partsWantedListing.id : quote.listing.id);
                            this.$scope.loading = false;
                        });

        this.$scope.$watch(() => {
                               if (this.$scope.dropdowns.selectedMake) {
                                   return this.$scope.dropdowns.selectedMake.id;
                               }
                               return false;
                           },
                           () => {
                               if (this.$scope.dropdowns.selectedMake && this.$scope.dropdowns.selectedModel) {
                                   if (this.$scope.dropdowns.selectedModel.makeId === this.$scope.dropdowns.selectedMake.id) {
                                       return;
                                   }
                               }
                               this.$scope.dropdowns.selectedModel = null;
                           });

        this.$scope.$watch('shippingType',
                           () => {
                               /* 6/1/18 - A. Beverly - I don't really know why this is not a part of the quote model already,
                                but we want to capture this selection and store it
                                since "free" shipping is not the same thing as "no" shipping.
                                Later, when completing the quote, we need something to hook into to decide whether to
                                require a shipping method and/or tracking number. Presently, the only thing to hook
                                into is a zero vs. non-zero shipping fee, which seems kludgy. */
                               if (this.$scope.quote) {
                                   this.$scope.quote.shippingType = this.$scope.shippingType;
                                   if (this.$scope.shippingType === 0) {
                                       this.$scope.quote.shippingQuote = 0;
                                   }
                               }
                           });
    }

    clearSelectedModel = () => {
        this.$scope.dropdowns.selectedModel = null;
    };

    groupLocations = (item : ILocationDropDownModel) : string => {
        return item.state.name;
    };

    submitQuote($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (!this.validateTotalPrice()) {
            return false;
        }

        if (this.$scope.addEditQuoteForm.$valid) {
            this.$scope.loading = true;

            if (this.$scope.quote.images) {
                delete this.$scope.quote.images;
            }

            try {
                this.quoteSubmitter.submit(this.$scope);
            }
            catch (err) {
                this.$scope.loading = false;
            }
            //this.$scope.loading = false;
        }
    }

    isThisImageRemoving(imageId : number) {
        return this.$scope.imageRemovingId === imageId;
    }

    removeImage($event : angular.IAngularEvent, imageId : any) {
        $event.preventDefault();

        if (!this.$scope.isImageRemoving) {
            this.$scope.isImageRemoving = true;
            this.$scope.imageRemovingId = imageId;

            this.pwqService
                .unattach(this.$scope.quote, imageId)
                .then(() => {
                    this.$scope.isImageRemoving = false;
                    this.$scope.imageRemovingId = null;
                    _.remove(this.$scope.quote.images as any,
                             {
                                 imageId : imageId
                             });
                });
        }
    }

    createNewQuote() : IPartsWantedQuote {
        let quote = this.pwqService.createEmpty();

        quote.partsWantedListingId = parseInt(this.$window[ 'partsWantedListingId' ], 10);
        quote.sellerUserId         = this.$scope.user.id;

        return quote;
    }

    cloneQuote() : IPartsWantedQuote {
        let quote = this.pwqService.clone(this.$scope.$parent.quoteToClone);

        quote.sellerUserId = this.$scope.user.id;

        return quote;
    }

    calculateTotalPrice() {
        if (!this.$scope.quote.taxes) {
            this.$scope.quote.taxes = 0.00;
        }

        return this.$scope.quote.partsLaborQuote +
               this.$scope.quote.shippingQuote +
               this.$scope.quote.taxes;
    }

    validateTotalPrice() {
        if (this.calculateTotalPrice() < 3.00) {
            this.$scope.lowPriceError = true;
            return false;
        }
        else {
            this.$scope.lowPriceError = false;
            return true;
        }
    }

    async changePricing($event : angular.IAngularEvent) {
        this.$scope.lowPriceError = false;

        if (this.debouncedTaxCalc && this.$scope.quote.partsLaborQuote > 0) {
            this.debouncedTaxCalc();
        }
        else {
            this.$scope.quote.taxes = 0;
        }
    }

    cancel($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.$scope.quote.id) {
            this.$scope.$emit(QuoteEvents.CloseQuote, this.$scope.quote);
        }
        else {
            this.$window.location.href = `/PartsWanted/Detail/${this.$scope.listing.id}`;
        }
    }

    showShiping($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.showShippingCalculator = !this.$scope.showShippingCalculator;
    }

    async estimateShipping($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (!this.$scope.shippingData.pounds) {
            this.$scope.shippingData.pounds = '0';
        }

        if (!this.$scope.shippingData.ounces) {
            this.$scope.shippingData.ounces = '0';
        }

        let weight = parseFloat(this.$scope.shippingData.ounces) +
                     ( parseFloat(this.$scope.shippingData.pounds) * AddEditQuoteCtrl.OUNCES_IN_POUND );

        if (this.$scope.shippingData.length > 0
            && this.$scope.shippingData.width > 0
            && this.$scope.shippingData.height > 0
            && weight > 0) {

            try {
                this.$scope.shippingLoading = true;
                this.$scope.shippingRates   = await this.pwService
                                                        .getShippingRates(this.$scope.listing,
                                                                          this.$scope.shippingData.length,
                                                                          this.$scope.shippingData.width,
                                                                          this.$scope.shippingData.height,
                                                                          weight);

                this.$scope.shippingRates = _.sortBy(this.$scope.shippingRates, [ 'estimatedDeliveryDays' ]);
            }
            catch (err) {

            }
            finally {
                this.$scope.shippingLoading = false;
            }
        }
    }

    validRateForm() : boolean {
        return this.$scope.shippingData.length > 0
               && this.$scope.shippingData.width > 0
               && this.$scope.shippingData.height > 0
               && ( parseFloat(this.$scope.shippingData.pounds) > 0
                    || parseFloat(this.$scope.shippingData.ounces) > 0 );
    }
}

interface ILocationDropDownModel {
    name : string;
    id : number;
    isParticipating : boolean;
    state : { name : string };
}

export interface IAddEditQuoteScope extends IBaseScope {
    makes : IMake[];
    models : IModel[];
    dropdowns : any;
    locations : ILocationDropDownModel[];
    returnPolicies : IReturnPolicy[];
    partsStatuses : IPartsStatus[];
    listing : IPartsWantedListing;
    quote : IPartsWantedQuote;
    user : IUserJsonModel;
    shippingType : any;
    myQuoteParam : any;
    uploader : any;
    newImages : any;
    isSubmitDisable : boolean;
    isWithdrawnDisable : boolean;
    buyer : IUserPublicModel;
    expirationTime : Date;
    addEditQuoteForm : angular.IFormController;
    $parent : IOutgoingQuoteListScope;
    loading : boolean;
    userId : string;
    datepickerOptions : any;
    attachments : { [ id : number ] : IAttachment };
    isImageRemoving : boolean;
    imageRemovingId : number;
    lowPriceError : boolean;
    taxError : boolean;
    displayShippingCalculator : boolean;
    showShippingCalculator : boolean;
    shippingData : {
        length : number;
        width : number;
        height : number;
        pounds : string;
        ounces : string;
    }
    shippingRates : IShippingRate[];
    shippingLoading : boolean;
}

angular.module('Row52.Views.Account.Quotes.OutgoingQuoteEdit',
               [
                   'Row52.Services.PartsWantedQuoteService',
                   'Row52.Services.MakeService',
                   'Row52.Services.ModelService',
                   'Row52.Services.PartsWantedListingService',
                   'Row52.Services.ReturnPoliciesService',
                   'Row52.Services.PartsStatusesService',
                   'Row52.Services.LocationService',
                   'Row52.Services.UserService',
                   'ui.bootstrap',
                   'Row52.Views.Account.Quotes.EstimateShippingCosts',
                   'Row52.Services.StripeFeeCalculator',
                   'Row52.Views.Account.Quotes.OutgoingQuoteSubmitter'
               ])
       .controller('AddEditQuoteCtrl', AddEditQuoteCtrl);