import * as angular                                     from 'angular';
import 'angular-cookies';
import { IListingUserModel }                            from 'Row52.Models';
import { MessagesService }                              from '../../../services/messages.service';
import { PartsForSaleStatuses }                         from '../../../constants/parts-for-sale-statuses';
import { IPartsSaleImage }                              from 'Row52.Models.Entities';
import { ShippingOfferTypes }                           from '../../../constants/shipping-offer-types';
import { ImageServiceModal }                            from '../../../services/images/image.service';
import { PartsSaleDetailShipCtrl }                      from './ship/ship';
import { PartsSaleBaseController, IPartsSaleBaseScope } from '../parts-sale-base-controller';
import './detail.html';
import './detail.scss';
import { PartsSaleDetailSidebarCtrl }                   from './sidebar/sidebar';

class PartsForSaleDetailCtrl extends PartsSaleBaseController {
    static $inject : string[] = [
        '$scope',
        '$rootScope',
        '$window',
        'MessagesService',
        'ImageServiceModal',
        '$cookies'
    ];

    constructor(protected $scope : IPartsForSaleDetailScope,
                private $rootScope : angular.IRootScopeService,
                protected $window : angular.IWindowService,
                public messageService : MessagesService,
                private imageModalService : ImageServiceModal) {

        super();
        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        this.initEvents();
    }

    private initModels() {
        this.$scope.isPurchased     = this.$window.isPurchased;
        this.$scope.statuses        = PartsForSaleStatuses;
        this.$scope.shipMode        = false;
        this.$scope.loading         = false;
        this.$scope.published       = false;
        this.$scope.completeLoading = false;
        this.$scope.cancelLoading   = false;
        this.$scope.shippingTypes   = ShippingOfferTypes;
    }

    private initEvents() {
        this.$scope.$on(PartsSaleDetailShipCtrl.SuccessfulShip, () => {
            this.$scope.shipMode = false;
            this.$window.location.reload();
        });

        this.$scope.$on(PartsSaleDetailSidebarCtrl.ShowShipmentForm, () => {
            this.$scope.shipMode = true;
        });

        this.$scope.$on(PartsSaleDetailShipCtrl.HideShip, () => {
            this.$scope.shipMode = false;
        });
    }

    displayImagePFS($event : angular.IAngularEvent, image : IPartsSaleImage) {
        $event.preventDefault();

        this.imageModalService.open(image.resourceUrl + image.size1 + image.extension);
    }

    sendMessage($event : angular.IAngularEvent, listingUser : IListingUserModel, subject : string) {
        $event.preventDefault();

        this.messageService.open(listingUser.userName, subject);
    }

    hideShipmentForm($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.shipMode = false;
    }

    displayReceipt() {
        return ( this.viewedByOwner() || this.viewedByBuyer() )
               && ( this.$scope.listing.status === PartsForSaleStatuses.Sold
                    || this.$scope.listing.status === PartsForSaleStatuses.Completed );
    }
}

interface IPartsForSaleDetailScope extends IPartsSaleBaseScope {
    isUserLike : boolean;
    loading : boolean;
    cancelLoading : boolean;
    published : boolean;
    statuses : PartsForSaleStatuses;
    shipMode : boolean;
    shippingTypes : ShippingOfferTypes;
    completeLoading : boolean;
    isPurchased : boolean;
}

angular.module('Row52.Views.PartsSale.Detail.MainCtrl', [
           'Row52.Services.MessageModal',
           'Row52.Services.ImageModal'
       ])
       .controller('PartsForSaleDetailCtrl', PartsForSaleDetailCtrl);