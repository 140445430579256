/**
 * Created by Ri Scott on 5/29/2017.
 */
import * as angular                                from 'angular';
import { ILocationService }                        from 'angular';
import '../../constants/constants';
import { PartsWantedStatuses }                     from '../../constants/parts-wanted-statuses';
import { IODataCountWrapper, ResourceCrudService } from './odata.resource';
import {
    IAttachment,
    IPartsWantedListing,
    IVehicle
}                                                  from 'Row52.Models.Entities';
import {
    IPartsWantedListingSearchResult,
    IPaginationState,
    IPaginationFilter,
    IShippingRate
}                                                  from 'Row52.Models';
import { IPromise }                                from 'angular';
import { PaginationService }                       from '../ui/pagination.service';
import * as moment                                 from 'moment';
import { KeywordFilterService }                    from './keyword-filter.service';

export class PartsWantedListingService extends ResourceCrudService<IPartsWantedListing> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain', 'PaginationService', 'KeywordFilterService', '$location' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string,
                private paginationService : PaginationService,
                private keywordFilterService : KeywordFilterService,
                private $location : ILocationService) {
        super($http, $q, $cookies, domain, 'PartsWantedListings');
    }

    search(keyword : string,
           distance : number,
           postalCode : string,
           hasImages : boolean,
           withClosed : boolean,
           paginationState : IPaginationState) : IPromise<IODataCountWrapper<IPartsWantedListingSearchResult>> {

        let qs = this.$location.search();
        qs['PostalCode'] = postalCode;
        
        let filter : string    = '1 eq 1';
        let columns : string[] = [ 'partsTitle', 'additionalInfo', 'makeName', 'modelName' ];

        if (keyword) {
            filter += this.keywordFilterService.getKeywordFilter(keyword, columns, 'PW');
        }
        qs['Keyword'] = keyword;

        if (hasImages) {
            filter = filter + ` and hasImage eq true`;
        }
        qs['HasImage'] = hasImages.toString();

        if (!withClosed) {
            filter = filter + ` and status eq ` + PartsWantedStatuses.Open;
        }
        qs['WithClosed'] = withClosed.toString();

        if (!distance) {
            distance = 0;
        }
        qs['Distance'] = distance;

        let limit : string = '';
        if (paginationState) {
            let filter : IPaginationFilter = this.paginationService.getFilterFromState(paginationState);
            limit                          = '&$skip=' + filter.skip + '&$top=' + filter.top;
        }

        this.$location.search(qs);

        return this.unwrapODataCountArray(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }/` +
                `Row52.Search(postalCode='${postalCode }', distance=${ distance })` +
                     `?$filter=${ filter }` +
                     `&$orderby=creationDate desc` +
                     `&$count=true` +
                     `${ limit }`,
                     this.getHeaders())
        );
    }

    getPartsWantedListingByBuyer(userId : string,
                                 top : number = 0) : IPromise<IODataCountWrapper<IPartsWantedListingSearchResult>> {
        let filter : string    = `userId eq ${ userId }`;
        let topClause : string = top > 0 ? `&$top=${ top }` : '';

        return this.unwrapODataCountArray(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }/Row52.Search(postalCode=null, distance=0)?` +
                     `$filter=${ filter }` +
                     `&$orderby=creationDate desc` +
                     `&$count=true${ topClause }`,
                     this.getHeaders())
        );
    }

    publish(listing : IPartsWantedListing) : IPromise<IPartsWantedListing> {
        return this.unwrapSingle(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ listing.id })/Row52.Publish`, {}, this.getHeaders())
        );
    }

    vehicleCount(listing : IPartsWantedListing) : IPromise<number> {
        let deferred = this.$q.defer();
        this.$http
            .post(`${ this.domain }/odata/${ this.resourceUrl }(${ listing.id })/Row52.VehicleCount`, {}, this.getHeaders())
            .then((x : angular.IHttpPromiseCallbackArg<any>) => {
                deferred.resolve(x.data.value);
            });

        return deferred.promise as angular.IPromise<number>;
    }

    attach(listing : IPartsWantedListing, attachment : IAttachment) : IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ listing.id })/Row52.Attach`,
                      { attachmentId : attachment.id },
                      this.getHeaders())
        );
    }

    unattach(listing : IPartsWantedListing, imageId : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ listing.id })/Row52.Unattach`,
                      { imageId : imageId },
                      this.getHeaders())
        );
    }

    reportInappropriateListing(listingId : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ listingId })/Row52.InappropriateListing`,
                      {},
                      this.getHeaders())
        );
    }

    calculateTaxes(listing : IPartsWantedListing, price : number, shipping : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }(${ listing.id })/Row52.CalculateTaxes(` +
                     `price=${ price }, shipping=${ shipping })`,
                     this.getHeaders())
        );
    }

    getShippingRates(listing : IPartsWantedListing,
                     length : number,
                     width : number,
                     height : number,
                     weight : number) : angular.IPromise<IShippingRate[]> {
        return this.unwrapArray(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ listing.id })/Row52.GetShippingRates`, {
                          length : length,
                          width  : width,
                          height : height,
                          weight : weight
                      },
                      this.getHeaders())
        );
    }

    static createEmpty() : IPartsWantedListing {
        return {
            id                   : 0,
            year                 : null,
            makeId               : null,
            vehicleId            : null,
            vehicle              : null,
            modelId              : null,
            model                : null,
            partsTitle           : null,
            additionalInfo       : null,
            partsPrice           : null,
            shippingOfferTypeId  : null,
            shippingOfferType    : null,
            shippingPrice        : 0,
            address1             : null,
            address2             : null,
            stateId              : null,
            state                : null,
            provinceName         : null,
            city                 : null,
            zipCode              : null,
            countryId            : null,
            country              : null,
            row52Fee             : 0,
            hasImage             : false,
            isActive             : false,
            status               : 3,
            isPublish            : false,
            creationDate         : moment()
                .toDate(),
            lastModificationDate : moment()
                .toDate(),
            expirationDate       : moment()
                .add(2, 'weeks')
                .toDate(),
            isMailSent           : false,
            userCount            : 0,
            user                 : null,
            userId               : null,
            images               : [],
            comments             : [],
            latitude             : null,
            longitude            : null,
            expiredDate          : null,
            completionDate       : null,
            refundDate           : null,
            firstName            : null,
            lastName             : null,
            isInexactAddress     : true
        };
    }
}

angular.module('Row52.Services.PartsWantedListingService', [ 'ngCookies', 'Row52.Constants' ])
       .service('PartsWantedListingService', PartsWantedListingService);
