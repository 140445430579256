import * as angular from 'angular';

function StartsWithFilter() {
    return function (input, searchString) {
        if (input) {
            return input.filter((item) => {
                return item.name.toLowerCase().search(searchString.toLowerCase()) === 0;
            });
        }
    }
}

angular
    .module('Row52.Directives')
    .filter('startsWith', StartsWithFilter);