import * as angular from 'angular';
import * as _ from 'lodash';
import { IPartsForSaleListingSearchResult, IUserJsonModel } from 'Row52.Models';
import { MessagesService } from '../../../services/messages.service';
import { PartsForSaleFavoriteService } from '../../../services/odata/parts-for-sale-favorite.service';
import { PartsForSaleStatusService } from '../../../services/data/parts-for-sale-status.service';
import '../../../templates/account/favorites/parts-sale.html';

interface IPartsSaleFavoriteListScope extends angular.IScope {
    listings : IPartsForSaleListingSearchResult[];
    loading : boolean;
    user : IUserJsonModel;
}

class FavoritePartsSaleCtrl {
    static $inject : string[] = [
        '$scope', '$window', 'PartsForSaleFavoriteService', 'MessagesService', 'PartsForSaleStatusService'
    ];

    constructor(private $scope : IPartsSaleFavoriteListScope,
        private $window : any,
        private favoriteService : PartsForSaleFavoriteService,
        private messageService : MessagesService,
        protected statusService : PartsForSaleStatusService) {
        this.$scope.loading = true;
        this.$scope.user = this.$window['user'];

        this.loadData();
    }

    sendMessage($event :
        angular.IAngularEvent,
        listing :
        IPartsForSaleListingSearchResult) {
        $event.preventDefault();

        this.messageService.open(listing.userName, 'Re: ' + listing.partsTitle.substring(0, 46));
    }

    loadData = async () => {
        this.$scope.listings = await this.favoriteService.getForUser(this.$scope.user.id);
        this.$scope.loading = false;
    };

    deleteFavorite = async ($event : angular.IAngularEvent, listing : IPartsForSaleListingSearchResult) => {
        $event.preventDefault();
        await this.favoriteService.deleteAllForListing(listing.id, this.$scope.user.id);
        _.remove(this.$scope.listings,
            (f : IPartsForSaleListingSearchResult) => {
                return f.id === listing.id;
            });
    };
}

angular.module('Row52.Views.Account.Favorites.FavoritePartsSaleCtrl', ['Row52.Services.PartsForSaleFavoriteService'])
    .controller('FavoritePartsSaleCtrl', FavoritePartsSaleCtrl);