import './saved-search-widget.html';
import './saved-search-widget.scss';

import * as angular             from 'angular';
import * as _                   from 'lodash';
import { IUserJsonModel }       from 'Row52.Models';
import { IUserVehicleSearch }   from 'Row52.Models.Entities';
import { VehicleSearchService } from '../../../../services/odata/vehicle-search.service';
import { BaseController }       from '../../../../base-controller';

class SavedSearchWidgetCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', 'VehicleSearchService' ];

    constructor(protected $scope : ISavedSearchWigetScope,
                protected $window : angular.IWindowService,
                private vehicleSearchService : VehicleSearchService) {
        super();
        this.init();
    }

    protected async init() {
        this.$scope.searchesLoading = true;
        this.$scope.user            = this.$window[ 'user' ];

        await this.loadData();
    }

    loadData = async () => {
        this.$scope.searches        = await this.vehicleSearchService.getSearchesByUser(this.$scope.user.id);
        this.$scope.searchesLoading = false;
    };

    saveSearch = async ($event, search : IUserVehicleSearch) => {
        await this.vehicleSearchService.update(search.id, search);
    };

    deleteSearch = async ($event : angular.IAngularEvent, search : IUserVehicleSearch) => {
        await this.vehicleSearchService.delete(search.id);
        _.remove(this.$scope.searches, (s : IUserVehicleSearch) => {
            return s.id === search.id;
        });
    };
}

interface ISavedSearchWigetScope extends angular.IScope {
    searches : IUserVehicleSearch[];
    searchesLoading : boolean;
    user : IUserJsonModel;
}

angular.module('Row52.Views.Account.Summary.SavedSearchWidget', [])
       .controller('SavedSearchWidgetCtrl', SavedSearchWidgetCtrl);