import * as angular from 'angular';
import { IVehicleCommentCreationModel, IVehicleCommentModel } from 'Row52.Models';
import '../../constants/constants';
import { ResourceService } from './odata.resource';

export class VehicleCommentService extends ResourceService<IVehicleCommentModel> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'VehicleComments');
    }

    public createComment(vehicleId : number,
                         body : string,
                         parentId? : number,
                         attachmentId? : number) : angular.IPromise<IVehicleCommentModel> {

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/VehicleComments/`,
                      <IVehicleCommentCreationModel>{
                          parentId     : parentId,
                          attachmentId : attachmentId,
                          body         : body,
                          vehicleId    : vehicleId
                      },
                      this.getHeaders())
        );
    }

    public getForVehicle(vehicleId : number) : angular.IPromise<IVehicleCommentModel[]> {
        let url = `${this.domain}/odata/VehicleComments/`
                  + `?$filter=vehicleId eq ${vehicleId}`
                  + `&$orderby=creationDate desc`;

        return this.unwrapArray(
            this.$http
                .get(url, this.getHeaders()));
    }

    public flag(commentId : number) : angular.IPromise<IVehicleCommentModel> {
        let url = `${this.domain}/odata/VehicleComments(${commentId}/Row52.Flag`;

        return this.unwrapSingle(
            this.$http
                .get(url, this.getHeaders()));
    }

    public remove(commentId : number) : angular.IPromise<IVehicleCommentModel> {
        let url = `${this.domain}/odata/VehicleComments(${commentId}/Row52.Remove`;

        return this.unwrapSingle(
            this.$http
                .get(url, this.getHeaders()));
    }
}

angular.module('Row52.Services.VehicleCommentService', [ 'ngCookies', 'Row52.Constants' ])
       .service('VehicleCommentService', VehicleCommentService);