import * as angular from 'angular';
import * as _ from 'lodash';
import '../../constants/constants';
import { IAngularEvent } from 'angular';
import { ICountry, IState } from 'Row52.Models.Entities';

export class AddressCompletionService {
    static GoogleAutoCompleteSelectEvent : string = 'g-places-autocomplete:select';
    public static $inject : string[] = ['$q', 'STATIC_LABELS'];
    public staticLabels : any;


    constructor(private $q : angular.IQService, STATIC_LABELS : any) {
        this.staticLabels = STATIC_LABELS.addresses;
    }

    createHandler = (
        address : IAddress,
        states : IState[],
        countries : ICountry[],
        dropdowns : IDropDownContainer,
        prefix? : string
    ) => {
        return (
            event : IAngularEvent,
            place : google.maps.places.PlaceResult,
            prediction : any,
            elementContext : any
        ) => {
            let elementIdPrefix = prefix;
            if (elementContext && elementContext.id) {
                if (elementContext.id === 'billingAddress1') {
                    elementIdPrefix = 'billing';
                }
            }
            if (place) {
                let streetNumber = this.getAddressComponent(
                    'street_number',
                    place.address_components
                );
                let streetName = this.getAddressComponent(
                    'route',
                    place.address_components
                );
                let address1 = `${streetNumber} ${streetName}`;
                let city = this.getAddressComponent(
                    'locality',
                    place.address_components
                );

                if (!streetNumber) {
                    address.isInexactAddress = true;
                } else {
                    address.isInexactAddress = false;
                }

                if (address.isInexactAddress && prediction) {
                    if (elementIdPrefix) {
                        address[elementIdPrefix + 'Address1'] =
                            prediction.structured_formatting.main_text;
                        address[elementIdPrefix + 'City'] = city;
                    } else {
                        address['address1'] = prediction.structured_formatting.main_text;
                        address['city'] = city;
                    }
                } else {
                    if (elementIdPrefix) {
                        address[elementIdPrefix + 'Address1'] = address1;
                        address[elementIdPrefix + 'City'] = city;
                    } else {
                        address['address1'] = address1;
                        address['city'] = city;
                    }
                }

                let state = this.getAddressComponent(
                    'administrative_area_level_1',
                    place.address_components
                );

                let country = this.getAddressComponent(
                    'country',
                    place.address_components
                );

                let dropdownFieldname =
                    elementIdPrefix === 'billing' ? 'billing' : 'selected';

                dropdowns[dropdownFieldname + 'Country'] = _.find(
                    countries,
                    (c : ICountry) => {
                        return (
                            c.name === country ||
                            (c.name === 'United States of America' &&
                                country === 'United States')
                        );
                    }
                );

                if (
                    dropdowns[dropdownFieldname + 'Country'] != null &&
                        dropdowns[dropdownFieldname + 'Country'].hasStates
                ) {
                    dropdowns[dropdownFieldname + 'State'] = _.find(
                        states,
                        (s : IState) => {
                            return s.name === state;
                        }
                    );
                } else {
                    if (elementIdPrefix) {
                        address[elementIdPrefix + 'ProvinceName'] = state;
                    } else {
                        address.provinceName = state;
                    }
                }

                this.setPostalCode(address, place, elementIdPrefix);
                this.setLatLng(address, place, elementIdPrefix);
            } else {
                address.isInexactAddress = true;
            }
        };
    };

    /* A. Beverly - on hold while I figure out a better way to do this. */
    verifyAddress(address : IAddress, states : IState[], countries : ICountry[], w) {
        let deferred = this.$q.defer() as angular.IDeferred<boolean>;
        let street = address.address1;
        let city = address.city;
        let postalCode = address.postalCode;
        if (street && city && address.countryId && postalCode) {
            let autocompleteService = new google.maps.places.AutocompleteService(),
                placesService = new google.maps.places.PlacesService(
                    <HTMLDivElement>w.document.createElement('div')
                );
            let request = angular.extend(
                {
                    input : this.getFormattedAddress(address, states, countries)
                },
                {}
            );
            console.log(request);
            placesService.textSearch(request,
                function(predictions, status) {
                    console.log(predictions);
                    if (predictions) {
                        // loop over predictions, finding closest matching prediction, then get place details for that prediction

                        deferred.resolve(true);
                    }
                    deferred.resolve(false);
                });
        }
        return deferred.promise;
    }

    getFormattedAddress(
        address : IAddress,
        states : IState[],
        countries : ICountry[]
    ) : string {
        let state = _.find(states,
            (s : IState) => {
                return s.id === address.stateId;
            });
        let stateName = state ? state.name : null;
        if (address.provinceName) {
            stateName = address.provinceName;
        }
        let country = _.find(countries,
            (c : ICountry) => {
                return c.id === address.countryId;
            });
        return (
            address.address1 +
                ', ' +
                address.city +
                (stateName ? ', ' + stateName : '') +
                ', ' +
                address.postalCode +
                ' ' +
                country.name
        );
    }

    geoCodeAddress(
        address : IAddress,
        states : IState[],
        countries : ICountry[],
        asPostalCodeOnly : boolean = false,
        withExistingDefer : angular.IDeferred<boolean> = null
    ) : angular.IPromise<boolean> {
        let deferred = withExistingDefer || this.$q.defer();
        let geoCoder = new google.maps.Geocoder();
        let formattedAddress = this.getFormattedAddress(address, states, countries);
        let addressService = this;

        geoCoder.geocode(
            {
                address : asPostalCodeOnly ? address.postalCode : formattedAddress //,
                //componentRestrictions: {
                //    postalCode: this.getPostalCode(address)
                //}
            },
            (
                results : google.maps.GeocoderResult[],
                status : google.maps.GeocoderStatus
            ) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    let result = results[0];

                    if (result) {
                        this.setLatLng(address, result);
                        deferred.resolve(true);
                    } else {
                        if (!asPostalCodeOnly) {
                            addressService.geoCodeAddress(
                                address,
                                states,
                                countries,
                                true,
                                deferred
                            );
                        } else {
                            deferred.resolve(true);
                        }
                    }
                } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                    /* changed by A. Beverly on 5/22/18 - 'no result' should not be a blocking error;
                     instead of rejecting, we instead try to silently geocode on the postal code alone. 
                                  If that fails too, we proceed without them. */
                    if (!asPostalCodeOnly) {
                        addressService.geoCodeAddress(
                            address,
                            states,
                            countries,
                            true,
                            deferred
                        );
                    } else {
                        deferred.resolve(true);
                    }
                } else {
                    deferred.reject({
                        message : 'Google Geocoder Error',
                        type : 'Geocoder_Error'
                    });
                }
            }
        );

        return deferred.promise as angular.IPromise<boolean>;
    }

    validate(address : IAddress) {
        if (
            address.address1 &&
                (address.postalCode || address.zipCode) &&
                (address.stateId || address.provinceName) &&
                address.countryId &&
                address.city
        ) {
            return true;
        }

        return false;
    }

    emptyAddress() : IAddress {
        return {
            address1 : null,
            city : null,
            postalCode : null,
            zipCode : null,
            latitude : null,
            longitude : null,
            stateId : null,
            provinceName : null,
            countryId : null,
            isInexactAddress : true
        };
    }

    private setPostalCode(
        address : IAddress,
        place : google.maps.places.PlaceResult,
        prefix? : string
    ) {
        let postalCode = this.getAddressComponent(
            'postal_code',
            place.address_components
        );

        if (prefix) {
            if (prefix + 'ZipCode' in address) {
                address[prefix + 'ZipCode'] = postalCode;
            }
            if (prefix + 'PostalCode' in address) {
                address[prefix + 'PostalCode'] = postalCode;
            }
        } else {
            if ('zipCode' in address) {
                address.zipCode = postalCode;
            }
            if ('postalCode' in address) {
                address['postalCode'] = postalCode;
            }
        }
    }

    private getPostalCode(address :
        IAddress) {
        if ('postalCode' in address) {
            return address.postalCode;
        }

        if ('zipCode' in address) {
            return address.zipCode;
        }

        return undefined;
    }

    private setLatLng(
        address :
        IAddress,
        place :
        google.maps.places.PlaceResult | google.maps.GeocoderResult,
        prefix ? : string
    ) {
        if (prefix) {
            if (prefix + 'Latitude' in address) {
                address[prefix + 'Latitude'] = place.geometry.location.lat();
            }

            if (prefix + 'Longitude' in address) {
                address[prefix + 'Longitude'] = place.geometry.location.lng();
            }
        } else {

            if (
                'latitude' in
                    address) {
                address.latitude = place.geometry.location.lat();
            }

            if ('longitude' in address) {
                address.longitude = place.geometry.location.lng();
            }
        }
    }

    public stateMatchesCounty(selectedState : any, selectedCountry : any) : boolean {
        if (selectedState && selectedCountry) {
            return selectedState.countryId === selectedCountry.id;
        }
        return true;
    }

    public zipCodeMatchesCountry(zipCode : string, selectedCountry : any) : boolean {
        let canadianRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        let usRegex = /^\d{5}(?:[-\s]\d{4})?$/;
        if (selectedCountry) {
            if (selectedCountry.name === 'United States') {
                return usRegex.test(zipCode);
            } else if (selectedCountry.name === 'Canada') {
                return canadianRegex.test(zipCode);
            }
        }
        return true;
    }

    private getAddressComponent(
            componentName :
            string,
            components :
            google.maps.GeocoderAddressComponent[]
        ) :
        string {
        let comp = _.find(
            components,
            (comp : google.maps.GeocoderAddressComponent) => {
                return comp.types.indexOf(componentName) > -1;
            }
        );
        if (comp) {
            return comp.long_name;
        }
        return '';
    }
}

export interface IAddress {
    address1 : string;
    city : string;
    postalCode? : string;
    zipCode? : string;
    latitude? : number;
    longitude? : number;
    stateId? : number;
    provinceName? : string;
    countryId : number;
    isInexactAddress : boolean;
}

export interface IDropDownContainer {
    selectedState : IState;
    selectedCountry : ICountry;
}

angular
    .module('Row52.Services.AddressCompletion', ['Row52.Constants'])
    .service('AddressCompletionService', AddressCompletionService);