import './mobile-verification.html';
import './mobile-verification.scss';

import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../../base-controller';
import { MobileVerificationCtrl }     from '../../../mobile-registration/mobile-verification/mobile-verification';

class RegistrationWizardMobileVerificationCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : IMobileVerificationScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.$on(MobileVerificationCtrl.MobileVerificationSuccess,
                        ($event : angular.IAngularEvent) => {
                            this.$scope.user.mobileStatus = 'activated';
                        });
    }
}

interface IMobileVerificationScope extends IBaseScope {

}

angular.module('Row52.Views.Account.SellerRegistration.RegistrationWizard.MobileVerification', [])
       .controller('RegistrationWizardMobileVerificationCtrl', RegistrationWizardMobileVerificationCtrl);