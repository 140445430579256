/**
 * Created by Ri Scott on 6/27/2017.
 */
import * as angular from 'angular';
import '../../constants/constants';
import { ResourceService } from './odata.resource';
import { IPartsWantedCommentCreationModel, IPartsWantedCommentModel } from 'Row52.Models';

export class PartsWantedCommentService extends ResourceService<IPartsWantedCommentModel> {
    static $inject : string[] = ['$http', '$q', '$cookies', 'domain'];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'PartsWantedComments');
    }

    public createComment(listingId : number,
                         body : string,
                         parentId? : number,
                         attachmentId? : number) : angular.IPromise<IPartsWantedCommentModel> {

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/PartsWantedComments/`,
                      <IPartsWantedCommentCreationModel>{
                          parentId            : parentId,
                          attachmentId        : attachmentId,
                          body                : body,
                          partsWantedListingId: listingId
                      },
                      this.getHeaders())
        );
    }

    public getForListing(listingId : number) : angular.IPromise<IPartsWantedCommentModel[]> {
        let url = `${this.domain}/odata/PartsWantedComments/`
                  + `?$filter=partsWantedListingId eq ${listingId}`
                  + `&$orderby=creationDate desc`;
        return this.unwrapArray(
            this.$http
                .get(url, this.getHeaders())
        );
    }
}

angular.module('Row52.Services.PartsWantedCommentService', ['ngCookies', 'Row52.Constants'])
       .service('PartsWantedCommentService', PartsWantedCommentService);