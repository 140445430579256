/**
 * Created by Ri Scott on 6/26/2017.
 */
import * as angular from 'angular';
import * as moment from 'moment';
import '../../constants/constants';
import { ResourceCrudService } from './odata.resource';
import { IUserPartsWantedFavoriteRelationship } from 'Row52.Models.Entities';
import { IPartsWantedListingSearchResult } from 'Row52.Models';

export class PartsWantedFavoriteService extends ResourceCrudService<IUserPartsWantedFavoriteRelationship> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'UserPartsWantedFavouriteRelationships');
    }

    public createFavorite(listingId : number, userId : string) : angular.IPromise<IUserPartsWantedFavoriteRelationship> {
        let creationDate = moment()
            .toDate();

        let favorite = {
            partsWantedListingId : listingId,
            userId               : userId,
            id                   : 0,
            user                 : null,
            partsWantedListing   : null,
            creationDate         : creationDate
        };

        return this.create(favorite);
    }

    public deleteAllForListing(listingId : number, userId : string) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}/Row52.DeleteAllForListing`,
                      { ListingId : listingId, UserId : userId },
                      this.getHeaders())
        );
    }

    public getForListing(listingId : number, userId : string) : angular.IPromise<IUserPartsWantedFavoriteRelationship[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/?$filter=partsWantedListingId eq `
                     + `${listingId} and userId eq ${userId}`,
                    this.getHeaders())
        );
    }

    public getForUser(userId : string) : angular.IPromise<IPartsWantedListingSearchResult[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/Users(${userId})/Row52.GetAllPartsWantedFavorites`,
                     this.getHeaders())
        );
    }
}

angular.module('Row52.Services.PartsWantedFavoriteService', [ 'ngCookies', 'Row52.Constants' ])
       .service('PartsWantedFavoriteService', PartsWantedFavoriteService);