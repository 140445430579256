import * as angular from 'angular';

import './quotes';
import './incoming-quote-detail/incoming-quote-detail';
import './incoming-quote-list/incoming-quote-list';
import './outgoing-quote-list/outgoing-quote-list';
import './outgoing-quote-detail/outgoing-quote-detail';
import './outgoing-quote-edit/outgoing-quote-edit';
import './outgoing-quote-edit/outgoing-quote-submitter.service';
import './estimate-shipping-costs/estimate-shipping-costs';
import './invalid-cost-modal.html';

angular.module('Row52.Views.Account.Quotes', [
    'Row52.Views.Account.Quotes.QuoteCtrl',
    'Row52.Views.Account.Quote.IncomingQuoteDetail',
    'Row52.Views.Account.Quote.IncomingQuoteList',
    'Row52.Views.Account.Quotes.OutgoingQuoteList',
    'Row52.Views.Account.Quotes.OutgoingQuoteDetail',
    'Row52.Views.Account.Quotes.OutgoingQuoteEdit',
    'Row52.Views.Account.Quotes.EstimateShippingCosts',
    'Row52.Views.Account.Quotes.OutgoingQuoteSubmitter'
]);