import * as angular from 'angular';
import * as _ from 'lodash';
import { IPartsWantedListingSearchResult, IUserJsonModel, IVehicleFavorite } from 'Row52.Models';
import '../../../templates/account/favorites/parts-wanted.html';
import { MessagesService } from '../../../services/messages.service';
import { PartsWantedFavoriteService } from '../../../services/odata/parts-wanted-favorite.service';
import { PartsWantedStatusService } from '../../../services/data/parts-wanted-status.service';

interface IPartsWantedFavoriteListScope extends angular.IScope {
    listings : IPartsWantedListingSearchResult[];
    loading : boolean;
    user : IUserJsonModel;
}

class FavoritePartsWantedCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'PartsWantedFavoriteService',
        'PartsWantedStatusService',
        'MessagesService'
    ];

    constructor(private $scope : IPartsWantedFavoriteListScope,
        private $window : any,
        private favoriteService : PartsWantedFavoriteService,
        public pwStatusService : PartsWantedStatusService,
        private messageService : MessagesService) {
        this.$scope.loading = true;
        this.$scope.user = this.$window['user'];

        this.loadData();
    }

    sendMessage($event : angular.IAngularEvent, listing : IPartsWantedListingSearchResult) {
        $event.preventDefault();

        this.messageService.open(listing.userName, 'Re: ' + listing.partsTitle.substring(0, 46));
    }

    loadData = async () => {
        this.$scope.listings = await this.favoriteService.getForUser(this.$scope.user.id);
        this.$scope.loading = false;
    };

    deleteFavorite = async ($event : angular.IAngularEvent, listing : IPartsWantedListingSearchResult) => {
        $event.preventDefault();
        await this.favoriteService.deleteAllForListing(listing.id, this.$scope.user.id);
        _.remove(this.$scope.listings,
            (f : IPartsWantedListingSearchResult) => {
                return f.id === listing.id;
            });
    };
}

angular.module('Row52.Views.Account.Favorites.FavoritePartsWantedCtrl', [])
    .controller('FavoritePartsWantedCtrl', FavoritePartsWantedCtrl);