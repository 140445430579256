import * as angular                         from 'angular';
import {
    IPartsSaleCommentCreationModel,
    IPartsSaleCommentModel
}                                           from 'Row52.Models';
import { IAttachment, IPartsWantedListing } from 'Row52.Models.Entities';
import { AttachmentService }                from '../../../../services/images/attachment.service';
import { ImageServiceModal }                from '../../../../services/images/image.service';
import { PartsSaleCommentService }          from '../../../../services/odata/parts-sale-comments.service';
import './comments.html';
import './comments.scss';
import { BaseController, IBaseScope } from '../../../../base-controller';
import { RemoteErrorModalService } from '../../../../services/ui/remoteerror.modal';

class PartsSaleCommentCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$http',
        '$window',
        'PartsSaleCommentService',
        'AttachmentService',
        'ImageServiceModal',
        'remoteErrorModalService'
    ];
     
    constructor(protected $scope : ICommentsScope,
                protected $http : angular.IHttpService,
                protected $window : angular.IWindowService,
                protected commentService : PartsSaleCommentService,
                protected attachmentService : AttachmentService,
                protected imageModalService : ImageServiceModal,
                private remoteErrorService : RemoteErrorModalService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.listing    = this.$window['listing'];
        this.$scope.newComment = {
            body              : '',
            attachmentId      : null,
            parentId          : null,
            partsSaleListingId: this.$scope.listing.id
        };

        await this.initComments();
        this.$scope.uploadImage = this.uploadImage;
    }

    private async initComments() {
        try {
            this.$scope.commentsLoading = true;
            this.$scope.comments = await this.commentService.getForListing(this.$scope.listing.id);
        }
        catch (err) {

        }
        this.$scope.commentsLoading = false;
    }

    async submitComment($event : angular.IAngularEvent,
                        commentForm : angular.IFormController,
                        comment? : IPartsSaleCommentModel) {
        $event.preventDefault();

        if (commentForm.$valid) {
            this.$scope.newCommentLoading = true;

            if (comment) {
                this.$scope.newComment.parentId = comment.id;
            }

            try {
                let newComment = await this.commentService
                                           .createComment(this.$scope.listing.id,
                                                          this.$scope.newComment.body,
                                                          this.$scope.newComment.parentId,
                                                          this.$scope.newComment.attachmentId);

                if (comment) {
                    comment.children.push(newComment);
                }
                else {
                    this.$scope.comments.unshift(newComment);
                }

                this.$scope.newComment = {
                    body              : '',
                    attachmentId      : null,
                    parentId          : null,
                    partsSaleListingId: this.$scope.listing.id
                };
                this.$scope.attachment = null;
            }
            catch (err) {

            }

            this.$scope.newCommentLoading = false;
            commentForm.$setPristine();
            commentForm.$setUntouched();
        }
    }

    uploadImage = (files : FileList) => {
        if (files.length > 1) {
            this.remoteErrorService
                .open('Attention',
                    'You may only upload one image at a time.',
                    { okBtnText: 'OK' });
            return false;
        }

        //Limit file size for 3MB
        if (files[0].size > 3 * 1024 * 1024) {
            this.remoteErrorService
                .open('Attention',
                    'Maximum file size is 3Mb.',
                    { okBtnText: 'OK' });
            return false;
        }

        this.$scope.uploadingImage = true;

        let file = files[0];
        let data = new FormData();
        data.append('file', file);

        this.attachmentService
            .upload(data)
            .then((attachment : IAttachment) => {
                this.$scope.newComment.attachmentId = attachment.id;
                this.$scope.attachment              = attachment;
            }, () => {
                //TODO: add error messaging;
            })
            .finally(() => {
                this.$scope.uploadingImage = false;
            });
    };

    removeImage($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.attachment              = null;
        this.$scope.newComment.attachmentId = null;
    }

    displayImage($event : angular.IAngularEvent, comment : IPartsSaleCommentModel) {
        $event.preventDefault();

        this.imageModalService.open(comment.fullImageUrl);
    }
}

class PartsSaleCommentReplyCtrl extends PartsSaleCommentCtrl {
    static $inject : string[] = [
        '$scope',
        '$http',
        '$window',
        'PartsSaleCommentService',
        'AttachmentService',
        'ImageServiceModal'
    ];

    constructor(protected $scope : ICommentReplyScope,
                $http : angular.IHttpService,
                $window : angular.IWindowService,
                commentService : PartsSaleCommentService,
                attachmentService : AttachmentService,
                imageModalService : ImageServiceModal,
                remoteErrorService : RemoteErrorModalService) {

        super($scope, $http, $window, commentService, attachmentService, imageModalService, remoteErrorService);

        this.$scope.showReply = false;
    }

    showReply($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.showReply = !this.$scope.showReply;
    }
}

interface ICommentsScope extends IBaseScope {
    listing : IPartsWantedListing;
    commentForm : angular.IFormController;
    newCommentLoading : boolean;
    newComment : IPartsSaleCommentCreationModel;
    comments : IPartsSaleCommentModel[];
    commentsLoading : boolean;

    uploadImage : (files : FileList) => boolean;
    uploadingImage : boolean;
    attachment : IAttachment;
}

interface ICommentReplyScope extends ICommentsScope {
    showReply : boolean;
}

angular.module('Row52.Views.PartsSale.Detail.Comments', [
           'Row52.Services',
           'Row52.Services.PartsSaleCommentService',
           'Row52.Services.ImageModal'
       ])
       .controller('PartsSaleCommentCtrl', PartsSaleCommentCtrl)
       .controller('PartsSaleCommentReplyCtrl', PartsSaleCommentReplyCtrl);