import * as angular from 'angular';

interface IPreventOutOfRangeScope extends angular.IScope {
    max : string;
    min : string;
}

// Taken from here: https://codepen.io/Jaydo/pen/yOMZJd and updated to handle min values as well.
// Also, fixed issue with 0 being assigned to oldValue on small (one symbol) max values.
class PreventOutOfRangeDirective implements angular.IDirective {
    public restrict : string                   = 'A';
    public scope : { [key : string] : string } = {
        'max' : '@',
        'min' : '@'
    };

    public link : (scope : angular.IScope, element : ng.IAugmentedJQuery, attrs : ng.IAttributes) => void;

    constructor() {
        this.link = (scope : IPreventOutOfRangeScope, element : ng.IAugmentedJQuery, attrs : ng.IAttributes) => {

            let previousValue : number = null;
            element.on('keydown keyup', function (e : JQueryEventObject) {
                let currentRawValue : any = element.val();
                if (! currentRawValue) {
                    return;
                }

                let maxValue : number     = Number(scope.max);
                let minValue : number     = Number(scope.min);
                let currentValue : number = Number(currentRawValue);

                if ((currentValue > maxValue || currentValue < minValue)
                    && e.keyCode !== 46 // delete
                    && e.keyCode !== 8) { // backspace
                    e.preventDefault();
                    element.val(previousValue);
                } else if ((currentValue <= maxValue && currentValue >= minValue)) {
                    previousValue = currentValue;
                }
            });
        };
    }

    public static Factory() {
        let directive = () => {
            return new PreventOutOfRangeDirective();
        };

        return directive;
    }
}

angular
    .module('Row52.Directives')
    .directive('preventOutOfRange', PreventOutOfRangeDirective.Factory());