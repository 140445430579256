import * as angular          from 'angular';
import { IPartsSearchModel } from 'Row52.Models';

class PaginationCtrl {
    static $inject : string[] = [ '$scope', '$http', '$timeout' ];

    constructor(private $scope : IPaginationScope,
                private $http : angular.IHttpService,
                private $timeout : angular.ITimeoutService) {
    }

    initModel(model) {
        this.$scope.partsSearchModel = model;
    }

    setCurrentPage(currentPage) {
        if (!this.$scope.searchModel) {
            this.$scope.searchModel = {
                page          : 0,
                pw            : '',
                partsSaleText : '',
                makeId        : 0,
                modelId       : 0,
                distance      : 0,
                sort          : '',
                sortDirection : '',
                zipCode       : '',
                locationId    : 0,
                searchType    : true
            };
        }

        this.$scope.searchModel.page = currentPage;
    }

    submit($event : angular.IAngularEvent, suppress : boolean) {
        if (suppress) {
            $event.preventDefault();
        }

        let url : null;

        url = this.$scope.paginationForm.$$element[ 'context' ].action;

        return this.$http.get(url, { params : this.$scope.searchModel });
    }

    nextPage($event : angular.IAngularEvent, suppress : boolean) {
        if (suppress) {
            $event.preventDefault();
        }

        this.$scope.searchModel.page          = this.$scope.searchModel.page + 1;
        this.$scope.paginationForm.$submitted = true;

        if (!suppress) {
            this.$timeout(() => {
                this.$scope.paginationForm.commit();
            }, 150);
        }
    }

    prevPage($event : angular.IAngularEvent, suppress : boolean) {
        if (suppress) {
            $event.preventDefault();
        }

        this.$scope.searchModel.page          = this.$scope.searchModel.page - 1;
        this.$scope.paginationForm.$submitted = true;

        if (!suppress) {
            this.$timeout(() => {
                this.$scope.paginationForm.commit();
            }, 150);
        }
    }
}

interface IPaginationScope extends angular.IScope {
    paginationForm : angular.IFormController;
    partsSalesWantedResult : any;
    searchModel : {
        page : number;
        pw : string;
        partsSaleText : string;
        makeId : number;
        modelId : number;
        distance : number;
        sort : string;
        sortDirection : string;
        zipCode : string;
        locationId : number;
        searchType : boolean;
    };
    partsSearchModel : IPartsSearchModel;
}

angular.module('Row52.Views')
       .controller('PaginationCtrl', PaginationCtrl);