/**
 * Created by Ri Scott on 5/5/2017.
 */
import * as angular from 'angular';
import '../../constants/constants';
import { ResourceService } from './odata.resource';
import { IMake } from 'Row52.Models.Entities';
import { Tso } from 'ts-odata/src/Tso';

export class MakeResourceService extends ResourceService<IMake> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'Makes');
    }

    public getSorted() : angular.IPromise<IMake[]> {
        let query : Tso = this.createQuery()
            .orderBy('name')
            .asc();
        return this.getByQuery(query);
    }
}

angular.module('Row52.Services.MakeService', [ 'ngCookies', 'Row52.Constants' ])
       .service('MakeService', MakeResourceService);