/// <reference types="@types/googlemaps" />
import * as angular                 from 'angular';
import { ConfirmModalService }      from '../../../services/ui/confirm.modal';
import IWindowService = angular.IWindowService;
import { ICountry, IState, IUser }  from 'Row52.Models.Entities';
import { UserService }              from '../../../services/odata/user.service';
import { StateService }             from '../../../services/odata/state.service';
import { AddressCompletionService } from '../../../services/helpers/address.completion.service';
import { CountryService }           from '../../../services/odata/country.service';

interface ISettingsScope extends angular.IScope {
    user : IUser;
    personalInfoLoading : boolean;
    password : {
        password : string,
        newPassword : string,
        confirmPassword : string
    };
    passwordLoading : boolean;
    changePassword : angular.IFormController;
    alertInfo : any[];
    alertAddress : any[];
    alertPassword : any[];
    alertAccount : any[];
    collapsed : boolean;
    personalInfo : angular.IFormController;
    addressForm : angular.IFormController;
    addressLoading : boolean;
    states : IState[];

    avatarUploading : boolean;
    place : google.maps.places.PlaceResult;

    countries : ICountry[];
}

class SettingsCtrl {
    static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(private $scope : ISettingsScope,
                private $window : IWindowService) {
        this.init();
    }

    init = async () => {
        this.$scope.alertAddress  = [];
        this.$scope.alertInfo     = [];
        this.$scope.alertPassword = [];
        this.$scope.alertAccount  = [];
        this.$scope.user          = this.$window[ 'userModel' ];
        this.$scope.collapsed     = true;
    };
}

angular.module('Row52.Views.Account.Settings.SettingsCtrl', [])
       .controller('SettingsCtrl', SettingsCtrl);
