import * as angular from 'angular';
import * as uib     from 'angular-ui-bootstrap';
import '../../templates/image/imageModal.html';

interface IImageModalScope extends angular.IScope {
    close : () => void;
    imgSrc : string;
}

export class ImageServiceModal {
    static $inject : string[] = [ '$uibModal' ];

    constructor(private $modal : uib.IModalService) {
    }

    open(imgSrc : string) {

        let instance = this.$modal
                           .open({
                                     templateUrl : 'image/imageModal.html',
                                     controller  : [
                                         '$scope',
                                         ($scope : IImageModalScope) => {
                                             $scope.imgSrc = imgSrc;
                                             $scope.close  = () => {
                                                 instance.close();
                                             };
                                         }
                                     ],
                                     size        : 'md'
                                 });

    }
}


angular.module('Row52.Services.ImageModal', [
           'ui.bootstrap'
       ])
       .service('ImageServiceModal', ImageServiceModal);