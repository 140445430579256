import * as angular from 'angular';
import '../../constants/constants';
import { ResourceCrudService } from './odata.resource';
import { IPartsSaleListing } from 'Row52.Models.Entities';
import { IStripeChargeFailure } from '../marketplace/stripe.service';

export class PartsForSalePaymentService extends ResourceCrudService<IPartsSaleListing> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'PartsForSaleListings');
    }

    purchase(listing : IPartsSaleListing,
             token : string,
             cardBrand : string,
             shipmentId? : string,
             rateId? : string) : angular.IPromise<IPartsSaleListing | IStripeChargeFailure> {
        let data = {
            token: token,
            shippingFirstName : listing.shippingFirstName,
            shippingLastName  : listing.shippingLastName,
            toAddress1        : listing.toAddress1,
            toCity            : listing.toCity,
            toCountryId       : listing.toCountryId,
            toZipCode         : listing.toZipCode,
            toIsResidential   : listing.toIsResidential,
            billingFirstName  : listing.billingFirstName,
            billingLastName   : listing.billingLastName,
            billingAddress1   : listing.billingAddress1,
            billingCity       : listing.billingCity,
            billingCountryId  : listing.billingCountryId,
            billingZipCode    : listing.billingZipCode,
            billingCardBrand  : cardBrand
        };

        this.addData(data, listing.toEmailAddress, 'toEmailAddress');
        this.addData(data, listing.toAddress2, 'toAddress2');
        this.addData(data, listing.toStateId, 'toStateId');
        this.addData(data, listing.toProvinceName, 'toProvinceName');
        this.addData(data, listing.toLatitude, 'toLatitude');
        this.addData(data, listing.toLongitude, 'toLongitude');

        this.addData(data, listing.billingEmailAddress, 'billingEmailAddress');
        this.addData(data, listing.billingAddress2, 'billingAddress2');
        this.addData(data, listing.billingStateId, 'billingStateId');
        this.addData(data, listing.billingProvinceName, 'billingProvinceName');
        this.addData(data, listing.billingLatitude, 'billingLatitude');
        this.addData(data, listing.billingLongitude, 'billingLongitude');

        this.addData(data, shipmentId, 'shipmentId');
        this.addData(data, rateId, 'rateId');

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.Purchase`, data, this.getHeaders())
        );
    }

    private addData(data : any, dataToAdd : any, fieldName) {
        if (dataToAdd) {
            data[ fieldName ] = dataToAdd;
        }
    }
}

angular.module('Row52.Services.PartsForSalePaymentService', [ 'ngCookies', 'Row52.Constants' ])
       .service('PartsForSalePaymentService', PartsForSalePaymentService);