/**
 * Created by Ri Scott on 7/3/2017.
 */
import * as angular from 'angular';
import * as qq      from 'fine-uploader/lib/traditional.js';
import 'fine-uploader/jquery.fine-uploader/fine-uploader.css';
import '../../css/fine-uploader-gallery.css';

export interface IFineUploaderScope extends angular.IScope {
    uploadButtonText : string;
    dropZoneText : string;
}

class FineUploaderDirective implements angular.IDirective {

    restrict : 'A';

    replace : true;

    constructor(private $compile : angular.ICompileService,
                private $interpolate : angular.IInterpolateService,
                private $cookies : angular.cookies.ICookiesService,
                private domain : string) {

    }

    link = ($scope : IFineUploaderScope, element : angular.IAugmentedJQuery, attrs) => {
        let endpoint                    = attrs.uploadServer,
            notAvailablePlaceholderPath = attrs.notAvailablePlaceholder,
            waitingPlaceholderPath      = attrs.waitingPlaceholder,
            acceptFiles                 = attrs.allowedMimes,
            sizeLimit                   = attrs.maxFileSize,
            // largePreviewSize            = parseInt(attrs.largePreviewSize),
            allowedExtensions           = JSON.parse(attrs.allowedExtensions),
            // previewDialog               = document.querySelector('.large-preview'),
            options                     = {
                debug   : true,
                element : element[ 0 ],
                request : {
                    endpoint      : `${this.domain}/odata/Attachments`,
                    customHeaders : this.getHeaders()
                },

                validation : {
                    acceptFiles       : acceptFiles,
                    allowedExtensions : allowedExtensions,
                    sizeLimit         : sizeLimit
                },

                deleteFile : {
                    endpoint : endpoint,
                    enabled  : true
                },

                thumbnails : {
                    placeholders : {
                        notAvailablePath : notAvailablePlaceholderPath,
                        waitingPath      : waitingPlaceholderPath
                    }
                },

                display : {
                    prependFiles : true
                },

                failedUploadTextDisplay : {
                    mode : 'custom'
                },

                retry : {
                    enableAuto : true
                },

                chunking : {
                    enabled  : true,
                    partSize : 10000000
                },

                resume : {
                    enabled : true
                },

                callbacks : {
                    onSubmitted : function (id) {
                        try {
                            let fileEl      = this.getItemByFileId(id),
                                thumbnailEl = fileEl.querySelector('.thumbnail-button');

                            thumbnailEl.addEventListener('click',
                                                         function () {
                                                             // openLargerPreview($scope,
                                                             //                   uploader,
                                                             //                   previewDialog,
                                                             //                   largePreviewSize,
                                                             //                   id);
                                                         });
                        }
                        catch {
                        }


                    },

                    onComplete : function (id : number, name : string, responseJson : any) {
                        $scope.$emit('fineUploader:file-upload', id, responseJson);
                    },

                    onSubmitDelete : function (id : number) {
                        $scope.$emit('fineUploader:file-removed', id);
                        this.getItemByFileId(id)
                            .remove();
                        this.removeFileRef(id);

                        return false;
                    }
                }
            };

        new qq.FineUploader(options);

        // dialogPolyfill.registerDialog(previewDialog);
        // $scope.closePreview = closePreview.bind(this, previewDialog);
        FineUploaderDirective.bindToRenderedTemplate(this.$compile, $scope, this.$interpolate, element);
    };

    private getHeaders() : any {
        const authCookieValue = this.$cookies.get('R52ACK');
        let config            = {};

        if (authCookieValue) {
            config[ 'Authorization' ] = 'Spider ' + authCookieValue;
        }

        return config;
    }

    static isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    static initButtonText($scope : IFineUploaderScope) {
        let input = document.createElement('input');

        input.setAttribute('multiple', 'true');

        if (input.multiple === true && !qq.android()) {
            $scope.uploadButtonText = 'Select Files';
        }
        else {
            $scope.uploadButtonText = 'Select a File';
        }
    }

    static initDropZoneText($scope : IFineUploaderScope, $interpolate : angular.IInterpolateService) {
        if (qq.supportedFeatures.folderDrop && !FineUploaderDirective.isTouchDevice()) {
            $scope.dropZoneText = 'Drop Files or Folders Here';
        }
        else if (qq.supportedFeatures.fileDrop && !FineUploaderDirective.isTouchDevice()) {
            $scope.dropZoneText = 'Drop Files Here';
        }
        else {
            $scope.dropZoneText = $scope.$eval($interpolate('Press \'{{uploadButtonText}}\''));
        }
    }

    static bindToRenderedTemplate($compile : angular.ICompileService,
                                  $scope : IFineUploaderScope,
                                  $interpolate : angular.IInterpolateService,
                                  element : angular.IAugmentedJQuery) {
        // @ts-ignore
        $compile(element.contents())($scope);

        FineUploaderDirective.initButtonText($scope);
        FineUploaderDirective.initDropZoneText($scope, $interpolate);
    }

    public static Factory() {
        let directive = ($compile : angular.ICompileService,
                         $interpolate : angular.IInterpolateService,
                         $cookies : angular.cookies.ICookiesService,
                         domain : string) => {
            return new FineUploaderDirective($compile, $interpolate, $cookies, domain);
        };

        directive[ '$inject' ] = [ '$compile', '$interpolate', '$cookies', 'domain' ];

        return directive;
    }
}

angular.module('Row52.Directives')
       .directive('fineUploader', FineUploaderDirective.Factory());