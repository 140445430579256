import * as angular from 'angular';

interface IResourcesWidgetScope extends angular.IScope {
}

class ResourcesWidgetCtrl {
    static $inject : string[] = [ '$scope' ];

    constructor(private $scope : IResourcesWidgetScope) {
    }
}

angular.module('Row52.Views.Account')
       .controller('ResourcesWidgetCtrl', ResourcesWidgetCtrl);