import * as angular from 'angular';
import { IUserJsonModel } from 'Row52.Models';
import { IUserVehicleSearch } from 'Row52.Models.Entities';
import { VehicleSearchService } from '../../services/odata/vehicle-search.service';
import * as _ from 'lodash';

interface ISavedSearchesScope extends angular.IScope {
    user : IUserJsonModel;
    searchesLoading : boolean;
    searches : IUserVehicleSearch[];
}

class SavedSearchesListController {
    public static $inject : string[] = [ '$scope', '$window', 'VehicleSearchService' ];

    constructor(private $scope : ISavedSearchesScope,
                private $window : angular.IWindowService,
                private searchService : VehicleSearchService) {
        this.$scope.searchesLoading = true;
        this.$scope.user            = this.$window[ 'user' ];

        this.loadData();
    }

    loadData = async () => {
        this.$scope.searches        = await this.searchService.getSearchesByUser(this.$scope.user.id);
        this.$scope.searchesLoading = false;
    };

    saveSearch = async ($event, search : IUserVehicleSearch) => {
        await this.searchService.update(search.id, search);
    };

    deleteSearch = async ($event : angular.IAngularEvent, search : IUserVehicleSearch) => {
        await this.searchService.delete(search.id);
        _.remove(this.$scope.searches, (s : IUserVehicleSearch) => {
            return s.id === search.id;
        });
    };
}

angular.module('Row52.Views.Account.SavedSearchesCtrl', [])
       .controller('SavedSearchesListController', SavedSearchesListController);