import * as angular from 'angular';
import 'angular-ui-carousel/dist/ui-carousel.css';

interface IMobileAppsScope extends angular.IScope {
    slides : object[]
}

class MobileAppsController {
    static $inject : string[] = ['$scope'];

    constructor(private $scope : IMobileAppsScope) {
        this.$scope.slides = [
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen1a.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen2a.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen3a.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen4a.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen5.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen6.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen7.jpg',
                class : 'android'
            },
            {
                imgSrc : 'https://cdn.row52.com/cdn/images/screen8.jpg',
                class : 'android'
            }
        ];
    }


}

angular.module('Row52.Views')
    .controller('MobileAppsController', MobileAppsController);