import * as angular from 'angular';
import { IPaginationState, IPaginationFilter } from 'Row52.Models';

export class PaginationService {
    public getFilterFromState(state : IPaginationState) : IPaginationFilter {
        let filter : IPaginationFilter = {
            skip : (state.currentPage - 1) * state.itemsPerPage,
            top  : state.itemsPerPage
        };

        // This is for incorrect input values, to make sure OData requests always receive correct value
        if (! filter.skip || filter.skip < 0) {
            filter.skip = 0;
        }

        return filter;
    }

    public createPaginationState(itemsPerPage : number = 25) : IPaginationState {
        let state : IPaginationState = {
            itemsPerPage : itemsPerPage,
            currentPage  : 1,
            totalPages   : 1
        };

        return state;
    }

    public updatePaginationStateWithTotalItems(state : IPaginationState, totalItems : number) : void {
        state.totalPages = Math.ceil(totalItems / state.itemsPerPage);
    }
}


angular.module('Row52.Services')
       .service('PaginationService', PaginationService);