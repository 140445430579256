import './consumer-parts-wanted-widget.html';
import './consumer-parts-wanted-widget.scss';
import '../../../partials/parts-wanted-listing-status/parts-wanted-listing-status.html';
import '../../../partials/parts-wanted-listing-dates/parts-wanted-listing-dates.html';

import * as angular                        from 'angular';
import * as _                              from 'lodash';
import { BaseController, IBaseScope }      from '../../../../base-controller';
import { PartsWantedListingService }       from '../../../../services/odata/parts-wanted-listing.service';
import { IPartsWantedListingSearchResult } from 'Row52.Models';
import { PartsWantedStatuses }             from '../../../../constants/parts-wanted-statuses';
import { ListingsTabs }                    from '../../../../constants/listings-tabs';

export class ConsumerPartsWantedWidgetCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'PartsWantedListingService',
        'currencyFilter'
    ];

    constructor(protected $scope : IConsumerPartsWantedWidgetScope,
                protected $window : angular.IWindowService,
                private pwService : PartsWantedListingService,
                private $currency : angular.IFilterCurrency) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.listingsLoading = true;
        await this.loadListings();
    }

    private async loadListings() {
        try {
            let result = await this.pwService.getPartsWantedListingByBuyer(this.$scope.user.id, 5);
            this.$scope.listings = _.filter(result.value, o => o.status !== PartsWantedStatuses.Expired);
            this.$scope.listingCount = result.count;
        }
        catch (err) {

        }

        this.$scope.listingsLoading = false;
    }

    public getPartPrice(price : number) : string {
        if (price !== null && price !== undefined) {
            return this.$currency(price);
        }
        return 'Negotiable';
    }

    goToPartsWanted($event : angular.IAngularEvent, listing : IPartsWantedListingSearchResult) {
        this.$window.location.href = `/PartsWanted/Detail/${listing.id}`;
    }

    goToPartsWantedListings($event : angular.IAngularEvent) {
        this.$window.location.href = `/Account/MyListing?tab=${ListingsTabs.PartsWantedListings}`;
    }
}

interface IConsumerPartsWantedWidgetScope extends IBaseScope {
    listingsLoading : boolean;
    listings : IPartsWantedListingSearchResult[];
    listingCount : number;
}

angular.module('Row52.Views.Account.Summary.ConsumerPartsWantedWidget', [])
       .controller('ConsumerPartsWantedWidgetCtrl', ConsumerPartsWantedWidgetCtrl);