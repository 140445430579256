import * as angular        from 'angular';
import { IUser }           from 'Row52.Models.Entities';
import { MessagesService } from '../../services/messages.service';
import { IUserJsonModel }  from 'Row52.Models';

interface IUserProfileScope extends angular.IScope {
    user : IUser;
    loggedInUser : IUserJsonModel;
}

class UserProfileCtrl {
    static $inject : string[] = [ '$scope', '$window', 'MessagesService' ];

    constructor(private $scope : IUserProfileScope,
                private $window : angular.IWindowService,
                private messageService : MessagesService) {
        this.$scope.user         = $window.profileUser;
        this.$scope.loggedInUser = $window[ 'user' ];
    }

    sendMessage(user : IUser, $event : angular.IAngularEvent) {
        $event.preventDefault();
        this.messageService.open(user.userName, '');
    }

    getImage = () => {
        if (this.$scope.user.avatarCroppedAbsolutePath) {
            return this.$scope.user.avatarCroppedAbsolutePath;
        }
        return 'https://cdn.row52.com/cdn/images/avatar300_opt.jpg';
    };

    canSendMessage = () => {
        return this.$scope.loggedInUser.isAuthenticated && this.$scope.loggedInUser.id !== this.$scope.user.id;
    };
}

angular.module('Row52.Views')
       .controller('UserProfileCtrl', UserProfileCtrl);