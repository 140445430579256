import * as angular                                 from 'angular';
import * as _                                       from 'lodash';
import { IShippingRate, ITaxModel, IUserJsonModel } from 'Row52.Models';
import { IPartsSaleListing, IState, ICountry }      from 'Row52.Models.Entities';
import { StateService }                             from '../../services/odata/state.service';
import { CountryService }                           from '../../services/odata/country.service';
import {
    IStripeTokenResponse, IStripeCardToken, IStripeChargeFailure
}                                                   from '../../services/marketplace/stripe.service';
import { PartsForSalePaymentService }               from '../../services/odata/parts-for-sale-payment.service';
import '../../templates/partsforsale/payment-details.html';
import '../../templates/partsforsale/payment-address.html';
import '../../templates/partsforsale/payment-shipping.html';
import '../../templates/partsforsale/payment-billing.html';
import { AddressCompletionService, IAddress }       from '../../services/helpers/address.completion.service';
import { PartsForSaleListingService }               from '../../services/odata/parts-for-sale-listing.service';
import { ShippingOfferTypes }                       from '../../constants/shipping-offer-types';

interface IPartsForSaleBuyScope extends angular.IScope {
    listing : IPartsSaleListing;
    user : IUserJsonModel;
    states : IState[];
    countries : ICountry[];
    dropdowns : any;
    submitPaymentLoading : boolean;
    payment : any;
    buyMode : string;
    rates : IShippingRate[];
    tax : ITaxModel;
    shipmentRate : IShippingRate;
    formModes : PurchaseFormModes;
    shippingTypes : ShippingOfferTypes;
    sameAsShipping : boolean;
    submitPaymentError : {
        status : number,
        message : string
    };
    shippingAddressError : boolean
    stripeValidationMessage : string;
}

export class PurchaseFormModes {
    public static Address : string       = 'address';
    public static ShippingRates : string = 'shipping';
    public static Payment : string       = 'payment';
    public static Confirmation : string  = 'confirmation';
}

class PartsForSaleBuyCtrl {
    // noinspection JSUnusedGlobalSymbols
    static $inject : string[] = [
        '$scope',
        '$window',
        'PartsForSalePaymentService',
        'stripeKey',
        'StateService',
        'CountryService',
        'AddressCompletionService',
        'PartsForSaleListingService',
        '$q'
    ];

    private stripe : any;
    private elements : any;
    private card : any;
    public addressForm : angular.IFormController;
    public billingForm : angular.IFormController;


    constructor(private $scope : IPartsForSaleBuyScope,
                private $window : angular.IWindowService,
                private paymentService : PartsForSalePaymentService,
                private stripeKey : string,
                private stateService : StateService,
                private countryService : CountryService,
                private addressService : AddressCompletionService,
                private partsSaleService : PartsForSaleListingService,
                private $q : angular.IQService) {
        // noinspection JSIgnoredPromiseFromCall
        this.init();
    }

    private init = async () => {
        this.$scope.user           = this.$window.user;
        this.$scope.listing        = this.$window.listing;
        this.$scope.buyMode        = 'address';
        this.$scope.formModes      = PurchaseFormModes;
        this.$scope.shippingTypes  = ShippingOfferTypes;
        this.$scope.sameAsShipping = true;

        this.stripe = Stripe(this.stripeKey);

        this.$scope.dropdowns            = {
            selectedState : null
        };
        this.$scope.submitPaymentLoading = false;
        this.$scope.payment              = {};

        this.$scope.submitPaymentError      = null;
        this.$scope.shippingAddressError    = false;
        this.$scope.stripeValidationMessage = null;

        let statePromise   = this.initStates();
        let countryPromise = this.initCountries();

        await this.$q.all([ statePromise, countryPromise ]);

        this.initAddress();
        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
                        this.addressService.createHandler(this.$scope.listing,
                                                          this.$scope.states,
                                                          this.$scope.countries,
                                                          this.$scope.dropdowns,
                                                          'to'));
    };

    private initStates = async () => {
        try {
            this.$scope.states = await this.stateService.getAll();
        }
        catch (err) {

        }
    };

    private initCountries = async () => {
        try {
            this.$scope.countries = await this.countryService.getSorted();
        }
        catch (err) {

        }
    };

    private initAddress = () => {
        let address : IAddress = {
            address1         : this.$scope.user.address1,
            city             : this.$scope.user.city,
            zipCode          : this.$scope.user.zipCode,
            latitude         : this.$scope.user.latitude,
            longitude        : this.$scope.user.longitude,
            stateId          : this.$scope.user.stateId,
            provinceName     : this.$scope.user.provinceName,
            countryId        : this.$scope.user.countryId,
            isInexactAddress : true
        };

        if (this.addressService.validate(address)) {
            this.$scope.listing.toAddress1     = this.$scope.user.address1;
            this.$scope.listing.toAddress2     = this.$scope.user.address2;
            this.$scope.listing.toCity         = this.$scope.user.city;
            this.$scope.listing.toStateId      = this.$scope.user.stateId;
            this.$scope.listing.toZipCode      = this.$scope.user.zipCode;
            this.$scope.listing.toCountryId    = this.$scope.user.countryId;
            this.$scope.listing.toLatitude     = this.$scope.user.latitude;
            this.$scope.listing.toLongitude    = this.$scope.user.longitude;
            this.$scope.listing.toProvinceName = this.$scope.user.provinceName;

            this.$scope.listing.shippingFirstName = this.$scope.user.firstName;
            this.$scope.listing.shippingLastName  = this.$scope.user.lastName;

            this.$scope.dropdowns.selectedCountry =
                _.find(this.$scope.countries, { 'id' : this.$scope.listing.toCountryId });
            this.$scope.dropdowns.selectedState   = _.find(this.$scope.states, { 'id' : this.$scope.listing.toStateId });
        }
        else {
            this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries, { 'id' : 234 });
        }
        this.cleanupProvinceState();
    };

    private initBillingAddress = () => {
        this.cleanupProvinceState();
        this.$scope.listing.billingFirstName    = this.$scope.listing.shippingFirstName;
        this.$scope.listing.billingLastName     = this.$scope.listing.shippingLastName;
        this.$scope.listing.billingEmailAddress = this.$scope.listing.toEmailAddress;
        this.$scope.listing.billingAddress1     = this.$scope.listing.toAddress1;
        this.$scope.listing.billingAddress2     = this.$scope.listing.toAddress2;
        this.$scope.listing.billingCity         = this.$scope.listing.toCity;
        this.$scope.listing.billingStateId      = this.$scope.listing.toStateId;
        this.$scope.listing.billingZipCode      = this.$scope.listing.toZipCode;
        this.$scope.listing.billingCountryId    = this.$scope.listing.toCountryId;
        this.$scope.listing.billingLatitude     = this.$scope.listing.toLatitude;
        this.$scope.listing.billingLongitude    = this.$scope.listing.toLongitude;
        this.$scope.listing.billingProvinceName = this.$scope.listing.toProvinceName;

        this.$scope.dropdowns.billingCountry =
            _.find(this.$scope.countries, { 'id' : this.$scope.listing.toCountryId });
        this.$scope.dropdowns.billingState   = _.find(this.$scope.states, { 'id' : this.$scope.listing.toStateId });
        this.cleanupProvinceState();
    };

    private cleanupProvinceState() {
        if (this.$scope.dropdowns.selectedCountry && !this.$scope.dropdowns.selectedCountry.hasStates) {
            this.$scope.dropdowns.selectedState = null;
        }
        if (this.$scope.dropdowns.selectedState != null) {
            this.$scope.listing.toStateId      = this.$scope.dropdowns.selectedState.id;
            this.$scope.listing.toProvinceName = null;
        }
        else {
            this.$scope.listing.toStateId = null;
        }
        if (this.$scope.dropdowns.billingCountry && !this.$scope.dropdowns.billingCountry.hasStates) {
            this.$scope.dropdowns.billingState = null;
        }
        if (this.$scope.dropdowns.billingState != null) {
            this.$scope.listing.billingStateId      = this.$scope.dropdowns.billingState.id;
            this.$scope.listing.billingProvinceName = null;
        }
        else {
            this.$scope.listing.billingStateId = null;
        }
    }

    private goToShipping = async () => {
        if (!this.addressForm.$valid) {
            return;
        }

        this.cleanupProvinceState();

        try {
            this.$scope.submitPaymentLoading = true;

            switch (this.$scope.listing.shippingOfferTypeId) {
                case ShippingOfferTypes.Fixed :
                    await this.calculateTaxes(this.$scope.listing.shippingPrice);
                    this.initBillingAddress();
                    this.$scope.buyMode = PurchaseFormModes.Payment;

                    break;
                case ShippingOfferTypes.Dynamic :
                    // This allows these operations to run in parallel, speeding up performance of the page-turn.
                    await this.$q.all([ this.calculateTaxes(0), this.calculateShippingRates() ]);
                    this.$scope.buyMode = PurchaseFormModes.ShippingRates;

                    break;
                case ShippingOfferTypes.Free :
                    await this.calculateTaxes(0);
                    this.initBillingAddress();
                    this.$scope.buyMode = PurchaseFormModes.Payment;

                    break;
            }
        }
        catch (ex) {
            this.$scope.shippingAddressError = true;
        }
        finally {
            this.$scope.submitPaymentLoading = false;
        }
    };

    private goToPayment = async () => {
        this.cleanupProvinceState();
        try {
            this.$scope.tax = await this.partsSaleService
                                        .calculateTax(this.$scope.listing,
                                                      this.$scope.dropdowns.selectedCountry.abbreviation,
                                                      this.$scope.listing.toProvinceName
                                                      || this.$scope.dropdowns.selectedState.abbreviation,
                                                      this.$scope.listing.toZipCode,
                                                      this.$scope.listing.toCity,
                                                      this.$scope.dropdowns.shipmentRate.listRate);

            this.$scope.buyMode = PurchaseFormModes.Payment;
        }
        catch (ex) {

        }
    };

    // noinspection JSUnusedGlobalSymbols
    nextStep = async ($event : angular.IAngularEvent, currentStep : string) => {
        $event.preventDefault();

        switch (currentStep) {
            case PurchaseFormModes.Address :
                await this.goToShipping();
                break;
            case PurchaseFormModes.ShippingRates :
                await this.goToPayment();
                break;
            case PurchaseFormModes.Payment :
                this.submitPayment();
                break;
            default :
                break;
        }
    };

    previousStep = ($event : angular.IAngularEvent, currentStep : string) => {
        $event.preventDefault();

        switch (currentStep) {
            case PurchaseFormModes.ShippingRates :
                this.$scope.buyMode = PurchaseFormModes.Address;
                break;
            case PurchaseFormModes.Payment :
                if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic) {
                    this.$scope.buyMode = PurchaseFormModes.ShippingRates;
                }
                else {
                    this.$scope.buyMode = PurchaseFormModes.Address;
                }
                break;
            default :
                this.$scope.buyMode = PurchaseFormModes.Address;
                break;
        }
    };

    private calculateTaxes = async (shippingPrice : number) => {
        this.$scope.tax = await this.partsSaleService
                                    .calculateTax(this.$scope.listing,
                                                  this.$scope.dropdowns.selectedCountry.abbreviation,
                                                  this.$scope.listing.toProvinceName
                                                  || this.$scope.dropdowns.selectedState.abbreviation,
                                                  this.$scope.listing.toZipCode,
                                                  this.$scope.listing.toCity,
                                                  shippingPrice);
    };

    private calculateShippingRates = async () => {
        this.$scope.rates = await this.partsSaleService
                                      .getShippingRates(this.$scope.listing,
                                                        this.$scope.listing.toAddress1,
                                                        this.$scope.listing.toAddress2,
                                                        this.$scope.listing.toCity,
                                                        this.$scope.listing.toProvinceName
                                                        || this.$scope.dropdowns.selectedState.abbreviation,
                                                        this.$scope.listing.toZipCode,
                                                        this.$scope.dropdowns.selectedCountry.abbreviation,
                                                        this.$scope.listing.shippingFirstName,
                                                        this.$scope.listing.shippingLastName,
                                                        true);
    };

    mountStripe = () => {
        let style = {
            base    : {
                color           : '#303238',
                fontSize        : '16px',
                lineHeight      : '32px',
                fontWeight      : '600',
                fontSmoothing   : 'antialiased',
                '::placeholder' : {
                    color : '#ccc'
                }
            },
            empty   : {
                color : '#e542f4'
            },
            invalid : {
                color    : '#e5424d',
                ':focus' : {
                    color : '#303238'
                }
            }
        };

        this.elements = this.stripe.elements();
        this.card     = this.elements.create('card', { style : style });
        this.card.mount('#card-element');

        this.card.addEventListener('change',
                                   (event) => {
                                       this.$scope.stripeValidationMessage = event.error ? event.error.message : null;
                                       this.$scope.$digest();
                                   });
    };

    private submitPayment = () => {
        if (this.billingForm.$valid) {
            this.$scope.submitPaymentLoading = true;

            this.$scope.listing.toCountryId = this.$scope.dropdowns.selectedCountry.id;
            this.$scope.listing.toCountry   = null;
            if (this.$scope.dropdowns.selectedState) {
                this.$scope.listing.toStateId = this.$scope.dropdowns.selectedState.id;
            }
            this.$scope.listing.toState = null;

            if (this.$scope.sameAsShipping) {
                this.initBillingAddress();
            }
            else {
                this.$scope.listing.billingCountryId = this.$scope.dropdowns.billingCountry.id;
                this.$scope.listing.billingCountry   = null;
                if (this.$scope.dropdowns.billingState) {
                    this.$scope.listing.billingStateId = this.$scope.dropdowns.billingState.id;
                }
                this.$scope.listing.billingState = null;
            }

            this.cleanupProvinceState();

            let shipmentId = null;

            if (this.$scope.dropdowns.shipmentRate) {
                shipmentId = this.$scope.dropdowns.shipmentRate.shipmentId;
            }

            let rateId = null;

            if (this.$scope.dropdowns.shipmentRate) {
                rateId = this.$scope.dropdowns.shipmentRate.id;
            }

            let name = `${this.$scope.listing.billingFirstName} ${this.$scope.listing.billingLastName}`;

            this.stripe
                .createToken(this.card,
                             {
                                 'name'            : name,
                                 'address_line1'   : this.$scope.listing.billingAddress1,
                                 'address_city'    : this.$scope.listing.billingCity,
                                 'address_zip'     : this.$scope.listing.billingZipCode,
                                 'address_country' : this.$scope.dropdowns.billingCountry.abbreviation
                             })
                .then((result : IStripeTokenResponse<IStripeCardToken>) => {
                          if (result.token && result.token.type === 'card') {
                              this.paymentService
                                  .purchase(this.$scope.listing,
                                            result.token.id,
                                            result.token.card.brand,
                                            shipmentId,
                                            rateId)
                                  .then((result : IPartsSaleListing | IStripeChargeFailure) => {
                                            if (PartsForSaleBuyCtrl.isPartsSaleListing(result)) {
                                                this.$window.location.href =
                                                    `/PartsSale/Detail/${this.$scope.listing.id}?purchased=true`;
                                            }
                                            else if (PartsForSaleBuyCtrl.isStripeError(result)) {
                                                console.log(result.code);
                                                console.log(result.message);
                                            }
                                        },
                                        (e) => {
                                            this.$scope.submitPaymentError = {
                                                status  : e.status,
                                                message : e.data.error.message
                                            };
                                        })
                                  .finally(() => {
                                      this.$scope.submitPaymentLoading = false;
                                  });
                          }
                          else {
                              this.$scope.submitPaymentLoading = false;
                          }
                      },
                      () => {
                          this.$scope.submitPaymentLoading = false;
                      });
        }
    };

    cancelShippment() {
        this.$window.history.back();
    }

    async updateTaxForShipping(shipRate : number) {
        await this.calculateTaxes(shipRate);
        this.calculatePayment();
    }

    calculatePayment() {
        let total = this.$scope.listing.price;

        if (this.$scope.tax) {
            total = total + this.$scope.tax.amountToCollect;
        }

        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic &&
            this.$scope.dropdowns.shipmentRate) {

            total = total + parseFloat(this.$scope.dropdowns.shipmentRate.listRate);
        }
        else {
            total = total + this.$scope.listing.shippingPrice;
        }

        return total;
    }

    // This is a hack, because we couldn't get it working in the view well.
    calcPay() {
        let price    = this.$scope.listing.price + this.$scope.tax.amountToCollect;
        let listRate = this.$scope.dropdowns.shipmentRate.listRate;

        return price + parseFloat(listRate);
    }

    private static isPartsSaleListing(x : any) : x is IPartsSaleListing {
        return x.id !== undefined && x.id !== null;
    }

    private static isStripeError(x : any) : x is IStripeChargeFailure {
        return x.code !== undefined && x.code !== null;
    }
}

angular.module('Row52.Views')
       .controller('PartsForSaleBuyCtrl', PartsForSaleBuyCtrl);