import * as angular                                         from 'angular';
import * as uib                                             from 'angular-ui-bootstrap';
import * as _                                               from 'lodash';
import { IUserJsonModel, IVehicleDetail, IVehicleFavorite } from 'Row52.Models';
import { ConfirmModalService }                              from '../../services/ui/confirm.modal';
import { VehicleDetailResourceService }                     from '../../services/odata/vehicle-detail.service';
import { VehicleFavoriteService }                           from '../../services/odata/vehicle-favorite.service';
import { VinModalService }                                  from '../../services/ui/vinmodal.service';
import '../../templates/vehicle/as-seen-on-popover.html';

interface ILike {
    [ key : number ] : boolean;
}

class VehicleDetailCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'confirmModalService',
        'VinModalService',
        '$rootScope',
        'VehicleFavoriteService',
        '$uibModal',
        'VehicleDetailResourceService'
    ];

    constructor(private $scope : IVehicleDetailScope,
                private $window : angular.IWindowService,
                private confirmModalService : ConfirmModalService,
                protected vinModalService : VinModalService,
                private $rootScope : angular.IRootScopeService,
                private vehicleFavoriteService : VehicleFavoriteService,
                private $modal : uib.IModalService,
                private vehicleService : VehicleDetailResourceService) {

        this.$scope.user               = $window[ 'user' ];
        this.$scope.model              = this.$window[ 'vehicleDetail' ];
        this.$scope.name               = `${ this.$scope.model.year } ${ this.$scope.model.make } ${ this.$scope.model.model }`;
        this.$scope.likes              = [];
        this.$scope.removeImageLoading = false;
        this.$scope.pwType             = 'ymm';

        if (this.$scope.user.isAuthenticated) {
            this.vehicleFavoriteService
                .getVehiclesForUser(this.$scope.user.id)
                .then((likes) => {
                    _.each(likes, (l : IVehicleFavorite) => {
                        this.$scope.likes[ l.id ] = true;
                    });
                });
        }
    }

    createPartsWantedListing($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (!this.$scope.user.isAuthenticated) {
            this.$window.location.href = '/Account/Login';
            return;
        }

        switch (this.$scope.pwType) {
            case 'ymm':
                this.$window.location.href = `/PartsWanted/Create?year=${ this.$scope.model.year }`
                                             + `&modelId=${ this.$scope.model.modelId }`;
                break;
            case 'vin':
                this.$window.location.href = `/PartsWanted/Create?vin=${ this.$scope.model.vin }`
                                             + `&vehicleId=${ this.$scope.model.id }`
                                             + `&year=${ this.$scope.model.year }`
                                             + `&modelId=${ this.$scope.model.modelId }`;
                break;
        }
    }

    removeImage = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        try {
            await this.confirmModalService
                      .open('Remove Vehicle Image',
                            'Are you sure you want to permanently delete this image?',
                            {
                                okBtnText     : 'Yes',
                                cancelBtnText : 'No'
                            });

            this.$scope.removeImageLoading = true;
            await this.vehicleService.removeImage(this.$scope.model.id);
            this.$scope.model.imageName    = 'https://cdn.row52.com/cdn/images/imagecomingsoon_600.jpg';
            this.$scope.removeImageLoading = false;
        }
        catch (err) {

        }
    };

    isInRole(role : string) : boolean {
        return _.includes(this.$scope.user.roles, role);
    }

    like = async ($event : angular.IAngularEvent, vehicleId : number) => {
        if ($event && $event.preventDefault) {
            $event.preventDefault();
        }

        if (!this.$scope.user.isAuthenticated) {
            let scope         = this.$rootScope.$new() as ILoginModalScope;
            scope.description = 'to make favorites and create alerts!';
            scope.redirectUrl = '/Search';
            this.$modal.open({
                                 templateUrl : 'modals/loginModal.html',
                                 scope       : scope
                             });
        }
        else {
            if (this.$scope.likes[ vehicleId ]) {
                await this.vehicleFavoriteService.deleteAllForVehicle(vehicleId, this.$scope.user.id);
                delete this.$scope.likes[ vehicleId ];
            }
            else {
                let v                            = await this.vehicleFavoriteService.createFavorite(vehicleId,
                                                                                                    this.$scope.user.id);
                this.$scope.likes[ v.vehicleId ] = true;
            }
        }
    };

    flag = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        try {
            await this.confirmModalService
                      .open('Incorrect Vehicle Image',
                            'Does the vehicle image shown here not match \n the year, make, or model? Please notify us.',
                            {
                                okBtnText        : 'Yes',
                                cancelBtnText    : 'No',
                                replyMessageText : 'Row52 will review it. Thanks!'
                            });

            this.vehicleService.reportIncorrectImage(this.$scope.model.id);
        }
        catch (err) {
            if (!err) {
                alert('Please login to flag this item');
                this.$window.location.href = `/Account/Login?RedirectUrl=/Vehicle/Index/${ this.$scope.model.vin }`;
            }
        }
    };
}

interface IVehicleDetailScope extends angular.IScope {
    pwType : 'ymm' | 'vin';
    model : IVehicleDetail;
    user : IUserJsonModel;
    name : string;
    likes : ILike;
    removeImageLoading : boolean;
}

interface ILoginModalScope extends angular.ui.bootstrap.IModalScope {
    description : string;
    redirectUrl : string;
}

angular.module('Row52.Views')
       .controller('VehicleDetailCtrl', VehicleDetailCtrl);