import * as angular from 'angular';
import { IBaseScope, BaseController } from '../../../../../base-controller';
import './puller-listing.html';
import './puller-listing.scss';
import '../../../../../templates/account/bio-description-template.html';
import { RegistrationWizardCtrl } from '../registration-wizard';

export class RegistrationWizardPullerListingCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    public static FinalStep = 'RegistrationWizardFinalStep';
    public static PreviousStep = 'PullerListingPreviousStep';

    public pullerInfoForm : angular.IFormController;

    constructor(protected $scope : IRegistrationWizardPullerListingScope,
        protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
    }

    finalStep($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.pullerInfoForm.$valid) {
            this.$scope.$emit(RegistrationWizardPullerListingCtrl.FinalStep);
        }
    }

    previousStep($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.$emit(RegistrationWizardPullerListingCtrl.PreviousStep);
    }
}

interface IRegistrationWizardPullerListingScope extends IBaseScope {
    wizardCtrl : RegistrationWizardCtrl;
    tosRequired : boolean;
}

angular.module('Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizardPullerListing', [])
    .controller('RegistrationWizardPullerListingCtrl', RegistrationWizardPullerListingCtrl);