import * as angular from 'angular';

export class FeatureTesterService {

    public localStorage : boolean;

    constructor() {
        this.localStorage = FeatureTesterService.testLocalStorage();
    }

    private static testLocalStorage() : boolean {
        try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');
            return true;
        }
        catch (exception) {
            return false;
        }
    }
}

angular.module('Row52.Services.FeatureTesterService', [])
       .service('FeatureTesterService', FeatureTesterService);

