import * as angular             from 'angular';
import { ICountry }             from 'Row52.Models.Entities';
import { Tso }                  from 'ts-odata/src/Tso';
import '../../constants/constants';
import { FeatureTesterService } from '../helpers/feature-tester';
import { ResourceService }      from './odata.resource';

export class CountryService extends ResourceService<ICountry> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain', 'FeatureTesterService', '$window' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string,
                private featureTest : FeatureTesterService,
                private $window : angular.IWindowService) {
        super($http, $q, $cookies, domain, 'Countries');
    }

    public getSorted() : angular.IPromise<ICountry[]> {
        let deferred = this.$q.defer() as angular.IDeferred<ICountry[]>;

        let query : Tso = this.createQuery()
                              .orderBy('sortOrder')
                              .asc();
        this.getByQuery(query).then((countries) => {
            deferred.resolve(countries);
        });
        return deferred.promise;
    }

    public getSortedBankingSupported() : angular.IPromise<ICountry[]> {
        let deferred = this.$q.defer() as angular.IDeferred<ICountry[]>;

        let requestUrl = `${ this.domain }/odata/${ this.resourceUrl }/?$filter=isStripeSupported eq true&$orderby=sortOrder`;
        this.unwrapArray(
            this.$http
                .get(requestUrl)
        ).then((countries : ICountry[]) => {
            deferred.resolve(countries);
        });

        return deferred.promise;
    }
}

angular.module('Row52.Services.CountryService', [ 'ngCookies', 'Row52.Constants', 'Row52.Services.FeatureTesterService' ])
       .service('CountryService', CountryService);
