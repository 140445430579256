import * as angular from 'angular';

angular.module('row52.security.token-updator', [ 'ngCookies' ])
       .service('tokenUpdator', [
           '$q', '$cookies',
           ($q : angular.IQService, $cookies : angular.cookies.ICookiesService) => {
               return {
                   'response' : function (response : angular.IHttpResponse<any>) {

                       const tokenCookieName = 'R52ACK';
                       const headers         = response.headers();
                       if (headers[ 'TokenExtension' ]) {
                           $cookies.put(tokenCookieName, headers[ 'TokenExtension' ], { path : '/' });
                       }

                       return response;
                   }
               };
           }
       ]);
