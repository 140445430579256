import * as angular from 'angular';
import './estimate-shipping-costs.html';
import './estimate-shipping-costs.scss';

export class EstimateShippingCostsModalService {

    static $inject : string[] = [
        '$uibModal'
    ];

    constructor(private $modal : angular.ui.bootstrap.IModalService) {
    }

    openModal($event : angular.IAngularEvent) {
        $event.preventDefault();
        this.$modal.open({
                             templateUrl : 'account/quotes/estimate-shipping-costs/estimate-shipping-costs.html',
                             size        : 'md',
                             controller  : 'EstimateShippingCostsModalCtrl as ctrl'
                         });
    }
}

export class EstimateShippingCostsModalCtrl implements angular.IController {
    static $inject : string[] = [ '$uibModalInstance' ];

    constructor(private modalInstance : angular.ui.bootstrap.IModalInstanceService) {
    }

    ok() {
        this.modalInstance.dismiss('cancel');
    }
}

angular.module('Row52.Views.Account.Quotes.EstimateShippingCosts', [
           'ui.bootstrap'
       ])
       .service('EstimateShippingCostsModalService', EstimateShippingCostsModalService)
       .controller('EstimateShippingCostsModalCtrl', EstimateShippingCostsModalCtrl);