import * as angular                    from 'angular';
import { IHttpResponse }               from 'angular';
import { IQuoteModel, IUserJsonModel } from 'Row52.Models';
import { IPartsWantedListing }         from 'Row52.Models.Entities';
import { BaseController }              from '../../base-controller';
import { PartsWantedStatuses }         from '../../constants/parts-wanted-statuses';
import { QuotesTabs }                  from '../../constants/quotes-tabs';
import { PartWantedQutoteStatuses }    from '../../constants/statuses';
import { StripeFeeCalculatorService }  from '../../services/marketplace/stripe-fee-calculator.service';
import { PartsWantedQuoteService }     from '../../services/odata/parts-wanted-quote.service';
import '../../templates/partswantedlisting/submit-quote.html';

interface IPartsWantedSubmitQuoteScope extends angular.IScope {
    listing : IPartsWantedListing;
    quote : IQuoteModel;
    dateNow : any;
    user : IUserJsonModel;
    quoteStatuses : PartWantedQutoteStatuses;
    partsWantedStatuses : PartsWantedStatuses;
    quotesTabs : QuotesTabs;
}

class PartsWantedSubmitQuoteCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', '$location', 'PartsWantedQuoteService', 'StripeFeeCalculator' ];

    constructor(protected $scope : IPartsWantedSubmitQuoteScope,
                protected $window : angular.IWindowService,
                private $location : angular.ILocationService,
                private pwqService : PartsWantedQuoteService,
                public feeCalculator : StripeFeeCalculatorService) {

        super();
        this.$scope.quoteStatuses       = PartWantedQutoteStatuses;
        this.$scope.listing             = $window[ 'listing' ];
        this.$scope.partsWantedStatuses = PartsWantedStatuses;
        this.$scope.quotesTabs          = QuotesTabs;

        this.pwqService
            .submittedQuote(this.$scope.listing.id, this.$scope.user.id)
            .then((quote : IQuoteModel) => {
                this.$scope.quote = quote;
            }, (response : IHttpResponse<any>) => {
                switch (response.status) {
                    case 404:
                        this.$scope.quote = null;
                        break;
                    default:
                        break;
                }
            });
    }

    redirectMyQuote(id) {
        this.$window.location.href = '/Account/MyQuotes?tab=1&qid=' + id;
    }

    calculateStripeFees() {
        if (this.$scope.quote) {
            if (!this.$scope.quote.partsLaborQuote
                && !this.$scope.quote.shippingQuote
                && !this.$scope.quote.taxes) {
                return null;
            }

            return ( this.$scope.quote.partsLaborQuote
                   + this.$scope.quote.shippingQuote
                   + this.$scope.quote.taxes
                   + this.$scope.quote.fees ) *
                   this.$window.row52Application.stripeFeePercentage
                   + this.$window.row52Application.stripeFlatFee;
        }

        return 0;
    }

    allowSubmitNew = () : boolean => {
        return this.$scope.listing.status === PartsWantedStatuses.Open
               && ( this.$scope.quote === null
                    || this.$scope.quote.status === PartWantedQutoteStatuses.Withdrawn
                    || this.$scope.quote.status === PartWantedQutoteStatuses.Declined
                    || this.$scope.quote.status === PartWantedQutoteStatuses.Expired
                    || this.$scope.quote.status === PartWantedQutoteStatuses.Refunded );
    };
}

angular.module('Row52.Views')
       .controller('PartsWantedSubmitQuoteCtrl', PartsWantedSubmitQuoteCtrl);
