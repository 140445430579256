import * as angular from 'angular';

interface IVehiclePartsGalleryScope extends angular.IScope {
}

class VehiclePartsGalleryCtrl {
    static $inject : string[] = [ '$scope', '$http', '$window' ];

    constructor(private $scope : IVehiclePartsGalleryScope,
                private $http : angular.IHttpService,
                private $window : angular.IWindowService) {
    }
}

angular.module('Row52.Views')
       .controller('VehiclePartsGalleryCtrl', VehiclePartsGalleryCtrl);