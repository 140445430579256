import * as angular from 'angular';
import '../../constants/constants';
import { ResourceCrudService } from './odata.resource';
import { IVehicle } from 'Row52.Models.Entities';

export class VehicleService extends ResourceCrudService<IVehicle> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'Vehicles');
    }

    public getVehiclesByYMM(year : number, modelId : number) : angular.IPromise<IVehicle[]> {
        return this.unwrapArray(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}()?$count=true&$filter=year eq ${year} ` +
                `and modelId eq ${modelId} and isActive eq true and isVisible eq true&$expand=location&$select=locationId`,
                this.getHeaders())
        );
    }
}

angular.module('Row52.Services.VehicleService', [ 'ngCookies', 'Row52.Constants' ])
       .service('VehicleService', VehicleService);