import * as angular from 'angular';
import { IHttpPromiseCallbackArg } from 'angular';

interface IContactUsScope extends angular.IScope {
    contactUsForm : angular.IFormController;
    wasSuccessful : boolean;
    hadError : boolean;
    errorMessage : string;
    contactUs : {
        type : string;
        name : string;
        phone : string;
        email : string;
        message : string;
        reason : string;
    };
}

class ContactUsCtrl {
    static $inject : string[] = [ '$scope', '$http', 'domain', '$timeout' ];

    constructor(private $scope : IContactUsScope,
                private $http : angular.IHttpService,
                private domain : string,
                private $timeout : angular.ITimeoutService) {

        this.$scope.hadError      = false;
        this.$scope.wasSuccessful = false;
    }

    submitContact($event : angular.IAngularEvent) {
        if (! this.$scope.contactUsForm.$valid) {
            $event.preventDefault();

            let errors = [];

            for (let key in this.$scope.contactUsForm.$error) {
                for (let index = 0; index < this.$scope.contactUsForm.$error[ key ].length; index ++) {
                    errors.push(this.$scope.contactUsForm.$error[ key ][ index ].$name + ' is invalid.');
                }
            }

            if (errors.length > 0) {
                this.$scope.wasSuccessful = false;
                this.$scope.hadError      = true;
                this.$scope.errorMessage  = errors[ 0 ];
            }
            return;
        }

        this.$http({
                       method  : 'POST',
                       url     : this.domain + '/api/contactus',
                       data    : this.$scope.contactUs,
                       headers : {
                           'Content-type' : 'application/json'
                       }
                   })
            .then((response : angular.IHttpPromiseCallbackArg<any>) => {
                this.$scope.contactUs.type    = undefined;
                this.$scope.contactUs.name    = undefined;
                this.$scope.contactUs.phone   = undefined;
                this.$scope.contactUs.email   = undefined;
                this.$scope.contactUs.message = undefined;
                this.$scope.contactUs.reason = undefined;

                this.$scope.contactUsForm.$setUntouched();
                this.$scope.contactUsForm.$setPristine();

                this.$scope.wasSuccessful = true;
                this.$timeout(() => {
                    this.$scope.wasSuccessful = false;
                }, 500);
            }, (response : IHttpPromiseCallbackArg<any>) => {
                this.$scope.hadError     = true;
                this.$scope.errorMessage = 'blah';
            });

        this.$scope.hadError      = false;
        this.$scope.wasSuccessful = true;
    }

}

angular.module('Row52.Views')
       .controller('ContactUsCtrl', ContactUsCtrl);