import * as angular from 'angular';

interface ICurrencyScope extends angular.IScope {
    customCompareValue : any;
}

class CurrencyDirective implements angular.IDirective {
    public restrict : string = 'A';
    public scope : { [key : string] : string } = {
        'customCompareValue' : '&'
    };
    public require : string = 'ngModel';

    public link : (scope : ICurrencyScope,
        element : ng.IAugmentedJQuery,
        attrs : ng.IAttributes,
        ctrl : ng.INgModelController) => void;

    constructor($filter : angular.IFilterService) {
        this.link = (scope : ICurrencyScope,
            element : ng.IAugmentedJQuery,
            attrs : ng.IAttributes,
            ctrl : ng.INgModelController) => {
            ctrl.$formatters.push(function(a) {
                let v = '0.00';
                let rv = 0.00;
                if (a) {
                    v = $filter('currency')(parseFloat(a.toString().replace(/[^\-0-9\.]/g, '')), '', 2);
                    rv = parseFloat(a.toString().replace(/[^\-0-9\.]/g, ''));
                    if (attrs['currencyMin']) {
                        let minVal = parseFloat(attrs['currencyMin']);
                        if (rv < minVal) {
                            ctrl.$setValidity('min', false);
                        } else {
                            ctrl.$setValidity('min', true);
                        }
                    }
                    if (attrs['currencyMax']) {
                        let maxVal = parseFloat(attrs['currencyMax']);
                        if (rv > maxVal) {
                            ctrl.$setValidity('max', false);
                        } else {
                            ctrl.$setValidity('max', true);
                        }
                    }
                } else {
                    v = null;
                }
                return v;
            });
            ctrl.$parsers.push(function(v) {
                let rv = 0.00;
                if (v) {
                    ctrl.$setViewValue($filter('currency')(parseFloat(v.replace(/[^\-0-9\.]/g, '')), '', 2));
                    rv = parseFloat(v.replace(/[^\-0-9\.]/g, ''));
                    if (attrs['currencyMin']) {
                        let minVal = parseFloat(attrs['currencyMin']);
                        if (rv < minVal) {
                            ctrl.$setValidity('min', false);
                        } else {
                            ctrl.$setValidity('min', true);
                        }
                    }
                    if (attrs['currencyMax']) {
                        let maxVal = parseFloat(attrs['currencyMax']);
                        if (rv > maxVal) {
                            ctrl.$setValidity('max', false);
                        } else {
                            ctrl.$setValidity('max', true);
                        }
                    }
                } else {
                    rv = null;
                }
                if (scope && scope.customCompareValue && typeof (scope.customCompareValue) === 'function') {
                    scope.customCompareValue();
                }
                return rv;
            });

            element.bind('blur',
                function() {
                    ctrl.$render();
                });
        };
    }

    public static Factory() {
        let directive = ($filter : angular.IFilterService) => {
            return new CurrencyDirective($filter);
        };

        directive['$inject'] = ['$filter'];

        return directive;
    }
}

angular
    .module('Row52.Directives')
    .directive('currency', CurrencyDirective.Factory());