import * as angular                         from 'angular';
import { IBaseScope, BaseController }       from '../../../../base-controller';
import './display-personal-info.html';
import './display-personal-info.scss';
import { IUser, IStripeMarketplaceAccount } from 'Row52.Models.Entities';

export class DisplayPersonalInfoCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];
    public static DisplayEdit        = 'DisplayEditPersonalInfo';

    constructor(protected $scope : IDisplayPersonalInfoScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        
        this.$scope.userModel      = this.$window[ 'userModel' ];
    }

    edit($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.$emit(DisplayPersonalInfoCtrl.DisplayEdit);
    }
}

interface IDisplayPersonalInfoScope extends IBaseScope {
    userModel : IUser;
}

angular.module('Row52.Views.Account.SellerRegistration.DisplayPersonalInfo', [])
       .controller('DisplayPersonalInfoCtrl', DisplayPersonalInfoCtrl);
