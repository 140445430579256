import * as angular from 'angular';
import { IPartsWantedQuote } from 'Row52.Models.Entities';
import { IQuoteModel } from 'Row52.Models';
import * as _ from 'lodash';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { PartWantedQutoteStatuses } from '../../../../constants/statuses';
import { StripeFeeCalculatorService } from '../../../../services/marketplace/stripe-fee-calculator.service';
import { PartsWantedQuoteService } from '../../../../services/odata/parts-wanted-quote.service';
import { QuoteEvents } from '../../../../constants/events';
import './outgoing-quote-list.html';
import './outgoing-quote-list.scss';
import './outgoing-quote-locked.html';

class OutgoingQuoteListCtrl extends BaseController {
    static $inject : string[] = ['$scope', '$window', 'PartsWantedQuoteService', 'StripeFeeCalculator'];

    constructor(protected $scope : IOutgoingQuoteListScope,
        protected $window : angular.IWindowService,
        private pwqService : PartsWantedQuoteService,
        public feeCalculator : StripeFeeCalculatorService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        this.initEvents();
    }

    protected initModels() {
        this.$scope.quoteStatuses = PartWantedQutoteStatuses;
        this.$scope.sortBy = { columnName : 'status', lastSortOrder : -1 };
        this.$scope.sortState = {
            sortColumns : [this.$scope.sortBy]
        };

        this.$scope.feeCalculator = this.feeCalculator;

        if (this.$window
            ['partsWantedListingId']) {
            this.$scope.editMode = true;
            this.$scope.didStartEditing = true;
        }
    }

    protected initEvents() {
        this.$scope.$on(QuoteEvents.LoadOutgoingQuotes, this.loadQuotes);
        this.$scope.$on(QuoteEvents.EditQuote, this.editQuote);
        this.$scope.$on(QuoteEvents.CloseQuote, this.closeQuote);

        this.$scope.$on('toggleEditMode',
            ($event : angular.IAngularEvent, quote : IPartsWantedQuote) => {
                if (this.$scope.didStartEditing) {
                    //this.$scope.editMode = false;
                    //this.$scope.didStartEditing = false;
                }
            });

        this.$scope.$on('newOutgoingQuote',
            ($event : angular.IAngularEvent, quote : IPartsWantedQuote) => {
                this.$scope.editMode = false;
                this.$scope.activeQuoteId = quote.id;
            });

        this.$scope.$on('cloneQuote',
            ($event : angular.IAngularEvent, quote : IQuoteModel) => {
                this.$scope.editMode = true;
                this.$scope.quoteToClone = quote;
                this.$scope.activeQuoteId = undefined;
            });
    }

    loadQuotes = ($event : angular.IAngularEvent, quotes : IQuoteModel[], quoteId : number) => {
        this.$scope.quotes = quotes;
        this.sort(null, 'status', 1);

        if (quoteId) {
            this.$scope.activeQuoteId = quoteId;
        }

        if (this.$scope.quotes.length) {
            if (!this.$scope.activeQuoteId && !this.$window['partsWantedListingId']) {
                let quote = this.$scope.quotes[0];
                if (quote) {
                    this.$scope.activeQuoteId = quote.id;
                    this.$scope.outgoingQuote = quote;
                    this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, quote);
                }
            } else {
                let quote = _.find(quotes, { 'id' : this.$scope.activeQuoteId });
                let listing_id = this.$window['partsWantedListingId'];
                if (!quote && this.$window['partsWantedListingId']) {
                    quote = _.find(quotes,
                        function(obj) {
                            return obj.listing.id === listing_id;
                        });
                }
                this.$scope.outgoingQuote = quote;
                if (quote) {
                    this.$scope.activeQuoteId = quote.id;
                    this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, quote);
                }
            }
        }
    };

    openQuote($event :
        angular.IAngularEvent,
        quote :
        IQuoteModel) {
        $event.preventDefault();
        this.$scope.didStartEditing = false;
        this.$scope.editMode = false;
        this.$scope.activeQuoteId = quote.id;
        this.$scope.outgoingQuote = quote;
        this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, _.find(this.$scope.quotes, { id : quote.id }));
    }

    editQuote = ($event : angular.IAngularEvent, quote : IQuoteModel) => {
        this.$scope.editMode = true;
        this.$scope.activeQuoteId = quote.id;
        this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, quote);
        this.$scope.didStartEditing = true;
    };

    closeQuote = ($event : angular.IAngularEvent, quote : IQuoteModel) => {
        this.$scope.editMode = false;
        this.$scope.didStartEditing = false;
        this.$scope.activeQuoteId = quote.id;
        this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, _.find(this.$scope.quotes, { id : quote.id }));
    };

    sort($event :
        angular.IAngularEvent,
        sortBy :
        string,
        defaultSortDirection ? : number) {
        if ($event) {
            $event.preventDefault();
        }

        let selectedSortColumn = null;

        if (!sortBy) {
            return;
        } else {
            if (!this.$scope.sortState.sortColumns[sortBy]) {
                this.$scope.sortState.sortColumns[sortBy] = {
                    columnName : sortBy,
                    lastSortOrder : defaultSortDirection
                }
            } else {
                this.$scope.sortState.sortColumns[sortBy].lastSortOrder *= -1;
            }
            selectedSortColumn = this.$scope.sortState.sortColumns[sortBy];
        }


        this.$scope.quotes = this.pwqService.sort(this.$scope.quotes, selectedSortColumn.columnName);

        if (selectedSortColumn.lastSortOrder === -1) {
            this.$scope.quotes.reverse();
        }

        this.$scope.sortBy = selectedSortColumn;
    };
}

interface ISortState {
    sortColumns : Object[];
}

export interface IOutgoingQuoteListScope extends IBaseScope {
    sortBy : Object;
    activeQuoteId : number;
    outgoingQuote : IQuoteModel;
    quotes : IQuoteModel[];
    myQuoteParam : any;
    editMode : boolean;
    didStartEditing : boolean;
    newQuote : IPartsWantedQuote;
    quoteToClone : IQuoteModel;
    quoteStatuses : PartWantedQutoteStatuses;
    feeCalculator : StripeFeeCalculatorService;
    sortState : ISortState;
}

angular.module('Row52.Views.Account.Quotes.OutgoingQuoteList', [])
    .controller('OutgoingQuoteListCtrl', OutgoingQuoteListCtrl);