import * as angular                                     from 'angular';
import { PartsSaleBaseController, IPartsSaleBaseScope } from '../../parts-sale-base-controller';
import './modal.html';
import './modal.scss';

class PartsSaleDetailPublishModalCtrl extends PartsSaleBaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$cookies'
    ];

    constructor(protected $scope : IPartsSaleDetailPublishModalScope,
                protected $window : angular.IWindowService,
                protected $cookies : angular.cookies.ICookiesService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
    }

    ok() {
        this.$window.location.href = `/partssale/detail/${this.$scope.listing.id}`;
    }
}

export interface IPartsSaleDetailPublishModalScope extends IPartsSaleBaseScope {

}

angular.module('Row52.Views.PartsSale.Detail.Publish.ModalCtrl', [])
       .controller('PartsSaleDetailPublishModalCtrl', PartsSaleDetailPublishModalCtrl);