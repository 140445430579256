import * as angular from 'angular';
import { IAngularEvent } from 'angular';
import * as _ from 'lodash';
import { IUserJsonModel, IPartsForSaleStatus } from 'Row52.Models';
import {
    IMake, IModel, IPartsSaleListing, IState, ICountry, IAttachment, IShippingValidationResponse
    } from 'Row52.Models.Entities';
import { IAddressJsonFilter } from '../../models/entities/IAddressExtension';
import { MakeResourceService } from '../../services/odata/make.service';
import { ModelResourceService } from '../../services/odata/model.service';
import { CountryService } from '../../services/odata/country.service';
import { PartsForSaleListingService } from '../../services/odata/parts-for-sale-listing.service';
import { StateService } from '../../services/odata/state.service';
import { PartsForSaleStatusService } from '../../services/data/parts-for-sale-status.service';
import { BaseController, IBaseScope } from '../../base-controller';
import { AddressCompletionService } from '../../services/helpers/address.completion.service';
import { PartsForSaleStatuses } from '../../constants/parts-for-sale-statuses';
import { ShippingOfferTypes } from '../../constants/shipping-offer-types';
import '../../../css/parts-sale/edit.scss';
import { EstimateShippingCostsModalService } from '../account/quotes/estimate-shipping-costs/estimate-shipping-costs';

class EditPartsSaleListCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window',
        'StateService',
        'MakeService',
        'ModelService',
        'CountryService',
        'PartsForSaleListingService',
        'PartsForSaleStatusService',
        '$q',
        'AddressCompletionService',
        'EstimateShippingCostsModalService'
    ];

    static readonly OUNCES_IN_POUND : number = 16;

    constructor(protected $scope : IEditPartsSaleEditScope,
        protected $window : angular.IWindowService,
        private stateService : StateService,
        private makeService : MakeResourceService,
        private modelService : ModelResourceService,
        private countryService : CountryService,
        private listingService : PartsForSaleListingService,
        private statusService : PartsForSaleStatusService,
        private $q : angular.IQService,
        private addressService : AddressCompletionService,
        public shippingModal : EstimateShippingCostsModalService) {
        super();
        this.$scope.autoCompleteOptions = {
            types : ['address']
        };
        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.listing = this.$window.listing;
        this.$scope.datepickerOptions = this.getStandardDatepickerOptions();
        this.$scope.loading = false;
        this.$scope.dateOpen = false;
        this.$scope.currentDate = Date.now();
        this.$scope.maxVehicleYear = new Date().getFullYear() + 1;
        this.$scope.minVehicleYear = 1900;
        this.$scope.originalTitle = this.$scope.listing.partsTitle;
        this.$scope.addressSupplied = true;
        this.$scope.partsForSaleStatuses = PartsForSaleStatuses;
        this.$scope.isImageRemoving = false;
        this.$scope.shippingRatesValid = true;
        this.$scope.shippingRateError = null;
        this.$scope.imageRemovingId = null;
        this.$scope.attachments = {};
        this.$scope.customErrors = {
            lowPriceError : false,
            invalidWeight : false
        };

        this.$scope.$on('fineUploader:file-upload',
            ($event : IAngularEvent, id : number, attachment : IAttachment) => {
                this.$scope.attachments[id] = attachment;
            });

        this.$scope.$on('fineUploader:file-removed',
            ($event : IAngularEvent, id : number) => {
                delete this.$scope.attachments[id];
            });

        this.$scope.weight = {
            pounds : Math.trunc(this.$scope.listing.weight / EditPartsSaleListCtrl.OUNCES_IN_POUND),
            ounces : this.$scope.listing.weight % EditPartsSaleListCtrl.OUNCES_IN_POUND
        };

        await this.initDropdowns();
    };

    initDropdowns = async () => {
        this.$scope.dropdowns = {
            selectedState : null,
            selectedMake : null,
            selectedModel : null,
            selectedCountry : null,
            selectedStatus : null
        };

        let loaderHelper = this.createDropdownLoader(this.$scope, this.$scope.dropdowns, this.$scope.listing);

        try {
            let makePromise = loaderHelper(this.makeService, 'getSorted', 'makes', 'selectedMake', 'makeId');
            let modelPromise = loaderHelper(this.modelService, 'getSorted', 'models', 'selectedModel', 'modelId');
            let countryPromise =
                loaderHelper(this.countryService, 'getSorted', 'countries', 'selectedCountry', 'countryId');
            let statePromise = loaderHelper(this.stateService, 'getAll', 'states', 'selectedState', 'stateId');

            await this.$q.all([makePromise, modelPromise, countryPromise, statePromise]);

            this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
                this.addressService.createHandler(this.$scope.listing,
                    this.$scope.states,
                    this.$scope.countries,
                    this.$scope.dropdowns));

            this.$scope.statuses = this.statusService.getSorted();
            this.$scope.dropdowns.selectedStatus = _.find(this.$scope.statuses,
                (status : IPartsForSaleStatus) => {
                    return this.$scope.listing.status === status.id;
                });
            this.$scope.zipCodePattern = (function(dds) {
                let canadianRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
                let usRegex = /^\d{5}(?:[-\s]\d{4})?$/;
                return {
                    test : function(value) {
                        if (dds.selectedCountry &&
                            dds.selectedCountry.name === 'United States') {
                            return (value.length > 0) ? usRegex.test(value) : true;
                        } else if (dds.selectedCountry &&
                            dds.selectedCountry.name === 'Canada') {
                            return (value.length > 0) ? canadianRegex.test(value) : true;
                        } else {
                            return true;
                        }
                    }
                };
            })(this.$scope.dropdowns);
        } finally {

        }
    };

    /* 6/5/2018 - A. Beverly - the purpose of this method is to determine at listing
     creation whether the selected shipping type can,
     in theory, produce at least one valid shipping method. 
     EasyPost has limits on weight, dimensions, and source/dest (e.g., USPS method cannot
     originate from a non-US address. Although we don't have a destination address, 
     we will use a default US address to determine if any methods are returned
     for the given listing. If there are not, we do not permit the listing to be placed. */
    private async verifyPossibleShippingMethods() {
        if (this.$scope.listing.shippingOfferTypeId !== 2) {
            this.$scope.shippingRatesValid = true;
            this.$scope.shippingRateError = '';
            return true;
        }
        this.$scope.listing.weight = this.$scope.weight.ounces +
            (this.$scope.weight.pounds * EditPartsSaleListCtrl.OUNCES_IN_POUND);
        try {
            let response = await this.listingService.verifyShippingRates(this.$scope);
            if (response && response.rates.length > 0) {
                this.$scope.shippingRatesValid = true;
            } else {
                this.$scope.shippingRatesValid = false;
                if (response && response.messages && response.messages.length > 0) {
                    this.$scope.shippingRateError = response.messages[0].message;
                } else {
                    this.$scope.shippingRateError = '';
                }
            }
        } catch (ex) {
            this.$scope.shippingRatesValid = false;
            this.$scope.shippingRateError = ex.message;
        }
        return this.$scope.shippingRatesValid;
    }

    clearSelectedModel = () => {
        this.$scope.dropdowns.selectedModel = null;
    };

    public verifyStateSelection(item, model) {
        if (this.$scope.newPartsSaleForm.stateId) {
            this.$scope.newPartsSaleForm.stateId.$setValidity('StateMatchesCountry',
                this.addressService.stateMatchesCounty(
                    this.$scope.dropdowns.selectedState,
                    this.$scope.dropdowns.selectedCountry
                )
            );
        }
    }

    async submit($event : IAngularEvent) {
        $event.preventDefault();

        this.$scope.customErrors.lowPriceError = false;
        this.$scope.customErrors.invalidWeight = false;
        this.$scope.alerts = null;

        await this.verifyPossibleShippingMethods();
        if (!this.$scope.shippingRatesValid) {
            return;
        }

        this.verifyStateSelection(null, null);

        if (this.$scope.listing.price < 3.00) {
            this.$scope.customErrors.lowPriceError = true;
        }

        let weight = this.$scope.weight.pounds * 16;
        weight += this.$scope.weight.ounces;
        if (weight >= 1120 && this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic) {
            this.$scope.customErrors.invalidWeight = true;
        }

        if (this.$scope.customErrors.lowPriceError ||
            this.$scope.customErrors.invalidWeight ||
            !this.$scope.newPartsSaleForm.$valid) {
            return;
        }

        if (this.$scope.newPartsSaleForm.$valid) {
            this.$scope.loading = true;

            this.setDropDownValues();
            this.clearAssociatedObjects();

            this.$scope.listing.weight = this.$scope.weight.ounces +
                (this.$scope.weight.pounds * EditPartsSaleListCtrl.OUNCES_IN_POUND);

            try {
                await this.listingService.update(this.$scope.listing.id,
                    new IAddressJsonFilter().filter(this.$scope.listing));

                let attachmentsPromises : angular.IPromise<any>[] = [];
                _.forEach(this.$scope.attachments,
                    (attachment : IAttachment) => {
                        attachmentsPromises.push(this.listingService.attach(this.$scope.listing, attachment));
                    });
                await this.$q.all(attachmentsPromises);

                this.$window.location.href = `/partssale/detail/${this.$scope.listing.id}`;
            } catch (err) {
                switch (err.status) {
                case 400 :
                    this.$scope.alerts = [
                        {
                            msg : err.data.error.message,
                            type : 'danger'
                        }
                    ];
                    break;
                default :
                    this.$scope.error = true;
                }
                console.log('Something went wrong.');
            } finally {
                this.$scope.loading = false;
            }
        }
    }

    private setDropDownValues() {
        this.$scope.listing.modelId = this.$scope.dropdowns.selectedModel.id;
        if (!this.$scope.dropdowns.selectedCountry.hasStates) {
            this.$scope.dropdowns.selectedState = null;
        }
        if (this.$scope.dropdowns.selectedState != null) {
            this.$scope.listing.stateId = this.$scope.dropdowns.selectedState.id;
            this.$scope.listing.provinceName = null;
        } else {
            this.$scope.listing.stateId = null;
        }
        this.$scope.listing.makeId = this.$scope.dropdowns.selectedMake.id;
        this.$scope.listing.countryId = this.$scope.dropdowns.selectedCountry.id;
        this.$scope.listing.status = this.$scope.dropdowns.selectedStatus.id;
    }

    // Failure to clear these objects prior to submitting the form will throw an OData error.
    private clearAssociatedObjects() {
        this.$scope.listing.user = null;
        this.$scope.listing.state = null;
        this.$scope.listing.model = null;
        this.$scope.listing.country = null;
        delete this.$scope.listing['coordinates'];
    }

    editAddress($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.addressSupplied = false;
    }


    public shippingOfferTypeChanged() {
        this.$scope.shippingRatesValid = true;
        this.$scope.shippingRateError = '';
        const clearDynamicOfferType = () => {
            this.$scope.weight = {
                pounds : 0,
                ounces : 0
            };
            this.$scope.listing.height = null;
            this.$scope.listing.length = null;
            this.$scope.listing.width = null;
        };

        const clearFreeOfferType = () => {
            this.$scope.listing.shippingVendorOther = null;
            this.$scope.listing.shippingServiceOther = null;
            this.$scope.listing.estimatedShippingTime = null;
        };

        const clearFixedOfferType = () => {
            this.$scope.listing.shippingPrice = 0;
            clearFreeOfferType();
        };

        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Fixed) {
            clearDynamicOfferType();
        }
        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic) {
            clearFixedOfferType();
        }
        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Free) {
            clearDynamicOfferType();
            clearFixedOfferType();
        }
    }

    isThisImageRemoving(imageId : number) {
        return this.$scope.imageRemovingId === imageId;
    }

    removeImage($event : angular.IAngularEvent, imageId : number) {
        $event.preventDefault();

        if (!this.$scope.isImageRemoving) {
            this.$scope.isImageRemoving = true;
            this.$scope.imageRemovingId = imageId;

            this.listingService.unattach(this.$scope.listing, imageId)
                .then(() => {
                    this.$scope.isImageRemoving = false;
                    this.$scope.imageRemovingId = null;
                    _.remove(this.$scope.listing.images,
                        {
                            id : imageId
                        });
                });
        }
    }
}

interface IEditPartsSaleEditScope extends IBaseScope {
    user : IUserJsonModel;
    makes : IMake[];
    models : IModel[];
    states : IState[];
    attachments : { [id : number] : IAttachment };
    newPartsSaleForm : angular.IFormController;
    listing : IPartsSaleListing;
    dateOpen : boolean;
    datepickerOptions : any;
    currentDate : number;
    loading : boolean;
    dropdowns : {
        selectedState : IState;
        selectedModel : IModel;
        selectedMake : IMake;
        selectedCountry : ICountry;
        selectedStatus : IPartsForSaleStatus;
    };
    minVehicleYear : number;
    maxVehicleYear : number;
    countries : ICountry[];
    weight : {
        pounds : number;
        ounces : number;
    },
    statuses : IPartsForSaleStatus[];
    originalTitle : string;
    addressSupplied : boolean;
    partsForSaleStatuses : PartsForSaleStatuses;
    isImageRemoving : boolean;
    imageRemovingId : number;
    customErrors : {
        lowPriceError : boolean;
        invalidWeight : boolean;
    }
    alerts : [ { msg : string, type : 'danger' } ]
    error : boolean;
    autoCompleteOptions : any;
    shippingRatesValid : boolean;
    shippingRateError : string;
    zipCodePattern : any;
}

angular.module('Row52.Views')
    .controller('EditPartsSaleListingCtrl', EditPartsSaleListCtrl);