import * as angular from 'angular';
import { IQuoteModel } from 'Row52.Models';
import * as _ from 'lodash';
import { BaseController, IBaseScope } from '../../../../base-controller';
import { QuoteEvents } from '../../../../constants/events';
import { PartsWantedQuoteService } from '../../../../services/odata/parts-wanted-quote.service';
import { PartWantedQutoteStatuses } from '../../../../constants/statuses';
import './incoming-quote-list.html';
import './incoming-quote-list.scss';

class IncomingQuotesCtrl extends BaseController {
    static $inject : string[] = ['$scope', '$window', 'PartsWantedQuoteService'];

    constructor(protected $scope : IIncomingQuotesScope,
        protected $window : angular.IWindowService,
        private pwqService : PartsWantedQuoteService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.sortBy = 'status';
        this.$scope.activeQuoteId = 0;
        this.$scope.incomingQuote = null;
        this.$scope.sortDirection = -1;
        this.$scope.quoteStatuses = PartWantedQutoteStatuses;
        this.$scope.$on(QuoteEvents.LoadIncomingQuotes, this.loadQuotes);
        this.$scope.loading = true;
    }

    public loadQuotes = ($event : angular.IAngularEvent, quotes : IQuoteModel[], quoteId : number) => {
        this.$scope.quotes = quotes;
        this.sort(null, 'status', true);

        if (quoteId) {
            this.$scope.activeQuoteId = quoteId;
        }

        if (this.$scope.quotes.length) {
            if (!this.$scope.activeQuoteId) {
                let activeQuote = this.$scope.quotes[0];
                this.$scope.activeQuoteId = activeQuote.id;
                this.$scope.incomingQuote = activeQuote;
                this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, activeQuote);
            } else {
                let quote = _.find(quotes, { 'id' : this.$scope.activeQuoteId });
                if (quote != null) {
                    this.$scope.activeQuoteId = quote.id;
                    this.$scope.incomingQuote = quote;
                    this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, quote);
                }
            }
        }

        this.$scope.loading = false;
    };

    public openQuote = ($event : angular.IAngularEvent, quote : IQuoteModel) => {
        $event.preventDefault();

        this.$scope.activeQuoteId = quote.id;
        this.$scope.incomingQuote = quote;
        this.$scope.$broadcast(QuoteEvents.UpdateActiveQuote, quote);
    };

    public sort($event : angular.IAngularEvent, sortBy : string, fresh : boolean = false) {
        if ($event) {
            $event.preventDefault();
        }

        if (fresh) {
            this.$scope.sortDirection = 1;
        } else {
            if (sortBy === this.$scope.sortBy) {
                this.$scope.sortDirection = this.$scope.sortDirection * -1;
            } else {
                this.$scope.sortDirection = 1;
            }
        }

        this.$scope.sortBy = sortBy;

        this.$scope.quotes = this.pwqService.sort(this.$scope.quotes, sortBy);

        if (this.$scope.sortDirection === -1) {
            this.$scope.quotes.reverse();
        }
    };
}

export interface IIncomingQuotesScope extends IBaseScope {
    sortBy : string;
    activeQuoteId : number;
    incomingQuote : IQuoteModel;
    outgoingQuote : IQuoteModel;
    quotes : IQuoteModel[];
    quoteStatuses : PartWantedQutoteStatuses;
    loading : boolean;
    sortDirection : number;
}

angular.module('Row52.Views.Account.Quote.IncomingQuoteList', [])
    .controller('IncomingQuotesCtrl', IncomingQuotesCtrl);