import './favorite-search-tab.html';
import './favorite-search-tab.scss';

import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';

export class SavedSearchFavoriteWidgetCtrl extends BaseController {
    public static $inject : string[] = [ '$scope', '$window' ];

    constructor(protected $scope : ISavedSearchFavoriteWidgetScope,
                protected $window : angular.IWindowService) {
        super();
        this.init();
    }

    viewAll($event : angular.IAngularEvent) {
        $event.preventDefault();

        switch (this.$scope.activeTab) {
            case 0 :
                this.$window.location.href = '/Account/Searches';
                break;
            case 1:
                this.$window.location.href = '/Account/Favorites';
                break;
        }
    }
}

export interface ISavedSearchFavoriteWidgetScope extends IBaseScope {
    activeTab : number;
}

angular.module('Row52.Views.Account.Summary.SavedSearchFavoriteWidget', [])
       .controller('SavedSearchFavoriteWidgetCtrl', SavedSearchFavoriteWidgetCtrl);