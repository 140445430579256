import * as angular from 'angular';

class CommitFormDirective implements angular.IDirective {
    public restrict : string = 'A';
    public require : string  = 'form';

    link(scope : angular.IScope,
         element : angular.IAugmentedJQuery,
         attributes : angular.IAttributes,
         form : angular.IFormController) {

        form.commit = () => {
            let f = element[ 0 ] as HTMLFormElement;
            f.submit();
        };
    }

    public static Factory() {
        let directive = () => {
            return new CommitFormDirective();
        };

        directive[ '$inject' ] = [];

        return directive;
    }
}

angular.module('Row52.Directives.CommitForm', [])
       .directive('commitForm', CommitFormDirective.Factory());