import * as angular                                                                   from 'angular';
import { IPartsWantedCommentCreationModel, IPartsWantedCommentModel, IUserJsonModel } from 'Row52.Models';
import { IAttachment, IPartsWantedListing }                                           from 'Row52.Models.Entities';
import { BaseController }                                                             from '../../base-controller';
import { AttachmentService }                                                          from '../../services/images/attachment.service';
import { ImageServiceModal }                                                          from '../../services/images/image.service';
import { PartsWantedCommentService }                                                  from '../../services/odata/parts-wanted-comments.service';
import { RemoteErrorModalService }                                                    from '../../services/ui/remoteerror.modal';
import '../../templates/partswantedlisting/comments.html';

interface ICommentsScope extends angular.IScope {
    user : IUserJsonModel;
    listing : IPartsWantedListing;
    commentForm : angular.IFormController;
    newCommentLoading : boolean;
    newComment : IPartsWantedCommentCreationModel;
    comments : IPartsWantedCommentModel[];
    commentsLoading : boolean;

    uploadImage : (files : FileList) => boolean;
    uploadingImage : boolean;
    attachment : IAttachment;
}

class PartsWantedCommentCtrl extends BaseController implements angular.IController {
    static $inject : string[] = [
        '$scope',
        '$http',
        '$window',
        'PartsWantedCommentService',
        'AttachmentService',
        'ImageServiceModal',
        'remoteErrorModalService'
    ];

    constructor(protected $scope : ICommentsScope,
                protected $http : angular.IHttpService,
                protected $window : angular.IWindowService,
                protected pwcService : PartsWantedCommentService,
                protected attachmentService : AttachmentService,
                protected imageModalService : ImageServiceModal,
                private remoteErrorService : RemoteErrorModalService,
                protected load : boolean = true) {

        super();
        this.$scope.listing    = $window[ 'listing' ];
        this.$scope.newComment = {
            body                 : '',
            attachmentId         : null,
            parentId             : null,
            partsWantedListingId : this.$scope.listing.id
        };

        this.$scope.commentsLoading = true;
        this.$scope.uploadImage     = this.uploadImage;
        this.$scope.attachment      = null;

        if (load) {
            // noinspection JSIgnoredPromiseFromCall
            this.init();
        }
    }

    protected async init() {
        await super.init();
        try {
            this.$scope.comments = await this.pwcService.getForListing(this.$scope.listing.id);
        }
        catch (err) {

        }
        this.$scope.commentsLoading = false;
    };

    submitComment($event :
                      angular.IAngularEvent,
                  commentForm :
                      angular.IFormController,
                  comment ? : IPartsWantedCommentModel) {
        $event.preventDefault();

        if (commentForm.$valid) {
            this.$scope.newCommentLoading = true;

            if (comment) {
                this.$scope.newComment.parentId = comment.id;
            }

            this.pwcService
                .createComment(this.$scope.listing.id,
                               this.$scope.newComment.body,
                               this.$scope.newComment.parentId,
                               this.$scope.newComment.attachmentId)
                .then((newComment : IPartsWantedCommentModel) => {
                    if (comment) {
                        comment.children.push(newComment);
                    }
                    else {
                        this.$scope.comments.unshift(newComment);
                    }

                    this.$scope.newComment = {
                        body                 : '',
                        attachmentId         : null,
                        parentId             : null,
                        partsWantedListingId : this.$scope.listing.id
                    };
                    this.$scope.attachment = null;
                })
                .finally(() => {
                    this.$scope.newCommentLoading = false;
                    commentForm.$setPristine();
                    commentForm.$setUntouched();
                });
        }
    }

    uploadImage = (files : FileList) => {
        if (files.length > 1) {
            this.remoteErrorService
                .open('Attention',
                      'You may only upload one image at a time.',
                      { okBtnText : 'OK' });
            return false;
        }

        //Limit file size for 3MB
        if (files[ 0 ].size > 3 * 1024 * 1024) {
            this.remoteErrorService
                .open('Attention',
                      'Maximum file size is 3Mb.',
                      { okBtnText : 'OK' });
            return false;
        }

        if (this.$scope.attachment !== null) {
            return false;
        }

        this.$scope.uploadingImage = true;

        let file = files[ 0 ];
        let data = new FormData();
        data.append('file', file);

        this.attachmentService
            .upload(data)
            .then((attachment : IAttachment) => {
                      this.$scope.newComment.attachmentId = attachment.id;
                      this.$scope.attachment              = attachment;
                  },
                  () => {
                      //TODO: add error messaging;
                  })
            .finally(() => {
                this.$scope.uploadingImage = false;
            });
    };

    removeImage($event :
                    angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.attachment              = null;
        this.$scope.newComment.attachmentId = null;
    }

    displayImage($event :
                     angular.IAngularEvent,
                 comment :
                     IPartsWantedCommentModel) {
        $event.preventDefault();

        this.imageModalService.open(comment.fullImageUrl);
    }

    isCommentingAvailable() : boolean {
        return !this.$scope.user.isAuthenticated ||
               ( this.$scope.user.isAuthenticated &&
                 ( this.isSeller || this.$scope.user.id === this.$scope.listing.userId ) );
    }
}

interface ICommentReplyScope extends angular.IScope {
    user : IUserJsonModel;
    listing : IPartsWantedListing;
    commentForm : angular.IFormController;
    newCommentLoading : boolean;
    newComment : IPartsWantedCommentCreationModel;
    comments : IPartsWantedCommentModel[];
    commentsLoading : boolean;

    uploadImage : (files : FileList) => boolean;
    uploadingImage : boolean;
    attachment : IAttachment;
    showReply : boolean;
}

class PartsWantedCommentReplyCtrl extends PartsWantedCommentCtrl {
    static $inject : string[] = [
        '$scope',
        '$http',
        '$window',
        'PartsWantedCommentService',
        'AttachmentService',
        'ImageServiceModal',
        'remoteErrorModalService'
    ];

    constructor(protected $scope : ICommentReplyScope,
                $http : angular.IHttpService,
                $window : angular.IWindowService,
                pwcService : PartsWantedCommentService,
                attachmentService : AttachmentService,
                imageModalService : ImageServiceModal,
                remoteErrorService : RemoteErrorModalService) {

        super($scope, $http, $window, pwcService, attachmentService, imageModalService, remoteErrorService, false);

        this.$scope.showReply = false;
    }

    showReply($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.showReply = !this.$scope.showReply;
    }
}

angular.module('Row52.Views')
       .controller('PartsWantedCommentCtrl', PartsWantedCommentCtrl)
       .controller('PartsWantedCommentReplyCtrl', PartsWantedCommentReplyCtrl);
