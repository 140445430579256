import * as angular                                     from 'angular';
import { PartsSaleBaseController, IPartsSaleBaseScope } from '../../parts-sale-base-controller';
import './ship.html';
import './ship.scss';
import { PartsForSaleListingService }                   from '../../../../services/odata/parts-for-sale-listing.service';
import { ShippingVendorsService, IShippingVendor }      from '../../../../services/data/shipping-vendors.service';

export class PartsSaleDetailShipCtrl extends PartsSaleBaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$cookies',
        'PartsForSaleListingService',
        'ShippingVendorsService'
    ];
    public static SuccessfulShip     = 'SuccessfulShipSubmitted';
    public static HideShip           = 'HideShip';

    constructor(protected $scope : IPartsSaleDetailShipScope,
                protected $window : angular.IWindowService,
                protected $cookies : angular.cookies.ICookiesService,
                private listingService : PartsForSaleListingService,
                private shippingVendorsService : ShippingVendorsService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.shippingVendors = this.shippingVendorsService.getAll();
    }

    async submitShipment($event : angular.IAngularEvent) {
        $event.preventDefault();
        this.$scope.completeLoading = true;

        try {
            this.$scope.listing = await this.listingService.submitShipment(this.$scope.listing);
            this.$scope.$emit(PartsSaleDetailShipCtrl.SuccessfulShip);
        }
        catch (err) {

        }
        this.$scope.completeLoading = false;
    }

    hide($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.$emit(PartsSaleDetailShipCtrl.HideShip);
    }
}

interface IPartsSaleDetailShipScope extends IPartsSaleBaseScope {
    completeLoading : boolean;
    shippingVendors : IShippingVendor[];
}

angular.module('Row52.Views.PartsSale.Detail.PartsSaleDetailShip', [])
       .controller('PartsSaleDetailShipCtrl', PartsSaleDetailShipCtrl);