import * as angular from 'angular';
import * as Tso from 'ts-odata/src/Tso';

export interface IODataWrapper<T> {
    '@odata.context' : string;
    '@odata.count'? : number;
    value : T[];
}

export interface IODataCountWrapper<T> {
    value : T[];
    count : number;
}

export class TypeScriptResourceFactory {
    constructor(protected $http : angular.IHttpService,
                protected $q : angular.IQService,
                protected $cookies : angular.cookies.ICookiesService) {

    }

    protected unwrapArray<T>(promise : angular.IHttpPromise<IODataWrapper<T[]>>) : angular.IPromise<T[]> {
        let deferred = this.$q.defer();
        promise.then((response : angular.IHttpResponse<IODataWrapper<T[]>>) => {
            deferred.resolve(response.data.value);
        }, (response) => {
            deferred.reject(response);
        });

        return deferred.promise as angular.IPromise<T[]>;
    }

    protected unwrapODataCountArray<T>(promise : angular.IHttpPromise<IODataWrapper<T[]>>)
    : angular.IPromise<IODataCountWrapper<T>> {
        let deferred = this.$q.defer();
        promise.then((response : angular.IHttpResponse<IODataWrapper<T[]>>) => {
            if (response.data.hasOwnProperty('@odata.count')) {
                let wrapper = {
                    count : response.data[ '@odata.count' ],
                    value : response.data.value
                };
                deferred.resolve(wrapper);
            } else {
                deferred.resolve(response.data.value);
            }

        }, (response) => {
            deferred.reject(response);
        });

        return deferred.promise as angular.IPromise<IODataCountWrapper<T>>;
    }

    protected unwrapSingle<T>(promise : angular.IHttpPromise<T>) : angular.IPromise<T> {
        let deferred = this.$q.defer();

        promise.then((x : angular.IHttpPromiseCallbackArg<T>) => {
            deferred.resolve(x.data);
        }, (response : angular.IHttpPromiseCallbackArg<any>) => {
            deferred.reject(response);
        });

        return deferred.promise as angular.IPromise<T>;
    }

    protected getHeaders() : angular.IRequestShortcutConfig {
        const authCookieValue = this.$cookies.get('R52ACK');
        let config            = <angular.IRequestShortcutConfig>{};

        if (authCookieValue) {
            config.headers = {
                'Authorization' : 'Spider ' + authCookieValue
            };
        }

        return config;
    }
}

export class ResourceService<T> extends TypeScriptResourceFactory {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                protected domain : string,
                protected resourceUrl) {
        super($http, $q, $cookies);
    }

    public getAll() : angular.IPromise<T[]> {
        return this.unwrapArray(this.$http.get(`${this.domain}/odata/${this.resourceUrl}/`, this.getHeaders()));
    }

    public getById(id : number) : angular.IPromise<T> {
        return this.unwrapSingle(this.$http.get(`${this.domain}/odata/${this.resourceUrl}(${id})/`, this.getHeaders()));
    }

    public getByQuery(query : Tso.Tso) : angular.IPromise<T[]> {
        return this.unwrapArray(this.$http.get(query.toString(), this.getHeaders()));
    }

    public createQuery() : Tso.Tso {
        return new Tso.Tso(`${this.domain}/odata/${this.resourceUrl}`);
    }
}

export class ResourceCrudService<T> extends ResourceService<T> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                protected $cookies : angular.cookies.ICookiesService,
                domain : string,
                protected resourceUrl) {
        super($http, $q, $cookies, domain, resourceUrl);
    }

    public create(model : T) : angular.IPromise<T> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}`, model, this.getHeaders())
        );
    }

    public update(key : number, model : T) : angular.IPromise<T> {
        return this.unwrapSingle(
            this.$http
                .put(`${this.domain}/odata/${this.resourceUrl}(${key})/`, model, this.getHeaders())
        );
    }

    public delete(key : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .delete(`${this.domain}/odata/${this.resourceUrl}(${key})/`, this.getHeaders())
        );
    }
}