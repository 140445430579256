/**
 * Created by Ri Scott on 5/14/2017.
 */

import { ResourceCrudService } from './odata.resource';
import { IUserLocationRelationship } from 'Row52.Models.Entities';
import * as angular from 'angular';
import '../../constants/constants';
import { IPromise } from 'angular';

export class UserLocationRelationshipService extends ResourceCrudService<IUserLocationRelationship> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'UserLocationRelationships');
    }

    public getForUser(userId : string) : IPromise<IUserLocationRelationship[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/?$filter=userId eq ${userId}&$expand=location`,
                     this.getHeaders())
        );
    }
}

angular.module('Row52.Services.UserLocationRelationshipService', [ 'ngCookies', 'Row52.Constants' ])
       .service('UserLocationRelationshipService', UserLocationRelationshipService);