import './consumer-parts-sale-widget.html';
import './consumer-parts-sale-widget.scss';
import '../../../partials/parts-sale-listing-status/parts-sale-listing-status.html';
import '../../../partials/parts-sale-listing-dates/parts-sale-listing-dates.html';

import * as angular                         from 'angular';
import { BaseController, IBaseScope }       from '../../../../base-controller';
import { ListingSqlViewService }            from '../../../../services/odata/listing.service';
import { IPartsForSaleListingSearchResult } from 'Row52.Models';
import { ListingsTabs }                     from '../../../../constants/listings-tabs';

import {
    IListing_SqlView,
    IListingImage_SqlView
    } from 'Row52.Models.Entities';

export class ConsumerPartsSaleWidgetCtrl extends BaseController {
    public static $inject : string[] = ['$scope', '$window', 'ListingSqlViewService' ];

    constructor(protected $scope : IConsumerPartsSaleWidgetScope,
                protected $window : angular.IWindowService,
                private listingService : ListingSqlViewService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();

        this.loadPartsForSale();
    }

    private async loadPartsForSale() {
        try {
            this.$scope.loading = true;

            let listings              = await this.listingService.getAllForBuyer(this.$scope.user.id, 5);
            this.$scope.listings      = this.listingService.sort(listings.value, 'status') as IListing_SqlView[];
            this.$scope.listingsCount = listings.count;
        }
        catch (err) {

        }

        this.$scope.loading = false;
    }

    goToDetails($event : angular.IAngularEvent, listing : IListing_SqlView) {
        if (listing.typeId === 1) {
            this.$window.location.href = `/PartsSale/Detail/${listing.listingId}`;
        } else if (listing.typeId === 2) {
            this.$window.location.href = `/Account/MyQuotes?tab=0&qId=${listing.quoteId}`;
        }
    }

    goToListings($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/Account/MyListing?tab=${ListingsTabs.PurchasedParts}`;
    }
}

interface IConsumerPartsSaleWidgetScope extends IBaseScope {
    loading : boolean;
    listings : IListing_SqlView[];
    listingsCount : number;
    listingImages : IListingImage_SqlView[];
}

angular.module('Row52.Views.Account.Summary.ConsumerPartsSaleWidget', [])
       .controller('ConsumerPartsSaleWidgetCtrl', ConsumerPartsSaleWidgetCtrl);
