import * as angular from 'angular';
import 'angular-ui-bootstrap';
import * as uib from 'angular-ui-bootstrap';
import '../../templates/partials/remote-error-modal.html'

interface IConfirmOptions {
    okBtnText? : string,
    replyMessageText? : string,
}

export interface IConfirmModalService {
    open(title : string, message? : string, options? : IConfirmOptions) : angular.IPromise<any>;
}

export class RemoteErrorModalService implements IConfirmModalService {
    static $inject = [ '$uibModal' ];

    constructor(private $uibModal : uib.IModalService) {
    }

    open(title : string, message? : string, options? : IConfirmOptions) : angular.IPromise<any> {
        return this.$uibModal.open({
                                       templateUrl : 'partials/remote-error-modal.html',
                                       controller  : 'RemoteErrorMessageCtrl',
                                       resolve     : {
                                           message() {
                                               return message;
                                           },
                                           title() {
                                               return title;
                                           },
                                           options() {
                                               return options || {};
                                           }
                                       }
                                    }).result.catch(function (res) {
                                        // Eat the error if it is standard close so we 
                                        //   don't see errors further down the line from catches
                                        if (!(res === 'backdrop click' 
                                            || res === 'close button' 
                                            || res === 'escape key press')) {
                                            throw res;
                                        }
                                    });
    }
}

export interface IConfirmModalScope extends angular.IScope {
    message : string;
    title : string;
    selected : { item : string };
    okBtnText : string;
    confirmationMessage : string;

    open(size);

    ok();

    openSplash(event, size);
}

export class RemoteErrorMessageCtrl {
    static $inject : string[] = [ '$scope', '$uibModalInstance', 'title', 'message', 'options' ];

    constructor($scope : IConfirmModalScope,
                $uibModalInstance : uib.IModalServiceInstance,
                title : string,
                message : string,
                options : IConfirmOptions) {

        $scope.message       = message;
        $scope.title         = title;
        $scope.okBtnText     = options.okBtnText || 'Ok';

        $scope.ok = () => {
            $scope.confirmationMessage = options.replyMessageText;
            setTimeout($uibModalInstance.close, 250);
        };
    }
}


angular.module('Row52.Services.RemoteErrorModal', [ 'ui.bootstrap' ])
    .service('remoteErrorModalService', RemoteErrorModalService)
    .controller('RemoteErrorMessageCtrl', RemoteErrorMessageCtrl);