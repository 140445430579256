import * as angular                from 'angular';
import * as _                      from 'lodash';
import { IMessageThread }          from 'Row52.Models';
import { IAttachment }             from 'Row52.Models.Entities';
import { AttachmentService }       from '../../services/images/attachment.service';
import { ConfirmModalService }     from '../../services/ui/confirm.modal';
import { ImageServiceModal }       from '../../services/images/image.service';
import { MessagesService }         from '../../services/messages.service';
import { MessagesApiService }      from '../../services/odata/messages.service';
import { RemoteErrorModalService } from '../../services/ui/remoteerror.modal';

interface IAccountMessageScope extends angular.IScope {
    threads : IMessageThread[];
    loading : boolean;
    selectedThread : IMessageThread;
    reply : {
        body : string,
        subject : string
    }
    replyLoading : boolean;
    replyForm : angular.IFormController;
    uploadingImage : boolean;
    attachment : IAttachment;
    attachmentId : number;
    uploadImage : (files : FileList) => boolean;
    sendingMessageError : string;
}

export class MessagesCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'MessagesApiService',
        'MessagesService',
        'confirmModalService',
        'ImageServiceModal',
        'AttachmentService',
        'remoteErrorModalService'
    ];

    constructor(private $scope : IAccountMessageScope,
                private $window : angular.IWindowService,
                private messageApi : MessagesApiService,
                private messages : MessagesService,
                private confirmModalService : ConfirmModalService,
                private imageModalService : ImageServiceModal,
                private attachmentService : AttachmentService,
                private remoteErrorService : RemoteErrorModalService) {

        this.initData();
        this.$scope.uploadImage         = this.uploadImage;
        this.$scope.sendingMessageError = null;
    }

    initData = async () => {
        this.$scope.loading = true;
        try {
            this.$scope.threads        = await this.messageApi.getAll();
            const selectedThreadId     = this.$window.selectedThreadId;
            this.$scope.selectedThread = _.find(this.$scope.threads, { id : selectedThreadId }) || this.$scope.threads[ 0 ];
            await this.messageApi.readThread(this.$scope.selectedThread);
            this.$scope.selectedThread.isUnread = false;
        }
        catch (err) {

        }
        this.$scope.loading = false;
    };

    openThread = async ($event : angular.IAngularEvent, thread : IMessageThread) => {
        $event.preventDefault();

        await this.messageApi.readThread(thread);

        this.$scope.selectedThread          = thread;
        this.$scope.selectedThread.isUnread = false;

        this.$scope.sendingMessageError = null;
    };

    deleteThread = async ($event : angular.IAngularEvent, thread : IMessageThread) => {
        $event.preventDefault();

        try {
            await this.confirmModalService
                      .open(`Delete Message from: ${ thread.sender.userName }`,
                            'Are you sure you wish to delete this message? If you delete this message, '
                            + 'you will not be able to recover it.',
                            {
                                okBtnText     : 'Yes',
                                cancelBtnText : 'Cancel'
                            });
            await this.messageApi.deleteThread(thread);
            this.$scope.threads        = _.reject(this.$scope.threads, { id : thread.id });
            this.$scope.selectedThread = this.$scope.threads[ 0 ];
        }
        catch (err) {

        }
    };

    composeNew = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        try {
            await this.messages.open('', '').result;

            this.initData();
        }
        catch (err) {

        }
    };

    sendReply = async ($event : angular.IAngularEvent, thread : IMessageThread) => {
        $event.preventDefault();

        try {
            this.$scope.replyLoading        = true;
            this.$scope.sendingMessageError = null;
            let model                       = {
                body              : this.$scope.reply.body,
                subject           : this.$scope.reply.subject,
                recipientUserName : thread.sender.userName,
                attachmentId      : this.$scope.attachmentId
            };

            await this.messageApi.create(model);

            await this.initData();
            this.$scope.selectedThread = _.find(this.$scope.threads, { id : thread.id });
        }
        catch (err) {
            switch (err.status) {
                case 401:
                    this.$scope.sendingMessageError = err.data.value && err.data.value !== ''
                                                      ? err.data.value
                                                      : 'Error has occured while sending message';
                    break;
                default:
                    this.$scope.sendingMessageError = 'Error has occured while sending message';
                    break;
            }
        }
        this.resetReplyForm();
    };

    private resetReplyForm() {
        this.$scope.reply        = { body : null, subject : null };
        this.$scope.replyLoading = false;
        this.$scope.replyForm.$setPristine();
        this.$scope.replyForm.$setUntouched();
        this.$scope.attachment   = null;
        this.$scope.attachmentId = null;
    }

    uploadImage = (files : FileList) => {
        if (files.length > 1) {
            this.remoteErrorService
                .open('Attention',
                      'You may only upload one image at a time.',
                      { okBtnText : 'OK' });
            return false;
        }

        //Limit file size for 3MB
        if (files[ 0 ].size > 3 * 1024 * 1024) {
            this.remoteErrorService
                .open('Attention',
                      'Maximum file size is 3Mb.',
                      { okBtnText : 'OK' });
            return false;
        }

        this.$scope.uploadingImage = true;

        let file = files[ 0 ];
        let data = new FormData();
        data.append('file', file);

        this.attachmentService
            .upload(data)
            .then((attachment : IAttachment) => {
                this.$scope.attachmentId = attachment.id;
                this.$scope.attachment   = attachment;
            }, () => {
                //TODO: add error messaging;
            })
            .finally(() => {
                this.$scope.uploadingImage = false;
            });
    };
}

angular.module('Row52.Views.Account')
       .controller('MessagesCtrl', MessagesCtrl);
