import * as angular from 'angular';
import * as Persona from 'persona';

export interface IRegisterAccountScope extends angular.IScope {
    user : {
        userName : string;
        email : string;
        mobilePhone : string;
        password : string;
        confirmPassword : string;
        timezone : number
    };
    loading : boolean;
    alerts : any[];
    registerForm : angular.IFormController;
    accountCreated : boolean;
    errorMessage : string;
    persona : Persona.Client;
    
    personaReady: boolean;
}

export class RegisterAccountCtrl {
    static $inject : string[] = [ '$scope', '$http', '$window', 'domain' ];

    constructor(private $scope : IRegisterAccountScope,
                private $http : angular.IHttpService,
                private $window : angular.IWindowService,
                private domain : string) {
        // noinspection JSIgnoredPromiseFromCall
        this.init();
    }

    private async init() {
        this.$scope.alerts = [];
        this.$scope.personaReady = false;

        this.$scope.user = {
            userName        : '',
            password        : '',
            email           : '',
            mobilePhone     : null,
            confirmPassword : '',
            timezone        : ( new Date() ).getTimezoneOffset() / 60
        };

        this.$scope.loading        = false;
        this.$scope.accountCreated = false;
        this.$scope.persona = this.createPersonaNewObject();
    }
    
    createPersonaNewObject() {
        return new Persona.Client({
            templateId: 'itmpl_buomxnuqtCfMUnmQ9RNFUpWz',
            environment: 'production',
            onReady: () => this.$scope.personaReady = true,
            onComplete: ({ inquiryId, status, fields }) => {
                this.$scope.registerForm['MobilePhone'].$setViewValue(fields['phone-number'].value.slice(2));
                this.$scope.registerForm.$setPristine();
                this.$scope.user.mobilePhone = fields['phone-number'].value.slice(2);
                this.$scope.$digest();
                this.$scope.persona = this.createPersonaNewObject();
            },
            onCancel: ({ inquiryId, sessionToken }) => {
                this.$scope.persona = this.createPersonaNewObject();
            },
            onError: (error) => {
                this.$scope.registerForm['MobilePhone'].$setViewValue(null);
                this.$scope.registerForm.$setPristine();
                this.$scope.user.mobilePhone = null;
                this.$scope.persona = this.createPersonaNewObject();
            },
        })
    }
    
    async openPersona($event : angular.IAngularEvent) {
        $event.preventDefault();
        this.$scope.persona.open();
    }

    async register($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.accountCreated = false;
        this.$scope.alerts       = [];
        this.$scope.errorMessage = null;
        if (this.$scope.registerForm.$valid) {
            this.$scope.loading = true;

            try {
                const createResponse = await this.$http.post(`${this.domain}/odata/Users/Row52.CreateAccount`, this.$scope.user);
                
                if (createResponse.data && createResponse.data['value'] != 'allow') {
                    this.$scope.alerts.push({
                        type : 'danger',
                        msg  : 'We\'re sorry, we cannot create an account for you at this time'
                    });
                    this.$scope.loading = false;
                    return;
                }
                
                this.$scope.alerts.push({
                                            type : 'info',
                                            msg  : 'Thank you for joining Row52.com, please check your email inbox ' +
                                                   'for activation instructions.'
                                        });
                this.$scope.accountCreated = true;
            }
            catch (err) {
                switch (err.status) {
                    case 400:
                        // this.$scope.errorMessage = err.data.error.message;
                        this.$scope.alerts.push({
                                                    type : 'danger',
                                                    msg  : err.data
                                                });
                        break;
                    default:
                        this.$scope.alerts.push({
                            type : 'danger',
                            msg  : 'Oops! There was an unanticipated error '
                                + 'on our side. Please try again. (' + err.status + err.data + ')'
                        });
                        break;
                }
            }

            this.$scope.loading = false;
        }
        else {
            this.$scope.alerts.push({ type : 'danger', msg : 'Make sure you have filled in all required fields.' });
        }
    }
}

angular.module('Row52.Views.Account.RegisterAccount', [ 'ngLocale' ])
       .controller('RegisterAccountCtrl', RegisterAccountCtrl);