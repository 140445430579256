import * as angular from 'angular';
import '../../constants/constants';

angular.module('Row52.Services.StripeWrapper', ['Row52.Constants'])
    .service('StripeWrapper',
        [
            'stripeKey',
            (stripeKey : string) => {
                if (TEST_FLAG) {
                    return {};
                } else {
                    return Stripe(stripeKey);
                }
            }
        ]
    );

export interface IStripeChargeFailure {
    code : string;
    message : string;
}

export interface IStripeTokenResponse<T extends IStripeToken> {
    token : T;
    error : IStripeErrorResponse;
}

export interface IStripeErrorResponse {
    message : string;
    param : string;
    type : string;
}

export interface IStripeToken {
    client_ip : string;
    created : number;
    id : string;
    object : string;
    type : string;
    used : boolean;
}

export interface IStripeCardToken extends IStripeToken {
    card : any;
}

export interface IStripeBankAccountToken extends IStripeToken {
    bank_account : {
        account_holder_name : string;
        account_holder_type : string;
        bank_name : string;
        country : string;
        currency : string;
        id : string;
        last4 : string;
        name : string;
        object : 'bank_account';
        status : string;
    }
}

export class StripeService {
    public static $inject : string[] = ['$q', 'StripeWrapper'];

    constructor(private $q : angular.IQService, private Stripe : any) {

    }

    getPersonalIdentifierToken(personalIdNumber : string) : angular.IPromise<IStripeTokenResponse<IStripeToken>> {
        let deferred = this.$q.defer();

        this.Stripe
            .createToken('pii',
                {
                    personal_id_number : personalIdNumber
                })
            .then((response : IStripeTokenResponse<IStripeToken>) => {
                deferred.resolve(response);
            });

        return deferred.promise as angular.IPromise<IStripeTokenResponse<IStripeToken>>;
    }

    getBankAccountToken(country : string,
        currency : string,
        routingNumber : string,
        accountNumber : string,
        holderName : string,
        accountHolderType : string) : angular.IPromise<IStripeTokenResponse<IStripeBankAccountToken>> {
        let deferred = this.$q.defer();

        this.Stripe
            .createToken('bank_account',
                {
                    country : country,
                    currency : currency,
                    routing_number : routingNumber,
                    account_number : accountNumber,
                    account_holder_name : holderName,
                    account_holder_type : accountHolderType
                })
            .then((response : IStripeTokenResponse<IStripeToken>) => {
                deferred.resolve(response);
            });

        return deferred.promise as angular.IPromise<IStripeTokenResponse<IStripeBankAccountToken>>;
    }
}

angular.module('Row52.Services.Stripe', ['Row52.Services.StripeWrapper', 'Row52.Constants'])
    .service('StripeService', StripeService);