import * as angular from 'angular';
import './mobile-registration';
import './mobile-number/mobile-number';
import './mobile-verification/mobile-verification';
import './mobile-display/mobile-display';

angular.module('Row52.Views.Account.MobileRegistration', [
    'Row52.Views.Account.MobileRegistrationView',
    'Row52.Views.Account.MobileRegistration.MobileNumber',
    'Row52.Views.Account.MobileRegistration.MobileVerification',
    'Row52.Views.Account.MobileRegistration.MobileDisplay'
]);