import * as angular from 'angular';

angular.module('Row52.Views',
               [
                   'Row52.Views.Account',
                   'Row52.Views.PartsSale',
                   'ngMap',
                   'ngSanitize',
                   'ui.bootstrap',
                   'toastr',
                   'angularFileUpload',
                   'dnTimepicker',
                   'ngMessages',
                   'angular-carousel',
                   'flow',
                   'slick',
                   'ui.select',
                   'ui.carousel'
               ]);

import './about/contact-us';
import './account/module';
import './comments/parts-wanted-comments';
import './home/home';
import './partials/header';
import './partials/pagination';
import './search/search.module';
import './vehicles/vehicle.module';
import './yards/yards.module';
import './partspullers/parts-puller.list';
import './users/user.profile';
import './users/user.review';
import './partswantedlisting/module';
import './partsforsale/module';
import './pages/got-inventory';
import './pages/mobile-apps';