import * as angular                           from 'angular';
import * as _                                 from 'lodash';
import {
    IAttachment, ICountry, IMake, IModel, IPartsWantedListing, IState
}                                             from 'Row52.Models.Entities';
import { IAddressJsonFilter }                 from '../../models/entities/IAddressExtension';
import { StateService }                       from '../../services/odata/state.service';
import { CountryService }                     from '../../services/odata/country.service';
import { MakeResourceService }                from '../../services/odata/make.service';
import { ModelResourceService }               from '../../services/odata/model.service';
import { AddressCompletionService, IAddress } from '../../services/helpers/address.completion.service';
import { IAngularEvent }                      from 'angular';
import { PartsWantedListingService }          from '../../services/odata/parts-wanted-listing.service';
import '../../../css/parts-wanted/create.scss';
import { EstimateShippingCostsModalService }  from '../account/quotes/estimate-shipping-costs/estimate-shipping-costs';
import { BaseController, IBaseScope }         from '../../base-controller';

class NewPartsWantedListCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window',
        '$timeout',
        'StateService',
        'CountryService',
        'MakeService',
        'ModelService',
        'AddressCompletionService',
        'PartsWantedListingService',
        '$q'
    ];

    private initData : IInitData;

    constructor(protected $scope : INewPartsWantedListScope,
                protected $window : angular.IWindowService,
                private $timeout : angular.ITimeoutService,
                private stateService : StateService,
                private countryService : CountryService,
                private makeService : MakeResourceService,
                private modelService : ModelResourceService,
                private addressService : AddressCompletionService,
                private partsWantedService : PartsWantedListingService,
                private $q : angular.IQService) {
        super();
        this.$scope.autoCompleteOptions = {
            types : [ 'address' ]
        };
        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        this.initUserInfo();
        await this.initDropdowns();
        this.initAddress();
        this.initEvents();
    }

    private initModels() {
        this.initData                 = this.$window.initData;
        this.$scope.listing           = PartsWantedListingService.createEmpty();
        this.$scope.attachments       = {};
        this.$scope.datepickerOptions = this.getStandardDatepickerOptions();
        this.$scope.dropdowns         = {};
        this.$scope.alerts            = [];
        this.$scope.loading           = false;
        this.$scope.dateOpen          = false;
        this.$scope.currentDate       = Date.now();
        this.$scope.maxVehicleYear    = new Date().getFullYear() + 1;

        if (this.initData.year) {
            this.$scope.listing.year = this.initData.year;
        }

        if (this.initData.vin) {
            this.$scope.vin = this.initData.vin;
        }

        if (this.initData.vehicleId) {
            this.$scope.listing.vehicleId = this.initData.vehicleId;
        }
    }

    private async initDropdowns() {
        this.$scope.makes          = await this.makeService.getSorted();
        this.$scope.models         = await this.modelService.getSorted();
        this.$scope.states         = await this.stateService.getAll();
        this.$scope.countries      = await this.countryService.getAll();
        this.$scope.zipCodePattern = ( function (dds) {
            let canadianRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            let usRegex       = /^\d{5}(?:[-\s]\d{4})?$/;
            return {
                test : function (value) {
                    if (dds.selectedCountry &&
                        dds.selectedCountry.name === 'United States') {
                        return ( value.length > 0 ) ? usRegex.test(value) : true;
                    }
                    else if (dds.selectedCountry &&
                             dds.selectedCountry.name === 'Canada') {
                        return ( value.length > 0 ) ? canadianRegex.test(value) : true;
                    }
                    else {
                        return true;
                    }
                }
            };
        } )(this.$scope.dropdowns);

        if (this.initData.modelId) {
            let selectedModel                   = _.find(this.$scope.models, (model : IModel) => {
                return model.id === this.initData.modelId;
            });
            this.$scope.dropdowns.selectedMake  = _.find(this.$scope.makes, (make : IMake) => {
                return make.id === selectedModel.makeId;
            });
            this.$scope.dropdowns.selectedModel = selectedModel;
        }
    }

    private initEvents() {
        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
                        this.addressService.createHandler(this.$scope.listing,
                                                          this.$scope.states,
                                                          this.$scope.countries,
                                                          this.$scope.dropdowns));

        this.$scope.$on('fineUploader:file-upload',
                        ($event : angular.IAngularEvent, id : number, attachment : IAttachment) => {
                            this.$scope.attachments[ id ] = attachment;
                        });

        this.$scope.$on('fineUploader:file-removed',
                        ($event : angular.IAngularEvent, id : number) => {
                            delete this.$scope.attachments[ id ];
                        });
    }

    clearSelectedModel = () => {
        this.$scope.dropdowns.selectedModel = null;
    };

    public verifyStateSelection(item, model) {
        this.$scope.newPartsWantedForm.stateId.$setValidity('StateMatchesCountry',
                                                            this.addressService.stateMatchesCounty(
                                                                this.$scope.dropdowns.selectedState,
                                                                this.$scope.dropdowns.selectedCountry
                                                            )
        );
    }

    private initAddress = () => {
        let address : IAddress = {
            address1         : this.$scope.user.address1,
            city             : this.$scope.user.city,
            zipCode          : this.$scope.user.zipCode,
            latitude         : this.$scope.user.latitude,
            longitude        : this.$scope.user.longitude,
            stateId          : this.$scope.user.stateId,
            provinceName     : this.$scope.user.provinceName,
            countryId        : this.$scope.user.countryId,
            isInexactAddress : true
        };

        if (this.addressService.validate(address)) {
            this.$scope.listing.address1     = this.$scope.user.address1;
            this.$scope.listing.address2     = this.$scope.user.address2;
            this.$scope.listing.city         = this.$scope.user.city;
            this.$scope.listing.stateId      = this.$scope.user.stateId;
            this.$scope.listing.provinceName = this.$scope.user.provinceName;
            this.$scope.listing.zipCode      = this.$scope.user.zipCode;
            this.$scope.listing.countryId    = this.$scope.user.countryId;

            this.$scope.dropdowns.selectedCountry =
                _.find(this.$scope.countries, { 'id' : this.$scope.listing.countryId });
            this.$scope.dropdowns.selectedState   = _.find(this.$scope.states, { 'id' : this.$scope.listing.stateId });
        }
        else {
            this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries, { 'id' : 234 });
        }
    };

    private initUserInfo() {
        if (this.$scope.user.firstName) {
            this.$scope.listing.firstName = this.$scope.user.firstName;
        }

        if (this.$scope.user.lastName) {
            this.$scope.listing.lastName = this.$scope.user.lastName;
        }
    }

    async submit(event : IAngularEvent) {
        event.preventDefault();
        this.verifyStateSelection(null, null);
        try {
            if (this.$scope.newPartsWantedForm.$valid) {
                this.$scope.loading           = true;
                this.$scope.listing.modelId   = this.$scope.dropdowns.selectedModel.id;
                this.$scope.listing.countryId = this.$scope.dropdowns.selectedCountry.id;
                if (!this.$scope.dropdowns.selectedCountry.hasStates) {
                    this.$scope.dropdowns.selectedState = null;
                }
                if (this.$scope.dropdowns.selectedState != null) {
                    this.$scope.listing.stateId      = this.$scope.dropdowns.selectedState.id;
                    this.$scope.listing.provinceName = null;
                }
                else {
                    this.$scope.listing.stateId = null;
                }
                this.$scope.listing.makeId = this.$scope.dropdowns.selectedMake.id;

                if (this.$scope.listing.latitude == null ||
                    this.$scope.listing.longitude == null ||
                    this.$scope.listing.latitude === 0 ||
                    this.$scope.listing.longitude === 0) {
                    try {
                        await this.addressService.geoCodeAddress(this.$scope.listing,
                                                                 this.$scope.states,
                                                                 this.$scope.countries);
                    }
                    catch (err) {
                        // SUPPRESS error is intentional
                        console.log(err);
                    }
                    await this.createListing();
                }
                else {
                    await this.createListing();
                }
            }
        }
        catch (err) {
            // TODO: Add error handling
        }

        this.$scope.loading = false;
    }

    private async createListing() {
        try {
            let result = await this.partsWantedService.create(new IAddressJsonFilter().filter(this.$scope.listing));

            this.$scope.alerts.push({ type : 'success', msg : 'Please wait...' });
            let promises : angular.IPromise<any>[] = [];
            _.forEach(this.$scope.attachments,
                      (attachment : IAttachment) => {
                          promises.push(this.partsWantedService.attach(result, attachment));
                      });
            await this.$q.all(promises);
            this.$timeout(() => {
                              this.$window.location.href = `/partswanted/detail/${ result.id }`;
                          },
                          500);
        }
        catch (err) {
            this.$scope.alerts = [];
            this.$scope.alerts.push({ type : 'danger', msg : err.data.Message });
        }
        this.$scope.loading = false;
    }
}

interface INewPartsWantedListScope extends IBaseScope {
    addEditQuoteForm : angular.IFormController;
    makes : IMake[];
    selectedMake : IMake;
    models : IModel[];
    selectedModel : IModel;
    states : IState[];
    countries : ICountry[];
    newPartsWantedForm : angular.IFormController;
    filteredModels : any[];
    listing : IPartsWantedListing;
    dateOpen : boolean;
    datepickerOptions : any;
    currentDate : number;
    selectedState : IState;
    selectedCountry : ICountry;
    loading : boolean;
    dropdowns : any;
    alerts : any[];
    attachments : { [ id : number ] : IAttachment };
    maxVehicleYear : number;
    autoCompleteOptions : any;
    zipCodePattern : any;
    vin : string;
}

interface IInitData {
    year? : number;
    modelId? : number;
    vin? : string;
    vehicleId? : number;
}

angular.module('Row52.Views')
       .controller('NewPartsWantedListCtrl', NewPartsWantedListCtrl);