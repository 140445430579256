import * as angular                                                          from 'angular';
import { IPaginationState, IPartsWantedListingSearchResult, IUserJsonModel } from 'Row52.Models';
import '../../../css/parts-wanted/list.scss';
import {
    PartsWantedStatusService
}                                          from '../../services/data/parts-wanted-status.service';
import { MessagesService }                                                   from '../../services/messages.service';
import {
    PartsWantedFavoriteService
}                                        from '../../services/odata/parts-wanted-favorite.service';
import {
    PartsWantedListingService
}                                         from '../../services/odata/parts-wanted-listing.service';
import { PaginationService }                                                 from '../../services/ui/pagination.service';

class PartsWantedListCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'PartsWantedListingService',
        'PartsWantedStatusService',
        'MessagesService',
        'PartsWantedFavoriteService',
        'PaginationService'
    ];

    constructor(private $scope : IPartsWantedListScope,
                private $window : angular.IWindowService,
                private pwService : PartsWantedListingService,
                public pwStatusService : PartsWantedStatusService,
                private messageService : MessagesService,
                private pwfService : PartsWantedFavoriteService,
                private paginationService : PaginationService) {

        $scope.user     = this.$window[ 'user' ];
        $scope.loading  = true;
        $scope.listings = [];
        $scope.search   = {
            keyword    : this.$window.searchData.keyword,
            distance   : this.$window.searchData.distance,
            postalCode : this.$window.searchData.postalCode,
            hasImages  : this.$window.searchData.hasImage === 'True',
            withClosed : this.$window.searchData.withClosed === 'True'
        };

        this.$scope.paginationState             = this.paginationService.createPaginationState(10);
        let page                                = parseInt(this.$window.searchData.page, 10);
        this.$scope.paginationState.currentPage = page === 0 ? 1 : page;

        this.$scope.$watch((scope : IPartsWantedListScope) : number => scope.paginationState.currentPage,
                           () => {
                               this.performSearch();
                           });
    }

    sendMessage($event : angular.IAngularEvent, listing : IPartsWantedListingSearchResult) {
        $event.preventDefault();

        this.messageService.open(listing.userName, 'Re: ' + listing.partsTitle.substring(0, 46));
    }

    search($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.$scope.searchForm.$valid) {
            this.$scope.paginationState.currentPage = 1;
            this.performSearch();
        }
        else {
            this.$scope.searched = false;
        }
    }

    performSearch = async () => {
        this.$scope.loading = true;

        try {
            let result           = await this.pwService
                                             .search(this.$scope.search.keyword,
                                                     this.$scope.search.distance && this.$scope.search.postalCode
                                                     ? this.$scope.search.distance
                                                     : null,
                                                     this.$scope.search.postalCode,
                                                     this.$scope.search.hasImages,
                                                     this.$scope.search.withClosed,
                                                     this.$scope.paginationState);
            this.$scope.listings = result.value;
            this.$scope.count    = result.count;
            this.$scope.loading  = false;
            this.$scope.searched = true;

            this.paginationService.updatePaginationStateWithTotalItems(this.$scope.paginationState, result.count);
        }
        catch (err) {
            if (err.data && err.data.error) {
                let remoteServiceErrorMsg = err.data.error.message;
                if (err.data.error.innererror) {
                    remoteServiceErrorMsg += ' (' + err.data.error.innererror.message + ')';
                }
                this.$scope.remoteServiceError = remoteServiceErrorMsg;
            }
            this.$scope.listings = null;
            this.$scope.count    = 0;
            this.$scope.loading  = false;
        }
    };

    like($event :
             angular.IAngularEvent,
         listing :
             IPartsWantedListingSearchResult) {
        $event.preventDefault();

        if (!listing.isUserLike) {
            this.pwfService
                .createFavorite(listing.id, this.$scope.user.id)
                .then(() => {
                          listing.isUserLike = true;
                      },
                      () => {

                      });
        }
        else {
            this.pwfService
                .deleteAllForListing(listing.id, this.$scope.user.id)
                .then(() => {
                          listing.isUserLike = false;
                      },
                      () => {

                      });
        }
    }
}

interface IPartsWantedListScope extends angular.IScope {
    listings : IPartsWantedListingSearchResult[];
    search : {
        keyword : string;
        distance : number;
        postalCode : string;
        hasImages : boolean;
        withClosed : boolean;
    };
    display : any;
    user : IUserJsonModel;
    count : number;
    loading : boolean;
    paginationState : IPaginationState;
    searched : boolean;
    searchForm : angular.IFormController;
    remoteServiceError : string;
}

angular.module(
    'Row52.Views')
       .controller(
           'PartsWantedListCtrl',
           PartsWantedListCtrl);
