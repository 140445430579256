import './seller-parts-sale-active-widget.html';
import './seller-parts-sale-active-widget.scss';
import '../../../partials/parts-sale-listing-status/parts-sale-listing-status.html';
import '../../../partials/parts-sale-listing-dates/parts-sale-listing-dates.html';

import * as angular                         from 'angular';
import { BaseController, IBaseScope }       from '../../../../base-controller';
import { PartsForSaleListingService }       from '../../../../services/odata/parts-for-sale-listing.service';
import { IPartsForSaleListingSearchResult } from 'Row52.Models';

export class SellerPartsSaleActiveWidgetCtrl extends BaseController {
    public static $inject : string[] = [ '$scope', '$window', 'PartsForSaleListingService' ];

    constructor(protected $scope : ISellerPartsSaleActiveWidgetScope,
                protected $window : angular.IWindowService,
                private pfsService : PartsForSaleListingService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();

        await this.loadPartsForSale();
    }

    private async loadPartsForSale() {
        try {
            this.$scope.loading = true;

            let listings              = await this.pfsService.getActiveForSeller(this.$scope.user.id, 5);
            this.$scope.listings      = listings.value;
            this.$scope.listingsCount = listings.count;
        }
        catch (err) {

        }

        this.$scope.loading = false;
    }
}

interface ISellerPartsSaleActiveWidgetScope extends IBaseScope {
    loading : boolean;
    listings : IPartsForSaleListingSearchResult[];
    listingsCount : number;
}

angular.module('Row52.Views.Account.Summary.SellerPartsSaleActiveWidget', [])
       .controller('SellerPartsSaleActiveWidgetCtrl', SellerPartsSaleActiveWidgetCtrl);