import './sell-registration';
import './registration-wizard/registration-wizard.module';
import './puller-locations/puller-locations.module';
import './edit-personal-info/edit-personal-info';
import './display-personal-info/display-personal-info';
import './verification-status/verification-status';
import './edit-alerts/edit-alerts';

import * as angular from 'angular';

angular.module('Row52.Views.Account.SellerRegistration', [
    'Row52.Views.Account.SellerRegistration.RegistrationWizard',
    'Row52.Views.Account.SellerRegistration.SellerRegistrationCtrl',
    'Row52.Views.Account.SellerRegistration.PullerLocations',
    'Row52.Views.Account.SellerRegistration.EditPersonalInfo',
    'Row52.Views.Account.SellerRegistration.DisplayPersonalInfo',
    'Row52.Views.Account.SellerRegistration.VerificationStatus',
    'Row52.Views.Account.SellerRegistration.EditAlertsCtrl'
]);