import * as angular from 'angular';
import 'angular-ui-bootstrap';
import * as uib from 'angular-ui-bootstrap';
import '../../templates/partials/confirm-address-modal.html'

interface IConfirmOptions {
    okBtnText? : string,
    cancelBtnText? : string,
    replyMessageText? : string,
}

export interface IConfirmModalService {
    open(title : string, message? : string, options? : IConfirmOptions) : angular.IPromise<any>;
}

export class ConfirmAddressModalService implements IConfirmModalService {
    static $inject = ['$uibModal'];

    constructor(private $uibModal : uib.IModalService) {
    }

    open(title : string, message? : string, options? : IConfirmOptions) : angular.IPromise<any> {
        return this.$uibModal.open({
            templateUrl : 'partials/confirm-address-modal.html',
            controller : 'ConfAddrMessageCtrl',
            resolve : {
                message() {
                    return message;
                },
                title() {
                    return title;
                },
                options() {
                    return options || {};
                }
            }
        }).result;
    }
}

export interface IConfirmModalScope extends angular.IScope {
    message : string;
    title : string;
    selected : { item : string };
    okBtnText : string;
    cancelBtnText : string;
    confirmationMessage : string;

    open(size : any) : any;

    ok() : any;

    cancel() : any;

    openSplash(event : any, size : any) : any;
}

export class ConfAddrMessageCtrl {
    static $inject : string[] = ['$scope', '$uibModalInstance', 'title', 'message', 'options'];

    constructor($scope : IConfirmModalScope,
        $uibModalInstance : uib.IModalServiceInstance,
        title : string,
        message : string,
        options : IConfirmOptions) {

        $scope.message = message;
        $scope.title = title;
        $scope.okBtnText = options.okBtnText || 'Ok';
        $scope.cancelBtnText = options.cancelBtnText || 'Cancel';

        $scope.ok = () => {
            $scope.confirmationMessage = options.replyMessageText;
            setTimeout($uibModalInstance.close, 250);
        };

        $scope.cancel = () => {
            setTimeout($uibModalInstance.close, 250);
        };
    }
}


angular.module('Row52.Services.ConfirmAddressModal', ['ui.bootstrap'])
    .service('ConfirmAddressModalService', ConfirmAddressModalService)
    .controller('ConfAddrMessageCtrl', ConfAddrMessageCtrl);