import './profile-tab.html';
import './profile-tab.scss';

import * as angular                   from 'angular';
import { BaseController, IBaseScope } from '../../../../base-controller';

export class ProfileTabCtrl extends BaseController {
    public static $inject : string[] = ['$scope', '$window'];

    constructor(protected $scope : ProfileTabScope,
                protected $window : angular.IWindowService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.activeTab = 0;
        this.$scope.viewText  = 'View Profile';

        this.$scope.$watch('activeTab', (newVal : number) => {
            if (newVal > 0) {
                this.$scope.viewText = 'View All';
            }
            else {
                this.$scope.viewText = 'View Profile';
            }
        });
    }

    viewAll($event : angular.IAngularEvent) {
        $event.preventDefault();

        switch (this.$scope.activeTab) {
            case 0 :
                this.$window.location.href = `/User/Profile/${this.$scope.user.userName}`;
                break;
            case 1:
                this.$window.location.href = '/Account/Searches';
                break;
            case 2:
                this.$window.location.href = '/Account/Favorites';
                break;
        }
    }
}

interface ProfileTabScope extends IBaseScope {
    viewText : string;
    activeTab : number;
}

angular.module('Row52.Views.Account.Summary.ProfileTab', [])
       .controller('ProfileTabCtrl', ProfileTabCtrl);