import * as angular from 'angular';
import * as _ from 'lodash';
import {
    IPartsForSaleListingSearchResult,
    IPartsWantedListingSearchResult,
    IUserJsonModel
    } from 'Row52.Models';
import {
    IListing_SqlView
    } from 'Row52.Models.Entities';
import { IBaseScope, BaseController } from '../../base-controller';
import { PartsForSaleListingService } from '../../services/odata/parts-for-sale-listing.service';
import { PartsWantedListingService } from '../../services/odata/parts-wanted-listing.service';
import { PartsWantedStatusService } from '../../services/data/parts-wanted-status.service';
import { PartsForSaleStatuses } from '../../constants/parts-for-sale-statuses';
import { PartsWantedStatuses } from '../../constants/parts-wanted-statuses';
import { PartsForSaleStatusService } from '../../services/data/parts-for-sale-status.service';
import { ListingSqlViewService } from '../../services/odata/listing.service';

export class MyListingsController extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'PartsWantedListingService',
        'PartsForSaleListingService',
        'PartsWantedStatusService',
        'PartsForSaleStatusService',
        'ListingSqlViewService',
        '$q'
    ];

    private parts : IPartsForSaleListingSearchResult[];
    private purchasedParts : IListing_SqlView[];

    constructor(protected $scope : IMyListingScope,
        protected $window : angular.IWindowService,
        private pwService : PartsWantedListingService,
        private pfsService : PartsForSaleListingService,
        private pwStatusService: PartsForSaleStatusService,
        public listingService : ListingSqlViewService,
        private $q : angular.IQService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.listings = [];
        this.$scope.parts = [];
        this.$scope.partStatuses = PartsForSaleStatuses;

        await this.$q.all([this.loadParts(), this.loadListings()]);
    }

    private loadParts = async () => {
        this.$scope.partsLoading = true;
        this.parts = (await this.pfsService.getAllForSeller(this.$scope.user.id)).value;
        this.purchasedParts = (await this.listingService.getAllForBuyer(this.$scope.user.id)).value;

        this.$scope.closedParts = _.filter(this.parts,
            (part : IPartsForSaleListingSearchResult) => {
                return (part.status === PartsForSaleStatuses.Sold && part.shippedDate !== null) ||
                    part.status === PartsForSaleStatuses.Canceled ||
                    part.status === PartsForSaleStatuses.Expired ||
                    part.status === PartsForSaleStatuses.Refunded ||
                    part.status === PartsForSaleStatuses.Shipped;
            });

        this.$scope.parts = _.orderBy(_.filter(this.parts,
                (part : IPartsForSaleListingSearchResult) => {
                    return part.status === PartsForSaleStatuses.Available ||
                        (part.status === PartsForSaleStatuses.Sold && part.shippedDate === null);
                }),
            ['status', 'soldDate'],
            ['desc', 'desc']);
        
        this.$scope.purchasedParts = this.purchasedParts;

        this.$scope.partsLoading = false;
    };

    private loadListings = async () => {
        this.$scope.listingsLoading = true;
        this.$scope.listings = (await this.pwService.getPartsWantedListingByBuyer(this.$scope.user.id)).value;
        this.$scope.listings = this.$scope.listings.sort((a, b) => {
            return a.creationDate.getTime() - b.creationDate.getTime();
            return a.creationDate.getTime() - b.creationDate.getTime();
        })
        this.$scope.activeListings = _.filter(this.$scope.listings,
            (listing : IPartsWantedListingSearchResult) => {
                return listing.status === PartsWantedStatuses.Open;
            });
        this.$scope.listingsLoading = false;
    };

    navigateToPartsSale($event : angular.IAngularEvent, part : IPartsForSaleListingSearchResult) {
        this.$window.location.href = `/PartsSale/Detail/${part.id}`;
    }

    navigateToPartsWanted($event : angular.IAngularEvent, listing : IPartsWantedListingSearchResult) {
        this.$window.location.href = `/PartsWanted/Detail/${listing.id}`;
    }
}

export interface IMyListingScope extends IBaseScope {
    listings : IPartsWantedListingSearchResult[];
    activeListings : IPartsWantedListingSearchResult[];
    listingsLoading : boolean;
    parts : IPartsForSaleListingSearchResult[];
    partsLoading : boolean;
    closedParts : IPartsForSaleListingSearchResult[];
    user : IUserJsonModel;
    purchasedParts : IListing_SqlView[];
    partStatuses : PartsForSaleStatuses;
}

angular.module('Row52.Views.Account.MyListings', [])
    .filter('orderByExpiration', function() {
    return function(listing) {
        let status = '';
        switch (listing.status) {
            case PartsWantedStatuses.Open:
                status = 'Open';
                break;
            case PartsWantedStatuses.Closed:
                status = 'Closed';
                break;
            case PartsWantedStatuses.Expired:
                status = 'Expired';
                break;
            default:
                status = 'Not-set';
                break;
        }
        if (status === 'Open') {
            return '0-expirationDate';
        } else if (status === 'Closed') {
            return '1-expirationDate';
        } else {
            return '2-expirationDate';
        }
    }})
    .controller('MyListingsCtrl', MyListingsController);