import * as angular from 'angular';
import './sidebar/sidebar';
import './ship/ship';
import './comments/comments';
import './detail';
import './publish/publish';
import './publish/modal';
import './receipt/receipt';

angular.module('Row52.Views.PartsSale.Detail', [
    'Row52.Views.PartsSale.Detail.Sidebar',
    'Row52.Views.PartsSale.Detail.PartsSaleDetailShip',
    'Row52.Views.PartsSale.Detail.Comments',
    'Row52.Views.PartsSale.Detail.MainCtrl',
    'Row52.Views.PartsSale.Detail.Publish.PartsSaleDetailPublish',
    'Row52.Views.PartsSale.Detail.Publish.ModalCtrl',
    'Row52.Views.PartsSale.Detail.PartsSaleDetailReceipt'
]);