import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService }                from '../../../../services/odata/user.service';
import { IAttachment, IUser }         from 'Row52.Models.Entities';
import './avatar-management.html';
import './avatar-management.scss';

class AvatarManagementCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService'
    ];

    constructor(protected $scope : IAvatarManagementScope,
                protected $window : angular.IWindowService,
                private userService : UserService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.userModel = this.$window[ 'userModel' ];
        this.$scope.jcrop     = {
            coords : [ 0, 0, 300, 300, 300, 300 ]
        };

        this.initEmptyAvatar();
        this.$scope.$on('fineUploader:file-upload',
                        ($event : angular.IAngularEvent, id : number, attachment : IAttachment) => {
                            this.attachAvatar(attachment);
                            this.$scope.userModel.avatarAttachment = attachment;
                            this.$scope.$apply();
                        });
    }

    private initEmptyAvatar() {
        if (this.$scope.userModel.avatarAttachment === null) {
            this.$scope.userModel.avatarAttachment = {
                id            : null,
                original      : 'https://cdn.row52.com/siteimages/avatar300.jpg',
                thumbnail     : null,
                fullSize      : 'https://cdn.row52.com/siteimages/avatar300.jpg',
                extension     : 'jpg',
                resourceUrl   : null,
                fullSizeName  : null,
                thumbnailName : null,
                originalName  : null,
                userId        : null,
                user          : null,
                creationDate  : null,
                isAttached    : true,
                success       : null
            };
        }
    }

    private async cropAvatar() {
        this.$scope.uploading = true;
        try {
            let data = await this.userService.crop(this.$scope.user,
                                                   this.$scope.userModel.avatarAttachment,
                                                   this.$scope.jcrop.coords);

            this.$scope.userModel.avatarAttachment = data;
        }
        catch (err) {
            console.error(err);
            // TODO : provide feedback to user about nature of error
        }
        this.$scope.uploading = false;
    }

    private attachAvatar(attachment) {
        this.userService.avatar(this.$scope.user, attachment)
            .then((ok : any) => {
                  },
                  (err) => {
                      console.error('error', err);
                  });
    }
}

interface IAvatarManagementScope extends IBaseScope {
    jcrop : {
        coords : number[]
    };

    uploading : boolean;
    userModel : IUser;
}

angular.module('Row52.Views.Account.Settings.AvatarManagement', [])
       .controller('AvatarManagementCtrl', AvatarManagementCtrl);