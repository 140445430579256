import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService }                from '../../../../services/odata/user.service';
import { ConfirmModalService }        from '../../../../services/ui/confirm.modal';
import './delete-account.html';
import './delete-account.scss';

class DeleteAccountCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService',
        'confirmModalService',
        '$cookies'
    ];

    constructor(protected $scope : IDeleteAccountScope,
                protected $window : angular.IWindowService,
                private userService : UserService,
                private confirm : ConfirmModalService,
                private $cookies : angular.cookies.ICookiesService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.alerts = [];
    }

    async deleteAccount() {
        try {
            await this.confirm
                      .open('Are you sure you want to permanently delete your account ?', null, {
                          okBtnText     : 'Yes',
                          cancelBtnText : 'No'
                      });

            await this.userService.deleteUser(this.$scope.user);
            this.$cookies.remove('R52ACK'); //This might not be the right cookie
            this.$window.location.href = '/';
        }
        catch (err) {
            if (typeof (err) === 'object') {
                let message = err.statusText;
                if (message === undefined || message === '') {
                    message = 'an error occurred';
                }
                this.$scope.alerts.push({ msg : message, type : 'danger' });
            }
        }
    }
}

interface IDeleteAccountScope extends IBaseScope {
    loading : boolean;
    alerts : { msg : string, type : 'success' | 'danger' }[]
}

angular.module('Row52.Views.Account.Settings.DeleteAccount', [])
       .controller('DeleteAccountCtrl', DeleteAccountCtrl);