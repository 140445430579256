import * as angular        from 'angular';
import '../../constants/constants';
import { ResourceService } from './odata.resource';
import { IVehicleDetail }  from 'Row52.Models';

/**
 * Created by Ri Scott on 5/5/2017.
 */
export class VehicleDetailResourceService extends ResourceService<IVehicleDetail> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'VehicleDetails');
    }

    public getMostRecentByYard(locationId : number, limitTo : number = 0) : angular.IPromise<IVehicleDetail[]> {
        let top = '';

        if (limitTo > 0) {
            top = '&$top=20';
        }

        return this.unwrapArray(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }/`
                     + `?$filter=locationId eq ${ locationId }`
                     + `&$orderby=dateAdded desc`
                     + `${ top }`,
                     this.getHeaders())
        );
    }

    public reportIncorrectImage(vehicleId : number) : angular.IPromise<void> {
        return this.unwrapSingle(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ vehicleId })/Row52.IncorrectPicture`, {},
                      this.getHeaders())
        );
    }

    public removeImage(vehicleId : number) : angular.IPromise<void> {
        return this.unwrapSingle(
            this.$http
                .post(`${ this.domain }/odata/${ this.resourceUrl }(${ vehicleId })/Row52.RemoveImage`, {},
                      this.getHeaders())
        );
    }
}

angular.module('Row52.Services.VehicleDetailResourceService', [ 'ngCookies', 'Row52.Constants' ])
       .service('VehicleDetailResourceService', VehicleDetailResourceService);
