import * as angular                from 'angular';
import * as _                      from 'lodash';
import { IMake, IModel }           from 'Row52.Models.Entities';
import { MakeResourceService }     from '../../services/odata/make.service';
import { ModelResourceService }    from '../../services/odata/model.service';
import { LocationResourceService } from '../../services/odata/location-service';

interface ILocationDropDownModel {
    name : string;
    id : number;
    isParticipating : boolean;
    state : { name : string };
}

interface IVehicleSearchScope extends angular.IScope {
    search : {
        make : IMake,
        model : IModel,
        location : ILocationDropDownModel,
        year : number,
        type : string,
        v : string[],
        distance : number,
        hasComment : boolean,
        hasImage : boolean,
        zipCode : string
    };
    radiuses : number[];
    makes : IMake[];
    models : IModel[];
    locations : ILocationDropDownModel[];
    filterModels : (model : IModel, make : IMake) => boolean;
    onMakeSelect : (make : IMake) => void;
    isSearchCollapsed : boolean;
    savedSearchExpander : string;
    savedSearchCount : string;
}

class VehicleSearchCtrl {
    static $inject : string[] = [
        '$scope',
        '$http',
        '$location',
        '$timeout',
        'MakeService',
        'ModelService',
        'LocationService',
        '$window'
    ];

    constructor(private $scope : IVehicleSearchScope,
                private $http : angular.IHttpService,
                private $location : angular.ILocationService,
                private $timeout : angular.ITimeoutService,
                private makeService : MakeResourceService,
                private modelService : ModelResourceService,
                private locationService : LocationResourceService,
                private $window : any) {

        if (!this.$window.searchData) {
            this.initUnitializedSearchData();
        }

        this.$scope.search = {
            make       : null,
            model      : null,
            location   : null,
            year       : null,
            type       : this.$window.searchData.isVin ? 'VIN' : 'YMM',
            v          : [],
            distance   : this.$window.searchData.distance || 50,
            hasComment : this.$window.searchData.hasComment === 'True',
            hasImage   : this.$window.searchData.hasImage === 'True',
            zipCode    : this.$window.searchData.zipCode || null
        };

        this.$scope.radiuses = [ 10, 25, 50, 100, 250, 500 ];

        for (let i = 1; i < 18; i++) {
            this.$scope.search.v.push(this.$window.searchData[ 'v' + i ]);
        }

        this.makeService
            .getSorted()
            .then(x => {
                this.$scope.makes = x;

                if (this.$window.searchData && this.$window.searchData.makeId) {
                    this.$scope.search.make = _.find(x, { 'id' : this.$window.searchData.makeId });
                }
            });

        this.modelService
            .getSorted()
            .then(x => {
                this.$scope.models = x;

                if (this.$window.searchData && this.$window.searchData.modelId) {
                    this.$scope.search.model = _.find(x, { 'id' : this.$window.searchData.modelId });
                }
            });

        this.locationService
            .getByQuery(this.locationService
                            .createQuery()
                            .expand('state($select=name)')
                            .orderBy('state/name')
                            .select([ 'id', 'name', 'isParticipating' ]))
            .then((x : ILocationDropDownModel[]) => {
                this.$scope.locations = _.orderBy(x, [ 'state.name', 'name' ], [ 'asc', 'asc' ]);

                if (this.$window.searchData && this.$window.searchData.locationId) {
                    this.$scope.search.location = _.find(x, { 'id' : this.$window.searchData.locationId });
                }
            });

        this.$scope.$on('savedSearchSaved',
                        () => {
                            this.getSavedSearches();
                        });

        if (this.$window.searchData && this.$window.searchData.year) {
            this.$scope.search.year = this.$window.searchData.year;
        }

        this.$scope.onMakeSelect = (make : IMake) : void => {
            this.$scope.search.model = null;
        };

        this.getSavedSearches();
    }

    private getSavedSearches() {
        let that = this;
        this.$http.get('/Search/SavedSearchCount')
            .then(function (response : angular.IHttpResponse<Object>) {
                      that.$scope.savedSearchCount = response.data.toString();
                  },
                  function (reason : any) {
                      that.$scope.savedSearchCount = '0';
                  }).catch(function (err) {
            that.$scope.savedSearchExpander = '0';
        });
        this.$http.get('/Search/SavedSearchExpander')
            .then(function (response : angular.IHttpResponse<Object>) {
                      that.$scope.savedSearchExpander = response.data.toString();
                  },
                  function (reason : any) {
                      that.$scope.savedSearchExpander = 'We could not load your saved searches.';
                  }).catch(function (err) {
            that.$scope.savedSearchExpander = err.message;
        });
    }

    private initUnitializedSearchData() {
        this.$window.searchData = {
            isVin : false
        };

        for (let i = 1; i < 18; i++) {
            this.$window.searchData[ 'v' + i ] = '';
        }
    }

    groupLocations = (item : ILocationDropDownModel) : string => {
        return item.state.name;
    };

    getName = (item : ILocationDropDownModel) : string => {
        return `&nbsp;&nbsp;&nbsp;&nbsp;${item.name}`;
    };

    getType = () : boolean => {
        return this.$scope.search.type === 'VIN';
    };
}

angular.module('Row52.Views')
       .controller('VehicleSearchCtrl', VehicleSearchCtrl);