import 'jquery';
import 'lodash';
import 'moment';
import 'toastr';
import { IHttpHeadersGetter } from 'angular';
import * as angular           from 'angular';

import 'angular-sanitize';
import 'angular-animate';
import 'angular-cookies';
import 'angular-messages';
import 'angular-touch';

import 'angular-ui-bootstrap';
import 'angular-moment';
import 'angular-file-upload';
import 'ngmap';
import 'angular-toastr';
import 'angular-carousel';
import 'slick-carousel/slick/slick.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'angular-slick';
import 'ui-select';
import 'tinyscrollbar';
import 'ng-infinite-scroll';
import 'angular-spinner';
import 'angular-ui-mask';
import 'angular-ui-carousel';

import '../vendor/flow.exec.js';
import '../vendor/ng-flow.js';
import '../vendor/angular-dateparser.js';
import '../vendor/angular-timepicker.js';

import '../scripts/row52/row52-ui.js';

import * as jcrop from 'jquery-jcrop';
import 'ng-jcrop';
import 'jquery-jcrop/css/jquery.Jcrop.css';

angular.module('row52-templates', []);
angular.module('row52-views', []);
angular.module('row52-directives', []);
import './directives/directives';
import './services/module';
import './views/module';
import './constants/constants';
import './token-updator';

import '../css/bootstrap.css';
import '../css/select.css';
import '../css/select2.css';
import '../css/autocomplete.css';
import '../css/app.scss';

module App {

}

angular.module('jQuery', [])
       .run([
                '$window', ($window) => {
               $window.jQuery = jQuery;
           }
            ]);

angular.module('Jcrop', [ 'jQuery' ])
       .run([
                '$window', ($window) => {
               $window.jQuery.Jcrop = jcrop;
           }
            ]);

angular.module('App',
               [
                   'Row52.Directives',
                   'Row52.Views',
                   'Row52.Services',
                   'Row52.Views.Account',
                   'infinite-scroll',
                   'row52-templates',
                   'row52-views',

                   'google.places',
                   'angularSpinner',
                   'Row52.Constants',
                   'Jcrop',
                   'ngJcrop',
                   'row52.security.token-updator'
               ])
        .config([
                '$locationProvider', '$httpProvider',
                ($locationProvider : angular.ILocationProvider, $httpProvider : angular.IHttpProvider) => {

                    if (window.history && window.history.pushState) {
                        $locationProvider.html5Mode({
                            enabled : true,
                            requireBase : false,
                            rewriteLinks : false
                        });
                    } else {
                        $locationProvider.html5Mode(false);
                    }

                    $httpProvider.interceptors.push('tokenUpdator');
                }
            ])
       .run([
                '$window', '$q',
                ($window : angular.IWindowService, $q : angular.IQService) => {
                    $window.Promise = $q;
                }
            ]);
