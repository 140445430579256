import * as angular                                    from 'angular';
import * as moment                                     from 'moment';
import { ICountry, IState, IStripeMarketplaceAccount } from 'Row52.Models.Entities';
import { BaseController, IBaseScope }                  from '../../../../base-controller';
import { IBankAccount }                                from '../../../../models/marketplace/stripe-marketplace-account';
import { UserService }                                 from '../../../../services/odata/user.service';
import { RemoteErrorModalService }                     from '../../../../services/ui/remoteerror.modal';
import { MobileVerificationCtrl }                      from '../../mobile-registration/mobile-verification/mobile-verification';
import { RegistrationWizardPersonalInfoCtrl }          from './personal-info/personal-info';
import { RegistrationWizardPullerListingCtrl }         from './puller-listing/puller-listing';
import './registration-wizard.html';
import './registration-wizard.scss';

enum WizardSteps {
    Mobile   = 1,
    Personal = 2,
    Banking  = 3,
    Puller   = 4,
    Button = 5
}

export class RegistrationWizardCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$q',
        'UserService',
        'remoteErrorModalService',
        '$timeout',
        'tagManager'
    ];

    public stripeAccount : IStripeMarketplaceAccount;
    public bankAccount : IBankAccount;
    public userSettings : {
        isTaxable : boolean;
        bio : string;
        marketplaceAlert : boolean;
        sellerTos : boolean;
    };
    public selectedCountry : ICountry;
    public selectedState : IState;
    public stripeTos : boolean;
    private stateId : number;
    private countryId : number;
    private remoteErrMsg : string;
    private googleEvent : any;

    constructor(protected $scope : IRegistrationWizardScope,
                protected $window : angular.IWindowService,
                private $q : angular.IQService,
                private userService : UserService,
                private remoteErrorService : RemoteErrorModalService,
                private $timeout : angular.ITimeoutService,
                private tagManager : any) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.calculateWizardStep();
        this.$scope.steps = WizardSteps;
        // this.initStripeAccount();
        // this.initBankAccount();
        // this.initWizardEvents();
        if (this.$scope.user.isSeller) {
            this.$scope.wizardStep = WizardSteps.Puller;
        } else {
            this.$scope.wizardStep = WizardSteps.Button
        }
        this.initUserSettings();

        this.googleEvent = {};
    }

    private initStripeAccount() {
        this.stripeAccount = {
            countryId  : 234, // TODO : Refactor this to a constant
            postalCode : null,
            latitude   : null,
            longitude  : null
        } as IStripeMarketplaceAccount;
    }

    private initBankAccount() {
        this.bankAccount = {
            holderName    : null,
            accountNumber : null,
            ssn           : null,
            routingNumber : null
        };
    }

    private initUserSettings() {
        this.userSettings = {
            sellerTos        : false,
            marketplaceAlert : true,
            bio              : null,
            isTaxable        : false
        };
    }

    private initWizardEvents() {
        this.$scope.$on(MobileVerificationCtrl.MobileVerificationSuccess,
                        ($event : angular.IAngularEvent) => {
                            this.$scope.user.mobileStatus      = 'activated';
                            this.$scope.user.isMobileActivated = true;
                            this.$scope.wizardStep             = WizardSteps.Personal;
                            this.sendGoogleEvent('Personal Information', 2);
                        });

        this.$scope.$on(RegistrationWizardPersonalInfoCtrl.PersonalInfoSaved,
                        () => {
                            this.$scope.wizardStep = WizardSteps.Puller;
                            this.sendGoogleEvent('Personal Information', 4);
                        });

        this.$scope.$on(RegistrationWizardPullerListingCtrl.PreviousStep,
                        () => {
                            this.$scope.wizardStep = WizardSteps.Personal;
                            this.sendGoogleEvent('Personal Information', 2);
                        });

        this.$scope.$on(RegistrationWizardPullerListingCtrl.FinalStep,
                        () => {
                            this.registerPuller();
                        });
    }

    private calculateWizardStep() {
        if (this.$scope.user.isMobileActivated) {
            this.$scope.wizardStep = WizardSteps.Personal;
            this.sendGoogleEvent('Personal Information', 2);
        }
        else {
            this.$scope.wizardStep = WizardSteps.Mobile;
            this.sendGoogleEvent('Mobile Verification', 1);
        }
    }

    private sendGoogleEvent(title : string, step : number) {
        let eventData = {
            'send_to'        : GOOGLE_TAG_KEY,
            'title'          : `Seller Registration - ${ title } (${ step })`,
            'event_category' : 'seller_registration',
            'event_action'   : 'registration_step_initiated',
            'value'          : step,
            'event_label'    : 'Seller Registration Step',
            'event_callback' : () => {

            }
        };

        if (this.$scope.user) {
            eventData[ 'user_id' ] = this.$scope.user.userName;
        }

        this.tagManager('event', 'reg_step', eventData);
    }

    getSetDob(newValue? : string) {
        if (arguments.length === 1) {
            if (RegistrationWizardCtrl.dateMatcher.test(newValue)) {
                let results                    = RegistrationWizardCtrl.dateMatcher.exec(newValue);
                this.stripeAccount.dateOfBirth = new Date(parseInt(results[ 3 ], 10),
                                                          parseInt(results[ 1 ], 10) - 1,
                                                          parseInt(results[ 2 ], 10));
            }
        }
        else {
            if (this.stripeAccount && this.stripeAccount.dateOfBirth) {
                return moment(this.stripeAccount.dateOfBirth).format('MM/DD/YYYY');
            }
            return null;
        }
    }

    async registerPuller() {
        if (!this.validateRegistration()) {
            this.$scope.$broadcast('ERROR');
        }

        this.setData();

        this.$scope.loading = true;

        let regRetVal = null;

        regRetVal = await this.registerNormalSeller();

        if (regRetVal === true) {
            this.$scope.registerSuccess = true;
            let that                    = this;
            this.$timeout(function () {
                              that.$window.location.reload();
                              that.$window.location.href = '/Account/MarketplaceSettings?registered=true';
                          },
                          2500);
        }
        else {
            this.remoteErrMsg = 'An unspecified error has occurred.';
            if (regRetVal.data && regRetVal.data.error) {
                this.remoteErrMsg = regRetVal.data.error.message;
                if (regRetVal.data.error.innererror) {
                    this.remoteErrMsg += ( ' ' + regRetVal.data.error.innererror.message );
                }
            }
            else if (regRetVal.message) {
                this.remoteErrMsg = regRetVal.message;
            }
            // show error in modal
            await this.remoteErrorService
                      .open('Attention',
                            this.remoteErrMsg,
                            { okBtnText : 'OK' });
        }

        this.$scope.loading = false;
    };

    private setData() {
        this.stateId = 0;
        if (this.selectedState) {
            this.stateId = this.selectedState.id;
        }

        this.countryId = 0;
        if (this.selectedCountry) {
            this.countryId = this.selectedCountry.id;
        }
    }

    private validateRegistration() : boolean {
        return true;
    }
    
    private async registerAsSeller() {
        try {
            this.$scope.loading = true;
            await this.userService.switchIsSeller(this.$scope.user);
            this.$scope.loading = true;
            this.$scope.registerSuccess = true;
            let that                    = this;
            this.$timeout(function () {
                    that.$window.location.reload();
                    that.$window.location.href = '/Account/MarketplaceSettings?registered=true';
                },
                2500);
            return true;
        } catch (err) {
            this.$scope.error = true;
            return err;
        }
    }

    private async registerNormalSeller() {
        try {
            //let piiPromise  = this.stripeService.getPersonalIdentifierToken(this.bankAccount.ssn);
            //let bankPromise = this.stripeService.getBankAccountToken(this.selectedCountry.abbreviation,
            //                                                         this.selectedCountry.currencyCode,
            //                                                         this.bankAccount.routingNumber,
            //                                                         this.bankAccount.accountNumber,
            //                                                         this.bankAccount.holderName,
            //                                                         this.stripeAccount.type);

            //let promiseValues = await this.$q.all([ piiPromise, bankPromise ]);

            //let piiToken  = promiseValues[ 0 ],
            //    bankToken = promiseValues[ 1 ];

            //if (piiToken && bankToken && piiToken.token && bankToken.token) {
                await this.userService
                          .registerPuller(this.$scope.user,
                                          this.stripeAccount,
                                          this.stateId,
                                          this.countryId,
                                          null,
                                          null,
                                          this.userSettings.bio,
                                          this.userSettings.marketplaceAlert,
                                          false);
                return true;
            //}
            //else if (bankToken.error || piiToken.error) {
            //    throw new Error(bankToken.error.message || piiToken.error.message);
            //}
        }
        catch (err) {
            this.$scope.error = true;
            return err;
        }
    }
}

interface IRegistrationWizardScope extends IBaseScope {
    wizardStep : WizardSteps;
    steps : any;
    loading : boolean;
    error : boolean;
    registerSuccess : boolean;
}

angular.module('Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizard', [
           'Row52.Constants',
           'Row52.Services.GoogleTagManager'
       ])
       .controller('RegistrationWizardCtrl', RegistrationWizardCtrl);
