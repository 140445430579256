import * as angular from 'angular';
import * as moment from 'moment';
import { IUserPartsSaleFavoriteRelationship } from 'Row52.Models.Entities';
import '../../constants/constants';
import { ResourceCrudService } from './odata.resource';
import { IPartsForSaleListingSearchResult } from 'Row52.Models';

export class PartsForSaleFavoriteService extends ResourceCrudService<IUserPartsSaleFavoriteRelationship> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'UserPartsForSaleFavouriteRelationships');
    }

    public createFavorite(listingId : number, userId : string) : angular.IPromise<IUserPartsSaleFavoriteRelationship> {
        let creationDate = moment()
            .toDate();

        let favorite : IUserPartsSaleFavoriteRelationship = {
            partsSaleListingId : listingId,
            userId             : userId,
            id                 : 0,
            user               : null,
            partsSaleListing   : null,
            creationDate       : creationDate
        };

        return this.create(favorite);
    }

    public deleteAllForListing(listingId : number, userId : string) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}/Row52.DeleteAllForListing`,
                      { ListingId : listingId, UserId : userId },
                      this.getHeaders())
        );
    }

    public getForListing(listingId : number, userId : string) : angular.IPromise<IUserPartsSaleFavoriteRelationship[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/`
                     + `?$filter=partsSaleListingId eq ${listingId} and userId eq ${userId}`,
                    this.getHeaders())
        );
    }

    public getForUser(userId : string) : angular.IPromise<IPartsForSaleListingSearchResult[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/Users(${userId})/Row52.GetAllPartsSaleFavorites`,
                     this.getHeaders())
        );
    }
}

angular.module('Row52.Services.PartsForSaleFavoriteService', [ 'ngCookies', 'Row52.Constants' ])
       .service('PartsForSaleFavoriteService', PartsForSaleFavoriteService);