import * as angular                           from 'angular';
import * as _                                 from 'lodash';
import {
    IMake, IModel, IPartsSaleListing, IAttachment, IState, ICountry, IShippingValidationResponse
}                                             from 'Row52.Models.Entities';
import { IAddressJsonFilter }                 from '../../models/entities/IAddressExtension';
import { MakeResourceService }                from '../../services/odata/make.service';
import { ModelResourceService }               from '../../services/odata/model.service';
import { CountryService }                     from '../../services/odata/country.service';
import { StateService }                       from '../../services/odata/state.service';
import { ConfirmModalService }                from '../../services/ui/confirm.modal';
import { IAngularEvent }                      from 'angular';
import { PartsForSaleListingService }         from '../../services/odata/parts-for-sale-listing.service';
import { AddressCompletionService, IAddress } from '../../services/helpers/address.completion.service';
import { BaseController, IBaseScope }         from '../../base-controller';
import { ShippingOfferTypes }                 from '../../constants/shipping-offer-types';
import { EstimateShippingCostsModalService }  from '../account/quotes/estimate-shipping-costs/estimate-shipping-costs';

class PartsForSaleCreateCtrl extends BaseController {
    // noinspection JSUnusedGlobalSymbols
    static $inject : string[] = [
        '$scope',
        '$window',
        '$timeout',
        '$q',
        'PartsForSaleListingService',
        'MakeService',
        'ModelService',
        'StateService',
        'confirmModalService',
        'AddressCompletionService',
        'CountryService',
        'EstimateShippingCostsModalService'
    ];

    static readonly OUNCES_IN_POUND : number = 16;
    public newPartsForSaleForm : angular.IFormController;

    constructor(protected $scope : INewPartsForSaleListScope,
                protected $window : angular.IWindowService,
                private $timeout : angular.ITimeoutService,
                private $q : angular.IQService,
                private partsForSaleService : PartsForSaleListingService,
                private makeService : MakeResourceService,
                private modelService : ModelResourceService,
                private stateService : StateService,
                private confirmModalService : ConfirmModalService,
                private addressService : AddressCompletionService,
                private countryService : CountryService,
                public shippingEstimatorModal : EstimateShippingCostsModalService) {
        // noinspection JSIgnoredPromiseFromCall
        super();
        this.$scope.autoCompleteOptions = {
            types : [ 'address' ]
        };
        this.init();
    }

    protected async init() {
        await super.init();

        if (!this.isSeller) {
            this.$window.location.href = '/Account/PartsPuller';
        }

        this.$scope.listing = PartsForSaleListingService.createEmpty(this.$scope.user.id);

        this.$scope.dropdowns = {};

        this.$scope.alerts             = [];
        this.$scope.lowPriceError      = false;
        this.$scope.error              = false;
        this.$scope.loading            = false;
        this.$scope.dateOpen           = false;
        this.$scope.currentDate        = Date.now();
        this.$scope.maxVehicleYear     = new Date().getFullYear() + 1;
        this.$scope.minVehicleYear     = 1900;
        this.$scope.weight             = {
            pounds : 0,
            ounces : 0
        };
        this.$scope.invalidWeight      = false;
        this.$scope.shippingRatesValid = true;
        this.$scope.shippingRateError  = null;

        this.$scope.attachments = {};

        this.$scope.datepickerOptions = this.getStandardDatepickerOptions();

        this.$scope.$on('fineUploader:file-upload',
                        ($event : IAngularEvent, id : number, attachment : IAttachment) => {
                            this.$scope.attachments[ id ] = attachment;
                        });

        this.$scope.$on('fineUploader:file-removed',
                        ($event : IAngularEvent, id : number) => {
                            delete this.$scope.attachments[ id ];
                        });

        await this.initDropdowns();
    };

    public verifyStateSelection(item, model) {
        if (this.newPartsForSaleForm.stateId) {
            this.newPartsForSaleForm.stateId.$setValidity('StateMatchesCountry',
                                                          this.addressService.stateMatchesCounty(
                                                              this.$scope.dropdowns.selectedState,
                                                              this.$scope.dropdowns.selectedCountry
                                                          )
            );
        }
    }

    initDropdowns = async () => {
        let loaderHelper = this.createDropdownLoader(this.$scope, this.$scope.dropdowns, this.$scope.listing);

        try {
            let makePromise    = loaderHelper(this.makeService, 'getSorted', 'makes', 'selectedMake', 'makeId');
            let modelPromise   = loaderHelper(this.modelService, 'getSorted', 'models', 'selectedModel', 'modelId');
            let countryPromise =
                    loaderHelper(this.countryService, 'getSorted', 'countries', 'selectedCountry', 'countryId');
            let statePromise   = loaderHelper(this.stateService, 'getAll', 'states', 'selectedState', 'stateId');

            await this.$q.all([ makePromise, modelPromise, countryPromise, statePromise ]);
            this.initAddress();

            this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
                            this.addressService.createHandler(this.$scope.listing,
                                                              this.$scope.states,
                                                              this.$scope.countries,
                                                              this.$scope.dropdowns));

            this.$scope.zipCodePattern = ( function (dds) {
                let canadianRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
                let usRegex       = /^\d{5}(?:[-\s]\d{4})?$/;
                return {
                    test : function (value) {
                        if (dds.selectedCountry &&
                            dds.selectedCountry.name === 'United States') {
                            return ( value.length > 0 ) ? usRegex.test(value) : true;
                        }
                        else if (dds.selectedCountry &&
                                 dds.selectedCountry.name === 'Canada') {
                            return ( value.length > 0 ) ? canadianRegex.test(value) : true;
                        }
                        else {
                            return true;
                        }
                    }
                };
            } )(this.$scope.dropdowns);
        }
        catch (err) {

        }
    };

    clearSelectedModel = () => {
        this.$scope.dropdowns.selectedModel = null;
    };

    initAddress = () => {
        let address : IAddress = {
            address1         : this.$scope.user.address1,
            city             : this.$scope.user.city,
            zipCode          : this.$scope.user.zipCode,
            latitude         : this.$scope.user.latitude,
            longitude        : this.$scope.user.longitude,
            stateId          : this.$scope.user.stateId,
            provinceName     : this.$scope.user.provinceName,
            countryId        : this.$scope.user.countryId,
            isInexactAddress : true
        };

        if (this.addressService.validate(address)) {
            this.$scope.addressSupplied       = true;
            this.$scope.listing.address1      = this.$scope.user.address1;
            this.$scope.listing.address2      = this.$scope.user.address2;
            this.$scope.listing.city          = this.$scope.user.city;
            this.$scope.listing.stateId       = this.$scope.user.stateId;
            this.$scope.listing.provinceName  = this.$scope.user.provinceName;
            this.$scope.listing.zipCode       = this.$scope.user.zipCode;
            this.$scope.listing.countryId     = this.$scope.user.countryId;
            this.$scope.listing.isResidential = this.$scope.user.isResidential;

            this.$scope.dropdowns.selectedCountry =
                _.find(this.$scope.countries, { 'id' : this.$scope.listing.countryId });
            this.$scope.dropdowns.selectedState   = _.find(this.$scope.states, { 'id' : this.$scope.listing.stateId });
        }
        else {
            this.$scope.addressSupplied           = false;
            this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries, { 'id' : 234 });
        }
    };

    editAddress($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.addressSupplied = false;
    }

    submit = async (event : angular.IAngularEvent) => {
        event.preventDefault();

        this.$scope.lowPriceError = false;
        this.$scope.invalidWeight = false;
        await this.verifyPossibleShippingMethods();
        if (!this.$scope.shippingRatesValid) {
            return;
        }

        this.verifyStateSelection(null, null);

        if (!this.newPartsForSaleForm.$valid) {
            return;
        }

        try {
            this.$scope.loading         = true;
            this.$scope.listing.modelId = this.$scope.dropdowns.selectedModel.id;
            this.$scope.listing.makeId  = this.$scope.dropdowns.selectedMake.id;
            if (!this.$scope.dropdowns.selectedCountry.hasStates) {
                this.$scope.dropdowns.selectedState = null;
            }
            if (this.$scope.dropdowns.selectedState != null) {
                this.$scope.listing.stateId      = this.$scope.dropdowns.selectedState.id;
                this.$scope.listing.provinceName = null;
            }
            else {
                this.$scope.listing.stateId = null;
            }
            this.$scope.listing.countryId = this.$scope.dropdowns.selectedCountry.id;
            this.$scope.listing.weight    = this.$scope.weight.ounces +
                                            ( this.$scope.weight.pounds * PartsForSaleCreateCtrl.OUNCES_IN_POUND );

            if (this.$scope.listing.latitude == null ||
                this.$scope.listing.longitude == null ||
                this.$scope.listing.latitude === 0 ||
                this.$scope.listing.longitude === 0) {
                try {
                    await this.addressService.geoCodeAddress(this.$scope.listing,
                                                             this.$scope.states,
                                                             this.$scope.countries);
                }
                catch (err) {
                    // suppress error is intentional
                    console.log(err);
                }
                await this.createListing();
            }
            else {
                await this.createListing();
            }
        }
        catch (reject) {
            console.log(reject);
            this.$scope.error = true;
        }

        this.$scope.loading = false;
    };

    calcMaxWeight() {
        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic) {
            return 70 - this.$scope.weight.ounces / PartsForSaleCreateCtrl.OUNCES_IN_POUND;
        }
        else {
            return 100000;
        }
    }

    private validateWeight() {
        let weight = this.$scope.weight.ounces + ( this.$scope.weight.pounds * PartsForSaleCreateCtrl.OUNCES_IN_POUND );
        if (weight >= 1120 && this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic) {
            this.$scope.invalidWeight = true;
        }
        else {
            this.$scope.invalidWeight = false;
        }
    }

    /* 6/5/2018 - A. Beverly - the purpose of this method is to determine at listing
     creation whether the selected shipping type can,
     in theory, produce at least one valid shipping method. 
     EasyPost has limits on weight, dimensions, and source/dest (e.g., USPS method cannot
     originate from a non-US address. Although we don't have a destination address, 
     we will use a default US address to determine if any methods are returned
     for the given listing. If there are not, we do not permit the listing to be placed. */
    private async verifyPossibleShippingMethods() {
        if (this.$scope.listing.shippingOfferTypeId !== 2) {
            this.$scope.shippingRatesValid = true;
            this.$scope.shippingRateError  = '';
            return true;
        }
        this.$scope.listing.weight = this.$scope.weight.ounces +
                                     ( this.$scope.weight.pounds * PartsForSaleCreateCtrl.OUNCES_IN_POUND );
        try {
            let response = await this.partsForSaleService.verifyShippingRates(this.$scope);
            if (response && response.rates.length > 0) {
                this.$scope.shippingRatesValid = true;
            }
            else {
                this.$scope.shippingRatesValid = false;
                if (response && response.messages && response.messages.length > 0) {
                    this.$scope.shippingRateError = response.messages[ 0 ].message;
                }
                else {
                    this.$scope.shippingRateError = '';
                }
            }
        }
        catch (ex) {
            this.$scope.shippingRatesValid = false;
            this.$scope.shippingRateError  = ex.message;
        }
        return this.$scope.shippingRatesValid;
    }

    private createListing = async () => {
        try {
            let ok = await this.partsForSaleService
                               .create(new IAddressJsonFilter().filter(this.$scope.listing));

            this.$scope.alerts.push({ type : 'success', msg : 'Please wait...' });
            let promises : angular.IPromise<any>[] = [];
            _.forEach(this.$scope.attachments,
                      (attachment : IAttachment) => {
                          promises.push(this.partsForSaleService.attach(ok, attachment));
                      });

            await this.$q.all(promises);

            this.$timeout(() => {
                              this.$window.location.href = `/partssale/detail/${ok.id}`;
                          },
                          500);
        }
        catch (err) {
            this.$scope.alerts = [];
            switch (err.status) {
                case 400 :
                    this.$scope.alerts.push({ type : 'danger', msg : err.data.error.message });
            }
        }

        this.$scope.loading = false;
    };

    public shippingOfferTypeChanged() {
        this.$scope.shippingRatesValid = true;
        this.$scope.shippingRateError  = '';
        const clearDynamicOfferType    = () => {
            this.$scope.weight         = {
                pounds : 0,
                ounces : 0
            };
            this.$scope.listing.height = null;
            this.$scope.listing.length = null;
            this.$scope.listing.width  = null;
        };

        const clearFreeOfferType = () => {
            this.$scope.listing.shippingVendorOther   = null;
            this.$scope.listing.shippingServiceOther  = null;
            this.$scope.listing.estimatedShippingTime = null;
        };

        const clearFixedOfferType = () => {
            this.$scope.listing.shippingPrice = 0;
            clearFreeOfferType();
        };

        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Fixed) {
            clearDynamicOfferType();
        }
        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Dynamic) {
            clearFixedOfferType();
        }
        if (this.$scope.listing.shippingOfferTypeId === ShippingOfferTypes.Free) {
            clearDynamicOfferType();
            clearFixedOfferType();
        }
    }
}

interface INewPartsForSaleListScope extends IBaseScope {
    makes : IMake[];
    models : IModel[];
    selectedModel : IModel;
    selectedMake : IMake;
    listing : IPartsSaleListing;
    dateOpen : boolean;
    datepickerOptions : any;
    currentDate : number;
    loading : boolean;
    dropdowns : any;
    alerts : any[];
    attachments : { [ id : number ] : IAttachment };
    maxVehicleYear : number;
    minVehicleYear : number;
    states : IState[];
    selectedState : IState;
    countries : ICountry[];
    selectedCountry : ICountry;
    addressSupplied : boolean;
    weight : {
        pounds : number;
        ounces : number;
    }
    lowPriceError : boolean;
    error : boolean;
    invalidWeight : boolean;
    autoCompleteOptions : any;
    shippingRatesValid : boolean;
    shippingRateError : string;
    zipCodePattern : any;
}

angular.module('Row52.Views')
       .controller('PartsForSaleCreateCtrl', PartsForSaleCreateCtrl);
