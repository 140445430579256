import * as angular from 'angular';
import IDirectiveLinkFn = angular.IDirectiveLinkFn;

class FileModelDirective implements angular.IDirective {
    public restrict : string = 'A';
    public link : IDirectiveLinkFn;

    constructor($parse : angular.IParseService) {
        FileModelDirective.prototype.link =
            (scope : angular.IScope, element : angular.IAugmentedJQuery, attributes : angular.IAttributes) => {
// ReSharper disable once TsNotResolved
                let model       = $parse(attributes.fileModel);
                let modelSetter = model.assign;

                element.bind('change', () => {
                    scope.$apply(() => {
                        modelSetter(scope, (<HTMLInputElement>element[ 0 ]).files[ 0 ]);
                    });
                });
            };
    }

    public static Factory() {
        let directive = ($parse : angular.IParseService) => {
            return new FileModelDirective($parse);
        };

        directive[ '$inject' ] = [ '$parse' ];

        return directive;
    }
}

angular
    .module('Row52.Directives')
    .directive('fileModel', FileModelDirective.Factory());
