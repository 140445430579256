import * as angular        from 'angular';
import * as _              from 'lodash';
import * as moment         from 'moment';
import { IUserJsonModel }  from 'Row52.Models';
import { Row52ClaimTypes } from './constants/constants';

export interface IBaseScope extends angular.IScope {
    user : IUserJsonModel;
}

interface IEntity {
    id : number;
}

export class BaseController implements angular.IController {
    protected static dateMatcher : RegExp = /(\d{2})(\d{2})(\d{4})/;
    protected $scope : IBaseScope;
    protected $window : angular.IWindowService;

    protected async init() {
        this.$scope.user = this.$window.user;
    };

    protected getStandardDatepickerOptions() {
        return {
            showWeeks : false,
            minDate   : Date.now(),
            maxDate   : moment()
                .add(30, 'd')
                .toDate()
        };
    }

    protected createDropdownLoader(scope : angular.IScope, dropdown : any, entity : any) {
        return async (service : any, methodName : string, scopeKey : string, dropdownKey : string, entityIdKey : string) => {
            scope[ scopeKey ]       = await service[ methodName ]();
            dropdown[ dropdownKey ] = _.find(scope[ scopeKey ], (obj : IEntity) => {
                return entity[ entityIdKey ] === obj.id;
            });
        };
    }

    protected calcOrganizationalUser() : boolean {
        return this.$scope.user.locationId > 0;
    }

    get isSeller() : boolean {
        return this.$scope.user.isSeller;
    }
}
