import { BaseController, IBaseScope } from '../../base-controller';
import { IPartsSaleListing }          from 'Row52.Models.Entities';
import { LoginCtrl }                  from '../account/login';
import * as moment                    from 'moment';
import * as angular                   from 'angular';

export class PartsSaleBaseController extends BaseController {
    protected $scope : IPartsSaleBaseScope;
    protected $cookies : angular.cookies.ICookiesService;

    protected async init() {
        await super.init();

        this.$scope.listing = this.$window[ 'listing' ];
    }

    public viewedByOwner() {
        return this.$scope.user.id === this.$scope.listing.userId;
    }

    public viewedByBuyer() {
        if (this.$scope.listing.buyer) {
            return this.$scope.user.id === this.$scope.listing.buyer.id;
        }
        else {
            return !!this.$window[ 'displayReceipt' ];
        }
    }

    public edit($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/PartsSale/Edit/${this.$scope.listing.id}`;
    }
}

export interface IPartsSaleBaseScope extends IBaseScope {
    listing : IPartsSaleListing;
}