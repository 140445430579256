import * as angular                   from 'angular';
import { BaseController, IBaseScope } from '../../../base-controller';


class AccountSummaryCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : IAccountSummaryScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
    }
}

interface IAccountSummaryScope extends IBaseScope {
}

angular.module('Row52.Views.Account.Summary.AccountSummaryCtrl', [])
       .controller('AccountSummaryCtrl', AccountSummaryCtrl);