import * as angular from 'angular';

import './vehicles';
import './parts-wanted';
import './parts-sale'

angular.module('Row52.Views.Account.Favorites', [
    'Row52.Views.Account.Favorites.FavoriteVehicleCtrl',
    'Row52.Views.Account.Favorites.FavoritePartsWantedCtrl',
    'Row52.Views.Account.Favorites.FavoritePartsSaleCtrl'
]);