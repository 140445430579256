import * as angular                                          from 'angular';
import './puller-locations.html';
import './puller-locations.scss';
import { IUserLocationRelationship }                         from 'Row52.Models.Entities';
import { INewLocationModalScope }                            from './new-location-modal/new-location-modal';
import { IModalService }                                     from 'angular-ui-bootstrap';
import * as _                                                from 'lodash';
import { UserLocationRelationshipService }                   from '../../../../services/odata/user-location.service';
import { IPullerLocationsBaseScope, PullerLocationBaseCtrl } from './base-controller';

class PullerLocationsCtrl extends PullerLocationBaseCtrl {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$rootScope',
        '$uibModal',
        'UserLocationRelationshipService'
    ];
    private static modalTemplate     = 'account/seller-registration/puller-locations/new-location-modal/new-location-modal.html';

    constructor(protected $scope : IPullerLocationsScope,
                protected $window : angular.IWindowService,
                private $rootScope : angular.IRootScopeService,
                private $modal : IModalService,
                private userLocationService : UserLocationRelationshipService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.setLocations();
    }

    async addYard($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.$scope.userLocations.length >= 6) {
            this.$scope.excessLocations = true;
            return;
        }
        else {
            this.$scope.excessLocations = false;
        }

        try {

            let scope = this.$rootScope.$new() as INewLocationModalScope;
            await                 this.$modal
                                      .open({
                                                templateUrl : PullerLocationsCtrl.modalTemplate,
                                                scope       : scope,
                                                controller  : 'NewLocationModalCtrl as ctrl'
                                            }).result;
            await this.refreshLocations();
        }
        catch (err) {

        }
    }

    async removeLocation($event : angular.IAngularEvent, userLocation : IUserLocationRelationship) {
        $event.preventDefault();

        try {
            await this.userLocationService.delete(userLocation.id);

            await this.refreshLocations();
        }
        catch (err) {

        }
    };

    async refreshLocations() {
        try {
            this.$scope.userLocations = await this.userLocationService.getForUser(this.$scope.user.id);
            this.syncLocations();
        }
        catch (err) {

        }
    }
}

interface IPullerLocationsScope extends IPullerLocationsBaseScope {
    excessLocations : boolean;
}

angular.module('Row52.Views.Account.SellerRegistration.PullerLocations.Ctrl', [])
       .controller('PullerLocationsCtrl', PullerLocationsCtrl);