import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../../base-controller';
import './banking-info.html';
import './banking-info.scss';

export class RegistrationWizardBankingInfoCtrl extends BaseController {
    public static $inject : string[]         = [
        '$scope',
        '$window'
    ];
    public static BankingInformationSuccess  = 'BankingInformationSuccess';
    public static BankingInformationPrevious = 'BankingInformationPrevious';
    public bankingInfoForm : angular.IFormController;
    public stripe : { tos : boolean };

    constructor(protected $scope : IRegistrationWizardBankingInfoScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.stripe = {
            tos : false
        };
    }

    nextStep($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.bankingInfoForm.$valid) {
            if (!this.stripe.tos) {
                console.log('No TOS');
            }
            else {
                this.$scope.$emit(RegistrationWizardBankingInfoCtrl.BankingInformationSuccess);
            }
        }
    }

    previousStep($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.$emit(RegistrationWizardBankingInfoCtrl.BankingInformationPrevious);
    }
}

interface IRegistrationWizardBankingInfoScope extends IBaseScope {
    stripe : {
        tos : boolean;
    }
}

angular.module('Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizardBankingInfo', [])
       .controller('RegistrationWizardBankingInfoCtrl', RegistrationWizardBankingInfoCtrl);