import * as angular from 'angular';
import './registration-wizard';
import './mobile-verification/mobile-verification';
import './personal-info/personal-info';
import './banking-info/banking-info';
import './puller-listing/puller-listing';

angular.module('Row52.Views.Account.SellerRegistration.RegistrationWizard', [
    'Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizard',
    'Row52.Views.Account.SellerRegistration.RegistrationWizard.MobileVerification',
    'Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizardPersonalInfo',
    'Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizardBankingInfo',
    'Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizardPullerListing'
]);