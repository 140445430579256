import * as angular from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import './verification-status.html';
import './verification-status.scss';
import { UserService } from '../../../../services/odata/user.service';
import { RemoteErrorModalService } from '../../../../services/ui/remoteerror.modal';

class VerificationStatusCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService',
        'remoteErrorModalService'
    ];

    constructor(protected $scope : IVerificationStatusScope,
        protected $window : angular.IWindowService,
        private userService : UserService,
        private remoteErrorService : RemoteErrorModalService) {
        super();
        
        this.init();
    }

    protected async init() {
        await super.init();
        await this.initVerification();
    }

    private async initVerification() {
        this.$scope.isVerified = () => {
            return angular.isDefined(this.$scope.verificationStatus) &&
                this.$scope.verificationStatus.disabledReason === null;
        };
    }
}

interface IVerificationStatusScope extends IBaseScope {
    isVerified : () => boolean;
    verificationStatus : any;
    statusChecked : boolean;
}

angular.module('Row52.Views.Account.SellerRegistration.VerificationStatus',
        [
            'Row52.Services.UserService'
        ])
    .controller('VerificationStatusCtrl', VerificationStatusCtrl);