import * as angular from 'angular';
import '../../constants/constants';
import { ResourceService } from './odata.resource';
import { IReturnPolicy } from 'Row52.Models.Entities';

/**
 * Created by Ri Scott on 5/31/2017.
 */
export class ReturnPoliciesService extends ResourceService<IReturnPolicy> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'ReturnPolicies');
    }
}

angular.module('Row52.Services.ReturnPoliciesService', [ 'ngCookies', 'Row52.Constants' ])
       .service('ReturnPoliciesService', ReturnPoliciesService);