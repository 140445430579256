import './incoming-quote-widget.html';
import './incoming-quote-widget.scss';

import * as angular                   from 'angular';
import { BaseController, IBaseScope } from '../../../../base-controller';
import { IQuoteModel }                from 'Row52.Models';
import * as _                         from 'lodash';
import { PartsWantedQuoteService }    from '../../../../services/odata/parts-wanted-quote.service';
import { PartWantedQutoteStatuses }   from '../../../../constants/statuses';

export class IncomingQuoteWidgetCtrl extends BaseController {
    public static $inject : string[]           = [ '$scope', '$window', 'PartsWantedQuoteService', 'StripeFeeCalculator' ];
    public static IncomingQuoteLoaded : string = 'incoming-quote:loaded';

    constructor(protected $scope : IIncomingQuoteWidgetScope,
                protected $window : angular.IWindowService,
                private pwqService : PartsWantedQuoteService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.quoteStatuses = PartWantedQutoteStatuses;
        await this.loadIncomingQuotes();
    }

    protected async loadIncomingQuotes() {
        this.$scope.incomingQuotesLoading = true;

        try {
            this.$scope.incomingQuotes = await this.pwqService.getIncomingQuotes(this.$scope.user);

            this.$scope.activeIncomingQuotes = _.filter(this.$scope.incomingQuotes, (quote : IQuoteModel) => {
                return quote.status === PartWantedQutoteStatuses.Active;
            }).length;

            this.$scope.incomingQuotes = this.pwqService.sort(this.$scope.incomingQuotes, 'status');
            this.$scope.$emit(IncomingQuoteWidgetCtrl.IncomingQuoteLoaded, this.$scope.activeIncomingQuotes);
        }
        catch (err) {

        }

        this.$scope.incomingQuotesLoading = false;
    };

    showQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$window.location.href = '/Account/MyQuotes?qId=' + quote.id;
    }

    goToIncomingQuotes($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = '/Account/MyQuotes?incomingQuotes=1';
    }
}

export interface IIncomingQuoteWidgetScope extends IBaseScope {
    incomingQuotes : IQuoteModel[];
    incomingQuotesLoading : boolean;
    activeIncomingQuotes : number;
    quoteStatuses : PartWantedQutoteStatuses;
}

angular.module('Row52.Views.Account.Summary.IncomingQuoteWidget', [
           'Row52.Services.PartsWantedQuoteService'
       ])
       .controller('IncomingQuoteWidgetCtrl', IncomingQuoteWidgetCtrl);