import * as angular from 'angular';
import './sidebar.html';
import './sidebar.scss';
import '../../../../templates/modals/loginModal.html';
import { PartsForSaleStatusService } from '../../../../services/data/parts-for-sale-status.service';
import { ShippingVendorsService, IShippingVendor } from '../../../../services/data/shipping-vendors.service';
import { PartsForSaleFavoriteService } from '../../../../services/odata/parts-for-sale-favorite.service';
import { PartsSaleBaseController, IPartsSaleBaseScope } from '../../parts-sale-base-controller';
import { IListingUserModel } from 'Row52.Models';
import { MessagesService } from '../../../../services/messages.service';
import { ConfirmModalService } from '../../../../services/ui/confirm.modal';
import { PartsForSaleStatuses } from '../../../../constants/parts-for-sale-statuses';
import { IPartsSaleListing } from 'Row52.Models.Entities';
import { PartsForSaleListingService } from '../../../../services/odata/parts-for-sale-listing.service';
import { ILoginModalScope } from '../../../search/vehicle-list';
import { RemoteErrorModalService } from '../../../../services/ui/remoteerror.modal';

export class PartsSaleDetailSidebarCtrl extends PartsSaleBaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$cookies',
        '$rootScope',
        '$uibModal',
        'PartsForSaleStatusService',
        'PartsForSaleFavoriteService',
        'ShippingVendorsService',
        'MessagesService',
        'confirmModalService',
        'PartsForSaleListingService',
        'remoteErrorModalService'
    ];
    public static ShowShipmentForm : string = 'ShowShipmentForm';

    constructor(protected $scope : IPartsSaleDetailSidebar,
        protected $window : angular.IWindowService,
        protected $cookies : angular.cookies.ICookiesService,
        private $rootScope : angular.IRootScopeService,
        private $modal : angular.ui.bootstrap.IModalService,
        public statusService : PartsForSaleStatusService,
        private favoritesService : PartsForSaleFavoriteService,
        private shippingVendorsService : ShippingVendorsService,
        private messageService : MessagesService,
        private confirmModalService : ConfirmModalService,
        private listingService : PartsForSaleListingService,
        private remoteErrorService : RemoteErrorModalService) {

        super();

        this.init();
    }

    protected async init() {
        await super.init();
        await this.loadLike();
    }

    private async loadLike() {
        if (this.$scope.user.isAuthenticated) {
            let favorites = await this.favoritesService.getForListing(this.$scope.listing.id, this.$scope.user.id);
            if (favorites.length > 0) {
                this.$scope.isUserLike = true;
            }
        } else {
            this.$scope.isUserLike = false;
        }
    }

    like = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        if (this.$scope.user.isAuthenticated) {
            if (!this.$scope.isUserLike) {
                await this.favoritesService.createFavorite(this.$scope.listing.id, this.$scope.user.id);
                this.$scope.isUserLike = true;
            } else {
                await this.favoritesService.deleteAllForListing(this.$scope.listing.id, this.$scope.user.id);
                this.$scope.isUserLike = false;
            }
        } else {
            let scope = this.$rootScope.$new() as ILoginModalScope;
            scope.description = 'to save this listing as a favorite!';
            scope.redirectUrl = '/Search';
            this.$modal.open({
                templateUrl : 'modals/loginModal.html',
                scope : scope
            });
        }
    };

    sendMessage($event : angular.IAngularEvent, listingUser : IListingUserModel, subject : string) {
        $event.preventDefault();

        this.messageService.open(listingUser.userName, subject);
    }

    async cancel($event : angular.IAngularEvent) {
        $event.preventDefault();

        try {
            await this.confirmModalService
                .open('Are you sure?',
                    'Are you sure that you want to remove this listing?',
                    {
                        okBtnText : 'Yes',
                        cancelBtnText : 'Cancel'
                    });

            this.$scope.cancelLoading = true;

            await this.listingService.cancel(this.$scope.listing);

            this.$scope.listing.status = PartsForSaleStatuses.Canceled;
            this.$scope.listing.isActive = false;

            this.$window.location.href = `/PartsSale/`;
        } catch (e) {
            console.log('Exception: ', e);
        }
        this.$scope.cancelLoading = false;
    }

    async refund($event : angular.IAngularEvent) {
        $event.preventDefault();

        try {
            await this.confirmModalService
                .open('Are you sure?',
                    'Are you sure that you want to issue a full refund for this listing?',
                    {
                        okBtnText : 'Yes',
                        cancelBtnText : 'Cancel'
                    });
            this.$scope.loading = true;
            let that = this;
            await this.listingService.refund(this.$scope.listing).then(function(listing : any) {
                that.$scope.listing = listing;
                that.remoteErrorService
                    .open('Success!',
                        'This listing has been successfully refunded.',
                    { okBtnText: 'OK' });
                that.$scope.loading = false;
                //that.$window.location.reload(true);
            }).catch(function(err : any) {
                let msg = err && err.data && err.data.error ? err.data.error.message : 'Something has gone wrong.';
                that.remoteErrorService
                    .open('Attention',
                        msg,
                    { okBtnText: 'OK' });
                that.$scope.loading = false;
            });
        } catch (e) {
            console.log('Exception: ', e);
            this.$scope.loading = false;
        }
    }

    showShipmentForm($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.$emit(PartsSaleDetailSidebarCtrl.ShowShipmentForm);
    }

    getShippingVendor() {
        const id = this.$scope.listing.shippingVendorId;
        if (id && id !== 99) {
            return this.shippingVendorsService.get(id);
        } else if (id === 99) {
            return this.$scope.listing.shippingVendorOther;
        } else {
            return 'N/A';
        }
    }
}

interface IPartsSaleDetailSidebar extends IPartsSaleBaseScope {
    isUserLike : boolean;
    cancelLoading : boolean;
    loading : boolean;
}

angular.module('Row52.Views.PartsSale.Detail.Sidebar',
        [
            'Row52.Services',
            'Row52.Services.PartsForSaleFavoriteService',
            'ngCookies',
            'Row52.Services.MessageModal',
            'Row52.Services.ConfirmModal',
            'Row52.Services.PartsForSaleListingService'
        ])
    .controller('PartsSaleDetailSideBarCtrl', PartsSaleDetailSidebarCtrl);