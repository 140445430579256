/**
 * Created by Ri Scott on 5/29/2017.
 */
import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import '../../constants/constants';
import { PartWantedQutoteStatuses } from '../../constants/statuses';
import { ResourceCrudService } from './odata.resource';
import {
    IAttachment,
    IPartsWantedQuote,
    IPartsWantedListing
    } from 'Row52.Models.Entities';
import { IQuoteModel, IUserJsonModel } from 'Row52.Models';
import { IStripeChargeFailure } from '../marketplace/stripe.service';
import { IPromise } from 'angular';

export class PartsWantedQuoteService extends ResourceCrudService<IPartsWantedQuote> {
    static $inject : string[] = ['$http', '$q', '$cookies', 'domain'];

    constructor($http : angular.IHttpService,
        $q : angular.IQService,
        $cookies : angular.cookies.ICookiesService,
        domain : string) {
        super($http, $q, $cookies, domain, 'PartsWantedQuotes');
    }

    /**
     * Retrieves the most recently submitted quote for a specific listing, filtered to the user specified.
     * This function is useful when reminding a user that they have submitted a quote for a specific listing already.
     */
    submittedQuote(listingId : number, userId : string) : angular.IPromise<IQuoteModel> {
        return this.unwrapSingle(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}/Row52.SubmittedQuote(listingId=${listingId},userId=${userId
                })`,
                this.getHeaders())
        );
    }

    getQuote(key : number) : angular.IPromise<IQuoteModel> {
        return this.unwrapSingle(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}(${key})/Row52.GetQuote()`, this.getHeaders())
        );
    }

    getOutgoingQuotes(user : IUserJsonModel) : angular.IPromise<IQuoteModel[]> {
        return this.unwrapArray(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}/Row52.GetQuotes()?$filter=seller/userId eq ${user.id}`,
                this.getHeaders())
        );
    }

    getIncomingQuotes(user : IUserJsonModel) : angular.IPromise<IQuoteModel[]> {
        return this.unwrapArray(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}/Row52.GetQuotes()?$filter=buyer/userId eq ${user.id}`,
                this.getHeaders())
        );
    }

    /**
     * Retrieves the active quote for a listing submitted by the currently logged in user.
     * The user identity is checked server-side by using the security token.
     * @param {number} partsWantedListingId to check for an active listing.
     * @returns {angular.IPromise<IPartsWantedQuote>}
     */
    getActiveQuoteForListing(partsWantedListingId : number) : angular.IPromise<IPartsWantedQuote> {
        let url1 = `${this.domain}/odata/${this.resourceUrl}`;
        let url2 = `/Row52.GetActiveQuoteForListing(partsWantedListingId=${partsWantedListingId})`;
        return this.unwrapSingle(
            this.$http
            .get(url1.concat('', url2), this.getHeaders())
        );
    }

    withdraw(quote : IPartsWantedQuote | IQuoteModel) {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Withdraw`, {}, this.getHeaders())
        );
    }

    decline(quote : IPartsWantedQuote | IQuoteModel) {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Decline`, {}, this.getHeaders())
        );
    }

    completeQuote(quote : IPartsWantedQuote | IQuoteModel) {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Complete`,
                {
                    trackingNumber : quote.trackingNumber,
                    shippingVendorId : quote.shippingVendorId,
                    shippingVendorOther : quote.shippingVendorOther
                },
                this.getHeaders())
        );
    }

    accept(quote : IPartsWantedQuote | IQuoteModel,
        billingInfo : any) : angular.IPromise<IPartsWantedQuote | IStripeChargeFailure> {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Accept`, billingInfo, this.getHeaders())
        );
    }

    refund(quote : IPartsWantedQuote | IQuoteModel) : angular.IPromise<IPartsWantedQuote | IStripeChargeFailure> {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Refund`, {}, this.getHeaders())
        );
    }

    complete(quote : IPartsWantedQuote | IQuoteModel,
        trackingNumber : string,
        shippingVendorId : number,
        shippingVendorOther : string) : angular.IPromise<IPartsWantedQuote | IStripeChargeFailure> {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Complete`,
                {
                    trackingNumber : trackingNumber,
                    shippingVendorId : shippingVendorId,
                    shippingVendorOther : shippingVendorOther
                },
                this.getHeaders())
        );
    }

    attach(quote : IPartsWantedQuote | IQuoteModel, attachment : IAttachment) : IPromise<any> {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Attach`,
                { attachmentId : attachment.id },
                this.getHeaders())
        );
    }

    unattach(quote : IPartsWantedQuote, imageId : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
            .post(`${this.domain}/odata/${this.resourceUrl}(${quote.id})/Row52.Unattach`,
                { imageId : imageId },
                this.getHeaders())
        );
    }

    getActiveQuotesForListing(quote : IPartsWantedListing) : IPromise<IQuoteModel[]> {
        let $filter = `$filter=listing/id eq ${quote.id} and status eq 1`;

        return this.unwrapArray(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}/Row52.GetQuotes()/?${$filter}`,
                this.getHeaders())
        );
    }

    sort(quotes : IQuoteModel[], by : string) {
        switch (by) {
        case 'status' : {
            return _.chain(quotes)
                .orderBy('creationDate', 'desc')
                .sortBy((quote : IQuoteModel) => {
                    switch (quote.status) {
                    case PartWantedQutoteStatuses.Accepted :
                        return 1;
                    case PartWantedQutoteStatuses.Active :
                        return 2;
                    case PartWantedQutoteStatuses.Completed :
                        return 3;
                    case PartWantedQutoteStatuses.Refunded :
                        return 4;
                    case PartWantedQutoteStatuses.Withdrawn :
                        return 5;
                    case PartWantedQutoteStatuses.Expired :
                        return 6;
                    default :
                        return 7;
                    }
                })
                .value();
        }
        case 'date' :
            return _.sortBy(quotes, ['creationDate']);
        case 'title' :
            return _.sortBy(quotes,
                (quote : IQuoteModel) => {
                    return quote.listing.title.toUpperCase();
                });
        case 'price' :
            return _.sortBy(quotes, ['totalPrice']);
        case 'puller' :
            return _.sortBy(quotes,
                (quote : IQuoteModel) => {
                    return quote.seller.userName.toUpperCase();
                });
        case 'buyer' :
            return _.sortBy(quotes,
                (quote : IQuoteModel) => {
                    return quote.buyer.userName.toUpperCase();
                });
        default :
            return quotes;
        }
    }

    createEmpty() : IPartsWantedQuote {
        return {
            id : 0,
            isActive : true,
            isVisible : true,
            creationDate : moment()
                .toDate(),
            completionDate : null,
            status : 0,
            partsWantedListingId : 0,
            partsWantedListing : null,
            sellerUserId : null,
            sellerUser : null,
            title : null,
            year : null,
            makeId : null,
            modelId : null,
            model : null,
            vin : null,
            locationId : null,
            location : null,
            expirationDate : moment()
                .add(7, 'days')
                .toDate(),
            partsLaborQuote : null,
            shippingQuote : null,
            shippingType : null,
            fees : 0,
            stripeFees : 0,
            taxes : null,
            totalPrice : null,
            returnPolicy : null,
            returnPolicyId : 1,
            partsStatus : null,
            partsStatusId : 1,
            comments : null,
            images : [],
            acceptedDate : null,
            refundDate : null,
            trackingNumber : null,
            shippingVendorId : null,
            shippingVendorOther : null,
            transactionId : null
        };
    }

    clone(quoteToClone : IQuoteModel) : IPartsWantedQuote {
        return {
            id : 0,
            isActive : true,
            isVisible : true,
            creationDate : moment()
                .toDate(),
            completionDate : null,
            status : 1,
            partsWantedListingId : quoteToClone.listing.id,
            partsWantedListing : null,
            sellerUserId : null,
            sellerUser : null,
            title : null,
            year : quoteToClone.year,
            makeId : quoteToClone.makeId,
            modelId : quoteToClone.modelId,
            model : null,
            vin : quoteToClone.vin,
            locationId : null,
            location : null,
            expirationDate : moment()
                .add(7, 'days')
                .toDate(),
            partsLaborQuote : quoteToClone.partsLaborQuote,
            shippingQuote : quoteToClone.shippingQuote,
            shippingType : quoteToClone.shippingType,
            fees : 0,
            stripeFees : 0,
            taxes : quoteToClone.taxes,
            totalPrice : 0,
            returnPolicy : null,
            returnPolicyId : quoteToClone.returnPolicyId,
            partsStatus : null,
            partsStatusId : 1,
            comments : null,
            images : [],
            acceptedDate : null,
            refundDate : null,
            trackingNumber : null,
            shippingVendorId : null,
            shippingVendorOther : null,
            transactionId : null
        };
    }
}

angular.module('Row52.Services.PartsWantedQuoteService', ['ngCookies', 'Row52.Constants'])
    .service('PartsWantedQuoteService', PartsWantedQuoteService);