import './messages-widget.html';
import './messages-widget.scss';

import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { MessagesApiService }         from '../../../../services/odata/messages.service';
import { IMessageThread }             from 'Row52.Models';

class MessagesWidgetCtrl extends BaseController {
    public static $inject : string[] = [ '$scope', '$window', 'MessagesApiService' ];

    constructor(protected $scope : IMessagesWidgetScope,
                protected $window : angular.IWindowService,
                private messageService : MessagesApiService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();

        //await this.loadMessages(); //pnp - turn off
    }

    protected async loadMessages() {
        this.$scope.loading = true;

        try {
            let result              = await this.messageService.getForWidget();
            this.$scope.threads     = result.value;
            this.$scope.unreadCount = result.count;
        }
        catch (err) {

        }

        this.$scope.loading = false;
    }

    goToMessage = async ($event : angular.IAngularEvent, thread : IMessageThread) => {
        $event.preventDefault();

        await this.messageService.readThread(thread);

        this.$window.location.href = `/Account/Messages?threadId=${thread.id}`;
    }

    goToMessages($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/Account/Messages`;
    }
}

interface IMessagesWidgetScope extends IBaseScope {
    loading : boolean;
    threads : IMessageThread[];
    unreadCount : number;
}

angular.module('Row52.Views.Account.Summary.MessagesWidget', [ 'Row52.Services.MessagesService' ])
       .controller('MessagesWidgetCtrl', MessagesWidgetCtrl);