import { BaseController, IBaseScope } from '../../../../base-controller';
import { IUserLocationRelationship }  from 'Row52.Models.Entities';

export class PullerLocationBaseCtrl extends BaseController {
    protected $scope : IPullerLocationsBaseScope;

    constructor() {
        super();
    }

    protected setLocations() {
        const user          = this.$window[ 'userModel' ];
        const userLocations = user.userLocationRelationships;
        if (userLocations) {
            this.$scope.userLocations = userLocations;
        }
        else {
            this.$scope.userLocations = [];
        }
    }

    protected syncLocations() {
        const user                     = this.$window[ 'userModel' ];
        user.userLocationRelationships = this.$scope.userLocations;
    }
}

export interface IPullerLocationsBaseScope extends IBaseScope {
    userLocations : IUserLocationRelationship[];
}