import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import './receipt.html';
import './receipt.scss';

class PartsSaleDetailReceiptCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$cookies'
    ];

    constructor(protected $scope : IPartsSaleDetailReceiptScope,
                protected $window : angular.IWindowService,
                protected $cookies : angular.cookies.ICookiesService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
    }
}

interface IPartsSaleDetailReceiptScope extends IBaseScope {

}

angular.module('Row52.Views.PartsSale.Detail.PartsSaleDetailReceipt', [
           'ngCookies'
       ])
       .controller('PartsSaleDetailReceiptCtrl', PartsSaleDetailReceiptCtrl);