import * as angular                          from 'angular';
import { IAngularEvent }                     from 'angular';
import * as _                                from 'lodash';
import * as moment                           from 'moment';
import {
    IMake, IModel, IPartsWantedListing, IState, IAttachment, ICountry
}                                            from 'Row52.Models.Entities';
import { IAddressJsonFilter }                from '../../models/entities/IAddressExtension';
import { MakeResourceService }               from '../../services/odata/make.service';
import { ModelResourceService }              from '../../services/odata/model.service';
import { AddressCompletionService }          from '../../services/helpers/address.completion.service';
import { PartsWantedListingService }         from '../../services/odata/parts-wanted-listing.service';
import { StateService }                      from '../../services/odata/state.service';
import { CountryService }                    from '../../services/odata/country.service';
import '../../../css/parts-wanted/edit.scss';
import { EstimateShippingCostsModalService } from '../account/quotes/estimate-shipping-costs/estimate-shipping-costs';

class EditPartsWantedListCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        '$q',
        'StateService',
        'CountryService',
        'MakeService',
        'ModelService',
        'PartsWantedListingService',
        'EstimateShippingCostsModalService',
        'AddressCompletionService'
    ];

    constructor(private $scope : IEditPartsWantedListScope,
                private $window : angular.IWindowService,
                private $q : angular.IQService,
                private stateService : StateService,
                private countryService : CountryService,
                private makeService : MakeResourceService,
                private modelService : ModelResourceService,
                private partsWantedService : PartsWantedListingService,
                private estimateShippingCostsModalService : EstimateShippingCostsModalService,
                private addressService : AddressCompletionService) {

        $scope.autoCompleteOptions = {
            types : [ 'address' ]
        };

        this.initDropdowns();

        $scope.datepickerOptions = {
            showWeeks : false,
            minDate   : Date.now(),
            maxDate   : moment()
                .add(30, 'd')
                .toDate()
        };

        $scope.dropdowns = {
            selectedState   : null,
            selectedCountry : null,
            selectedMake    : null,
            selectedModel   : null
        };

        $scope.loading                = false;
        $scope.dateOpen               = false;
        $scope.currentDate            = Date.now();
        $scope.maxVehicleYear         = new Date().getFullYear() + 1;
        $scope.listing                = $window[ 'listing' ];
        $scope.listing.comments       = null;
        $scope.listing.expirationDate = new Date(this.$scope.listing.expirationDate);
        $scope.isImageRemoving        = false;
        $scope.imageRemovingId        = null;
        $scope.attachments            = {};

        this.$scope.$on('fineUploader:file-upload',
                        ($event : IAngularEvent, id : number, attachment : IAttachment) => {
                            this.$scope.attachments[ id ] = attachment;
                        });

        this.$scope.$on('fineUploader:file-removed',
                        ($event : IAngularEvent, id : number) => {
                            delete this.$scope.attachments[ id ];
                        });
    }

    async initDropdowns() {
        this.makeService
            .getSorted()
            .then((makes : IMake[]) => {
                this.$scope.makes = makes;

                this.$scope.dropdowns.selectedMake = _.find(makes,
                                                            (make : IMake) => {
                                                                return this.$scope.listing.makeId === make.id;
                                                            });
            });

        this.modelService
            .getSorted()
            .then((models : IModel[]) => {
                this.$scope.models = models;

                this.$scope.dropdowns.selectedModel = _.find(models,
                                                             (model : IModel) => {
                                                                 return this.$scope.listing.modelId === model.id;
                                                             });
            });

        await this.stateService
                  .getAll()
                  .then((states : IState[]) => {
                      this.$scope.states = states;

                      this.$scope.dropdowns.selectedState = _.find(states,
                                                                   (state : IState) => {
                                                                       return this.$scope.listing.stateId === state.id;
                                                                   });
                  });

        await this.countryService
                  .getAll()
                  .then((countries : ICountry[]) => {
                      this.$scope.countries = countries;

                      this.$scope.dropdowns.selectedCountry = _.find(countries,
                                                                     (country : ICountry) => {
                                                                         return this.$scope.listing.countryId === country.id;
                                                                     });
                  });

        this.$scope.zipCodePattern = ( function (dds) {
            let canadianRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            let usRegex       = /^\d{5}(?:[-\s]\d{4})?$/;
            return {
                test : function (value) {
                    if (dds.selectedCountry &&
                        dds.selectedCountry.name === 'United States') {
                        return ( value.length > 0 ) ? usRegex.test(value) : true;
                    }
                    else if (dds.selectedCountry &&
                             dds.selectedCountry.name === 'Canada') {
                        return ( value.length > 0 ) ? canadianRegex.test(value) : true;
                    }
                    else {
                        return true;
                    }
                }
            };
        } )(this.$scope.dropdowns);

        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
                        this.addressService.createHandler(this.$scope.listing,
                                                          this.$scope.states,
                                                          this.$scope.countries,
                                                          this.$scope.dropdowns));
    }

    clearSelectedModel = () => {
        this.$scope.dropdowns.selectedModel = null;
    };

    public verifyStateSelection(item, model) {
        this.$scope.newPartsWantedForm.stateId.$setValidity('StateMatchesCountry',
                                                            this.addressService.stateMatchesCounty(
                                                                this.$scope.dropdowns.selectedState,
                                                                this.$scope.dropdowns.selectedCountry
                                                            )
        );
    }

    submit(event : IAngularEvent) {
        event.preventDefault();

        this.verifyStateSelection(null, null);

        if (this.$scope.newPartsWantedForm.$valid) {
            this.$scope.loading         = true;
            this.$scope.listing.modelId = this.$scope.dropdowns.selectedModel.id;
            this.$scope.listing.model   = this.$scope.dropdowns.selectedModel;
            if (!this.$scope.dropdowns.selectedCountry.hasStates) {
                this.$scope.dropdowns.selectedState = null;
            }
            if (this.$scope.dropdowns.selectedState != null) {
                this.$scope.listing.stateId      = this.$scope.dropdowns.selectedState.id;
                this.$scope.listing.provinceName = null;
            }
            else {
                this.$scope.listing.stateId = null;
            }
            this.$scope.listing.countryId = this.$scope.dropdowns.selectedCountry.id;
            this.$scope.listing.makeId    = this.$scope.dropdowns.selectedMake.id;
            this.$scope.listing.user      = null;
            this.$scope.listing.state     = null;
            this.$scope.listing.country   = null;
            this.$scope.listing.comments  = [];

            delete this.$scope.listing[ 'coordinates' ];

            this.partsWantedService
                .update(this.$scope.listing.id, new IAddressJsonFilter().filter(this.$scope.listing))
                .then((ok : IPartsWantedListing) => {
                          let attachmentsPromises : angular.IPromise<any>[] = [];
                          _.forEach(this.$scope.attachments,
                                    (attachment : IAttachment) => {
                                        attachmentsPromises.push(
                                            this.partsWantedService.attach(new IAddressJsonFilter().filter(this.$scope.listing),
                                                                           attachment));
                                    });
                          this.$q.all(attachmentsPromises)
                              .then(() => {
                                  this.$window.location.href = `/partswanted/detail/${this.$scope.listing.id}`;
                              });
                      },
                      (err) => {
                          console.log('Something went wrong.');
                      })
                .finally(() => {
                    this.$scope.loading = false;
                });
        }
    }

    private getAddressComponent(componentName : string, components : google.maps.GeocoderAddressComponent[]) : string {
        return _.find(components,
                      (comp : google.maps.GeocoderAddressComponent) => {
                          return comp.types.indexOf(componentName) > -1;
                      }).long_name;
    }

    isThisImageRemoving(imageId : number) {
        return this.$scope.imageRemovingId === imageId;
    }

    removeImage($event : angular.IAngularEvent, imageId : number) {
        $event.preventDefault();

        if (!this.$scope.isImageRemoving) {
            this.$scope.isImageRemoving = true;
            this.$scope.imageRemovingId = imageId;

            this.partsWantedService.unattach(this.$scope.listing, imageId)
                .then(() => {
                    this.$scope.isImageRemoving = false;
                    this.$scope.imageRemovingId = null;
                    _.remove(this.$scope.listing.images,
                             {
                                 id : imageId
                             });
                });
        }
    }
}

interface IEditPartsWantedListScope extends angular.IScope {
    makes : IMake[];
    models : IModel[];
    states : IState[];
    countries : ICountry[];
    attachments : { [ id : number ] : IAttachment };
    newPartsWantedForm : angular.IFormController;
    filteredModels : any[];
    listing : IPartsWantedListing;
    dateOpen : boolean;
    datepickerOptions : any;
    currentDate : number;
    loading : boolean;
    dropdowns : {
        selectedState : IState;
        selectedCountry : ICountry;
        selectedModel : IModel;
        selectedMake : IMake;
    };
    maxVehicleYear : number;
    isImageRemoving : boolean;
    imageRemovingId : number;
    autoCompleteOptions : any;
    zipCodePattern : any;
}

angular.module('Row52.Views')
       .controller('EditPartsWantedListingCtrl', EditPartsWantedListCtrl);