import './consumer-listings-tab.html';
import './consumer-listings-tab.scss';

import * as angular                   from 'angular';
import { BaseController, IBaseScope } from '../../../../base-controller';
import { IncomingQuoteWidgetCtrl }    from '../incoming-quote-widget/incoming-quote-widget';
import { ListingsTabs }               from '../../../../constants/listings-tabs';
import { Pages }                      from '../../../../constants/pages';
import { QuotesTabs }                 from '../../../../constants/quotes-tabs';

export class ConsumerListingsTabCtrl extends BaseController {
    public static $inject : string[] = [ '$scope', '$window' ];

    constructor(protected $scope : IConsumerListingsTabScope,
                protected $window : angular.IWindowService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.listingsTabs = ListingsTabs;
        this.$scope.quotesTabs = QuotesTabs;
        this.$scope.pages = Pages;

        this.$scope.viewAllTab = QuotesTabs.IncomingQuotes;
        this.$scope.viewAllPage = Pages.MyQuotes;

        this.$scope.$on('', ($event : angular.IAngularEvent, count) => {
            this.$scope.purchasedParts = count;
        });

        this.$scope.$on('', ($event : angular.IAngularEvent, count) => {
            this.$scope.pendingPartsWanted = count;
        });

        this.$scope.$on(IncomingQuoteWidgetCtrl.IncomingQuoteLoaded, ($event : angular.IAngularEvent, count : number) => {
            this.$scope.activeIncomingQuotes = count;
        });
    }

    onTabSelect($event, $index, $page) {
        this.$scope.viewAllPage = $page;
        this.$scope.viewAllTab = $index;
    }
}

interface IConsumerListingsTabScope extends IBaseScope {
    purchasedParts : number;
    pendingPartsWanted : number;
    activeIncomingQuotes : number;
    listingsTabs : ListingsTabs;
    quotesTabs : QuotesTabs;
    pages : Pages;
    viewAllTab : number;
    viewAllPage : number;
}

angular.module('Row52.Views.Account.Summary.ConsumerListingTab', [])
       .controller('ConsumerListingsTabCtrl', ConsumerListingsTabCtrl);