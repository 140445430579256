/**
 * Created by Ri Scott on 5/2/2017.
 */
import * as angular from 'angular';
import IDirectiveLinkFn = angular.IDirectiveLinkFn;

class NextFocusDirective implements angular.IDirective {
    public restrict : string = 'A';
    public link : IDirectiveLinkFn;

    constructor() {
        NextFocusDirective.prototype.link =
            (_scope : angular.IScope, element : angular.IAugmentedJQuery) => {
                element.bind('keyup', (e : JQueryKeyEventObject) => {
                    if (! ((e.shiftKey && e.keyCode === 9)
                           || e.keyCode
                           === 16
                           || e.keyCode
                           === 9
                           || e.keyCode
                           === 8
                           || e.keyCode
                           === 46)) {
                        element.next()
                               .focus();
                    }
                });
            };
    }

    public static Factory() {
        return () => {
            return new NextFocusDirective();
        };
    }
}

angular
    .module('Row52.Directives')
    .directive('nextfocus', NextFocusDirective.Factory());