import * as angular from 'angular';
import * as uib from 'angular-ui-bootstrap';
import * as _ from 'lodash';
import { IUserJsonModel } from 'Row52.Models';
import { VehicleFavoriteService } from '../../services/odata/vehicle-favorite.service';
import '../../templates/modals/loginModal.html';

interface ILike {
    [key : number] : boolean;
}

export interface IVehicleListScope extends angular.IScope {
    likes : ILike;
    user : IUserJsonModel;
    showMore : any;
}

interface IVINModalScope extends angular.ui.bootstrap.IModalScope {
    isAuthenticated : boolean;
}

export interface ILoginModalScope extends angular.ui.bootstrap.IModalScope {
    description : string;
    redirectUrl : string;
}

export class VehicleListController implements angular.IController {
    public static $inject : string[] =
        ['$scope', '$http', '$uibModal', '$window', '$rootScope', 'VehicleFavoriteService'];

    constructor(public $scope : IVehicleListScope,
        protected $http : angular.IHttpService,
        protected $modal : uib.IModalService,
        protected $window : angular.IWindowService,
        protected $rootScope : angular.IRootScopeService,
        protected vehicleFavoriteService : VehicleFavoriteService) {

        this.$scope.likes = {};
        this.$scope.user = this.$window['user'];
        this.$scope.showMore = {};

        _.each($window.userLikes,
            (l : number) => {
                this.$scope.likes[l] = true;
            });
    }


    like = async ($event : angular.IAngularEvent, vehicleId : number) => {
        if ($event && $event.preventDefault) {
            $event.preventDefault();
        }

        if (!this.$scope.user.isAuthenticated) {
            let scope = this.$rootScope.$new() as ILoginModalScope;
            scope.description = 'to make favorites and create alerts!';
            scope.redirectUrl = '/Search';
            this.$modal.open({
                templateUrl : 'modals/loginModal.html',
                scope : scope
            });
        } else {
            if (this.$scope.likes[vehicleId]) {
                await this.vehicleFavoriteService.deleteAllForVehicle(vehicleId, this.$scope.user.id);
                delete this.$scope.likes[vehicleId];
            } else {
                await this.vehicleFavoriteService.createFavorite(vehicleId, this.$scope.user.id);
                this.$scope.likes[vehicleId] = true;
            }
        }
    };

    openVinDetail = ($event : angular.IAngularEvent, vin : string) => {
        if ($event && $event.preventDefault) {
            $event.preventDefault();
        }

        if (!vin) {
            return;
        }

        this.$http({
                method : 'GET',
                url : '/Vehicle/Vin/' + vin
            })
            .then((response : angular.IHttpPromiseCallbackArg<any>) => {
                let scope = this.$rootScope.$new() as IVINModalScope;
                scope.isAuthenticated = this.$window.user.Id !== '00000000-0000-0000-0000-000000000000';

                this.$modal.open({
                    template : response.data,
                    scope : scope
                }).result.catch(function(res) {
                    //Eat the error given that we expect the window to close, it is odd that angular expects a call
                    //todo: This should be done on more of a global scale at some point
                    //According to one stack overflow response: Unfortunately that's how 
                    //they handle it in The official Plucker for Modal(ui.bootstrap.modal).
                    if (!(res === 'backdrop click' || res === 'close button')) {
                        throw res;
                    }
                });
            });
    };
}

angular.module('Row52.Views')
    .controller('VehicleListCtrl', VehicleListController);