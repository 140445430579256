import * as angular from 'angular';
import * as uib from 'angular-ui-bootstrap';

export class VinModalService {
    static $inject = [ '$uibModal' ];

    constructor(private $uibModal : uib.IModalService) {
    }

    open(vin : string) : angular.IPromise<any> {
        return this.$uibModal
                   .open({
                             templateUrl : '/Vehicle/Vin/' + vin
                         }).result;
    }
}

angular.module('Row52.Services')
       .service('VinModalService', VinModalService);