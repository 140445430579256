import * as angular                from 'angular';
import { ILocation }               from 'Row52.Models.Entities';
import { ILocationStatistics }     from 'Row52.Models';
import { LocationResourceService } from '../../services/odata/location-service';

export interface IYardDetailScope extends angular.IScope {
    map : any;
    yard : ILocation;
    statistics : ILocationStatistics;
    locationId : any;
    searchAddress : any;
    displayTab : string;
    loading : boolean;
}

class YardDetailCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'LocationService'
    ];

    constructor(private $scope : IYardDetailScope,
                private $window : any,
                private locationService : LocationResourceService) {

        this.$scope.locationId = $window.locationId;

        this.$scope.loading = true;
        
        this.init();

        $scope.searchAddress = (event, yard) => {
            this.$window.location.href =
                'http://maps.google.com/?daddr=' + yard.Address1 + ' ' + yard.City + ' ' + yard.State + ' ' + yard.ZipCode;
        };
        $scope.displayTab    = 'vehicles';
    }

    claimYard = ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        this.$window.location.href = '/GotInventory';
    };

    init() {
        this.locationService
            .getByIdWithState(this.$scope.locationId)
            .then((loc : ILocation) => {
                this.$scope.yard       = loc;
                this.$scope.yard.hours = this.$scope.yard.hours.trim();
            });

        this.locationService
            .getStatistics(this.$scope.locationId)
            .then((stats : ILocationStatistics) => {
                this.$scope.statistics = stats;
                this.$scope.loading    = false;
            });
    }
}

angular.module('Row52.Views')
       .controller('YardDetailCtrl', YardDetailCtrl);
