import * as angular from 'angular';
import { IUserJsonModel, IVehicleCommentCreationModel, IVehicleCommentModel, IVehicleDetail } from 'Row52.Models';
import { IAttachment, IComment } from 'Row52.Models.Entities';
import { VehicleCommentService } from '../../services/odata/vehicle-comments.service';
import '../../templates/vehicle/comments.html';
import { ImageServiceModal } from '../../services/images/image.service';
import { AttachmentService } from '../../services/images/attachment.service';
import { RemoteErrorModalService } from '../../services/ui/remoteerror.modal';

interface IVehicleCommentsScope extends angular.IScope {
    user : IUserJsonModel;
    vehicle : IVehicleDetail;
    vehicleCommentForm : angular.IFormController;

    newCommentLoading : boolean;
    newComment : IVehicleCommentCreationModel;

    comments : IVehicleCommentModel[];
    commentsLoading : boolean;

    uploadImage : (files : FileList) => Promise<boolean>;
    uploadingImage : boolean;
    attachment : IAttachment;

    hideNewComment : boolean;
}

class VehicleCommentsCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'VehicleCommentService',
        '$log',
        'AttachmentService',
        'ImageServiceModal',
        'remoteErrorModalService'
    ];

    constructor(protected $scope : IVehicleCommentsScope,
                protected $window : angular.IWindowService,
                protected commentsService : VehicleCommentService,
                protected $log : angular.ILogService,
                protected attachmentService : AttachmentService,
                protected imageModalService : ImageServiceModal,
                protected remoteErrorService : RemoteErrorModalService) {
        this.$scope.user       = this.$window[ 'user' ];
        this.$scope.commentsLoading = true;
        this.$scope.vehicle    = this.$window[ 'vehicleDetail' ];
        this.$scope.newComment = {
            body         : '',
            attachmentId : null,
            parentId     : null,
            vehicleId    : this.$scope.vehicle.id
        };


        //this.loadComments(); //pnp - turn off
        this.$scope.uploadImage = this.uploadImage;
    }

    loadComments = async () => {
        try {
            this.$scope.comments = await this.commentsService.getForVehicle(this.$scope.vehicle.id);
        }
        catch (err) {
            this.$log.error(err);
        }

        this.$scope.commentsLoading = false;
    };

    postComment = async ($event : angular.IAngularEvent,
                         commentForm : angular.IFormController,
                         comment? : IVehicleCommentModel) => {

        $event.preventDefault();

        if (commentForm.$valid) {
            if (comment) {
                this.$scope.newComment.parentId = comment.id;
            }

            this.$scope.newCommentLoading = true;

            try {
                let newComment = await this.commentsService
                                           .createComment(this.$scope.vehicle.id,
                                                          this.$scope.newComment.body,
                                                          this.$scope.newComment.parentId,
                                                          this.$scope.newComment.attachmentId);

                if (comment) {
                    comment.children.push(newComment);
                }
                else {
                    this.$scope.comments.unshift(newComment);
                }

                this.$scope.newComment = {
                    body         : '',
                    attachmentId : null,
                    parentId     : null,
                    vehicleId    : this.$scope.vehicle.id
                };
                this.$scope.attachment = null;
            }
            catch (err) {
                this.$log.error(err);
            }
            finally {
                this.$scope.newCommentLoading = false;
                commentForm.$setPristine();
                commentForm.$setUntouched();
            }
        }
    };

    uploadImage = async (files : FileList) => {
        if (files.length > 1) {
            this.remoteErrorService
                .open('Attention',
                    'You may only upload one image at a time.',
                    { okBtnText: 'OK' });
            return false;
        }

        //Limit file size for 3MB
        if (files[0].size > 3 * 1024 * 1024) {
            this.remoteErrorService
                .open('Attention',
                    'Maximum file size is 3Mb.',
                    { okBtnText: 'OK' });
            return false;
        }

        this.$scope.uploadingImage = true;

        let file = files[0];
        let data = new FormData();
        data.append('file', file);

        try {
            let attachment = await this.attachmentService.upload(data);
            this.$scope.newComment.attachmentId = attachment.id;
            this.$scope.attachment              = attachment;
        }
        catch (err) {
            this.$log.error(err);
        }

        this.$scope.uploadingImage = false;
    };

    removeImage($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.attachment              = null;
        this.$scope.newComment.attachmentId = null;
    }

    displayImage($event : angular.IAngularEvent, comment : IVehicleCommentModel) {
        $event.preventDefault();

        this.imageModalService.open(comment.fullImageUrl);
    }
}

interface ICommentReplyScope extends angular.IScope {
    user : IUserJsonModel;
    vehicle : IVehicleDetail;
    vehicleCommentForm : angular.IFormController;

    newCommentLoading : boolean;
    newComment : IVehicleCommentCreationModel;

    comments : IVehicleCommentModel[];
    commentsLoading : boolean;

    uploadImage : (files : FileList) => Promise<boolean>;
    uploadingImage : boolean;
    attachment : IAttachment;

    hideNewComment : boolean;
    showReply : boolean;
}

class VehicleCommentReplyCtrl extends VehicleCommentsCtrl {
    static $inject : string[] = [
        '$scope',
        '$window',
        'VehicleCommentService',
        '$log',
        'AttachmentService',
        'ImageServiceModal'
    ];

    constructor(protected $scope : ICommentReplyScope,
                $window : angular.IWindowService,
                cService : VehicleCommentService,
                $log : angular.ILogService,
                attachmentService : AttachmentService,
                imageModalService : ImageServiceModal,
                remoteErrorService : RemoteErrorModalService) {

        super($scope, $window, cService, $log, attachmentService, imageModalService, remoteErrorService);

        this.$scope.showReply = false;
    }

    showReply($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.showReply = ! this.$scope.showReply;
    }
}

angular.module('Row52.Views')
       .controller('VehicleCommentsCtrl', VehicleCommentsCtrl)
       .controller('VehicleCommentReplyCtrl', VehicleCommentReplyCtrl);