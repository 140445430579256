import * as angular from 'angular';
import * as _ from 'lodash';
import { PartsWantedListingService } from '../../services/odata/parts-wanted-listing.service';
import { PartsWantedQuoteService } from '../../services/odata/parts-wanted-quote.service';
import { PartWantedQutoteStatuses } from '../../constants/statuses';
import { PartsForSaleStatuses } from '../../constants/parts-for-sale-statuses';
import { PartsForSaleStatusService } from '../../services/data/parts-for-sale-status.service';
import { IPartsForSaleListingSearchResult, IPartsWantedListingSearchResult, IUserJsonModel, IQuoteModel } from 'Row52.Models';
import { PartsForSaleListingService } from '../../services/odata/parts-for-sale-listing.service';
import { BaseController } from '../../base-controller';

interface IMyListingWidgetCtrlScope extends angular.IScope {
    listings : IPartsWantedListingSearchResult[];
    listingsLoading : boolean;
    incomingQuotes : IQuoteModel[];
    outgoingQuotes : IQuoteModel[];
    activeIncomingQuotes : IQuoteModel[];
    activeOutgoingQuotes : IQuoteModel[];
    incomingQuotesReceived : object;
    outgoingQuotesReceived : object;
    parts : IPartsForSaleListingSearchResult[];
    closedParts : IPartsForSaleListingSearchResult[];
    partsLoading : boolean;
    closedPartsLoading : boolean;
    user : IUserJsonModel;
}

class MyListingWidgetCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window',
        'PartsWantedListingService',
        'PartsForSaleListingService',
        'PartsForSaleStatusService'
    ];

    constructor(protected $scope : IMyListingWidgetCtrlScope,
                protected $window : angular.IWindowService,
                private pwService : PartsWantedListingService,
                private pfsService : PartsForSaleListingService,
                public pfsStatusService : PartsForSaleStatusService) {
        super();

        this.init();


        this.loadParts();
    }

    protected async init() {
        await super.init();

        this.$scope.incomingQuotesReceived = {};
        this.$scope.outgoingQuotesReceived = {};
    }

    private loadParts = async () => {
        this.$scope.partsLoading = true;
        let parts = (await this.pfsService.getActiveForSeller(this.$scope.user.id, 5)).value;
        this.$scope.parts        = parts;
        this.$scope.partsLoading = false;
    };
    


    navigateToPartsSale($event : angular.IAngularEvent, part : IPartsForSaleListingSearchResult) {
        this.$window.location.href = `/PartsSale/Detail/${part.id}`;
    }


}

angular.module('Row52.Views.Account')
       .controller('MyListingWidgetCtrl', MyListingWidgetCtrl);