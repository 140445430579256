import * as angular from 'angular';
import 'angular-cookies';

declare var QueryString : any;

export class LoginCtrl {
    static $inject : string[]      = [ '$scope', '$http', '$location', '$window', '$cookies', 'domain' ];
    static TokenCookieKey : string = 'R52ACK';

    constructor(private $scope : ILoginScope,
                private $http : angular.IHttpService,
                private $location : angular.ILocationService,
                private $window : angular.IWindowService,
                private $cookies : angular.cookies.ICookiesService,
                private domain : string) {

        $scope.user    = {
            userName   : '',
            password   : '',
            timezone   : ( new Date() ).getTimezoneOffset() / 60,
            rememberMe : false
        };
        $scope.alerts  = [];
        $scope.loading = false;
    }

    login = async () => {
        if (this.$scope.loginForm.$valid) {
            this.$scope.loading = true;

            this.$scope.alerts = [];

            try {
                let tokenResponse = await this.$http.post<any>(`${ this.domain }/odata/Users/Row52.Authenticate`,
                                                               this.$scope.user);

                this.$cookies.put(LoginCtrl.TokenCookieKey, tokenResponse.data.value, { path : '/' });

                this.$scope.alerts.push({ type : 'success', msg : 'Please wait...' });

                let url    = '/account';
                let params = [];

                for (let _i = 0; _i < QueryString.length; _i++) {
                    if (QueryString[ _i ].name.toLowerCase() === 'redirecturl') {
                        url = decodeURIComponent(QueryString[ _i ].value);
                    }
                    else {
                        params.push(QueryString[ _i ].name + '=' + QueryString[ _i ].value);
                    }
                }

                this.$window.location.href = url + '?' + params.join('&');

                this.$cookies.remove('R2LOGOUT');
            }
            catch (err) {
                this.$scope.alerts = [];
                switch (err.status) {
                    case 401:
                        this.$scope.alerts.push({
                                                    type : 'danger',
                                                    msg  : err.data.value && err.data.value !== ''
                                                           ? err.data.value
                                                           : 'Please check your username and password and try again.'
                                                });
                        break;
                    default:
                        this.$scope.alerts.push({
                                                    type : 'danger',
                                                    msg  : 'There was an unanticipated error. Please try again. If the '
                                                           + 'problem persists, please contact us.'
                                                });
                        break;
                }
            }

            this.$scope.loading = false;
        }
        else {
            this.$scope.loading = false;
        }
    };
}

export interface ILoginScope extends angular.IScope {
    user : {
        userName : string;
        password : string;
        timezone : number;
        rememberMe : boolean;
    };
    loading : boolean;
    loginForm : angular.IFormController;
    alerts : any[];
}

angular.module('Row52.Views.Account.Login', [ 'ngCookies', 'ngLocale', 'ui.bootstrap' ])
       .controller('LoginCtrl', LoginCtrl);
