import * as angular from 'angular';
import * as _ from 'lodash';
import * as uib from 'angular-ui-bootstrap';
import * as moment from 'moment';
import { IUserJsonModel, IVehicleFavorite } from 'Row52.Models';
import { VehicleFavoriteService } from '../../../services/odata/vehicle-favorite.service';
import '../../../templates/account/favorites/vehicles.html';

interface IFavoriteVehicleWidgetScope extends angular.IScope {
    favorites : IVehicleFavorite[];
    fromNow : (favorite : IVehicleFavorite) => string;
    vehicleThumbnail : (favorite : IVehicleFavorite) => string;
    loading : boolean;
    user : IUserJsonModel;
}

interface IVINModalScope extends angular.ui.bootstrap.IModalScope {
    isAuthenticated : boolean;
}

class FavoriteVehicleCtrl {
    static $inject : string[] = [
        '$scope', '$http', '$uibModal', '$window', '$rootScope', 'VehicleFavoriteService'
    ];

    constructor(private $scope : IFavoriteVehicleWidgetScope,
        protected $http : angular.IHttpService,
        protected $modal : uib.IModalService,
        protected $window : angular.IWindowService,
        protected $rootScope : angular.IRootScopeService,
        private vehicleFavoriteSearch : VehicleFavoriteService) {
        this.$scope.loading = true;
        this.$scope.user = this.$window['user'];

        this.loadData();

        this.$scope.fromNow = (favorite : IVehicleFavorite) : string => {
            return moment(favorite.dateAdded)
                .fromNow();
        };

        this.$scope.vehicleThumbnail = (favorite : IVehicleFavorite) : string => {
            if (favorite.url) {
                return favorite.url + favorite.size3.toLowerCase() + favorite.extension;
            }
            return 'https://cdn.row52.com/cdn/images/imagecomingsoon_150.png';
        };
    }

    loadData = async () => {
        this.$scope.favorites = await this.vehicleFavoriteSearch.getVehiclesForUser(this.$scope.user.id);
        this.$scope.loading = false;
    };

    openVinDetail = ($event : angular.IAngularEvent, vin : string) => {
        if ($event && $event.preventDefault) {
            $event.preventDefault();
        }

        if (!vin) {
            return;
        }

        this.$http({
                method : 'GET',
                url : '/Vehicle/Vin/' + vin
            })
            .then((response : angular.IHttpPromiseCallbackArg<any>) => {
                let scope = this.$rootScope.$new() as IVINModalScope;
                scope.isAuthenticated = this.$window.user.Id !== '00000000-0000-0000-0000-000000000000';

                this.$modal.open({
                    template : response.data,
                    scope : scope
                }).result.catch(function(res) {
                    //Eat the error given that we expect the window to close, it is odd that angular expects a call
                    //todo: This should be done on more of a global scale at some point
                    //According to one stack overflow response: Unfortunately that's how 
                    //they handle it in The official Plucker for Modal(ui.bootstrap.modal).
                    if (!(res === 'backdrop click' || res === 'close button')) {
                        throw res;
                    }
                });
            });
    }

    deleteFavorite = async ($event : angular.IAngularEvent, favorite : IVehicleFavorite) => {
        $event.preventDefault();
        await this.vehicleFavoriteSearch.delete(favorite.userVehicleRelationshipId);
        _.remove(this.$scope.favorites,
            (f : IVehicleFavorite) => {
                return f.userVehicleRelationshipId === favorite.userVehicleRelationshipId;
            });
    };
}

angular.module(
        'Row52.Views.Account.Favorites.FavoriteVehicleCtrl',
        [])
    .controller(
        'FavoriteVehicleCtrl',
        FavoriteVehicleCtrl);