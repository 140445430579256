import * as angular from 'angular';
import '../../constants/constants';
import { ResourceService } from './odata.resource';
import { IState } from 'Row52.Models.Entities';

/**
 * Created by Ri Scott on 5/5/2017.
 */
export class StateService extends ResourceService<IState> {
    static $inject : string[] = ['$http', '$q', '$cookies', 'domain'];
    static cachedStates : IState[] = [];

    constructor($http : angular.IHttpService,
        $q : angular.IQService,
        $cookies : angular.cookies.ICookiesService,
        domain : string) {
        super($http, $q, $cookies, domain, 'States');
    }

    public getAndCache() : angular.IPromise<IState[]> {
        let deferred = this.$q.defer() as angular.IDeferred<IState[]>;
        let result = StateService.cachedStates;

        if (result && result.length > 0) {
            deferred.resolve(result as IState[]);
        } else {
            this.getAll()
                .then((states) => {
                    StateService.cachedStates = states;
                    deferred.resolve(states);
                });
        }
        return deferred.promise;
    }
}

angular.module('Row52.Services.StateService', ['ngCookies', 'Row52.Constants'])
    .service('StateService', StateService);