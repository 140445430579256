/**
 * Created by Ri Scott on 6/9/2017.
 */

export class PartWantedQutoteStatuses {
    public static Active : number    = 1;
    public static Completed : number = 2;
    public static Accepted : number  = 3;
    public static Withdrawn : number = 4;
    public static Declined : number  = 5;
    public static Expired : number   = 6;
    public static Fraud : number     = 7;
    public static Refunded : number  = 8;
}