import * as angular                         from 'angular';
import { PartsForSaleStatuses }             from '../../constants/parts-for-sale-statuses';
import * as _                               from 'lodash';
import { IPartsSaleListing }                from 'Row52.Models.Entities';
import { IPartsForSaleListingSearchResult } from 'Row52.Models';

export class PartsForSaleStatusService {
    partsStatus(listing : IPartsSaleListing | IPartsForSaleListingSearchResult) : string {
        let status = '';

        switch (listing.status) {
            case PartsForSaleStatuses.Available:
                status = 'Available';
                break;
            case PartsForSaleStatuses.Sold:
                status = 'Sold';
                break;
            case PartsForSaleStatuses.Canceled:
                status = 'Canceled';
                break;
            case PartsForSaleStatuses.Completed :
                status = 'Sold';
                break;
            case PartsForSaleStatuses.Refunded :
                status = 'Refunded';
                break;
            default:
                status = 'Not-set';
                break;
        }

        if (listing.status === PartsForSaleStatuses.Sold && listing.refundDate) {
            status = 'Refunded';
        }

        return status;
    }

    partsStatusIcon(listing : IPartsSaleListing | IPartsForSaleListingSearchResult) : string {
        let statusClass = '';

        switch (listing.status) {
            case PartsForSaleStatuses.Available:
                statusClass = 'active-status';
                break;
            case PartsForSaleStatuses.Sold:
                statusClass = 'completed';
                break;
            case PartsForSaleStatuses.Canceled:
                statusClass = 'inactive';
                break;
            case PartsForSaleStatuses.Completed:
                statusClass = 'completed';
                break;
            case PartsForSaleStatuses.Refunded:
                statusClass = 'inactive';
                break;
            default:
                statusClass = '';
                break;
        }

        if (listing.status === PartsForSaleStatuses.Sold && listing.refundDate) {
            statusClass = 'inactive';
        }

        return statusClass;
    }

    getSorted() : { id : number, name : string }[] {
        let statusesKeys = Object.keys(PartsForSaleStatuses);
        return _.map(statusesKeys, (statusKey : string) => {
            return {
                id   : PartsForSaleStatuses[ statusKey ],
                name : statusKey
            };
        });
    }
}

angular.module('Row52.Services')
       .service('PartsForSaleStatusService', PartsForSaleStatusService);