import * as angular                                      from 'angular';
import { ICreateMessageModel, IMessageThread }           from 'Row52.Models';
import { TypeScriptResourceFactory, IODataCountWrapper } from './odata.resource';

export class MessagesApiService extends TypeScriptResourceFactory {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                private domain : string) {
        super($http, $q, $cookies);
    }

    getAll() : angular.IPromise<IMessageThread[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/Messages?$orderby=mostRecentMessage desc`, this.getHeaders())
        );
    }

    getForWidget() : angular.IPromise<IODataCountWrapper<IMessageThread>> {
        return this.unwrapODataCountArray(
            this.$http
                .get(`${this.domain}/odata/Messages?`
                     + `$orderby=mostRecentMessage desc`
                     + `&$top=5`
                     + `&$filter=isUnread eq true`
                     + `&$count=true`,
                    this.getHeaders())
        );
    }

    get(threadId : string) : angular.IPromise<IMessageThread> {
        return this.unwrapSingle(
            this.$http
                .get(`${this.domain}/odata/Messages(${threadId})`, this.getHeaders())
        );
    }

    create(model : ICreateMessageModel) : angular.IPromise<void> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Messages`, model, this.getHeaders())
        );
    }

    deleteThread(thread : IMessageThread) : angular.IPromise<void> {
        return this.unwrapSingle(
            this.$http
                .get(`${this.domain}/odata/Messages/Row52.DeleteThread(threadId=${thread.id})`, this.getHeaders())
        );
    }

    readThread(thread : IMessageThread) : angular.IPromise<void> {
        return this.unwrapSingle(
            this.$http
                .get(`${this.domain}/odata/Messages/Row52.ReadThread(threadId=${thread.id})`, this.getHeaders())
        );
    }
}

angular.module('Row52.Services.MessagesService', [ 'ngCookies', 'Row52.Constants' ])
       .service('MessagesApiService', MessagesApiService);