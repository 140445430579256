import * as angular from 'angular';

import './list';
import './create';
import './edit';
import './buy';
import './detail/detail.module';

angular.module('Row52.Views.PartsSale', [
    'Row52.Views.PartsSale.Detail',
    'Row52.Views.PartsSale.List'
]);