import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService }                from '../../../../services/odata/user.service';
import './change-password.html';
import './change-password.scss';

class ChangePasswordCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService'
    ];

    public changePassword : angular.IFormController;

    constructor(protected $scope : IChangePasswordScope,
                protected $window : angular.IWindowService,
                private userService : UserService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.password = {
            password        : null,
            newPassword     : null,
            confirmPassword : null
        };
        this.$scope.alerts    = [];
    }

    async updatePassword($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (this.changePassword.$valid) {
            this.$scope.loading = true;
            this.$scope.alerts   = [];

            try {
                await this.userService.changePassword(this.$scope.user.id,
                                                      this.$scope.password.password,
                                                      this.$scope.password.newPassword,
                                                      this.$scope.password.confirmPassword);
                this.$scope.alerts.push({
                                           msg  : 'Your password has been successfully changed.',
                                           type : 'success'
                                       });
            }
            catch (resp) {
                if (ChangePasswordCtrl.isHttpError(resp)) {
                    switch (resp.status) {
                        case 401:
                            this.$scope.alerts.push({
                                                       msg  : 'Please make sure that you are entering your old password '
                                                              + 'correctly and try again.',
                                                       type : 'danger'
                                                   });
                            break;
                        case 404:
                            this.$scope.alerts.push({
                                                       msg  : 'We were unable to locate the user you were '
                                                              + 'attempting to modify.',
                                                       type : 'danger'
                                                   });
                            break;
                        default:
                            this.$scope.alerts.push({
                                                       msg  : resp.data.error.message,
                                                       type : 'danger'
                                                   });
                            break;
                    }
                }
            }

            this.$scope.loading = false;
        }
    };

    private static isHttpError(error : any) : error is angular.IHttpPromiseCallbackArg<any> {
        return error.status !== undefined;
    }
}

interface IChangePasswordScope extends IBaseScope {
    loading : boolean;
    alerts : {
        msg : string;
        type : 'danger' | 'success'
    }[]

    password : {
        password : string;
        newPassword : string;
        confirmPassword : string;
    }
}

angular.module('Row52.Views.Account.Settings.ChangePassword', [])
       .controller('ChangePasswordCtrl', ChangePasswordCtrl);