import * as angular                                                       from 'angular';
import { CountryService }                                                 from '../../services/odata/country.service';
import { StateService }                                                   from '../../services/odata/state.service';
import { IState, ICountry }                                               from 'Row52.Models.Entities';
import { UserService }                                                    from '../../services/odata/user.service';
import { BasePullerRegistrationController, IBasePullerRegistrationScope } from './pullerRegistration/base-controller';
import { AddressCompletionService }                                       from '../../services/helpers/address.completion.service';
import { IBankAccount }                                                   from '../../models/marketplace/stripe-marketplace-account';

export class PartsPullerRegistrationCtrl extends BasePullerRegistrationController {
    static $inject : string[] = [
        '$scope',
        '$window',
        'CountryService',
        'StateService',
        '$q',
        'UserService',
        'AddressCompletionService'
    ];

    pullerRegistrationForm : angular.IFormController;

    constructor(protected $scope : IPartsPullerRegistrationScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.isRegistered = this.$window.isRegistered;

        this.initPullerRegistration();
        this.initStatusVariables();

    }

    private initPullerRegistration() {
        this.$scope.pullerRegistration = {
            pullerTos : false,
            sellerTos : false,
            stripeTos : false
        };

        if (this.isSeller) {
            this.$scope.pullerRegistration.sellerTos = true;
        }
    }

    private initStatusVariables() {
        this.$scope.pullerDetailsLoading = false;
        this.$scope.noOptionSelected     = true;
        this.$scope.error                = false;
    }
}

export interface IPartsPullerRegistrationScope extends IBasePullerRegistrationScope {
    states : IState[];
    countries : ICountry[];

    dropdowns : any;

    pullerRegistration : {
        pullerTos : boolean;
        sellerTos : boolean;
        stripeTos : boolean;
    }
    bankAccount : IBankAccount;

    pullerDetailsLoading : boolean;
    noOptionSelected : boolean;
    error : boolean;
    isRegistered : boolean;
}

angular.module('Row52.Views.Account.PartsPullerRegistration',
               [
                   'ui.bootstrap',
                   'Row52.Services.CountryService',
                   'Row52.Services.StateService',
                   'Row52.Services.UserService',
                   'Row52.Services.UserLocationRelationshipService',
                   'row52-templates'
               ])
       .controller('PartsPullerRegistrationCtrl', PartsPullerRegistrationCtrl);
