import * as angular                                     from 'angular';
import { IBaseScope }                                   from '../../../../../base-controller';
import './new-location-modal.html';
import './new-location-modal.scss';
import { IModalServiceInstance, IModalScope }           from 'angular-ui-bootstrap';
import { LocationResourceService }                      from '../../../../../services/odata/location-service';
import { StateService }                                 from '../../../../../services/odata/state.service';
import { UserLocationRelationshipService }              from '../../../../../services/odata/user-location.service';
import { ILocation, IState, IUserLocationRelationship } from 'Row52.Models.Entities';
import * as _                                           from 'lodash';
import { PullerLocationBaseCtrl }                       from '../base-controller';


class NewLocationModalCtrl extends PullerLocationBaseCtrl {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$uibModalInstance',
        'LocationService',
        'StateService',
        'UserLocationRelationshipService',
        '$q'
    ];

    constructor(protected $scope : INewLocationModalScope,
                protected $window : angular.IWindowService,
                private $modalInstance : IModalServiceInstance,
                private locationService : LocationResourceService,
                private stateService : StateService,
                private userLocationService : UserLocationRelationshipService,
                private $q : angular.IQService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.selectedLocations = {};

        this.setLocations();
        this.setSelectedLocations();

        this.$scope.locationsLeft = () => {
            return 5 - _.filter(this.$scope.selectedLocations, (userLocation) => {
                return userLocation[ 'checked' ] === true;
            }).length;
        };

        await this.initLocations();
    }

    private setSelectedLocations() {
        _.forEach(this.$scope.userLocations, (userLoc : IUserLocationRelationship) => {
            this.$scope.selectedLocations[ userLoc.locationId ] = {
                checked        : true,
                userLocationId : userLoc.id
            };
        });
    }

    private async initLocations() {
        let locations : ILocation[] = null;

        try {
            let statePromise    = this.stateService.getAll()
                                      .then((states : IState[]) => {
                                          this.$scope.states = states;
                                      });
            let locationPromise = this.locationService.getParticipating()
                                      .then((locs : ILocation[]) => {
                                          locations = locs;
                                      });

            await this.$q.all([ statePromise, locationPromise ]);

            _.forEach(this.$scope.states, (state) => {
                state.locations = _.filter(locations, { 'stateId' : state.id });
            });
        }
        catch (err) {

        }
    }

    async update($event : angular.IAngularEvent) {
        $event.preventDefault();

        let newLocations = _.pickBy(this.$scope.selectedLocations, (value : SelectedLocation, key) => {
            return value.checked && !_.some(this.$scope.userLocations, { 'locationId' : parseInt(key, 10) });
        });

        let locationsForDelete = _.pickBy(this.$scope.selectedLocations, (value : SelectedLocation, key) => {
            return !value.checked && _.some(this.$scope.userLocations, { 'locationId' : parseInt(key, 10) });
        });

        let newLocationsPromises : angular.IPromise<IUserLocationRelationship>[] = [];
        let deletedLocationsPromises : angular.IPromise<any>[]                   = [];

        _.forEach(newLocations, (value, key) => {
            newLocationsPromises.push(this.userLocationService
                                          .create({
                                                      locationId : parseInt(key, 10),
                                                      userId     : this.$scope.user.id,
                                                      location   : null,
                                                      user       : null,
                                                      id         : undefined,
                                                      isActive   : true
                                                  }));
        });

        _.forEach(locationsForDelete, (value : SelectedLocation) => {
            deletedLocationsPromises.push(this.userLocationService.delete(value.userLocationId));
        });

        this.$q
            .all(_.flatten([ newLocationsPromises, deletedLocationsPromises ]))
            .then(() => {
                this.syncLocations();
                this.$modalInstance.close(null);
            });
    }
}

export interface INewLocationModalScope extends IBaseScope, IModalScope {
    selectedLocations : {
        [ index : number ] : SelectedLocation
    };

    states : IState[];
    userLocations : IUserLocationRelationship[];
    participatingLocations : ILocation[];
    locationsLeft : () => number;
}

export interface SelectedLocation {
    checked : boolean,
    userLocationId : number
}

angular.module('Row52.Views.Account.SellerRegistration.PullerLocations.NewLocationModal', [
           'ui.bootstrap',
           'Row52.Services.LocationService',
           'Row52.Services.StateService',
           'Row52.Services.UserLocationRelationshipService'
       ])
       .controller('NewLocationModalCtrl', NewLocationModalCtrl);