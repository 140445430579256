import * as angular from 'angular';
import IDirectiveLinkFn = angular.IDirectiveLinkFn;
import { IAugmentedJQuery } from 'angular';

class FileUploaderDirective implements angular.IDirective {
    public restrict : string = 'A';
    public link : IDirectiveLinkFn;
    public template : string = '<canvas />';

    constructor($window : angular.IWindowService) {
        let helper = {
// ReSharper disable once TsResolvedFromInaccessibleModule
            support : ! ! ($window.FileReader && $window.CanvasRenderingContext2D),
            isFile: function (item) {
                // ReSharper disable once TsResolvedFromInaccessibleModule
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage : function (file) {
                let type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== - 1;
            }
        };

        FileUploaderDirective.prototype.link =
            (scope : angular.IScope, element : angular.IAugmentedJQuery, attributes : angular.IAttributes) => {
                if (! helper.support) {
                    return;
                }

                // ReSharper disable once TsResolvedFromInaccessibleModule
                let params = scope.$eval(attributes.ngThumb);

                if (! helper.isFile(params.file)) {
                    return;
                }
                if (! helper.isImage(params.file)) {
                    return;
                }

                let canvas : IAugmentedJQuery = element.find('canvas');
                let reader          = new FileReader();

                reader.onload = onLoadFile;
                reader.readAsDataURL(params.file);

                function onLoadFile(event : any) {
                    let img    = new Image();
                    img.onload = onLoadImage;
                    img.src    = event.target.result;
                }

                function onLoadImage() {
                    let width  = params.width || this.width / this.height * params.height;
                    let height = params.height || this.height / this.width * params.width;
                    canvas.attr({ width : width, height : height });
                    (<HTMLCanvasElement>canvas[ 0 ]).getContext('2d')
                                                    .drawImage(this, 0, 0, width, height);
                }
            };
    }

    public static Factory() {
        let directive = ($window : angular.IWindowService) => {
            return new FileUploaderDirective($window);
        };

        directive[ '$inject' ] = [ '$window' ];

        return directive;
    }
}

angular
    .module('Row52.Directives')
    .directive('ngThumb', FileUploaderDirective.Factory());
