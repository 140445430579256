import * as angular from 'angular';
import * as _ from 'lodash';

export interface IShippingVendor {
    id : number;
    name : string;
}
export class ShippingVendorsService {
    private vendors : IShippingVendor[] = [
        { id: 1, name: 'USPS' },
        { id: 2, name: 'FedEx' },
        { id: 3, name: 'UPS' },
        { id: 99, name: 'Other' }
    ];

    getAll() : IShippingVendor[] {
        return this.vendors;
    }

    get(key : number) : string {
        return _.find(this.vendors, v => v.id === key).name;
    }
}

angular.module('Row52.Services')
    .service('ShippingVendorsService', ShippingVendorsService);