import * as angular from 'angular';
import { IBaseScope, BaseController } from '../../../../../base-controller';
import { AddressCompletionService, IAddress } from '../../../../../services/helpers/address.completion.service';
import { RegistrationWizardCtrl } from '../registration-wizard';
import { ICountry, IState } from 'Row52.Models.Entities';
import { CountryService } from '../../../../../services/odata/country.service';
import { StateService } from '../../../../../services/odata/state.service';
import * as _ from 'lodash';
import './personal-info.html';
import './personal-info.scss';
import '../../../../../templates/partials/sales-tax-tooltip.html';
import { ConfirmAddressModalService } from '../../../../../services/ui/confirmaddress.modal';

export class RegistrationWizardPersonalInfoCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'AddressCompletionService',
        'CountryService',
        'StateService',
        '$q',
        'ConfirmAddressModalService'
    ];
    public static PersonalInfoSaved = 'PersonalInfoSaveSuccess';
    public static ControllerNameKey = 'RegistrationWizardPersonalInfoCtrl';
    private static isInited = false;

    public personalInfoForm : angular.IFormController;

    constructor(protected $scope : IRegistrationWizardPersonalInfoScope,
        protected $window : angular.IWindowService,
        private addressService : AddressCompletionService,
        private countryService : CountryService,
        private stateService : StateService,
        private $q : angular.IQService,
        protected modalService : ConfirmAddressModalService) {
        super();

        this.$scope.autoCompleteOptions = {
            types : ['address']
        };

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.addressServiceLabels = this.addressService.staticLabels;
        await this.initDropdowns();
        this.initAddress();

        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
            this.addressService.createHandler(this.$scope.wizardCtrl.stripeAccount,
                this.$scope.states,
                this.$scope.countries,
                this.$scope.dropdowns));
    }

    public verifyStateSelection(item, model) {
        this.personalInfoForm.state.$setValidity('StateMatchesCountry',
            this.addressService.stateMatchesCounty(
                this.$scope.dropdowns.selectedState,
                this.$scope.dropdowns.selectedCountry
            )
        );
    }

    private async initDropdowns() {
        this.$scope.dropdowns = {};
        let loaderHelper = this.createDropdownLoader(this.$scope,
            this.$scope.dropdowns,
            this.$scope.wizardCtrl.stripeAccount);

        try {
            let countryPromise = loaderHelper(this.countryService,
                'getSortedBankingSupported',
                'countries',
                'selectedCountry',
                'countryId');
            let statePromise = loaderHelper(this.stateService, 'getAndCache', 'states', 'selectedState', 'stateId');

            await this.$q.all([countryPromise, statePromise]);
            this.initAddress();
        } catch (err) {
            console.log(err);
        }
    }

    private initAddress() {
        let address : IAddress = {
            address1 : this.$scope.user.address1,
            city : this.$scope.user.city,
            zipCode : this.$scope.user.zipCode,
            latitude : this.$scope.user.latitude,
            longitude : this.$scope.user.longitude,
            stateId : this.$scope.user.stateId,
            provinceName : this.$scope.user.provinceName,
            countryId : this.$scope.user.countryId,
            isInexactAddress : true
        };

        if (!RegistrationWizardPersonalInfoCtrl.isInited) {
            if (this.addressService.validate(address)) {
                this.$scope.wizardCtrl.stripeAccount.address1 = this.$scope.user.address1;
                this.$scope.wizardCtrl.stripeAccount.address2 = this.$scope.user.address2;
                this.$scope.wizardCtrl.stripeAccount.city = this.$scope.user.city;
                this.$scope.wizardCtrl.stripeAccount.stateId = this.$scope.user.stateId;
                this.$scope.wizardCtrl.stripeAccount.postalCode = this.$scope.user.zipCode;
                this.$scope.wizardCtrl.stripeAccount.countryId = this.$scope.user.countryId;
                this.$scope.wizardCtrl.stripeAccount.latitude = this.$scope.user.latitude;
                this.$scope.wizardCtrl.stripeAccount.longitude = this.$scope.user.longitude;
                this.$scope.wizardCtrl.stripeAccount.firstName = this.$scope.user.firstName;
                this.$scope.wizardCtrl.stripeAccount.lastName = this.$scope.user.lastName;

                this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries,
                    { 'id' : this.$scope.wizardCtrl.stripeAccount.countryId });
                this.$scope.dropdowns.selectedState = _.find(this.$scope.states,
                    { 'id' : this.$scope.wizardCtrl.stripeAccount.stateId });
            } else {
                this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries, { 'id' : 234 });
            }
        }
        RegistrationWizardPersonalInfoCtrl.isInited = true;
    }

    async nextStep($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.verifyStateSelection(null, null);

        if (this.personalInfoForm.$valid) {
            this.$scope.wizardCtrl.selectedCountry = this.$scope.dropdowns.selectedCountry;
            this.$scope.wizardCtrl.selectedState = this.$scope.dropdowns.selectedState;
            this.$scope.wizardCtrl.stripeAccount.stateId = this.$scope.dropdowns.selectedState.id;
            this.$scope.wizardCtrl.stripeAccount.countryId = this.$scope.dropdowns.selectedCountry.id;
            
            this.$scope.user.stateId = this.$scope.dropdowns.selectedState.id;
            this.$scope.user.countryId = this.$scope.dropdowns.selectedCountry.id;
            this.$scope.user.city = this.$scope.wizardCtrl.stripeAccount.city;
            this.$scope.user.address1 = this.$scope.wizardCtrl.stripeAccount.address1;
            this.$scope.user.address2 = this.$scope.wizardCtrl.stripeAccount.address2;
            this.$scope.user.firstName = this.$scope.wizardCtrl.stripeAccount.firstName;
            this.$scope.user.lastName = this.$scope.wizardCtrl.stripeAccount.lastName;
            this.$scope.user.zipCode = this.$scope.wizardCtrl.stripeAccount.postalCode;

            this.$scope.$emit(RegistrationWizardPersonalInfoCtrl.PersonalInfoSaved);
        }
    }

    cancel($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = '/Account';
    }
}

export interface IRegistrationWizardPersonalInfoScope extends IBaseScope {
    wizardCtrl : RegistrationWizardCtrl;
    dropdowns : any;
    states : IState[];
    countries : ICountry[];
    autoCompleteOptions : any;
    addressServiceLabels : any;
}

angular.module('Row52.Views.Account.SellerRegistration.RegistrationWizard.RegistrationWizardPersonalInfo',
        [
            'Row52.Services.AddressCompletion',
            'Row52.Services.CountryService',
            'Row52.Services.StateService'
        ])
    .controller(RegistrationWizardPersonalInfoCtrl.ControllerNameKey, RegistrationWizardPersonalInfoCtrl);