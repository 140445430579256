import './outgoing-quote-widget.html';
import './outgoing-quote-widget.scss';
import { BaseController, IBaseScope } from '../../../../base-controller';
import * as _                         from 'lodash';
import * as angular                   from 'angular';
import { IQuoteModel }                from 'Row52.Models';
import { PartsWantedQuoteService }    from '../../../../services/odata/parts-wanted-quote.service';
import { PartWantedQutoteStatuses }   from '../../../../constants/statuses';
import { StripeFeeCalculatorService } from '../../../../services/marketplace/stripe-fee-calculator.service';
import { QuotesTabs }                 from '../../../../constants/quotes-tabs';

export class OutgoingQuoteWidgetCtrl extends BaseController {
    public static $inject : string[]           = [ '$scope', '$window', 'PartsWantedQuoteService', 'StripeFeeCalculator' ];
    public static OutgoingQuoteLoaded : string = 'outgoing-quote:loaded';

    constructor(protected $scope : IOutgoingQuoteWidgetCtrl,
                protected $window : angular.IWindowService,
                private pwqService : PartsWantedQuoteService,
                public feeCalculator : StripeFeeCalculatorService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        await this.loadOutgoingQuotes();

        this.$scope.quoteStatuses = PartWantedQutoteStatuses;
        this.$scope.feeCalculator = this.feeCalculator;
        this.$scope.quotesTabs    = QuotesTabs;
    }

    private async loadOutgoingQuotes() {
        this.$scope.outgoingQuotesLoading = true;

        try {
            this.$scope.outgoingQuotes = await this.pwqService.getOutgoingQuotes(this.$scope.user);

            this.$scope.activeOutgoingQuotes = _.filter(this.$scope.outgoingQuotes, (quote : IQuoteModel) => {
                return quote.status === PartWantedQutoteStatuses.Accepted;
            }).length;

            this.$scope.outgoingQuotes = this.pwqService.sort(this.$scope.outgoingQuotes, 'status');
            this.$scope.$emit(OutgoingQuoteWidgetCtrl.OutgoingQuoteLoaded, this.$scope.activeOutgoingQuotes);
        }
        catch (err) {

        }

        this.$scope.outgoingQuotesLoading = false;
    }

    showQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$window.location.href = `/Account/MyQuotes?tab=${QuotesTabs.OutgoingQuotes}&qId=${quote.id}`;
    }

    goToOutgoingQuotes($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/Account/MyQuotes?tab=${QuotesTabs.OutgoingQuotes}`;
    }
}

interface IOutgoingQuoteWidgetCtrl extends IBaseScope {
    outgoingQuotes : IQuoteModel[];
    outgoingQuotesLoading : boolean;
    activeOutgoingQuotes : number;
    quoteStatuses : PartWantedQutoteStatuses;
    feeCalculator : StripeFeeCalculatorService;
    quotesTabs : QuotesTabs;
}

angular.module('Row52.Views.Account.Summary.OutgoingQuoteWidget', [
           'Row52.Services.PartsWantedQuoteService',
           'Row52.Services.StripeFeeCalculator'
       ])
       .controller('OutgoingQuoteWidgetCtrl', OutgoingQuoteWidgetCtrl);