import * as angular from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import './edit-personal-info.html';
import './edit-personal-info.scss';
import { UserService } from '../../../../services/odata/user.service';
import {
    IUser,
    IState,
    ICountry
    } from 'Row52.Models.Entities';
import { CountryService } from '../../../../services/odata/country.service';
import { StateService } from '../../../../services/odata/state.service';
import {
    AddressCompletionService
    } from '../../../../services/helpers/address.completion.service';
import '../../../../templates/partials/parts-seller-tooltip.html';
import '../../../../templates/partials/sales-tax-tooltip.html';

export class EditPersonalInfoCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService',
        'CountryService',
        'StateService',
        'AddressCompletionService',
        '$q'
    ];

    public static PullerDetailsSaved = 'SellerRegistrationPullerDetailsSaved';
    public static PullerDetailCanceled = 'SellerRegistrationPullerDetailCanceled';

    public pullerUpdateForm : angular.IFormController;

    constructor(protected $scope : IEditPersonalInfoScope,
        protected $window : angular.IWindowService,
        private userService : UserService,
        private countryService : CountryService,
        private stateService : StateService,
        private addressService : AddressCompletionService,
        private $q : angular.IQService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.userModel = this.$window['userModel'];
        this.$scope.autoCompleteOptions = {
            types : ['address']
        };

        this.initAccount();

        await this.initDropdowns();

        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
            this.addressService.createHandler(this.$scope.userModel,
                this.$scope.states,
                this.$scope.countries,
                this.$scope.dropdowns));
    }

    public verifyStateSelection(item, model) {
        this.pullerUpdateForm.state.$setValidity('StateMatchesCountry',
            this.addressService.stateMatchesCounty(
                this.$scope.dropdowns.selectedState,
                this.$scope.dropdowns.selectedCountry
            )
        );
    }

    private initAccount() {
        this.$scope.userModel = this.$window['userModel'];

        if (!this.$scope.userModel.zipCode) {
            this.$scope.userModel.zipCode = '';
        }
    }

    private async initDropdowns() {
        this.$scope.dropdowns = {};
        let loaderHelper = this.createDropdownLoader(this.$scope, this.$scope.dropdowns, this.$scope.userModel);

        try {
            let countryPromise = loaderHelper(this.countryService,
                'getSortedBankingSupported',
                'countries',
                'selectedCountry',
                'countryId');
            let statePromise = loaderHelper(this.stateService, 'getAll', 'states', 'selectedState', 'stateId');

            await this.$q.all([countryPromise, statePromise]);
        } catch (err) {
            console.log(err);
        }
    }

    async updatePullerDetails($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.verifyStateSelection(null, null);

        if (this.pullerUpdateForm.$valid) {
            this.$scope.loading = true;

            try {

                await this.userService.updatePullerDetails(this.$scope.userModel,
                    this.$scope.dropdowns.selectedState.id,
                    this.$scope.dropdowns.selectedCountry.id);

                this.$scope.error = false;

                this.$scope.$emit(EditPersonalInfoCtrl.PullerDetailsSaved, this.$scope.userModel);
            } catch (err) {
                console.log(err);
                if (err.status === 400) {
                    this.$scope.error = true;
                    this.$scope.errorMessage = err.data.error.message;
                }
            }

            this.$scope.loading = false;
        }
    };

    cancel() {
        this.$scope.$emit(EditPersonalInfoCtrl.PullerDetailCanceled);
    }
}

interface IEditPersonalInfoScope extends IBaseScope {
    states : IState[];
    countries : ICountry[];
    loading : boolean;
    error : boolean;
    errorMessage : string;
    userModel : IUser;
    dropdowns : any;
    autoCompleteOptions : any;
}

angular.module('Row52.Views.Account.SellerRegistration.EditPersonalInfo',
        [
            'Row52.Services.UserService',
            'Row52.Services.CountryService',
            'Row52.Services.StateService'
        ])
    .controller('EditPersonalInfoCtrl', EditPersonalInfoCtrl);