/**
 * Created by Ri Scott on 5/30/2017.
 */
import * as angular from 'angular';
import { PartsWantedStatuses } from '../../constants/parts-wanted-statuses';
import { IPartsWantedListing } from 'Row52.Models.Entities';

export class PartsWantedStatusService {
    partsStatus(statusId : number, isSeller : boolean) : string {
        let status = '';

        switch (statusId) {
            case PartsWantedStatuses.Open:
                status = 'Open';
                break;
            case PartsWantedStatuses.Closed:
                status = 'Closed';
                break;
            case PartsWantedStatuses.Expired:
                status = isSeller ? 'Expired' : 'Closed';
                break;
            default:
                status = 'Not-set';
                break;
        }

        return status;
    }

    partsStatusIcon(statusId : number, isSeller : boolean) : string {
        let statusClass = '';

        switch (statusId) {
            case PartsWantedStatuses.Open:
                statusClass = 'active-status';
                break;
            case PartsWantedStatuses.Closed:
                statusClass = 'completed';
                break;
            case PartsWantedStatuses.Expired:
                statusClass = isSeller ? 'inactive' : 'completed';
                break;
            default:
                statusClass = '';
                break;
        }

        return statusClass;
    }

    partsStatusExpirationText(statusId : number, isSeller : boolean) : string {
        let expirationText = '';

        switch (statusId) {
            case PartsWantedStatuses.Open:
                expirationText = 'Expires on';
                break;
            case PartsWantedStatuses.Closed:
                expirationText = 'Closed on';
                break;
            case PartsWantedStatuses.Expired:
                expirationText = isSeller ? 'Expired on' : 'Closed on';
                break;
            default:
                expirationText = '';
                break;
        }

        return expirationText;
    }

    partsStatusExpirationDate(listing : IPartsWantedListing) : Date {
        let expirationDate : Date;

        switch (listing.status) {
            case PartsWantedStatuses.Open:
                expirationDate = listing.expirationDate;
                break;
            case PartsWantedStatuses.Closed:
            case PartsWantedStatuses.Expired:
                expirationDate = listing.lastModificationDate;
                break;
            default:
                expirationDate = new Date();
                break;
        }

        return expirationDate;
    }
}

angular.module('Row52.Services')
       .service('PartsWantedStatusService', PartsWantedStatusService);