import { BaseController, IBaseScope }       from '../../../base-controller';
import { IUser, IStripeMarketplaceAccount } from 'Row52.Models.Entities';
import { IBankAccount }                     from '../../../models/marketplace/stripe-marketplace-account';

export class BasePullerRegistrationController extends BaseController {

    protected $scope : IBasePullerRegistrationScope;

    protected async init() {
        await super.init();

        this.$scope.userModel     = this.$window[ 'userModel' ];
        this.$scope.stripeAccount = this.$window[ 'stripeAccount' ];
    }

    protected calculateRegistrationMode() {
        this.$scope.modes = PullerRegistrationMode;

        if (!this.$scope.user.isMobileActivated) {
            this.$scope.registrationMode = PullerRegistrationMode.VerifyMobile;
            console.log(this.$scope.registrationMode);
            return;
        }

        if (this.isSeller) {
            if (this.$scope.user.locationId) {
                this.$scope.registrationMode = PullerRegistrationMode.UpdateOrganizational;
            }
            else if (this.$scope.stripeAccount) {
                this.$scope.registrationMode = PullerRegistrationMode.Update;
            }
            else {
                this.$scope.registrationMode = PullerRegistrationMode.Register;
            }
        }
        else {
            if (this.$scope.user.locationId) {
                this.$scope.registrationMode = PullerRegistrationMode.RegisterOrganizational;
            }
            else {
                this.$scope.registrationMode = PullerRegistrationMode.Register;
            }
        }
    }
}

export interface IBasePullerRegistrationScope extends IBaseScope {
    userModel : IUser;
    stripeAccount : IStripeMarketplaceAccount;

    bankAccount : IBankAccount;

    registrationMode : PullerRegistrationMode;
    modes : typeof PullerRegistrationMode;
}

export enum PullerRegistrationMode {
    Register               = 1,
    RegisterOrganizational = 2,
    Update                 = 3,
    UpdateOrganizational   = 4,
    VerifyMobile           = 5
}
