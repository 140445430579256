import * as angular                                                  from 'angular';
import { ILocationService }                                          from 'angular';
import * as _                                                        from 'lodash';
import { IPaginationState, IPartsPullerLocation, IPartsPullerUsers } from 'Row52.Models';

import { ILocation, IState } from 'Row52.Models.Entities';
import { MessagesService }   from '../../services/messages.service';
import { PaginationService } from '../../services/ui/pagination.service';
import '../../templates/yards/puller-list.html';

class PartsPullerListCtrl {
    static $inject : string[]     = [ '$scope', '$window', 'MessagesService', 'PaginationService', '$location' ];
    private displayCount : number = 10;
    private allPullers : IPartsPullerUsers[];
    private filteredPullers : IPartsPullerUsers[];

    constructor(private $scope : IPartsPullerListScope,
                private $window : any,
                private messageService : MessagesService,
                private paginationService : PaginationService,
                private $location : ILocationService) {
        this.initSearchData();
        this.initPullers();

        this.$scope.$watch((scope : IPartsPullerListScope) : number => scope.paginationState.currentPage, () => {
            this.goToPage();
        });
    }

    private initSearchData() {
        this.$scope.states    = this.$window.states;
        this.$scope.locations = _.sortBy(this.$window.yards, (item : ILocation) => {
            return item.state.name;
        });

        let locationid = this.$window.searchData.yardId;
        let location = this.$window.searchData.yardId === 0
            ? null
            : $.grep(this.$scope.locations,
                function(obj) {
                    return obj.id === locationid;
                })[0];

        this.$scope.search    = {
            location : location,
            keyword : this.$window.searchData.keyword
        };
    }

    private initPullers() {
        this.allPullers             = this.$window[ 'pullers' ];
        this.$scope.paginationState = this.paginationService.createPaginationState(10);
        let page = parseInt(this.$window.searchData.page, 10);
        this.$scope.paginationState.currentPage = page === 0 ? 1 : page;
        this.paginationService.updatePaginationStateWithTotalItems(this.$scope.paginationState, this.allPullers.length);
        this.performSearch(null);
    }

    goToPage() {
        let paginationFilter = this.paginationService.getFilterFromState(this.$scope.paginationState);
        this.$scope.displayedPullers = _.slice(this.filteredPullers,
            paginationFilter.skip, paginationFilter.skip + paginationFilter.top);
        
    }

    performSearch($event : angular.IAngularEvent) {
        if ($event) {
            $event.preventDefault();
            this.$scope.paginationState.currentPage = 1;
        }

        let qs = this.$location.search();
        
        let paginationFilter = this.paginationService.getFilterFromState(this.$scope.paginationState);

        let matchingPullers = _.filter(this.allPullers, (puller : IPartsPullerUsers) => {
            if (this.$scope.search.location) {
                qs['YardId'] = this.$scope.search.location.id;
                
                return _.some(puller.locations, (location : IPartsPullerLocation) => {
                    return location.locationId === this.$scope.search.location.id;
                });
            }
            else {
                qs['YardId'] = 0;
                return true;
            }
        });

        qs['Keyword'] = this.$scope.search.keyword;
        if (this.$scope.search.keyword) {
            matchingPullers = _.filter(matchingPullers, (puller : IPartsPullerUsers) => {
                return _.includes(puller.userName.toLowerCase(), this.$scope.search.keyword.toLowerCase());
            });
        }
        

        this.$location.search(qs);

        this.$scope.displayedPullers = _.slice(matchingPullers,
            paginationFilter.skip, paginationFilter.skip + paginationFilter.top);
        this.filteredPullers = matchingPullers;
        this.displayCount            = matchingPullers.length;
        this.$scope.pullerCount      = matchingPullers.length;
        this.paginationService.updatePaginationStateWithTotalItems(this.$scope.paginationState, matchingPullers.length);
    }

    changeSort = (sortType : string) => {
        this.displayCount = 10;

        let qs = this.$location.search();
        qs['Sort'] = sortType;
        this.$location.search(qs);

        switch (sortType) {
            case 'mreview':
                this.filteredPullers = _.reverse(_.sortBy(this.filteredPullers, [ 'reviewCount', 'modifieDateTime' ]));
                break;
            case 'hrating':
                this.filteredPullers = _.reverse(_.sortBy(this.filteredPullers, [ 'rating', 'modifieDateTime' ]));
                break;
            case 'llogin':
                this.filteredPullers = _.reverse(_.sortBy(this.filteredPullers, [ 'modifieDateTime', 'reviewCount' ]));
                break;
            case 'msince':
                this.filteredPullers = _.reverse(_.sortBy(this.filteredPullers,
                    [function (o) { return new Date(o.createDateTime); }, function (o) { return new Date(o.modifieDateTime); }]));
                break;
        }

        let paginationFilter = this.paginationService.getFilterFromState(this.$scope.paginationState);
        this.$scope.sort             = sortType;
        this.$scope.displayedPullers = _.slice(this.filteredPullers,
            paginationFilter.skip, paginationFilter.skip + paginationFilter.top);
    };

    sendMessage(user : IPartsPullerUsers, $event : angular.IAngularEvent) {
        $event.preventDefault();
        this.messageService.open(user.userName, '');
    }

    groupLocations = (item : ILocationDropDownModel) : string => {
        return item.state.name;
    };
}

interface ILocationDropDownModel {
    name : string;
    id : number;
    isParticipating : boolean;
    state : { name : string };
}

interface IPartsPullerListScope extends angular.IScope {
    states : IState[];
    locations : ILocation[];
    displayedPullers : IPartsPullerUsers[];
    pullerCount : number;
    sort : string;
    search : {
        location : ILocation;
        keyword : string;
    };
    paginationState : IPaginationState;
}

angular.module('Row52.Views')
       .controller('PartsPullerList', PartsPullerListCtrl);
