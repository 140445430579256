import * as angular         from 'angular';
import { ILocationService } from 'angular';
import { IPaginationState } from 'Row52.Models';
import './pagination.html';
import './pagination.scss';

class PaginationDirective implements angular.IDirective {
    public restrict : string                     = 'E';
    public templateUrl : string                  = 'pagination/pagination.html';
    public scope : { [ key : string ] : string } = {
        'state' : '='
    };

    public link : (scope : IPaginationScope, element : ng.IAugmentedJQuery, attrs : ng.IAttributes) => void;

    constructor(private $location : ILocationService) {
        this.link = (scope : IPaginationScope, element : ng.IAugmentedJQuery, attrs : ng.IAttributes) => {
            scope.localState = {
                localPage : scope.state.currentPage
            };

            scope.$watch((iScope : IPaginationScope) : number => iScope.state.currentPage,
                         () => {
                             scope.localState.localPage = scope.state.currentPage;

                             let qs       = $location.search();
                             qs[ 'Page' ] = scope.state.currentPage;
                             $location.search(qs);

                             this.$location.search();
                         });

            scope.prev = () => {
                --scope.state.currentPage;
                scope.localState.localPage = scope.state.currentPage;
            };

            scope.next = () => {
                ++scope.state.currentPage;
                scope.localState.localPage = scope.state.currentPage;
            };

            scope.updateState = ($event : angular.IAngularEvent) => {
                $event.preventDefault();

                scope.state.currentPage = scope.localState.localPage;
            };
        };
    }

    public static Factory() {
        let directive = ($location : ILocationService) => {
            return new PaginationDirective($location);
        };

        directive[ '$inject' ] = [ '$location' ];

        return directive;
    }
}

interface IPaginationScope extends angular.IScope {
    state : IPaginationState;
    paginationForm : angular.IFormController;
    localPage : number;
    localState : {
        localPage : number;
    }
    prev : () => void;
    next : () => void;
    updateState : ($event : angular.IAngularEvent) => void;
}

angular
    .module('Row52.Directives.Pagination',
            [
                'row52-directives'
            ])
    .directive('pagination', PaginationDirective.Factory());
