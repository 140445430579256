import * as angular                   from 'angular';
import { UserService }                from '../../services/odata/user.service';
import { BaseController, IBaseScope } from '../../base-controller';


class ResetPasswordCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', '$cookies', 'UserService', '$timeout', '$log' ];

    constructor(protected $scope : IResetPasswordScope,
                protected $window : angular.IWindowService,
                private $cookies : angular.cookies.ICookiesService,
                private userService : UserService,
                private $timeout : angular.ITimeoutService,
                private $log : angular.ILogService) {
        super();
        this.init();
    }

    protected async init() {
        super.init();

        this.$scope.error   = false;
        this.$scope.success = false;
        this.$scope.loading = false;
    }

    reset = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        if (this.$scope.resetPasswordForm.$valid) {
            this.$scope.loading = true;
            this.$scope.error   = false;
            this.$scope.success = false;

            try {
                await this.userService
                          .resetPassword(this.$scope.email);
                this.$scope.success = true;
                this.$timeout(() => {
                    this.$window.location.href = '/Account/ResetPassword';
                }, 1500);
            }
            catch (err) {
                this.$scope.error = true;
                this.$log.error(err);
            }
            this.$scope.loading = false;
        }
    };
}

angular.module('Row52.Views.Account')
       .controller('ResetPasswordCtrl', ResetPasswordCtrl);


class ResetPasswordFinishCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', 'UserService', '$timeout', '$log' ];

    constructor(protected $scope : IResetPasswordFinishScope,
                protected $window : angular.IWindowService,
                private userService : UserService,
                private $timeout : angular.ITimeoutService,
                private $log : angular.ILogService) {
        super();

        this.init();
    }

    protected async init() {
        super.init();

        this.$scope.success = false;
        this.$scope.error   = false;
        this.$scope.model   = {
            password         : null,
            confirmPassword  : null,
            verificationCode : this.$window.code
                ? this.$window.code
                : null
        };
    }

    resetPassword = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        if (this.$scope.resetPasswordForm.$valid) {
            this.$scope.loading = true;

            try {
                await this.userService
                          .confirmResetPassword(this.$scope.model.verificationCode,
                                                this.$scope.model.password,
                                                this.$scope.model.confirmPassword);

                this.$scope.success = true;
                this.$timeout(() => {
                    this.$window.location.href = '/Account/Login';
                }, 1500);
            }
            catch (err) {
                this.$log.error(err);
                this.$scope.error = true;
            }

            this.$scope.loading = false;
        }
    };
}

interface IResetPasswordScope extends IBaseScope {
    resetPasswordForm : angular.IFormController;
    loading : boolean;
    error : boolean;
    email : string;
    success : boolean;
}

interface IResetPasswordFinishScope extends IBaseScope {
    resetPasswordForm : angular.IFormController;
    success : boolean;
    error : boolean;
    model : {
        password : string;
        confirmPassword : string;
        verificationCode : string;
    };
    loading : boolean;
}

angular.module('Row52.Views.Account')
       .controller('ResetPasswordFinishCtrl', ResetPasswordFinishCtrl);