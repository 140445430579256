import * as angular from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService } from '../../../../services/odata/user.service';
import { IUser, IState, ICountry } from 'Row52.Models.Entities';
import './address-management.html';
import './address-management.scss';
import * as _ from 'lodash';
import { CountryService } from '../../../../services/odata/country.service';
import { StateService } from '../../../../services/odata/state.service';
import { AddressCompletionService } from '../../../../services/helpers/address.completion.service';

class AddressManagementCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService',
        'CountryService',
        'StateService',
        'AddressCompletionService'
    ];

    public addressForm : angular.IFormController;
    public old_address : IUser;

    constructor(protected $scope : IAddressManagementScope,
        protected $window : angular.IWindowService,
        private userService : UserService,
        private countryService : CountryService,
        private stateService : StateService,
        private addressService : AddressCompletionService) {
        super();

        this.$scope.autoCompleteOptions = {
            types : ['address']
        };

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.userModel = this.$window['userModel'];
        this.$scope.dropdowns = {
            selectedState : null,
            selectedCountry : null
        };
        this.$scope.zipCodePattern = (function(dds) {
            let canadianRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            let usRegex = /^\d{5}(?:[-\s]\d{4})?$/;
            return {
                test : function(value) {
                    if (dds.selectedCountry &&
                        dds.selectedCountry.name === 'United States') {
                        return (value.length > 0) ? usRegex.test(value) : true;
                    } else if (dds.selectedCountry &&
                        dds.selectedCountry.name === 'Canada') {
                        return (value.length > 0) ? canadianRegex.test(value) : true;
                    } else {
                        return true;
                    }
                }
            };
        })(this.$scope.dropdowns);

        await this.initCountries();
        await this.initStates();
        this.old_address = this.$scope.userModel;
        this.$scope.$on(AddressCompletionService.GoogleAutoCompleteSelectEvent,
            this.addressService.createHandler(this.$scope.userModel,
                this.$scope.states,
                this.$scope.countries,
                this.$scope.dropdowns));
    }

    private async initStates() {
        try {
            this.$scope.states = await this.stateService.getAndCache();

            if (this.$scope.user.stateId) {
                this.$scope.dropdowns.selectedState = _.find(this.$scope.states, { 'id' : this.$scope.user.stateId });
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    public verifyStateSelection(item, model) {
        this.addressForm.stateId.$setValidity('StateMatchesCountry',
            this.addressService.stateMatchesCounty(
                this.$scope.dropdowns.selectedState,
                this.$scope.dropdowns.selectedCountry
            )
        );
    }

    private async initCountries() {
        try {
            this.$scope.countries = await this.countryService.getSorted();

            if (this.$scope.user.countryId) {
                this.$scope.dropdowns.selectedCountry =
                    _.find(this.$scope.countries, { 'id' : this.$scope.user.countryId });
            } else {
                this.$scope.dropdowns.selectedCountry = _.find(this.$scope.countries, { 'name' : 'United States' });
            }
        } catch (ex) {

        }
    };

    async updateAddress($event : angular.IAngularEvent) {
        $event.preventDefault();
        this.verifyStateSelection(null, null);
        if (this.addressForm.$valid) {
            this.$scope.loading = true;
            this.$scope.alerts = [];
            this.$scope.user.countryId = this.$scope.dropdowns.selectedCountry.id;
            this.$scope.userModel.countryId = this.$scope.user.countryId;
            if (this.$scope.dropdowns.selectedCountry.hasStates) {
                this.$scope.user.stateId = this.$scope.dropdowns.selectedState.id;
                this.$scope.userModel.stateId = this.$scope.user.stateId;
                this.$scope.userModel.provinceName = null;
                this.$scope.user.provinceName = null;
            } else {
                this.$scope.userModel.stateId = null;
                this.$scope.user.stateId = null;
            }
            
            if (this.$scope.userModel.longitude == null) {
                this.$scope.userModel.longitude = 0;
            } 
            if (this.$scope.userModel.latitude == null) {
                this.$scope.userModel.latitude = 0;
            }

            try {
                await this.userService.updateAddress(this.$scope.userModel);
                this.$scope.alerts.push({
                    msg : 'Your address has been successfully changed.',
                    type : 'success'
                });
            } catch (response) {
                switch (response.status) {
                case 401 :
                    this.$scope.alerts.push({
                        msg : 'You are not authorized to take this action.',
                        type : 'danger'
                    });
                    break;
                case 404 :
                    this.$scope.alerts.push({
                        msg : 'We were unable to locate the user you ' + 'were attempting to modify.',
                        type : 'danger'
                    });
                    break;
                default :
                    this.$scope.alerts.push({
                        msg : response.data.error.message,
                        type : 'danger'
                    });
                    break;
                }
            }

            this.$scope.loading = false;
        }
    }
}

interface IAddressManagementScope extends IBaseScope {
    loading : boolean;
    dropdowns : any;
    alerts : {
        type : 'danger' | 'success';
        msg : string;
    }[]
    userModel : IUser;
    states : IState[];
    countries : ICountry[];
    autoCompleteOptions : any;
    zipCodePattern : any;
}

angular.module('Row52.Views.Account.Settings.AddressManagement', [])
    .controller('AddressManagementCtrl', AddressManagementCtrl);