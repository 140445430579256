import * as angular                                               from 'angular';
import { ILocationModel, ILocationStatistics, IPaginationFilter } from 'Row52.Models';
import { ILocation }                                              from 'Row52.Models.Entities';
import '../../constants/constants';
import { SortDirection }                                          from '../../constants/sort-direction';
import { IODataCountWrapper, ResourceService }                    from './odata.resource';

/**
 * Created by Ri Scott on 5/5/2017.
 */
export class LocationResourceService extends ResourceService<ILocation> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'Locations');
    }

    public getByIdWithState(id : number) : angular.IPromise<ILocation> {
        return this.unwrapSingle(
            this.$http.get(`${ this.domain }/odata/${ this.resourceUrl }(${ id })/?$expand=state`, this.getHeaders())
        );
    }

    public getStatistics(locationId : number) : angular.IPromise<ILocationStatistics> {
        return this.unwrapSingle(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }(${ locationId })/Row52.GetStatistics`,
                     this.getHeaders())
        );
    }

    public getParticipating() : angular.IPromise<ILocation[]> {
        return this.unwrapArray(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }/?$filter=isParticipating eq true`)
        );
    }

    public search(showNonParticipating : boolean,
                  distance : number,
                  postalCode : string,
                  sortBy : string,
                  sortDirection : SortDirection,
                  paginationFilter : IPaginationFilter) : angular.IPromise<IODataCountWrapper<ILocationModel>> {

        let filter : string = 'isPublishable eq true';

        if (!showNonParticipating) {
            filter = filter + ` and isParticipating eq true`;
        }

        let limit : string = '';
        if (paginationFilter) {
            limit = '&$skip=' + paginationFilter.skip + '&$top=' + paginationFilter.top;
        }

        let order = sortDirection === SortDirection.Ascending ? 'asc' : 'desc';

        if (!distance) {
            distance = 0;
        }

        return this.unwrapODataCountArray(
            this.$http
                .get(`${ this.domain }/odata/${ this.resourceUrl }/`
                     + `Row52.Search(postalCode='${ postalCode }', distance=${ distance })`
                     + `?$filter=${ filter }`
                     + `&$orderby=${ sortBy } ${ order }`
                     + `&$count=true`
                     + `${ limit }`,
                     this.getHeaders())
        );
    }
}

angular.module('Row52.Services.LocationService', [ 'ngCookies', 'Row52.Constants' ])
       .service('LocationService', LocationResourceService);
