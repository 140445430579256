import * as angular from 'angular';

angular.module('Row52.Services.GoogleTagManager', [])
       .service('tagManager', [
           () => {
               // @ts-ignore
               return gtag;
           }
       ])
       .service('googleDataLayer', [
           () => {
               // @ts-ignore
               console.log(window.dataLayer);
               // @ts-ignore
               return window.dataLayer;
           }
       ]);