import * as angular                   from 'angular';
import { BaseController, IBaseScope } from '../../../../base-controller';
import './puller-expiration-warning.html';
import './puller-expiration-warning.scss';

interface IPullerExpirationWarningScope extends angular.IScope {
    daysLeft : string;
}

export class PullerExpirationWarningCtrl {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : IPullerExpirationWarningScope,
                protected $window : angular.IWindowService) {

        const currentDate = new Date();
        const endDate = new Date('December 1, 2018 00:00:00');
        $scope.daysLeft = Math.floor((endDate.getTime() - currentDate.getTime()) / (24 * 60 * 60 * 1000)).toString();
    }
}

angular.module('Row52.Views.Account.Summary.PullerExpirationWarning', [])
       .controller('PullerExpirationWarningCtrl', PullerExpirationWarningCtrl);