import * as angular           from 'angular';
import * as uib               from 'angular-ui-bootstrap';
import { IAttachment }        from 'Row52.Models.Entities';
import '../templates/messages/messages.html';
import { AttachmentService }  from './images/attachment.service';
import { MessagesApiService } from './odata/messages.service';
import { RemoteErrorModalService } from '../services/ui/remoteerror.modal';

export class MessagesService {
    static $inject : string[] = [
        '$uibModal',
        '$rootScope'
    ];
    private $modalInstance : any;

    constructor(private $modal : uib.IModalService,
        private $rootScope : angular.IRootScopeService) {

    }

    open(name : string, subject : string) : uib.IModalInstanceService {
        let scope  = this.$rootScope.$new() as IMessageModalScope;
        scope.name = name;
        scope.subject = subject;

        this.$modalInstance = this.$modal
                                  .open({
                                            templateUrl : 'messages/messages.html',
                                            controller  : 'MessageModalCtrl as mctrl',
                                            scope       : scope,
                                            size        : 'md'
                                        });

        return this.$modalInstance;
    }
}

interface IMessageModalScope extends uib.IModalScope {
    name : string;
    files : any;
    body : string;
    subject : string;
    uploadedImgSrc : any;
    uploadImage : (files : FileList) => boolean;
    attachmentId : number;
    attachment : IAttachment;
    uploadingImage : boolean;
    loading : boolean;
    sendingMessageError : string;
}

class MessageModalCtrl {
    public static $inject : string[] = [
        '$scope',
        'MessagesApiService',
        '$uibModalInstance',
        'AttachmentService',
        'remoteErrorModalService' ];

    constructor(private $scope : IMessageModalScope,
                private messageApiService : MessagesApiService,
                private $modalInstance : uib.IModalServiceInstance,
                private attachmentService : AttachmentService,
                private remoteErrorService : RemoteErrorModalService) {

        this.$scope.uploadImage         = this.uploadImage;
        this.$scope.attachmentId        = null;
        this.$scope.loading             = false;
        this.$scope.sendingMessageError = null;
    }

    sendMessage = async ($event : angular.IAngularEvent) => {
        $event.preventDefault();

        this.$scope.loading = true;

        try {
            await this.messageApiService
                      .create({
                                  recipientUserName : this.$scope.name,
                                  body              : this.$scope.body,
                                  subject           : this.$scope.subject,
                                  attachmentId      : this.$scope.attachmentId
                              });
            this.$modalInstance.close();
        }
        catch (err) {
            this.$scope.loading = false;

            switch (err.status) {
                case 401:
                    this.$scope.sendingMessageError = err.data.value && err.data.value !== ''
                        ? err.data.value
                        : 'Error has occured while sending message'
                    break;
                default:
                    this.$scope.sendingMessageError = 'Error has occured while sending message';
                    break;
            }
        }
    };

    uploadImage = (files : FileList) => {
        if (files.length > 1) {
            this.remoteErrorService
                .open('Attention',
                    'You may only upload one image at a time.',
                    { okBtnText: 'OK' });
            return false;
        }

        //Limit file size for 3MB
        if (files[0].size > 3 * 1024 * 1024) {
            this.remoteErrorService
                .open('Attention',
                    'Maximum file size is 3Mb.',
                    { okBtnText: 'OK' });
            return false;
        }

        this.$scope.uploadingImage = true;

        let file = files[ 0 ];
        let data = new FormData();
        data.append('file', file);

        this.attachmentService
            .upload(data)
            .then((attachment : IAttachment) => {
                this.$scope.attachmentId = attachment.id;
                this.$scope.attachment   = attachment;
            }, () => {
                //TODO: add error messaging;
            })
            .finally(() => {
                this.$scope.uploadingImage = false;
            });
    };

    // noinspection JSUnusedGlobalSymbols
    removeImage($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.attachment   = null;
        this.$scope.attachmentId = null;
    }
}

angular.module('Row52.Services.MessageModal', [
           'ui.bootstrap'
       ])
       .controller('MessageModalCtrl', MessageModalCtrl)
       .service('MessagesService', MessagesService);