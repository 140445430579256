import * as angular                                           from 'angular';
import { IPaginationState, IPartsForSaleListingSearchResult } from 'Row52.Models';
import { BaseController, IBaseScope }                         from '../../base-controller';
import { PartsForSaleStatusService }                          from '../../services/data/parts-for-sale-status.service';
import { MessagesService }                                    from '../../services/messages.service';
import { PartsForSaleFavoriteService }                        from '../../services/odata/parts-for-sale-favorite.service';
import { PartsForSaleListingService }                         from '../../services/odata/parts-for-sale-listing.service';
import { PaginationService }                                  from '../../services/ui/pagination.service';

class PartsForSaleListCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window',
        'PartsForSaleListingService',
        'PartsForSaleStatusService',
        'MessagesService',
        'PartsForSaleFavoriteService',
        'PaginationService'
    ];

    constructor(protected $scope : IPartsForSaleListScope,
                protected $window : angular.IWindowService,
                private listingService : PartsForSaleListingService,
                private statusService : PartsForSaleStatusService,
                private messageService : MessagesService,
                private favoritesService : PartsForSaleFavoriteService,
                private paginationService : PaginationService) {
        super();

        // noinspection JSIgnoredPromiseFromCall
        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        this.initEvents();
    }

    private initModels() {
        this.$scope.loading  = true;
        this.$scope.listings = [];
        this.$scope.search   = {
            keyword    : this.$window.searchData.keyword,
            hasImages  : this.$window.searchData.hasImages,
            distance   : this.$window.searchData.distance,
            postalCode : this.$window.searchData.postalCode
        };

        this.$scope.paginationState             = this.paginationService.createPaginationState(10);
        let page                                = parseInt(this.$window.searchData.page, 10);
        this.$scope.paginationState.currentPage = page === 0 ? 1 : page;

        if (!this.$scope.user.isAuthenticated) {
            this.$scope.createBtnText = 'Log In to Sell Parts';
        }
        // provisional seller, can be removed once we no longer have provisional sellers
        // else if (this.isSeller &&
        //         !this.$scope.user.isInstitutionalSeller) {
        //    this.$scope.createBtnText = 'Register to Create Listing';
        // }
        else if (this.isSeller) {
            this.$scope.createBtnText = 'Create Listing';
        }
        else {
            this.$scope.createBtnText = 'Become a Parts Seller';
        }
    }

    private initEvents() {
        this.$scope.$watch((scope : IPartsForSaleListScope) : number => scope.paginationState.currentPage,
                           () => {
                               this.performSearch();
                           });
    }

    performSearch = async () => {
        this.$scope.loading = true;
        try {
            let results = await this.listingService.search(this.$scope.search.keyword,
                                                           this.$scope.search.postalCode,
                                                           this.$scope.search.distance && this.$scope.search.postalCode
                                                           ? this.$scope.search.distance
                                                           : null,
                                                           this.$scope.search.hasImages,
                                                           false,
                                                           this.$scope.paginationState);

            this.$scope.listings = results.value;
            this.$scope.count    = results.count;
            this.$scope.loading  = false;

            this.paginationService.updatePaginationStateWithTotalItems(this.$scope.paginationState, results.count);
        }
        catch (err) {
            if (err.data && err.data.error) {
                let remoteServiceErrorMsg = err.data.error.message;
                if (err.data.error.innererror) {
                    remoteServiceErrorMsg += ' (' + err.data.error.innererror.message + ')';
                }
                this.$scope.remoteServiceError = remoteServiceErrorMsg;
            }
            this.$scope.listings = null;
            this.$scope.count    = 0;
        }

        this.$scope.loading = false;
    };

    search($event : angular.IAngularEvent) {
        $event.preventDefault();
        this.$scope.remoteServiceError          = null;
        this.$scope.paginationState.currentPage = 1;
        this.performSearch();
    }

    sendMessage($event : angular.IAngularEvent, userName : string, partsTitle : string) {
        $event.preventDefault();

        this.messageService.open(userName, partsTitle);
    }

    async like($event : angular.IAngularEvent, listing : IPartsForSaleListingSearchResult) {
        $event.preventDefault();

        try {
            if (!listing.isUserLike) {
                await this.favoritesService.createFavorite(listing.id, this.$scope.user.id);
                listing.isUserLike = true;
            }
            else {
                await this.favoritesService.deleteAllForListing(listing.id, this.$scope.user.id);
                listing.isUserLike = false;
            }
        }
        catch (err) {

        }
    }

    createNewListing($event : angular.IAngularEvent) {
        $event.preventDefault();

        if (!this.$scope.user.isAuthenticated) {
            this.$window.location.href = '/Account/Login/?redirectUrl=/PartsSale/Create';
        }
        // provisional seller, can be removed once provisional sellers no longer exist
        // else if (this.isSeller &&
        //         !this.$scope.user.isInstitutionalSeller) {
        //    this.$window.location.href = '/Account/PartsPuller';
        // }
        else if (this.isSeller || this.$scope.user.isInstitutionalSeller) {
            this.$window.location.href = '/PartsSale/Create';
        }
        else {
            this.$window.location.href = '/Account/PartsPuller';
        }
    }
}

interface IPartsForSaleListScope extends IBaseScope {
    listings : IPartsForSaleListingSearchResult[];
    search : {
        keyword : string
        hasImages : boolean;
        distance : number;
        postalCode : string;
    };
    count : number;
    loading : boolean;
    paginationState : IPaginationState;
    createBtnText : string;
    remoteServiceError : string;
}

angular.module('Row52.Views.PartsSale.List', [])
       .controller('PartsForSaleListCtrl', PartsForSaleListCtrl);
