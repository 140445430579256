import './favorite-widget.html';
import './favorite-widget.scss';

import * as angular                         from 'angular';
import * as _                               from 'lodash';
import { IUserJsonModel, IVehicleFavorite } from 'Row52.Models';
import { VehicleFavoriteService }           from '../../../../services/odata/vehicle-favorite.service';
import { BaseController, IBaseScope }       from '../../../../base-controller';

class FavoriteVehicleWidgetCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', 'VehicleFavoriteService' ];

    constructor(protected $scope : IFavoriteVehicleWidgetScope,
                protected $window : angular.IWindowService,
                private vehicleFavoriteSearch : VehicleFavoriteService) {
        super();
        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.favoritesLoading = true;
        this.$scope.user             = this.$window[ 'user' ];

        await this.loadData();
    }

    private async loadData() {
        this.$scope.favorites        = await this.vehicleFavoriteSearch.getVehiclesForUser(this.$scope.user.id);
        this.$scope.favoritesLoading = false;
    }

    deleteFavorite = async ($event : angular.IAngularEvent, favorite : IVehicleFavorite) => {
        await this.vehicleFavoriteSearch.delete(favorite.userVehicleRelationshipId);
        _.remove(this.$scope.favorites, (f : IVehicleFavorite) => {
            return f.userVehicleRelationshipId === favorite.userVehicleRelationshipId;
        });
    };
}

interface IFavoriteVehicleWidgetScope extends IBaseScope {
    favorites : IVehicleFavorite[];
    favoritesLoading : boolean;
    user : IUserJsonModel;
}

angular.module('Row52.Views.Account.Summary.FavoriteVehicleWidget', [])
       .controller('FavoriteVehicleWidgetCtrl', FavoriteVehicleWidgetCtrl);