import * as angular from 'angular';

import './odata/user.service';
import './odata/state.service';
import './odata/user-location.service';
import './odata/make.service';
import './odata/model.service';
import './odata/country.service';
import './odata/location-service';
import './odata/part-statuses.service';
import './odata/parts-wanted-comments.service';
import './odata/parts-wanted-favorite.service';
import './odata/parts-wanted-listing.service';
import './odata/parts-wanted-quote.service';
import './odata/parts-for-sale-listing.service';
import './odata/keyword-filter.service';
import './odata/puller-reviews.service';
import './odata/parts-sale-comments.service';
import './odata/parts-for-sale-favorite.service';
import './odata/parts-for-sale-payment.service';
import './odata/return-policies.service';
import './odata/vehicle-detail.service';
import './odata/vehicle-favorite.service';
import './odata/vehicle-search.service';
import './marketplace/stripe.service';
import './marketplace/stripe-fee-calculator.service';
import './odata/vehicle-comments.service';
import './odata/messages.service';
import './helpers/address.completion.service';
import './odata/country.service';
import './helpers/feature-tester';
import './images/image.service';
import './messages.service';
import './ui/confirm.modal';
import './ui/confirm-terms.modal';
import './ui/remoteerror.modal';
import './ui/confirmaddress.modal';
import './google-tag.service';
import './odata/listing.service';
import './odata/vehicle.service';

angular.module('Row52.Services',
               [
                   'ui.bootstrap',
                   'toastr',
                   'Row52.Services.UserService',
                   'Row52.Services.LocationService',
                   'Row52.Services.StateService',
                   'Row52.Services.UserLocationRelationshipService',
                   'Row52.Services.MakeService',
                   'Row52.Services.ModelService',
                   'Row52.Services.CountryService',
                   'Row52.Services.PartsStatusesService',
                   'Row52.Services.PartsWantedCommentService',
                   'Row52.Services.PartsWantedFavoriteService',
                   'Row52.Services.PartsWantedListingService',
                   'Row52.Services.PartsWantedQuoteService',
                   'Row52.Services.PartsForSaleListingService',
                   'Row52.Services.KeywordFilterService',
                   'Row52.Services.PullerReviewsService',
                   'Row52.Services.PartsForSaleFavoriteService',
                   'Row52.Services.PartsForSalePaymentService',
                   'Row52.Services.VehicleDetailResourceService',
                   'Row52.Services.VehicleFavoriteService',
                   'Row52.Services.VehicleSearchService',
                   'Row52.Services.StripeWrapper',
                   'Row52.Services.Stripe',
                   'Row52.Services.StripeFeeCalculator',
                   'Row52.Services.ReturnPoliciesService',
                   'Row52.Services.PartsSaleCommentService',
                   'Row52.Services.VehicleCommentService',
                   'Row52.Services.MessagesService',
                   'Row52.Services.AddressCompletion',
                   'Row52.Services.CountryService',
                   'Row52.Services.FeatureTesterService',
                   'Row52.Services.ImageModal',
                   'Row52.Services.MessageModal',
                   'Row52.Services.ConfirmModal',
                   'Row52.Services.ConfirmTermsModal',
                   'Row52.Services.RemoteErrorModal',
                   'Row52.Services.ConfirmAddressModal',
                   'Row52.Services.ListingSqlViewService',
                   'Row52.Services.GoogleTagManager',
                   'Row52.Services.VehicleService'
               ]);

import './ui/vinmodal.service';
import './data/parts-wanted-status.service';
import './data/parts-for-sale-status.service';
import './data/shipping-vendors.service';
import './images/attachment.service';
import './ui/pagination.service';