import * as angular from 'angular';
import { IAddEditQuoteScope } from './outgoing-quote-edit';
import * as moment from 'moment';
import * as _ from 'lodash';
import { IAttachment, IPartsWantedQuote } from 'Row52.Models.Entities';
import { PartsWantedQuoteService } from '../../../../services/odata/parts-wanted-quote.service';

export class QuoteSubmitter {
    public static $inject : string[] = [
        '$uibModal',
        'PartsWantedQuoteService',
        '$q'
    ];

    private $scope : IAddEditQuoteScope;

    constructor(private $modal : angular.ui.bootstrap.IModalService,
        private pwqService : PartsWantedQuoteService,
        private $q : angular.IQService) {
    }

    public async submit($scope : IAddEditQuoteScope) {
        this.$scope = $scope;

        if (this.checkZeroQuoteAmount()) {
            this.$modal
                .open({
                    templateUrl : 'account/quotes/invalid-cost-modal.html'
                });
            this.$scope.loading = false;
            return;
        }

        this.setExpirationDate();
        this.calculateFinances();
        this.setDropDowns();

        try {
            if (this.$scope.quote.id) {
                await this.updateQuote();
            } else {
                await this.createQuote();
            }
        } catch (err) {
            console.log(err);
            this.$scope.loading = false;
        }
    }

    private checkZeroQuoteAmount() : boolean {
        if (this.$scope.quote.partsLaborQuote <= 0) {
            return true;
        }

        if (this.$scope.shippingType === 1 && this.$scope.quote.shippingQuote <= 0) {
            return true;
        }

        if (this.$scope.quote.taxes < 0) {
            return true;
        }

        // The shipping quote amount can equal zero if the shippingType == 0, so this condition makes sure that it's never
        // negative.
        return this.$scope.quote.shippingQuote < 0;
    }

    private setExpirationDate() {
        let expirationDate = moment(this.$scope.quote.expirationDate);
        expirationDate.hour(this.$scope.expirationTime.getHours());
        expirationDate.minute(this.$scope.expirationTime.getMinutes());
        this.$scope.quote.expirationDate = expirationDate.toDate();
    }

    private calculateFinances() {
        if (!this.$scope.quote.taxes) {
            this.$scope.quote.taxes = 0.00;
        }

        this.$scope.quote.totalPrice =
            this.$scope.quote.partsLaborQuote + this.$scope.quote.shippingQuote + this.$scope.quote.taxes;
    }

    private setDropDowns() {
        if (this.$scope.dropdowns.selectedMake) {
            this.$scope.quote.makeId = this.$scope.dropdowns.selectedMake.id;
        } else {
            this.$scope.quote.makeId = null;
        }
        if (this.$scope.dropdowns.selectedModel) {
            this.$scope.quote.modelId = this.$scope.dropdowns.selectedModel.id;
        } else {
            this.$scope.quote.modelId = null;
        }

        if (this.$scope.dropdowns.selectedLocation) {
            this.$scope.quote.locationId = this.$scope.dropdowns.selectedLocation.id;
        } else {
            this.$scope.quote.locationId = null;
        }
    }

    private async updateQuote() {
        try {
            let quote = await this.pwqService.update(this.$scope.quote.id, this.$scope.quote);
            await this.processAttachments(quote);
            if (quote && quote.expirationDate && typeof (quote.expirationDate) === 'string') {
                // convert string representation of date into a date object to avoid validation problem in Angular
                quote.expirationDate = new Date(quote.expirationDate);
            }
            this.$scope.quote = quote;
            this.$scope.loading = false;
            //this.$scope.$broadcast('updatedOutgoingQuote', quote);
            this.$scope.$emit('updatedOutgoingQuote', quote);
        } catch (err) {
            console.log(err);
            this.$scope.loading = false;
        }
    }

    private async createQuote() {
        try {
            let quote = await this.pwqService.create(this.$scope.quote);
            await this.processAttachments(quote);
            this.$scope.quote = quote;
            this.$scope.$emit('newOutgoingQuote', quote);
        } catch (err) {
            this.$scope.$emit('remoteErrorCondition', err);
            this.$scope.loading = false;
        }
    }

    private async processAttachments(quote : IPartsWantedQuote) {
        try {
            let promises : angular.IPromise<any>[] = [];
            _.forEach(this.$scope.attachments,
                (attachment : IAttachment) => {
                    promises.push(this.pwqService.attach(quote, attachment));
                });
            await this.$q.all(promises);
        } catch (err) {

        }
    }
}

angular.module('Row52.Views.Account.Quotes.OutgoingQuoteSubmitter',
        [
            'ui.bootstrap',
            'Row52.Services.PartsWantedQuoteService'
        ])
    .service('QuoteSubmitter', QuoteSubmitter);