import * as angular from 'angular';
import { IPullerReviewModel } from 'Row52.Models';
import { ResourceCrudService } from './odata.resource';

export class PullerReviewsService extends ResourceCrudService<IPullerReviewModel> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'PullerReviews');
    }

    static createEmpty(pullerId, reviewerId) : IPullerReviewModel {
        return {
            pullerId   : pullerId,
            reviewerId : reviewerId,
            review     : null,
            rating     : null
        };
    }
}

angular.module('Row52.Services.PullerReviewsService', [ 'ngCookies' ])
       .service('PullerReviewsService', PullerReviewsService);