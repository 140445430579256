import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import './mobile-display.html';
import './mobile-display.scss';
import { UserService }                from '../../../../services/odata/user.service';
import { ConfirmModalService }        from '../../../../services/ui/confirm.modal';

class MobileDisplayCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService',
        'confirmModalService'
    ];

    public static RemovePhoneSuccess = 'RemovePhoneSuccess';

    constructor(protected $scope : IMobileDisplayScope,
                protected $window : angular.IWindowService,
                private userService : UserService,
                private confirmService : ConfirmModalService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.alerts = [];
    }

    public async removePhone($event) {
        $event.preventDefault();

        try {
            await this.confirmService
                      .open('Remove Phone Number',
                            'Are you sure you want to disassociate this phone number with your account?',
                            { okBtnText : 'Yes', cancelBtnText : 'No' });

            this.$scope.removePhoneLoading = true;

            try {
                await this.userService.deleteMobilePhone(this.$scope.user);
                this.$scope.$emit(MobileDisplayCtrl.RemovePhoneSuccess);

                this.$scope.alerts = [];
                this.$scope.alerts.push({
                                            type : 'success',
                                            msg  : 'Your number has been successfully removed'
                });

                this.$scope.user.mobileStatus = 'new';
                this.$scope.user.mobilePhone = '';
            }
            catch (err) {

            }
            this.$scope.removePhoneLoading = false;
        }
        catch (reject) {

        }
    };
}

interface IMobileDisplayScope extends IBaseScope {
    alerts : {
        type : 'danger' | 'success';
        msg : string;
    }[];
    removePhoneLoading : boolean;
}

angular.module('Row52.Views.Account.MobileRegistration.MobileDisplay', [
           'Row52.Services.UserService',
           'Row52.Services.ConfirmModal'
       ])
       .controller('MobileDisplayCtrl', MobileDisplayCtrl);
