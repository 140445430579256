import * as angular from 'angular';

import './login';
import './register-account';
import './my-listings';
import './summary/module';
import './favorites/module';
import './saved-searches';
import './parts-puller-registration';
import './seller-registration/module';
import './mobile-registration/mobile-registration.module';
import './settings/settings.module';
import './quotes/quotes.module';

angular.module('Row52.Views.Account', [
    'ui.bootstrap',
    'angularMoment',
    'ngCookies',
    'ngAnimate',
    'angularFileUpload',
    'Row52.Views.Account.Login',
    'Row52.Views.Account.RegisterAccount',
    'Row52.Views.Account.MyListings',
    'Row52.Views.Account.Summary',
    'Row52.Views.Account.Favorites',
    'Row52.Views.Account.SavedSearchesCtrl',
    'Row52.Views.Account.SellerRegistration',
    'Row52.Views.Account.MobileRegistration',
    'Row52.Views.Account.Settings',
    'Row52.Views.Account.Quotes'
]);

import './messages';
import './my-listing.widget';
import './resources.widget';
import './reset-password';