import * as angular from 'angular';
import * as uib from 'angular-ui-bootstrap';
import {IConfirmModalService, IConfirmOptions} from './confirm.modal';
import {UserService} from '../odata/user.service';
import {LoginCtrl} from '../../views/account/login';
import '../../templates/partials/accept-terms-modal.html'
import '../../templates/partials/terms.html'
import '../../templates/partials/privacy.html'

export class ConfirmTermsModalService implements IConfirmModalService {
    static $inject = ['$uibModal', 'UserService'];

    constructor(private $uibModal : uib.IModalService) {

    }

    open(title : string, message? : string, options? : IConfirmOptions) : angular.IPromise<any> {
        return this.$uibModal.open({
            windowTopClass: 'terms-modal',
            templateUrl : 'partials/accept-terms-modal.html',
            controller : 'ConfirmTermsModalMessageCtrl',
            resolve : {
                message() {
                    return message;
                },
                title() {
                    return title;
                },
                options() {
                    return options || {};
                }
            }
        }).result;
    }
}

export interface IConfirmModalScope extends angular.IScope {
    message : string;
    title : string;
    selected : { item : string };
    okBtnText : string;
    cancelBtnText : string;
    confirmationMessage : string;
    messagePlace : string;

    open(size : any) : any;

    ok(place : string) : any;

    cancel() : any;

    openSplash(event : any, size : any) : any;
}

export class ConfirmTermsModalMessageCtrl {
    static $inject : string[] = ['$scope', '$window', '$uibModalInstance', 'title', 'message', 'options', 'UserService', '$cookies'];

    constructor($scope : IConfirmModalScope,
                $window,
                $uibModalInstance : uib.IModalServiceInstance,
                title : string,
                message : string,
                options : IConfirmOptions,
                private $userService : UserService,
                private $cookies : angular.cookies.ICookiesService) {

        $scope.message = message;
        $scope.title = 'Row52 Terms of Service and Privacy Policy Update';
        $scope.okBtnText = 'I Agree to the Terms of Service and Privacy Policy';
        $scope.cancelBtnText = options.cancelBtnText || 'Cancel';
        $scope.ok = async (place : string) => {
            $scope.messagePlace = place;
            $scope.confirmationMessage = 'Thank you, you are going to be redirected...';
            let tokenResponse : angular.IHttpResponse<any> = await this.$userService.addTermsClaim('1.0');
            this.$cookies.remove(LoginCtrl.TokenCookieKey);
            setTimeout(null, 1000);
            this.$cookies.put(LoginCtrl.TokenCookieKey, tokenResponse.data.value, {path: '/'});
            setTimeout($window.location.reload(), 1000);
        };

        $scope.cancel = () => {
            $uibModalInstance.dismiss('cancel');
        };
    }
}


angular.module('Row52.Services.ConfirmTermsModal', ['ui.bootstrap'])
    .service('confirmTermsModalService', ConfirmTermsModalService)
    .controller('ConfirmTermsModalMessageCtrl', ConfirmTermsModalMessageCtrl);
