import * as angular from 'angular';
import './settings';
import './delete-account/delete-account';
import './personal-info/personal-info';
import './avatar-management/avatar-management';
import './change-password/change-password';
import './address-management/address-management';

angular.module('Row52.Views.Account.Settings', [
    'Row52.Views.Account.Settings.SettingsCtrl',
    'Row52.Views.Account.Settings.SettingsPersonalInfo',
    'Row52.Views.Account.Settings.AvatarManagement',
    'Row52.Views.Account.Settings.ChangePassword',
    'Row52.Views.Account.Settings.AddressManagement',
    'Row52.Views.Account.Settings.DeleteAccount'
]);