import * as angular from 'angular';
import '../../constants/constants';
import { ResourceCrudService } from './odata.resource';
import { IUserVehicleSearch, IVehicle } from 'Row52.Models.Entities';

/**
 * Created by Ri Scott on 5/5/2017.
 */
export class VehicleSearchService extends ResourceCrudService<IUserVehicleSearch> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'UserVehicleSearches');
    }

    public getSearchesByUser(userId : string) : angular.IPromise<IUserVehicleSearch[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/?$filter=userId eq ${userId}&$orderby=creationDate desc`,
                     this.getHeaders())
        );
    }

    public getVehiclesByYMM(year : number, modelId : number) : angular.IPromise<IVehicle[]> {
        return this.unwrapArray(
            this.$http
            .get(`${this.domain}/odata/${this.resourceUrl}/Vehicles()?$count=true&$filter=year eq ${year} ` +
                `and modelId eq ${modelId} and isActive eq true and isVisible eq true&$expand=location&$select=locationId`,
                this.getHeaders())
        );
    }
}

angular.module('Row52.Services.VehicleSearchService', [ 'ngCookies', 'Row52.Constants' ])
       .service('VehicleSearchService', VehicleSearchService);