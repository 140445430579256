import * as angular from 'angular';
import * as moment from 'moment';
import { IBaseScope, BaseController } from '../../../../base-controller';
import './edit-alerts.html';
import './edit-alerts.scss';
import { UserService } from '../../../../services/odata/user.service';
import {
    IUser
    } from 'Row52.Models.Entities';
import { RegistrationWizardCtrl } from '../registration-wizard/registration-wizard';

export class EditAlertsCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        '$timeout',
        'UserService',
        '$q'
    ];

    public static AlertsSaved = 'SellerRegistrationAlertsSaved';

    public alertUpdateForm : angular.IFormController;

    constructor(protected $scope : IEditPersonalInfoScope,
        protected $window : angular.IWindowService,
        protected $timeout : angular.ITimeoutService,
        private userService : UserService,
        private $q : angular.IQService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.userModel = this.$window['userModel'];
    }

    async updateAlerts($event : angular.IAngularEvent) {

        if (this.alertUpdateForm.$valid) {
            this.$scope.alertSaveLoading = true;
            let that = this;
            try {
                let newModel = JSON.parse(JSON.stringify(this.$scope.userModel));
                newModel.marketPlaceAlert = !newModel.marketPlaceAlert;
                await this.userService.updateMarketplaceAlerts(newModel);

                this.$scope.alertSavedSuccess = true;
                this.$scope.alertSavedError = false;
                this.$timeout(function() { that.$scope.alertSavedSuccess = false; }, 2500);
            } catch (err) {
                $event.preventDefault();
                this.$scope.alertSavedSuccess = false;
                if (err.status === 400) {
                    this.$scope.alertSavedError = true;
                    this.$scope.errorMessage = err.data.error.message;
                    this.$timeout(function () { that.$scope.alertSavedError = false; }, 2500);
                }
            }

            this.$scope.alertSaveLoading = false;
        }
    };
}

interface IEditPersonalInfoScope extends IBaseScope {
    alertSaveLoading : boolean;
    alertSavedSuccess : boolean;
    alertSavedError : boolean;
    errorMessage : string;
    userModel : IUser;
}

angular.module('Row52.Views.Account.SellerRegistration.EditAlertsCtrl',
        [
            'Row52.Services.UserService',
        ])
    .controller('EditAlertsCtrl', EditAlertsCtrl);