const ADDRESS_IGNORE_FIELDS = [ 'isInexactAddress' ];


// TODO : The naming convention here is off - the preceding I indicates that this is an interface, which is not correct.
export class IAddressJsonFilter {
    public filter(obj : any) : any {
        let json = {};

        // TODO : We use lodash instead of the built in Object filter/foreach methods for browser compatability.
        // We need to make sure that these Object methods are properly polyfilled in older browsers.

        Object.getOwnPropertyNames(obj)
              .filter(name => ADDRESS_IGNORE_FIELDS.indexOf(name) < 0)
              .forEach(name => {
                  json[ name ] = obj[ name ];
              });
        return json;
    }
}