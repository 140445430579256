import * as angular from 'angular';
import * as uib from 'angular-ui-bootstrap';
import { IUserVehicleSearch } from 'Row52.Models.Entities';
import { IUserJsonModel } from 'Row52.Models';
import { BaseController, IBaseScope } from '../../base-controller';

class SaveSearchButtonsCtrl extends BaseController {
    public static $inject : string[] = ['$scope', '$uibModal', '$window'];

    constructor(protected $scope : ISaveSearchScope,
        private $modal : uib.IModalService,
        protected $window : angular.IWindowService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.searchData = this.$window['searchData'];
        this.$scope.searchCount = this.$window['searchCount'];
    }

    openModal = ($event : angular.IAngularEvent, type) => {
        if ($event && $event.preventDefault) {
            $event.preventDefault();
        }

        this.$modal.open({
            templateUrl : 'saveSearchModal.html',
            controller : 'SaveSearchModalCtrl'
        });
    };
}

interface ISaveSearchScope extends IBaseScope {
    searchData : ISearchModel;
    searchCount : number;
}

angular.module('Row52.Views')
    .controller('SaveSearchButtonsCtrl', SaveSearchButtonsCtrl);

interface ISaveSearchModalScope extends angular.IScope {
    savedsearch : IUserVehicleSearch;
    type : string;
    phoneVerified : boolean;
    save : ($event : angular.IAngularEvent) => void;
    saving : boolean;
    success : boolean;
    user : IUserJsonModel;
}

class SaveSearchModalCtrl {
    public static $inject : string[] = ['$scope', '$window', '$http', '$uibModalInstance', '$timeout', '$rootScope'];

    constructor(private $scope : ISaveSearchModalScope,
        private $window : any,
        private $http : angular.IHttpService,
        private $modalInstance : uib.IModalServiceInstance,
        private $timeout : angular.ITimeoutService,
        protected $rootScope : angular.IRootScopeService) {

        this.$scope.user = $window['user'];

        if (!this.$scope.type) {
            if (!this.$scope.user.isAuthenticated) {
                this.$scope.type = 'notloggedin';
            } else {
                this.$scope.type = 'detailed';
            }
        }

        this.$scope.phoneVerified = this.$window.phoneVerified;

        let name = this.$window.searchData['name'];

        if (name.length > 30) {
            name = name.substring(0, 30);
        }

        let makeId = this.$window.searchData['makeId'];

        if (makeId === 0) {
            makeId = null;
        }

        let modelId = this.$window.searchData['modelId'];

        if (modelId === 0) {
            modelId = null;
        }

        this.$scope.savedsearch = this.$window.searchData;
        this.$scope.savedsearch.sendEmailAlert = false;
        this.$scope.savedsearch.sendSMSAlert = false;
        this.$scope.savedsearch.makeId = makeId;
        this.$scope.savedsearch.modelId = modelId;

        this.$scope.save = ($event : angular.IAngularEvent) => {
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            }

            this.$scope.saving = true;

            this.$http
                .post('/api/v3/uservehiclesearches/', this.$scope.savedsearch)
                .then(() => {
                        this.$scope.saving = false;
                        this.$scope.success = true;
                        this.$rootScope.$broadcast('savedSearchSaved');
                        this.$timeout(() => {
                                this.$modalInstance.close();
                            },
                            1000);
                    },
                    () => {
                        this.$scope.saving = false;
                    });
        };
    }
}

angular.module('Row52.Views')
    .controller('SaveSearchModalCtrl', SaveSearchModalCtrl);

interface ISearchModel {
    distance : number;
    hasComment : 'False' | 'True';
    hasImage : 'False' | 'True';
    isVin : boolean;
    locationId : number;
    makeId : number;
    modelId : number;
    name : string;
    page : number;
    pageCount : number;
    sort : string;
    sortDirection : 'asc' | 'desc';
    url : string;
    userId : string;
    v1 : string;
    v2 : string;
    v3 : string;
    v4 : string;
    v5 : string;
    v6 : string;
    v7 : string;
    v8 : string;
    v9 : string;
    v10 : string;
    v11 : string;
    v12 : string;
    v13 : string;
    v14 : string;
    v15 : string;
    v16 : string;
    v17 : string;
    year : string;
    zipCode : string;
}