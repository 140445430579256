/**
 * Created by Ri Scott on 6/27/2017.
 */
import * as angular from 'angular';
import { IAttachment } from 'Row52.Models.Entities';
import { TypeScriptResourceFactory } from '../odata/odata.resource';

export class AttachmentService extends TypeScriptResourceFactory {
    public static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                private domain : string) {
        super($http, $q, $cookies);

    }

    public upload(data : FormData) : angular.IPromise<IAttachment> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Attachments/`, data, this.getHeaders())
        );
    }

    protected getHeaders() : angular.IRequestShortcutConfig {
        const authCookieValue = this.$cookies.get('R52ACK');
        let config            = <angular.IRequestShortcutConfig>{};

        config.transformRequest = angular.identity;
        config.headers          = {
            'Content-Type' : undefined
        };

        if (authCookieValue) {
            config.headers[ 'Authorization' ] = 'Spider ' + authCookieValue;
        }

        return config;
    }
}

angular.module('Row52.Services')
       .service('AttachmentService', AttachmentService);