import * as angular                   from 'angular';
import { BaseController, IBaseScope } from '../../../base-controller';
import { DisplayPersonalInfoCtrl }    from './display-personal-info/display-personal-info';
import { EditPersonalInfoCtrl }       from './edit-personal-info/edit-personal-info';

enum PageMode {
    Register            = 1,
    Edit                = 2
}

class SellerRegistrationCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : ISellerRegistrationScope,
                protected $window : angular.IWindowService) {
        super();

        // noinspection JSIgnoredPromiseFromCall
        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.user = Object.assign({}, this.$scope.user, this.$window[ 'userModel' ], { claims : this.$scope.user.claims });

        this.initEvents();
        this.calculatePageMode();
        this.$scope.modes = PageMode;
    }

    private initEvents() {
        this.$scope.$on(DisplayPersonalInfoCtrl.DisplayEdit,
                        () => {
                            this.$scope.editPersonalInfo = true;
                        });

        this.$scope.$on(EditPersonalInfoCtrl.PullerDetailsSaved,
                        () => {
                            this.$window.location.reload();
                        });

        this.$scope.$on(EditPersonalInfoCtrl.PullerDetailCanceled,
                        () => {
                            this.$scope.editPersonalInfo = false;
                        });
    }

    private calculatePageMode() {

        if (this.$scope.user.isSeller) {
                this.$scope.mode = PageMode.Edit;
        }
        else {
            this.$scope.mode = PageMode.Register;
        }
    }
}

interface ISellerRegistrationScope extends IBaseScope {
    mode : PageMode;
    modes : any;
    editPersonalInfo : boolean;
    editBankInfo : boolean;
}

angular.module('Row52.Views.Account.SellerRegistration.SellerRegistrationCtrl', [])
       .controller('SellerRegistrationCtrl', SellerRegistrationCtrl);
