/**
 * Created by Ri Scott on 5/10/2017.
 */
import * as angular                                           from 'angular';
import '../../constants/constants';
import { ResourceCrudService }                                from './odata.resource';
import { IUser, IAttachment, IStripeMarketplaceAccount }      from 'Row52.Models.Entities';
import { IUserPublicModel, IVehicleFavorite, IUserJsonModel } from 'Row52.Models';
import { IPromise }                                           from 'angular';
import * as moment                                            from 'moment';

export class UserService extends ResourceCrudService<IUser> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'Users');
    }

    getUser(userId : string) : IPromise<IUserPublicModel> {
        return this.unwrapSingle(this.$http.get(`${this.domain}/odata/${this.resourceUrl}(${userId})/`,
                                                this.getHeaders()));
    }

    public updateUserData(userId : string, email : string, firstName : string, lastName : string) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${userId})/Row52.UpdateUserData`,
                      {
                          email     : email,
                          firstName : firstName,
                          lastName  : lastName
                      },
                      this.getHeaders())
        );
    }

    public changePassword(userId : string,
                          password : string,
                          newPassword : string,
                          confirmPassword : string) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${userId})/Row52.ChangePassword`,
                      {
                          password        : password,
                          newPassword     : newPassword,
                          confirmPassword : confirmPassword
                      },
                      this.getHeaders())
        );
    }

    public resetPassword(email : string) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users/Row52.ResetPassword`,
                      {
                          email : email
                      },
                      this.getHeaders())
        );
    }

    public confirmResetPassword(verificationCode : string,
                                password : string,
                                confirmPassword : string) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users/Row52.ConfirmResetPassword`,
                      {
                          verificationCode : verificationCode,
                          password         : password,
                          confirmPassword  : confirmPassword
                      },
                      this.getHeaders())
        );
    }

    public updateAddress(user : IUser) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.UpdateAddress`,
                      {
                          address1     : user.address1,
                          address2     : user.address2,
                          city         : user.city,
                          stateId      : user.stateId,
                          provinceName : user.provinceName,
                          countryId    : user.countryId,
                          zipCode      : user.zipCode,
                          latitude     : user.latitude,
                          longitude    : user.longitude
                      },
                      this.getHeaders())
        );
    }

    public updateMobile(user : IUser | IUserJsonModel) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.UpdateMobileNumber`,
                      {
                          mobileNumber : user.mobilePhone
                      },
                      this.getHeaders())
        );
    }

    public verifyMobile(user : IUser | IUserJsonModel, activationCode : string) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.VerifyMobileNumber`,
                      {
                          activationCode : activationCode
                      },
                      this.getHeaders())
        );
    }

    public resendMobileVerificationCode(user : IUser | IUserJsonModel) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.ResendMobileVerificationCode`, {}, this.getHeaders())
        );
    }

    public deleteMobilePhone(user : IUser | IUserJsonModel) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.DeleteMobilePhone`, {}, this.getHeaders())
        );
    }
    
    public switchIsSeller(user: IUser | IUserJsonModel) {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.RegisterSeller`,
                    {},
                    this.getHeaders())
        );
    }

    public registerPuller(user : IUser | IUserJsonModel,
                          stripeAccount : IStripeMarketplaceAccount,
                          stateId : number,
                          countryId : number,
                          ssnToken : string,
                          bankAccountToken : string,
                          bio : string,
                          marketplaceAlert : boolean,
                          isTaxable : boolean) : angular.IHttpPromise<any> {

        let dob = moment(stripeAccount.dateOfBirth);

        let data = {
            firstName        : stripeAccount.firstName,
            lastName         : stripeAccount.lastName,
            address1         : stripeAccount.address1,
            city             : stripeAccount.city,
            postalCode       : stripeAccount.postalCode,
            stateId          : stateId,
            countryId        : countryId,
            ssnToken         : ssnToken,
            dateOfBirth      : dob.toDate(),
            bankAccountToken : bankAccountToken,
            businessName     : stripeAccount.businessName,
            businessTaxId    : stripeAccount.businessTaxId,
            address2         : stripeAccount.address2,
            latitude         : stripeAccount.latitude,
            longitude        : stripeAccount.longitude,
            businessType     : stripeAccount.type,
            bio              : bio,
            marketPlaceAlert : marketplaceAlert,
            isTaxable        : isTaxable
        };

        if (stripeAccount.businessName) {
            data.businessName = stripeAccount.businessName;
        }

        if (stripeAccount.address2) {
            data.address2 = stripeAccount.address2;
        }

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.RegisterPuller`,
                      data,
                      this.getHeaders())
        );
    }

    public updateMarketplaceAlerts(user : IUser) : angular.IHttpPromise<any> {
        let data = {};
        data     = {
            marketPlaceAlert : !user.marketPlaceAlert,
            isSeller         : user.isSeller
        };

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.UpdateMarketplaceAlerts`,
                      data,
                      this.getHeaders())
        );
    }

    public updatePullerDetails(user : IUser,
                               stateId : number,
                               countryId : number) : angular.IHttpPromise<any> {
        let data = {};

        data = {
          firstName: user.firstName,
          lastName: user.lastName,
          bio: user.bio,
          marketPlaceAlert: user.marketPlaceAlert,
          isSeller: user.isSeller,
          address1: user.address1,
          address2: user.address2,
          city: user.city,
          stateId: stateId,
          postalCode: user.zipCode,
          countryId: countryId,
          latitude: user.latitude,
          longitude: user.longitude
        };

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.UpdatePullerDetails`,
                      data,
                      this.getHeaders())
        );
    }

    public updateBankAccount(user : IUser,
                             bankToken : string,
                             ssnToken : string) : angular.IHttpPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.UpdateBankAccount`,
                      {
                          bankToken : bankToken,
                          ssnToken  : ssnToken
                      },
                      this.getHeaders())
        );
    }

    public getVehiclesForUser(userId : string) : angular.IPromise<IVehicleFavorite[]> {
        return this.unwrapArray(
            this.$http
                .get(`${this.domain}/odata/Users(${userId})/Row52.GetAllVehicleFavorites`,
                     this.getHeaders())
        );
    }

    crop(user : IUser | IUserJsonModel, attachment : IAttachment, coords : number[]) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.Crop`,
                      {
                          x1 : coords[ 0 ],
                          y1 : coords[ 1 ],
                          x2 : coords[ 2 ],
                          y2 : coords[ 3 ]
                      },
                      this.getHeaders())
        );
    }

    avatar(user : IUser | IUserJsonModel, attachment : IAttachment) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.Avatar`,
                      {
                          attachmentId : attachment.id
                      },
                      this.getHeaders())
        );
    }

    deleteUser(user : IUser | IUserJsonModel) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/Users(${user.id})/Row52.DeleteAccount`, {}, this.getHeaders())
        );
    }
    
    addTermsClaim(version : string) : any {
        return this.$http
                .post(`${this.domain}/odata/Users/Row52.AddTermClaim`, { version: version }, this.getHeaders())
    };
}

angular.module('Row52.Services.UserService', [ 'ngCookies', 'Row52.Constants' ])
       .service('UserService', UserService);