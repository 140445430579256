import * as angular                             from 'angular';
import { BaseController, IBaseScope }           from '../../../../base-controller';
import './profile-widget.html';
import './profile-widget.scss';
import { ILocation, IUserLocationRelationship } from 'Row52.Models.Entities';

export class ProfileWidgetCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window'
    ];

    constructor(protected $scope : IProfileWidgetScope,
                protected $window : angular.IWindowService) {
        super();

        this.init();
        this.$scope.user.avatar;
    }

    protected async init() {
        await super.init();

        if (this.$window[ 'userLocation' ]) {
            this.$scope.userLocation = this.$window[ 'userLocation' ];
        }

        if (this.$window[ 'pullerLocations' ]) {
            this.$scope.pullerLocations = this.$window[ 'pullerLocations' ];
        }
        else {
            this.$scope.pullerLocations = [];
        }
    }
}

export interface IProfileWidgetScope extends IBaseScope {
    userLocation : ILocation;
    pullerLocations : IUserLocationRelationship[];
}

angular.module('Row52.Views.Account.Summary.ProfileWidget', [ 'Row52.Directives' ])
       .controller('ProfileWidgetCtrl', ProfileWidgetCtrl);