/**
 * Created by Ri Scott on 6/27/2017.
 */
import * as angular from 'angular';
import { IPartsSaleCommentCreationModel, IPartsSaleCommentModel } from 'Row52.Models';
import '../../constants/constants';
import { ResourceService } from './odata.resource';

export class PartsSaleCommentService extends ResourceService<IPartsSaleCommentModel> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'PartsSaleComments');
    }

    public createComment(listingId : number,
                         body : string,
                         parentId? : number,
                         attachmentId? : number) : angular.IPromise<IPartsSaleCommentModel> {

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/PartsSaleComments/`,
                      <IPartsSaleCommentCreationModel>{
                          parentId           : parentId,
                          attachmentId       : attachmentId,
                          body               : body,
                          partsSaleListingId : listingId
                      },
                      this.getHeaders())
        );
    }

    public getForListing(listingId : number) : angular.IPromise<IPartsSaleCommentModel[]> {
        let url = `${this.domain}/odata/PartsSaleComments/?`
                  + `$filter=partsSaleListingId eq ${listingId}`
                  + `&$orderby=creationDate desc`;
        return this.unwrapArray(
            this.$http
                .get(url, this.getHeaders())
        );
    }
}

angular.module('Row52.Services.PartsSaleCommentService', [ 'ngCookies', 'Row52.Constants' ])
       .service('PartsSaleCommentService', PartsSaleCommentService);