import * as angular                                                    from 'angular';
import * as moment                                                     from 'moment';
import * as _                                                          from 'lodash';
import {
    IPaginationFilter, IPaginationState, IPartsForSaleListingSearchResult, IShippingRate
}                                                                      from 'Row52.Models';
import { IAttachment, IPartsSaleListing, IShippingValidationResponse } from 'Row52.Models.Entities';
import { PartsForSaleStatuses }                                        from '../../constants/parts-for-sale-statuses';
import { PaginationService }                                           from '../ui/pagination.service';
import { IODataCountWrapper, ResourceCrudService }                     from './odata.resource';
import { IStripeChargeFailure }                                        from '../marketplace/stripe.service';
import { KeywordFilterService }                                        from './keyword-filter.service';
import { ILocationService }                                            from 'angular';

export class PartsForSaleListingService extends ResourceCrudService<IPartsSaleListing> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain', 'PaginationService', 'KeywordFilterService', '$location' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string,
                private paginationService : PaginationService,
                private keywordFilterService : KeywordFilterService,
                private $location : ILocationService) {
        super($http, $q, $cookies, domain, 'PartsForSaleListings');
    }

    search(keyword : string,
           postalCode : string,
           distance : number,
           hasImages : boolean,
           includeSold : boolean,
           paginationState : IPaginationState) : angular.IPromise<IODataCountWrapper<IPartsForSaleListingSearchResult>> {
        let filter : string    = 'isPublish eq true';
        let columns : string[] = [ 'partsTitle', 'additionalInformation', 'makeName', 'modelName' ];

        let qs = this.$location.search();

        if (keyword) {
            filter += this.keywordFilterService.getKeywordFilter(keyword, columns, 'PFS');
        }
        qs['Keyword'] = keyword;

        if (hasImages) {
            filter = filter + ` and hasImage eq true`;
        }
        qs['HasImages'] = hasImages.toString();

        if (postalCode === '') {
            postalCode = null;
        }
        qs['PostalCode'] = postalCode;

        if (!distance) {
            distance = 0;
        }
        qs['Distance'] = distance;

        let limit : string = '';
        if (paginationState) {
            let filter : IPaginationFilter = this.paginationService.getFilterFromState(paginationState);
            limit                          = '&$skip=' + filter.skip + '&$top=' + filter.top;
        }

        if (!includeSold) {
            filter = filter + ` and status eq 1`;
        }

        this.$location.search(qs);

        return this.unwrapODataCountArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/` +
                     `Row52.Search(postalCode='${postalCode}', distance=${distance})` +
                     `?$filter=${filter}` +
                     `&$orderby=creationDate desc` +
                     `&$count=true` +
                     `${limit}`,
                     this.getHeaders())
        );
    }

    getSoldForSeller(userId : string, top? : number) : angular.IPromise<IODataCountWrapper<IPartsForSaleListingSearchResult>> {
        let filter : string = `userId eq ${userId} and (status eq 2)`;

        let $top : string = '';
        if (top) {
            $top = `&$top=${top}`;
        }

        return this.unwrapODataCountArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/` +
                     `Row52.Search(postalCode=null, distance=0)` +
                     `?$filter=${filter}` +
                     `&$orderby=creationDate desc` +
                     `&$count=true` +
                     `${$top}`,
                     this.getHeaders())
        );
    }

    getActiveForSeller(userId : string, top? : number) : angular.IPromise<IODataCountWrapper<IPartsForSaleListingSearchResult>> {
        let filter : string = `userId eq ${userId} and status eq 1`;

        let $top : string = '';
        if (top) {
            $top = `&$top=${top}`;
        }

        return this.unwrapODataCountArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/` +
                     `Row52.Search(postalCode=null, distance=0)` +
                     `?$filter=${filter}` +
                     `&$orderby=creationDate desc` +
                     `&$count=true` +
                     `${$top}`,
                     this.getHeaders())
        );
    }

    getAllForSeller(userId : string, top? : number) : angular.IPromise<IODataCountWrapper<IPartsForSaleListingSearchResult>> {
        let filter : string = `userId eq ${userId}`;

        let $top : string = '';
        if (top) {
            $top = `&$top=${top}`;
        }

        return this.unwrapODataCountArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/` +
                     `Row52.Search(postalCode=null, distance=0)` +
                     `?$filter=${filter}` +
                     `&$orderby=creationDate desc` +
                     `&$count=true` +
                     `${$top}`,
                     this.getHeaders())
        );
    }

    getAllForBuyer(userId : string,
                   top? : number) : angular.IPromise<IODataCountWrapper<IPartsForSaleListingSearchResult>> {
        let filter : string = `buyerId eq ${userId}`;

        let topClause = '99999';
        if (top) {
            topClause = `${top}`;
        }

        return this.unwrapODataCountArray(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}/` +
                     `Row52.Search(postalCode=null, distance=0)` +
                     `?$filter=${filter}` +
                     `&$orderby=creationDate desc` +
                     `&$top=${topClause}` +
                     `&$count=true`,
                     this.getHeaders())
        );
    }

    publish(listing : IPartsSaleListing) : angular.IPromise<IPartsSaleListing> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.Publish`, {}, this.getHeaders())
        );
    }

    cancel(listing : IPartsSaleListing) : angular.IPromise<IPartsSaleListing> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.Cancel`, {}, this.getHeaders())
        );
    }

    attach(listing : IPartsSaleListing, attachment : IAttachment) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.Attach`,
                      { attachmentId : attachment.id },
                      this.getHeaders())
        );
    }

    unattach(listing : IPartsSaleListing, imageId : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.Unattach`,
                      { imageId : imageId },
                      this.getHeaders())
        );
    }

    refund(listing : IPartsSaleListing) : angular.IPromise<IPartsSaleListing | IStripeChargeFailure> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.Refund`, {}, this.getHeaders())
        );
    }

    calculateTax(listing : IPartsSaleListing,
                 toCountry : string,
                 toState : string,
                 toZip : string,
                 toCity : string,
                 shipping : number) : angular.IPromise<any> {
        return this.unwrapSingle(
            this.$http
                .get(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.CalculateTaxes(` +
                     `toCountry='${toCountry}', toState='${toState}', toZip='${toZip}', toCity='${toCity}',` +
                     `shipping=${shipping})`,
                     this.getHeaders())
        );
    }

    getShippingRates(listing : IPartsSaleListing,
                     street1 : string,
                     street2 : string,
                     city : string,
                     state : string,
                     zipCode : string,
                     country : string,
                     firstName : string,
                     lastName : string,
                     isResidential : boolean) : angular.IPromise<IShippingRate[]> {

        return this.unwrapArray(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.GetShippingRates`,
                      {
                          street1       : street1,
                          street2       : street2,
                          city          : city,
                          state         : state,
                          zipCode       : zipCode,
                          country       : country,
                          firstName     : firstName,
                          lastName      : lastName,
                          isResidential : isResidential
                      },
                      this.getHeaders())
        );
    }

    verifyShippingRates(scope : any) : angular.IPromise<IShippingValidationResponse> {
        let listing = scope.listing;

        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.VerifyShippingParameters`,
                      {
                          street1       : listing.address1,
                          street2       : listing.address2,
                          city          : listing.city,
                          state         : scope.dropdowns.selectedState.name,
                          zipCode       : listing.zipCode,
                          country       : scope.dropdowns.selectedCountry.name,
                          isResidential : listing.isResidential,
                          weight        : listing.weight,
                          height        : listing.height,
                          width         : listing.width,
                          length        : listing.length
                      },
                      this.getHeaders()));
    }

    submitShipment(listing : IPartsSaleListing) : angular.IPromise<IPartsSaleListing> {
        return this.unwrapSingle(
            this.$http
                .post(`${this.domain}/odata/${this.resourceUrl}(${listing.id})/Row52.SubmitShipment`,
                      {
                          trackingNumber      : listing.trackingNumber,
                          shippingVendorId    : listing.shippingVendorId,
                          shippingVendorOther : listing.shippingVendorOther
                      },
                      this.getHeaders())
        );
    }

    sort(listings : IPartsSaleListing[] | IPartsForSaleListingSearchResult[], by : string) {
        switch (by) {
            case 'status' : {
                return _.chain(listings)
                        .orderBy(listings['creationDate'], 'desc')
                        .sortBy((listing : IPartsSaleListing | IPartsForSaleListingSearchResult) => {
                            switch (listing.status) {
                                case PartsForSaleStatuses.Completed :
                                    return 1;
                                case PartsForSaleStatuses.Sold :
                                    return 2;
                                default :
                                    return 3;
                            }
                        })
                        .value();
            }
            default :
                return listings;
        }
    }

    static createEmpty(userId : string) : IPartsSaleListing {
        return {
            id                    : 0,
            partsTitle            : null,
            additionalInformation : null,
            price                 : 0,
            shippingOfferTypeId   : null,
            shippingOfferType     : null,
            shippingPrice         : 0,
            hasImage              : false,
            isActive              : false,
            status                : PartsForSaleStatuses.Available,
            isPublish             : false,
            yearFrom              : null,
            yearTo                : null,
            makeId                : null,
            modelId               : null,
            model                 : null,
            vehicleId             : null,
            vehicle               : null,
            address1              : null,
            address2              : null,
            city                  : null,
            state                 : null,
            stateId               : null,
            provinceName          : null,
            countryId             : null,
            country               : null,
            zipCode               : null,
            latitude              : null,
            longitude             : null,
            isResidential         : false,
            userId                : userId,
            user                  : null,
            creationDate          : moment()
                .toDate(),
            lastModificationDate  : moment()
                .toDate(),
            images                : [],
            comments              : [],
            shippingFirstName     : null,
            shippingLastName      : null,
            toEmailAddress        : null,
            toAddress1            : null,
            toAddress2            : null,
            toCity                : null,
            toState               : null,
            toStateId             : null,
            toProvinceName        : null,
            toZipCode             : null,
            toCountryId           : null,
            toCountry             : null,
            toLatitude            : null,
            toLongitude           : null,
            toIsResidential       : false,
            weight                : 0,
            length                : null,
            height                : null,
            width                 : null,
            shipmentId            : null,
            rateId                : null,
            shippingCarrier       : null,
            shippingServiceName   : null,
            trackingNumber        : null,
            shippingVendorId      : null,
            shippingVendorOther   : null,
            fees                  : null,
            stripeFees            : null,
            taxes                 : null,
            soldDate              : null,
            shippedDate           : null,
            refundDate            : null,
            buyerId               : null,
            buyer                 : null,
            shippingNotes         : null,
            billingFirstName      : null,
            billingLastName       : null,
            billingEmailAddress   : null,
            billingAddress1       : null,
            billingAddress2       : null,
            billingCity           : null,
            billingState          : null,
            billingStateId        : null,
            billingProvinceName   : null,
            billingZipCode        : null,
            billingCountryId      : null,
            billingCountry        : null,
            billingLatitude       : null,
            billingLongitude      : null,
            location              : null,
            locationId            : null,
            shippingServiceId     : null,
            shippingServiceOther  : null,
            estimatedShippingTime : null,
            transactionId         : null,
            canceledDate          : null,
            isInexactAddress      : true
        };
    }
}

angular.module('Row52.Services.PartsForSaleListingService', [ 'ngCookies' ])
       .service('PartsForSaleListingService', PartsForSaleListingService);
