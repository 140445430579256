import * as angular from 'angular';
import './form-commit';
import './pagination/pagination';

angular
    .module('Row52.Directives', [
        'Row52.Directives.CommitForm',
        'Row52.Directives.Pagination'
    ]);

import './filemodel';
import './fileuploader';
import './next-focus';
import './google-places-autocomplete.js';
import './fine-uploader';
import './currency';
import './prevent-out-of-range';
import './larger-than';
import './smaller-than';
import './telephone-filter';
import './starts-with-filter';