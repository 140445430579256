import * as angular from 'angular';
import { IQuoteModel } from 'Row52.Models';
import { IApplication } from 'Row52.Models.Entities';
import { PartWantedQutoteStatuses } from '../../constants/statuses';
import * as _ from 'lodash';

export class StripeFeeCalculatorService {
    public static $inject : string[] = ['$window'];
    public row52Application : IApplication;

    constructor(private $window : any) {
        this.row52Application = this.$window.row52Application;
    }

    calculate(quote : IQuoteModel) : number {
        if (quote) {
            let cost = 0;

            if (quote.partsLaborQuote) {
                cost = cost + quote.partsLaborQuote;
            }

            if (quote.shippingQuote) {
                cost = cost + quote.shippingQuote;
            }

            if (quote.taxes) {
                cost = cost + quote.taxes;
            }

            /* 6/4/18 - A. Beverly: for quotes that are not Active,
             return the fees that were previously calculated and stored */
            if (quote.status > 0 && quote.status !== PartWantedQutoteStatuses.Active) {
                return quote.fees + quote.stripeFees;
            }

            if (cost === 0) {
                return 0;
            }

            let sellerFees = _.round(cost * this.row52Application.marketplaceFeePercentage, 2);

            if (sellerFees < this.row52Application.marketplaceFeeMinimum) {
                sellerFees = this.row52Application.marketplaceFeeMinimum;
            }

            let creditCardFees = _.round(cost * this.row52Application.stripeFeePercentage, 2) +
                this.row52Application.stripeFlatFee;

            return sellerFees + creditCardFees;
        }

        return 0;
    }
}

angular.module('Row52.Services.StripeFeeCalculator', [])
    .service('StripeFeeCalculator', StripeFeeCalculatorService);