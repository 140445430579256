import * as angular from 'angular';
import '../../constants/constants';
import { ResourceService } from './odata.resource';
import { IPartsStatus } from 'Row52.Models.Entities';

/**
 * Created by Ri Scott on 5/31/2017.
 */
export class PartsStatusesService extends ResourceService<IPartsStatus> {
    static $inject : string[] = [ '$http', '$q', '$cookies', 'domain' ];

    constructor($http : angular.IHttpService,
                $q : angular.IQService,
                $cookies : angular.cookies.ICookiesService,
                domain : string) {
        super($http, $q, $cookies, domain, 'PartsStatuses');
    }
}

angular.module('Row52.Services.PartsStatusesService', [ 'ngCookies', 'Row52.Constants' ])
       .service('PartsStatusesService', PartsStatusesService);