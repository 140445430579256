import * as angular from 'angular';
import { BaseController } from '../../base-controller';
import { PullerReviewsService } from '../../services/odata/puller-reviews.service';
import { IUserJsonModel, IPullerReviewModel } from 'Row52.Models';

interface IUserReviewScope extends angular.IScope {
    user : IUserJsonModel;
    profileUser : IUserJsonModel;
    review : IPullerReviewModel;
    userReviewForm : angular.IFormController;
    wasSuccessful : boolean;
    hadError : boolean;
    errorMessage : string;
    model : any;
}

class UserReviewCtrl extends BaseController {
    // noinspection JSUnusedGlobalSymbols
    static $inject : string[] = [
        '$scope',
        '$http',
        '$window',
        '$timeout',
        'PullerReviewsService'
    ];

    constructor(protected $scope : IUserReviewScope,
                private $http : angular.IHttpService,
                protected $window : angular.IWindowService,
                private $timeout : angular.ITimeoutService,
                private pullerReviewsService : PullerReviewsService) {
        super();

        this.$scope.user        = this.$window.user;
        this.$scope.profileUser = this.$window.profileUser;

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.review = PullerReviewsService.createEmpty(this.$scope.profileUser.id, this.$scope.user.id);
    }

    submit = async ($event : angular.IAngularEvent) => {
        if (!this.$scope.userReviewForm.$valid) {
            $event.preventDefault();

            let errors = [];

            for (let key in this.$scope.userReviewForm.$error) {
                for (let index = 0; index < this.$scope.userReviewForm.$error[ key ].length; index++) {
                    errors.push(this.$scope.userReviewForm.$error[ key ][ index ].$name + ' is invalid.');
                }
            }

            if (errors.length > 0) {
                this.$scope.wasSuccessful = false;
                this.$scope.hadError      = true;
                this.$scope.errorMessage  = errors[ 0 ];
            }
            return;
        }

        if (this.$scope.userReviewForm.rating.$pristine) {
            return;
        }

        try {
            let ok                    = await this.pullerReviewsService.create(this.$scope.review);
            this.$scope.wasSuccessful = true;
            this.$window.location.reload();
            this.$timeout(() => {
                this.$scope.wasSuccessful = false;
            }, 500);
        }
        catch (err) {
            this.$scope.hadError     = true;
            this.$scope.errorMessage = 'Error!';
        }

        this.$scope.hadError      = false;
        this.$scope.wasSuccessful = true;
    };
}

angular.module('Row52.Views')
       .controller('UserReviewCtrl', UserReviewCtrl);