import * as angular                    from 'angular';
import { BaseController }              from '../../base-controller';
import { IUserJsonModel, IQuoteModel } from 'Row52.Models';
import { IPartsWantedListing }         from 'Row52.Models.Entities';
import { PartsWantedQuoteService }     from '../../services/odata/parts-wanted-quote.service';
import '../../templates/partswantedlisting/incoming-quote-list.html';

export interface IIncomingQuoteSingleListingScope extends angular.IScope {
    user : IUserJsonModel;
    listing : IPartsWantedListing;
    quotes : IQuoteModel[];
    loading : boolean;
}

class IncomingQuoteSingleListingCtrl extends BaseController implements angular.IController {
    static $inject : string[] = [ '$scope', '$window', 'PartsWantedQuoteService' ];

    constructor(protected $scope : IIncomingQuoteSingleListingScope,
                protected $window : angular.IWindowService,
                protected quoteService : PartsWantedQuoteService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.$scope.listing = this.$window[ 'listing' ];

        await this.loadIncomingQuotes();
    }

    protected async loadIncomingQuotes() {
        this.$scope.loading = true;
        try {
            this.$scope.quotes = await this.quoteService.getActiveQuotesForListing(this.$scope.listing);
        }
        catch (err) {

        }
        finally {
            this.$scope.loading = false;
        }
    }

    openQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$window.location.href = `/Account/MyQuotes/?qId=${quote.id}`;
    }
}

angular.module('Row52.Views')
       .controller('IncomingQuoteSingleListingCtrl', IncomingQuoteSingleListingCtrl);