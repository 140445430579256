import * as angular from 'angular';
import { IVehiclesGallery } from 'Row52.Models';
import * as moment from 'moment';

interface IVehicleGalleryScope extends angular.IScope {
    gallery : any;
    thumbnailPath : ((vehicle : IVehiclesGallery) => string)
    getTooltip : (vehcile : IVehiclesGallery) => string;
}

class VehicleGalleryCtrl {
    static $inject : string[] = [ '$scope' ];

    constructor(private $scope : IVehicleGalleryScope) {
        this.$scope.thumbnailPath = (vehicle : IVehiclesGallery) : string => {
            if (vehicle.thumbNail) {
                return vehicle.thumbNail;
            }
            else {
                return 'https://cdn.row52.com/cdn/images/imagecomingsoon_150.png';
            }
        };

        this.$scope.getTooltip = (vehicle : IVehiclesGallery) : string => {
            let date = moment(vehicle.dateAdded)
                .format('MMM D, YYYY');
            return `<strong>${vehicle.locationName}</strong><br />ADDED: ${date}`;
        };
    }

    init(model) {
        this.$scope.gallery = model;
        console.log(this.$scope.gallery);
    }
}

angular.module('Row52.Views')
       .controller('VehicleGalleryCtrl', VehicleGalleryCtrl);