import * as angular from 'angular';

interface ISmallerThanScope extends angular.IScope {
    smallerThan : number;
}

class SmallerThanDirective implements angular.IDirective {
    public restrict : string                   = 'A';
    public scope : { [key : string] : string } = {
        'smallerThan' : '='
    };
    public require : string                    = 'ngModel';

    public link : (scope : ISmallerThanScope,
                   element : ng.IAugmentedJQuery,
                   attrs : ng.IAttributes,
                   ctrl : ng.INgModelController) => void;

    constructor() {
        this.link = (scope : ISmallerThanScope,
                     element : ng.IAugmentedJQuery,
                     attrs : ng.IAttributes,
                     ctrl : ng.INgModelController) => {
            ctrl.$validators.smallerThan = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue) || !scope.smallerThan) {
                    // consider empty models to be valid
                    return true;
                }
                return scope.smallerThan >= viewValue;
            }
        };
    }

    public static Factory() {
        let directive = () => {
            return new SmallerThanDirective();
        };

        return directive;
    }
}

angular
    .module('Row52.Directives')
    .directive('smallerThan', SmallerThanDirective.Factory());