import * as angular                                 from 'angular';
import * as uib                                     from 'angular-ui-bootstrap';
import { IVehicleDetail }                           from 'Row52.Models';
import { ILocation }                                from 'Row52.Models.Entities';
import { VehicleDetailResourceService }             from '../../services/odata/vehicle-detail.service';
import { VehicleFavoriteService }                   from '../../services/odata/vehicle-favorite.service';
import '../../templates/yards/vehicle-list.html';
import { IVehicleListScope, VehicleListController } from '../search/vehicle-list';
import { IYardDetailScope }                         from './yard.detail';

interface IYardVehicleListScope extends IVehicleListScope {
    yard : ILocation;
    vehicles : IVehicleDetail[];
    thumbnailPath : (vehicle : IVehicleDetail) => string;
    $parent : IYardDetailScope;
}

class YardVehicleListCtrl extends VehicleListController {
    static $inject : string[] = [
        '$scope',
        '$window',
        '$http',
        'VehicleDetailResourceService',
        '$uibModal',
        '$rootScope',
        'VehicleFavoriteService'
    ];

    constructor(public $scope : IYardVehicleListScope,
                $window : any,
                $http : angular.IHttpService,
                private vehicleDetailService : VehicleDetailResourceService,
                $modal : uib.IModalService,
                $rootScope : angular.IRootScopeService,
                vehicleFavoriteService : VehicleFavoriteService) {
        super($scope, $http, $modal, $window, $rootScope, vehicleFavoriteService);

        this.$scope.yard = this.$scope.$parent.yard;
        this.loadVehicles();

        this.$scope.thumbnailPath = (vehicle : IVehicleDetail) : string => {
            if (vehicle.smallImage) {
                return vehicle.smallImage;
            }
            else {
                return 'https://cdn.row52.com/cdn/images/imagecomingsoon_150.png';
            }
        };
    }

    loadVehicles = async () => {
        this.$scope.vehicles = await this.vehicleDetailService.getMostRecentByYard(this.$scope.yard.id, 20);
    };

    showVinOrNotAvailable(content) {
        if (!content || content.length === 0) {
            return '';
        }
        else if (content.startsWith('RND')) {
            return 'Not Available';
        }
        else {
            return content;
        }
    }

    isRnd(content) {
        if (!content || content.length === 0) {
            return false;
        }
        else {
            return !!content.startsWith('RND');
        }
    }
}

angular.module('Row52.Views')
       .controller('YardVehicleListCtrl', YardVehicleListCtrl);
