import './mobile-verification.html';
import './mobile-verification.scss';
import * as angular                   from 'angular';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { UserService }                from '../../../../services/odata/user.service';

export class MobileVerificationCtrl extends BaseController {
    public static $inject : string[] = [
        '$scope',
        '$window',
        'UserService'
    ];

    public static MobileVerificationSuccess = 'MobileVerificationSuccess';

    constructor(protected $scope : IMobileVerificationCtrlScope,
                protected $window : angular.IWindowService,
                private userService : UserService) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.$scope.verifyMobilePhoneLoading = false;
        this.$scope.activationCode = null;
        this.$scope.alerts = [];

        if (this.$scope.user.mobileStatus === 'confirm'
            || this.$scope.user.mobileStatus === 'activated') {
            this.$scope.phone = MobileVerificationCtrl.formatPhone(this.$scope.user.mobilePhone);
        }
    }

    public async verifyCode($event : angular.IAngularEvent) {
        $event.preventDefault();

        let isCodeValid = MobileVerificationCtrl.validateCode(this.$scope.activationCode);

        if (this.$scope.verifyMobilePhoneForm.$valid && isCodeValid) {
            this.$scope.verifyMobilePhoneLoading = true;

            try {
                await this.userService.verifyMobile(this.$scope.user, this.$scope.activationCode);
                this.$scope.alerts.push({
                                            type : 'success',
                                            msg  : 'Congratulations! You have verified your phone.'
                                        });

                this.$scope.$emit(MobileVerificationCtrl.MobileVerificationSuccess);
            }
            catch (err) {
                switch (err.status) {
                    case 500:
                        this.$scope.alerts.push({
                                                    type : 'danger',
                                                    msg  : 'Oops! There was an unanticipated server error '
                                                           + 'on our side. Give it a whirl again!'
                                                });
                        break;
                    default:
                        this.$scope.alerts.push({
                                                    type : 'danger',
                                                    msg  : 'Sorry, but the code entered was incorrect.'
                                                });
                        break;
                }
            }

            this.$scope.verifyMobilePhoneLoading = false;
        }
    };

    public async resendCode($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.resendCodeLoading = true;

        try {
            await this.userService.resendMobileVerificationCode(this.$scope.user);
            this.$scope.alerts.push({ type : 'success', msg : 'The verification code has been resent to your phone' });
        }
        catch (err) {
            this.$scope.alerts.push({
                                        type : 'danger',
                                        msg  : 'Sorry, there was an error.'
                                    });
        }

        this.$scope.resendCodeLoading = false;
    };

    public async changePhoneNumber($event : angular.IAngularEvent) {
        $event.preventDefault();
        
        this.$scope.user.mobileStatus = 'new';
    };

    private static formatPhone(phoneNumber : string) {
        let country = '+1';
        let start   = phoneNumber.substring(1, 4);
        let mid     = phoneNumber.substring(4, 7);
        let end     = phoneNumber.substring(7, 11);

        return ( country + ' (' + start + ') ' + mid + '-' + end );
    }

    private static validateCode(code) : boolean {
        return new RegExp(/^\d{4}$/g).test(code);
    }
}

interface IMobileVerificationCtrlScope extends IBaseScope {
    verifyMobilePhoneForm : angular.IFormController;
    verifyMobilePhoneLoading : boolean;
    resendCodeLoading : boolean;
    activationCode : string;
    alerts : {
        type : 'danger' | 'success';
        msg : string;
    }[]
    phone : string;
}

angular.module('Row52.Views.Account.MobileRegistration.MobileVerification', [
           'Row52.Services.UserService'
       ])
       .controller('MobileVerificationCtrl', MobileVerificationCtrl);