import * as angular                    from 'angular';
import * as _                          from 'lodash';
import { IQuoteModel, IUserJsonModel } from 'Row52.Models';
import { QuoteEvents }                 from '../../../constants/events';
import { PartWantedQutoteStatuses }    from '../../../constants/statuses';
import { PartsWantedQuoteService }     from '../../../services/odata/parts-wanted-quote.service';
import { BaseController, IBaseScope }  from '../../../base-controller';
import { QuotesTabs }                  from '../../../constants/quotes-tabs';
import './quote-status-dates.html';
import './quote-status.html';

class MyQuotesCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', 'PartsWantedQuoteService', '$q' ];

    constructor(protected $scope : IMyQuotesScope,
                protected $window : angular.IWindowService,
                private pwqService : PartsWantedQuoteService,
                private $q : angular.IQService) {
        super();


        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();

        this.$scope.quoteStatuses = PartWantedQutoteStatuses;

        await this.$q.all([ this.loadOutgoingQuotes(), this.loadIncomingQuotes() ]);

        this.initEvents();

        if (this.$scope.incomingQuotes.length <= 0) {
            this.$scope.activeTab = QuotesTabs.OutgoingQuotes;
        }
    };

    private initModels() {
        this.$scope.activeTab     = QuotesTabs.IncomingQuotes;
        this.$scope.quoteId       = this.$window[ 'quoteId' ];
        this.$scope.partsWantedId = this.$window[ 'partsWantedListingId' ];

        if (this.$scope.partsWantedId || this.isSeller) {
            this.$scope.activeTab = QuotesTabs.OutgoingQuotes;
        }
    }

    private initEvents() {
        this.$scope.$on('newOutgoingQuote', this.loadOutgoingQuotes);
        this.$scope.$on(QuoteEvents.UpdatedOutgoingQuote, this.loadOutgoingQuotes);
        this.$scope.$on(QuoteEvents.UpdatedIncomingQuote, this.loadIncomingQuotes);
    }

    private loadOutgoingQuotes = async () => {
        try {
            this.$scope.outgoingQuotes = await this.pwqService.getOutgoingQuotes(this.$scope.user);
            this.$scope.$broadcast(QuoteEvents.LoadOutgoingQuotes, this.$scope.outgoingQuotes, this.$scope.quoteId);
            this.$scope.activeOutgoingQuotes = _.filter(this.$scope.outgoingQuotes,
                                                        (quote : IQuoteModel) => {
                                                            return quote.status === 1 || quote.status === 3;
                                                        }).length;

            if (this.$scope.quoteId) {
                let activeQuote = _.find(this.$scope.outgoingQuotes,
                                         (q) => {
                                             return q.id === this.$scope.quoteId;
                                         });

                if (activeQuote) {
                    this.$scope.activeTab = QuotesTabs.OutgoingQuotes;
                    this.$scope.quoteId   = null;
                    this.$scope.$emit(QuoteEvents.UpdateActiveQuote, activeQuote);
                }
            }
        }
        catch (err) {

        }
    };

    private loadIncomingQuotes = async () => {
        try {
            this.$scope.incomingQuotes = await this.pwqService.getIncomingQuotes(this.$scope.user);

            this.$scope.$broadcast(QuoteEvents.LoadIncomingQuotes, this.$scope.incomingQuotes, this.$scope.quoteId);
            this.$scope.activeIncomingQuotes = _.filter(this.$scope.incomingQuotes,
                                                        (quote : IQuoteModel) => {
                                                            return quote.status === PartWantedQutoteStatuses.Active;
                                                        }).length;

            if (this.$scope.quoteId) {
                let activeQuote = _.find(this.$scope.incomingQuotes,
                                         (q) => {
                                             return q.id === this.$scope.quoteId;
                                         });

                if (activeQuote) {
                    this.$scope.activeTab = QuotesTabs.IncomingQuotes;
                    this.$scope.quoteId   = null;
                    this.$scope.$emit(QuoteEvents.UpdateActiveQuote, activeQuote);
                }
            }
        }
        catch (err) {

        }
    };
}

interface IMyQuotesScope extends IBaseScope {
    quoteId : number;
    partsWantedId : number;
    activeTab : number;
    incomingQuotes : IQuoteModel[];
    outgoingQuotes : IQuoteModel[];
    activeIncomingQuotes : number;
    activeOutgoingQuotes : number;
    user : IUserJsonModel;
    quoteStatuses : PartWantedQutoteStatuses;
    quotesTabs : QuotesTabs;
}

angular.module('Row52.Views.Account.Quotes.QuoteCtrl', [])
       .controller('MyQuotesCtrl', MyQuotesCtrl);
