import * as angular                         from 'angular';
import * as _                               from 'lodash';
import { ILocationModel, IPaginationState } from 'Row52.Models';

import { ILocation } from 'Row52.Models.Entities';
import { SortDirection } from '../../constants/sort-direction';
import { LocationResourceService } from '../../services/odata/location-service';
import { PaginationService } from '../../services/ui/pagination.service';
import '../../templates/yards/ParticipantYard.html';
import '../../templates/yards/NonParticipantYard.html';
import { IBaseScope, BaseController } from '../../base-controller';
import { ILocationService } from 'angular';

interface ISelfServiceYardScope extends angular.IScope {
    result : any;
    showInfo : any;
    map : any;
    mapLocation : any;
    participantYardToolTip : string;
    nonParticipantYardToolTip : string;
    participatingFilter : any;

    search : {
        showNonParticipating : boolean;
        sortBy : string;
        sortDirection : SortDirection;
    }
    loading : boolean;
    paginationState : IPaginationState;
    count : number;
    yards : ILocationModel[];

    dropdownLocations : ILocationModel[];
    dropdownLocation : ILocationModel;

    SortDirection : SortDirection;
}

class SelfServiceYardCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        'NgMap',
        '$window',
        'LocationService',
        'PaginationService',
        '$location'
    ];

    constructor(protected $scope : ISelfServiceYardScope,
                private ngMap,
                protected $window : angular.IWindowService,
                private yardsService : LocationResourceService,
                private paginationService : PaginationService,
                private $location : ILocationService) {

        super();

        this.init();
    }

    protected async init() {
        await super.init();
        this.initModels();
        this.initMap();
        this.initEvents();
    }

    private initModels() {
        let qs = this.$location.search();
        qs['Sort'];
        qs['SortDirection'];

        this.$scope.search                    = {
            showNonParticipating : this.$window.searchData.showNonParticipating === 'True',
            sortBy               : qs['Sort'] === undefined ? 'name' : qs['Sort'],
            sortDirection        : qs['SortDirection'] === undefined ? SortDirection.Ascending : qs['SortDirection']
        };
        this.$scope.loading                   = false;
        this.$scope.SortDirection             = SortDirection;
        this.$scope.participantYardToolTip    = 'These yards list their vehicle inventory on Row52! '
                                                + 'You can search, view pictures, set-up alerts and more.';
        this.$scope.nonParticipantYardToolTip = 'These yards have not yet listed their inventory on Row52. '
                                                + 'Let them know you want to see what vehicles are in their yard!';
        this.$scope.dropdownLocation          = null;

        this.$scope.dropdownLocations = _.orderBy(this.$window.yards, ['state', 'name'], ['asc', 'asc']);

        this.$scope.participatingFilter = (yard) => {
            if (this.$scope.search.showNonParticipating) {
                return true;
            }
            return yard.isParticipating;
        };
        
        this.$scope.paginationState = this.paginationService.createPaginationState(50);
        let page = parseInt(this.$window.searchData.page, 10);
        this.$scope.paginationState.currentPage = page === 0 ? 1 : page;
    }

    private initMap() {
        this.ngMap.getMap('map_canvas')
            .then((map) => {
                this.$scope.map = map;
            });

        let scope = this.$scope;
        this.$scope.showInfo = function (event, loc) {
            scope.mapLocation = loc;
            scope.map.showInfoWindow('yardInfoWindow', this);
        };
    }

    private initEvents() {
        this.$scope.$watch((scope : ISelfServiceYardScope) : number => scope.paginationState.currentPage, () => {
            this.performSearch();
        });
    }

    goToYard(location) {
        this.$window.location.href = '/Yards/Detail/' + location.locationId;
    }

    performSearch = async () => {
        this.$scope.loading = true;

        let qs = this.$location.search();
        qs['ShowNonParticipating'] = this.$scope.search.showNonParticipating.toString();
        this.$location.search(qs);

        let result          = await this.yardsService
                                        .search(this.$scope.search.showNonParticipating,
                                                null,
                                                null,
                                                this.$scope.search.sortBy,
                                                this.$scope.search.sortDirection,
                                                this.paginationService.getFilterFromState(this.$scope.paginationState));
        this.$scope.yards   = result.value;
        this.$scope.count   = result.count;
        this.$scope.loading = false;

        this.paginationService.updatePaginationStateWithTotalItems(this.$scope.paginationState, result.count);
    };

    search($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.paginationState.currentPage = 1;
        this.performSearch();
    }

    // noinspection JSUnusedGlobalSymbols
    dropdownGroupLocations = (item : ILocationModel) : string => {
        return item.state;
    };

    // noinspection JSUnusedGlobalSymbols
    sortBy(sortBy) {
        if (this.$scope.search.sortBy === sortBy) {
            this.$scope.search.sortDirection =
                this.$scope.search.sortDirection === SortDirection.Ascending
                    ? SortDirection.Descending
                    : SortDirection.Ascending;
        }
        else {
            this.$scope.search.sortDirection = SortDirection.Ascending;
        }
        this.$scope.search.sortBy = sortBy;
        
        this.performSearch();

        let qs = this.$location.search();
        qs['Sort'] = this.$scope.search.sortBy;
        qs['SortDirection'] = this.$scope.search.sortDirection
        this.$location.search(qs);
    }
}

interface ISelfServiceYardScope extends IBaseScope {
    result : any;
    showInfo : any;
    map : any;
    mapLocation : any;
    participantYardToolTip : string;
    nonParticipantYardToolTip : string;
    participatingFilter : any;

    search : {
        showNonParticipating : boolean;
        sortBy : string;
        sortDirection : SortDirection;
    }
    loading : boolean;
    paginationState : IPaginationState;
    count : number;
    yards : ILocationModel[];

    dropdownLocations : ILocationModel[];
    dropdownLocation : ILocationModel;

    SortDirection : SortDirection;
}

angular.module('Row52.Views')
       .controller('SelfServiceYardCtrl', SelfServiceYardCtrl);