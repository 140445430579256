import * as angular from 'angular';
import * as uib from 'angular-ui-bootstrap';
import {
    IQuoteModel,
    IQuoteUserModel,
    IQuoteImageModel
    } from 'Row52.Models';
import { PartWantedQutoteStatuses } from '../../../../constants/statuses';
import { PartsWantedStatuses } from '../../../../constants/parts-wanted-statuses';
import { ShippingVendorsService, IShippingVendor } from '../../../../services/data/shipping-vendors.service';
import { StripeFeeCalculatorService } from '../../../../services/marketplace/stripe-fee-calculator.service';
import { PartsWantedQuoteService } from '../../../../services/odata/parts-wanted-quote.service';
import { MessagesService } from '../../../../services/messages.service';
import { ConfirmModalService } from '../../../../services/ui/confirm.modal';
import { ImageServiceModal } from '../../../../services/images/image.service';
import { QuoteEvents } from '../../../../constants/events';
import './outgoing-quote-detail.html';
import './outgoing-quote-detail.scss';
import { IBaseScope, BaseController } from '../../../../base-controller';
import { EstimateShippingCostsModalService } from '../estimate-shipping-costs/estimate-shipping-costs';
import { IOutgoingQuoteListScope } from '../outgoing-quote-list/outgoing-quote-list';
import '../../../../templates/partials/sales-tax-tooltip.html';

class OutgoingQuoteDetailCtrl extends BaseController {
    static $inject : string[] = [
        '$scope',
        '$window',
        '$rootScope',
        '$timeout',
        'PartsWantedQuoteService',
        'ShippingVendorsService',
        '$uibModal',
        'EstimateShippingCostsModalService',
        'StripeFeeCalculator',
        'MessagesService',
        'confirmModalService',
        'ImageServiceModal'
    ];

    constructor(protected $scope : IQuoteDetailScope,
        protected $window : angular.IWindowService,
        private $rootScope : angular.IRootScopeService,
        private $timeout : angular.ITimeoutService,
        private pwqService : PartsWantedQuoteService,
        private shippingVendorsService : ShippingVendorsService,
        private $uibModal : uib.IModalService,
        private estimateShippingCostsModalService : EstimateShippingCostsModalService,
        public feeCalculator : StripeFeeCalculatorService,
        private messages : MessagesService,
        private confirmModalService : ConfirmModalService,
        protected imageModalService : ImageServiceModal) {
        super();

        this.init();
    }

    protected async init() {
        await super.init();

        this.initModels();
        this.initEvents();
    }

    private initModels() {
        this.$scope.quote = null;
        this.$scope.quoteStatuses = PartWantedQutoteStatuses;
        this.$scope.listingStatuses = PartsWantedStatuses;
        this.$scope.shippingVendors = this.shippingVendorsService.getAll();

        this.$scope.completeQuote = {
            trackingNumber : null,
            shippingVendorId : null,
            shippingVendorOther : null
        };

        this.$scope.completeMode = false;
        this.$scope.completeLoading = false;
        this.$scope.loading = false;

        let parentScope = this.$scope.$parent as IOutgoingQuoteListScope;
        if (parentScope.outgoingQuote) {
            this.$scope.quote;
        }
    }

    private initEvents() {
        this.$scope.$on(QuoteEvents.UpdateActiveQuote,
            ($event : angular.IAngularEvent, quote : IQuoteModel) => {
                this.$scope.completeMode = false;
                this.$scope.quote = quote;
                let that = this;
                this.$timeout(function() {
                        that.$rootScope.$broadcast('toggleEditMode', quote);
                    },
                    500);
            });
    }

    cloneQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$scope.$emit('cloneQuote', quote);
    }

    openVinModal(vin) {
        //this.myQuotesService.openVinModal(vin);
    }

    createNewQuote(listing) {
        //this.$window.location.href = '/account/myquotes/?edit=true&tab=1&pwId=' + pwId;
        let quote = this.pwqService.createEmpty();
        quote.partsWantedListingId = listing.id;
        quote.partsWantedListing = listing;
        quote.sellerUserId = this.$scope.user.id;
        this.$scope.$emit('editQuote', quote);
    }

    openLink($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$window.location.href = `/partswanted/detail/${this.$scope.quote.listing.id}`;
    }

    editQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();
        this.$scope.$emit('editQuote', quote);
    };

    async withdrawQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$scope.loading = true;

        try {
            let updatedQuote = await this.pwqService.withdraw(quote);
            this.$scope.$emit('updatedOutgoingQuote', updatedQuote);
        } catch (err) {

        }

        this.$scope.loading = false;
    };

    displayCompleteMode($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.completeMode = true;
    }

    hideCompleteMode($event : angular.IAngularEvent) {
        $event.preventDefault();

        this.$scope.completeMode = false;
    }

    getShippingVendor() {
        const id = this.$scope.quote.shippingVendorId;
        if (id && id !== 99) {
            return this.shippingVendorsService.get(id);
        } else if (id === 99) {
            return this.$scope.quote.shippingVendorOther;
        } else {
            return 'N/A';
        }
    }

    async completeQuote($event : angular.IAngularEvent, quote : IQuoteModel) {
        $event.preventDefault();

        this.$scope.completeLoading = true;

        if (this.$scope.completeForm.$valid) {
            try {
                let updatedQuote = await this.pwqService.completeQuote(quote);
                this.$scope.$emit('updatedOutgoingQuote', updatedQuote);
                this.$scope.completeMode = false;
            } catch (err) {

            }
        }

        this.$scope.completeLoading = false;
    };

    async refundQuote($event : angular.IAngularEvent, quote : IQuoteModel) {

        try {
            await this.confirmModalService
                .open('Are you sure?',
                    'Are you sure that you want to issue a full refund for this quote?',
                    {
                        okBtnText : 'Yes',
                        cancelBtnText : 'Cancel'
                    });
            this.$scope.loading = true;
            await this.pwqService.refund(quote);
            this.$scope.$emit('updatedOutgoingQuote', quote);
        } catch (err) {

        }
        this.$scope.loading = false;
    };

    openMessage($event : angular.IAngularEvent, buyer : IQuoteUserModel, subject : string) {
        $event.preventDefault();

        this.messages.open(buyer.userName, subject);
    }

    displayImage($event : angular.IAngularEvent, image : IQuoteImageModel) {
        $event.preventDefault();

        let url = image.resourceUrl + image.size1 + image.extension;
        this.imageModalService.open(url);
    }
}

interface IQuoteDetailScope extends IBaseScope {
    quote : IQuoteModel;
    quoteStatuses : PartWantedQutoteStatuses;
    completeForm : angular.IFormController;
    listingStatuses : PartsWantedStatuses;
    completeQuote : {
        trackingNumber : string;
        shippingVendorId : number;
        shippingVendorOther : string;
    }
    shippingVendors : IShippingVendor[];
    completeMode : boolean;
    completeLoading : boolean;
    loading : boolean;
    refundLoading : boolean;
}

angular.module('Row52.Views.Account.Quotes.OutgoingQuoteDetail',
        [
            'Row52.Services.PartsWantedQuoteService',
            'Row52.Views.Account.Quotes.EstimateShippingCosts',
            'Row52.Services.StripeFeeCalculator',
            'Row52.Services.MessageModal',
            'Row52.Services.ConfirmModal',
            'Row52.Services.ImageModal'
        ])
    .controller('OutgoingQuoteDetailCtrl', OutgoingQuoteDetailCtrl);