import * as angular                   from 'angular';
import { IUserJsonModel }             from 'Row52.Models';
import { BaseController, IBaseScope } from '../../base-controller';
import * as uib from 'angular-ui-bootstrap';
import {ConfirmTermsModalService} from '../../services/ui/confirm-terms.modal';

class HomeCtrl extends BaseController {
    static $inject : string[] = [ '$scope', '$window', '$uibModal', 'confirmTermsModalService' ];

    constructor(protected $scope : IHomeScope,
                protected $window : any,
                private $uibModal : uib.IModalService,
                private confirmTermsModalService : ConfirmTermsModalService) {
        super();
        
        this.init();
    }

    openPolicyModal(isSigned : boolean | number) : void {
        
        if (isSigned !== 0 && !isSigned) {
            this.confirmTermsModalService
                .open('',
                    '',
                    {
                        okBtnText: '',
                        cancelBtnText: '',
                        replyMessageText: ''
                    });
        }
    }
}

interface IHomeScope extends IBaseScope {
    user : IUserJsonModel;
}

angular.module('Row52.Views')
       .controller('HomeCtrl', HomeCtrl);